.internal_banner{
    &__container{
        display: flex;
        padding-top: clampFluid(60);
        .left{
            width: 42.2%;
            padding-top: clampFluid(49);
            h1{
                @include font-100-5;
                line-height: clampFluid(110.55);
                span{
                    font-family: "Inter", sans-serif;
                    font-weight: 700;
                    font-size: clampFluid(193.52);
                    line-height: clampFluid(193.52);
                    color: $green;
                    max-width: clampFluid(641);
                    width: 100%;
                    display: block;
                    word-break: break-all;
                    position: absolute;
                    right: clampFluid(100);
                    top: clampFluid(109);
                    @include _1279 {
                        top: auto;
                        bottom: 22px;
                        right: 50%;
                        translate: 50% 0;
                        font-size: clampFluid(90,245.52,320,1279);
                        line-height: clampFluid(81,245.52,320,1279);
                        max-width: 815px;
                    }
                }
                @include _1279 {
                    font-size: clampFluid(30,58,320,1279);
                    line-height: clampFluid(33,58,320,1279);
                }
            }
            .text{
                @include font-21;
                line-height: clampFluid(27.3);
                color: $text;
                margin-top: clampFluid(34);
                max-width: 79%;
                @include _1279 {
                    max-width: 100%;
                }
            }
            @include _1279 {
                width: 100%;
                padding-top: clampFluid(0,32,320,1279);
            }
        }
        .right{
            img{
                max-width: clampFluid(463.74);
                max-height: clampFluid(618);
                object-fit: contain;
                position: relative;
                z-index: 1;
                @include _1279 {
                    margin-left: auto;
                    // margin-right: auto;
                    margin-top: 24px;
                    max-width: clampFluid(180,463.74,320,1279);
                    max-height: clampFluid(240,618,320,1279);
                }
            }
        }
        @include _1279 {
            flex-direction: column;
        }
    }
    .container_1920{
        position: relative;
        border-radius: 0 0 clampFluid(60) clampFluid(60);
        overflow: hidden;
    }
    .background_line{
        z-index: -1;
    }
}