.seo_block{
    padding-top: clampFluid(57);
    position: relative;
    .background_line {
        z-index: -1;
    }
    &.brb {
        border-radius: 0 0 clampFluid(60) clampFluid(60);
        overflow: hidden;
    }
    &__container{
        display: flex;
        justify-content: space-between;
        &.reverse {
            flex-direction: row-reverse;
        }
        @include _1279{
            flex-direction: column !important;
        }
    }
    &__left{
        width: 46%;
        padding-bottom: clampFluid(60);
        margin-top: auto;
        margin-bottom: auto;
        .btn {
            margin-top: clampFluid(67);
        }
        @include _1279{
            width: 100%;
        }
    }
    &__right{
        width: 49%;
        display: flex;
        align-items: end;
        justify-content: flex-end;
        img{
            width: 100%;
            // height: 100%;
            height: auto;
            max-height: 985.62px;
            object-fit: contain;
            @include _1279{
                max-height: 1014px;
            }
        }
        @include _1279{
            width: 100%;
            justify-content: center;
            margin-top: -20px;
            width: 100vw;
            margin-left: -56px;
            margin-right: -56px;
        }
        @include _1023{
            margin-left: clampFluid(-20,-56,320,1023);
            margin-right: clampFluid(-20,-56,320,1023);
            margin-top: clampFluid(-12,-20,320,1023);
        }
    }
    &__hashtag{
        margin-left: clampFluid(63);
        @include _1279{
            margin-left: 25px;
        }
        @include _1023{
            margin-left: clampFluid(1,25,320,1023);
        }
    }
    &__title{
        @include font-100-5;
        line-height: clampFluid(100.5);
        &.font-60 {
            @include font-60;
        }
        &.font-80 {
            @include font-80;
        }
        margin-left: clampFluid(3);
        margin-bottom: clampFluid(44);
        span{
            font-style: italic
        }
        @include _1279{
            margin-top: 2px;
            margin-left: 0;
            margin-bottom: 19px;
        }
        @include _1023{
            font-size: clampFluid(30,58,320,1023);
            line-height: clampFluid(30,58,320,1023);
            margin-top: 3px;
            margin-left: 2px;
            margin-bottom: clampFluid(13,19,320,1023);
        }
    }
    &__text{
        @include font-21;
        line-height: clampFluid(27.3);
        margin-left: 2px;
        h3 {
            @include font-36;
            margin-bottom: clampFluid(42);
        }
        p, ul, ol{
            margin-bottom: clampFluid(19);
            @include _1279{
                margin-bottom: 20px;
            }
            @include _1023{
                margin-bottom: clampFluid(12,20,320,1023);
            }
        }
        p {
            span {
                color: $green;
            }
        }
        ul{
            padding-left: clampFluid(42);
            margin-top: clampFluid(-8);
            li{
                position: relative;
                list-style-type: disc;
                &::marker{
                    color: $green;
                }
                p {
                    margin-bottom: 0;
                }
                &:not(:last-child){
                    margin-bottom: clampFluid(11);
                    @include _1279{
                        margin-bottom: 12px;
                    }
                    @include _1023{
                        margin-bottom: clampFluid(9,12,320,1023);
                    }
                }
            }
            @include _1279{
                padding-left: 48px;
            }
            @include _1023{
                padding-left: clampFluid(28,48,320,1023);
            }
        }
        ul, ol {
            margin-top: clampFluid(33);
            li {
                &:not(:last-child) {
                    margin-bottom: clampFluid(29);
                }
                span {
                    @include font-21;
                    color: $text;
                    margin-top: clampFluid(10);
                }
            }
        }
        @include _1279{
            margin-left: 0;
            line-height: 31.2px;
        }
        @include _1023{
            font-size: clampFluid(14,24,320,1023);
            line-height: clampFluid(17,31.2,320,1023);
            margin-left: 2px;
        }
    }
    @include _1279{
        padding-top: 67px;
    }
    @include _1023{
        padding-top: clampFluid(30,67,320,1023);
    }
}