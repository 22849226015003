.work_scheme{
    touch-action: pan-up;
    position: relative;
    padding-top: clampFluid(58);
    padding-bottom: clampFluid(141);
    .container_1920 {
        position: relative;
    }
    &__hashtag{
        width: 100%;
        text-align: center;
        @include _1279{
            font-size: 27.72px;
            line-height: 30.49px;
        }
        @include _1023{
            font-size: clampFluid(12,27.72,320,1023);
            line-height: clampFluid(13.2,30.49,320,1023);
        }
    }
    &__title{
        @include font-100-5;
        width: 100%;
        text-align: center;
        margin-top: clampFluid(-20);
        @include _1279{
            font-size: 69.3px;
            line-height: 69.3px;
            margin-top: 3px;
            padding-bottom: 15px;
        }
        @include _1023{
            font-size: clampFluid(30,69.3,320,1023);
            line-height: clampFluid(30,69.3,320,1023);
            padding-bottom: clampFluid(10,15,320,1023);
        }
    }
    &__svg{
        width: 100%;
        height: 100%;
        position: relative;
        &.el7, &.el5, &.el4 {
            padding-top: clampFluid(36);
            padding-bottom: clampFluid(228);
        }
        &.el5 {
            padding-bottom: clampFluid(139);
        }
        &.el4 {
            padding-bottom: clampFluid(165);
        }
        &.el3:not(.left_text) {
            padding-top: clampFluid(74);
            padding-bottom: clampFluid(218);
            svg {
                width: clampFluid(674);
                height: clampFluid(370);
                margin-left: clampFluid(373);
            }
        }
        &.el2 {
            padding-top: clampFluid(93);
            padding-bottom: clampFluid(411);
        }
        &.left_text {
            padding-top: clampFluid(93);
            padding-bottom: clampFluid(446);
            svg {
                margin-left: clampFluid(427);
                width: clampFluid(513);
                height: clampFluid(456);
            }
        }
        svg{
            &.el7 {
                width: clampFluid(1120);
                height: clampFluid(582);
                margin-left: clampFluid(237);
            }
            &.el5 {
                width: clampFluid(1061);
                height: clampFluid(427);
                margin-left: clampFluid(237);
            }
            &.el4 {
                width: clampFluid(777);
                height: clampFluid(480);
                margin-left: clampFluid(454);
            }
            &.el2 {
                width: clampFluid(625);
                height: clampFluid(125);
                margin-left: clampFluid(293);
            }
        }
        .item{
            position: absolute;
            display: flex;
            &_1{
                left: clampFluid(30);
                top: clampFluid(-12);
                .text{
                    max-width: clampFluid(280.5);
                }
                .img{
                    left: clampFluid(-28);
                    top: clampFluid(12);
                }
                &.el4 {
                    .text {
                        max-width: clampFluid(330);
                    }
                }
                &.el3 {
                    left: clampFluid(88);
                    top: clampFluid(5);
                    flex-direction: column;
                    .text.not_title {
                        padding-top: 0;
                        margin-top: clampFluid(-6);
                        margin-left: clampFluid(8);
                        max-width: clampFluid(511);
                    }
                    &.left_text {
                        flex-direction: row;
                        left: clampFluid(33);
                        top: clampFluid(47);
                        .text {
                            max-width: clampFluid(515);
                        }
                    }
                }
                &.el2 {
                    left: clampFluid(48);
                    top: clampFluid(42);
                    .text {
                        max-width: clampFluid(608);
                        @include font-18;
                        p {
                            color: $green;
                            margin-bottom: clampFluid(13);
                        }
                        ul {
                            padding-left: clampFluid(18);
                            li {
                                margin-bottom: clampFluid(13);
                                list-style-type: disc;
                                &::marker{
                                    color: $green;
                                }
                                span {
                                    color: $text;
                                }
                            }
                            ul {
                                padding-left: 0;
                                li {
                                    list-style-type: none;
                                    color: $text;
                                }
                            }
                        }
                    }
                }
            }
            &_2{
                left: clampFluid(487);
                top: clampFluid(47);
                .img{
                    left: clampFluid(-8);
                    top: clampFluid(14);
                }
                .text{
                    max-width: clampFluid(630);
                }
                &.el4 {
                    left: clampFluid(609);
                    .right {
                        max-width: clampFluid(446);
                    }
                    .text {
                        max-width: 100%;
                    }
                    // top: 47px;
                }
                &.el3 {
                    left: clampFluid(1071);
                    top: clampFluid(74);
                    flex-direction: column;
                    .text.not_title {
                        padding-top: 0;
                        margin-top: clampFluid(-7);
                        margin-left: clampFluid(-1);
                        max-width: clampFluid(511);
                    }
                    &.left_text {
                        flex-direction: row;
                        left: clampFluid(967);
                        top: clampFluid(60);
                        .text {
                            max-width: clampFluid(685);
                        }
                    }
                }
                &.el2 {
                    left: clampFluid(941);
                    top: clampFluid(139);
                    .text {
                        max-width: clampFluid(516);
                        @include font-18;
                        p {
                            color: $green;
                            margin-bottom: clampFluid(13);
                        }
                        ul {
                            padding-left: clampFluid(18);
                            li {
                                margin-bottom: clampFluid(13);
                                list-style-type: disc;
                                &::marker{
                                    color: $green;
                                }
                                span {
                                    color: $text;
                                }
                            }
                            ul {
                                padding-left: 0;
                                li {
                                    list-style-type: none;
                                    color: $text;
                                }
                            }
                        }
                    }
                }
            }
            &_3{
                top: clampFluid(110);
                right: clampFluid(42);
                .right{
                    max-width: clampFluid(313.5);
                }
                .img{
                    top: clampFluid(13);
                    left: clampFluid(-7);
                }
                &.el4 {
                    right: clampFluid(6);
                    .right {
                        max-width: clampFluid(410);
                    }
                }
                &.el3 {
                    top: clampFluid(431);
                    left: clampFluid(306);
                    flex-direction: column;
                    .text.not_title {
                        padding-top: 0;
                        margin-top: clampFluid(-6);
                        margin-left: clampFluid(-2);
                        max-width: clampFluid(511);
                    }
                    .right {
                        max-width: 100%;
                    }
                    &.left_text {
                        flex-direction: row;
                        top: clampFluid(470);
                        left: clampFluid(772);
                        .right {
                            margin-left: clampFluid(24);
                            max-width: 100%;
                        }
                        .text {
                            max-width: clampFluid(625);
                        }
                    }
                }
            }
            &_4{
                left: clampFluid(578);
                top: clampFluid(293);
                .img{
                    top: clampFluid(13);
                    left: clampFluid(-7);
                }
                .text{
                    max-width: clampFluid(576);
                }
                &.el5 {
                    top: clampFluid(302);
                    left: clampFluid(32);
                    flex-direction: row-reverse;
                    .right {
                        max-width: clampFluid(379);
                        margin-left: 0;
                        margin-right: clampFluid(25);
                    }
                    .img {
                        right: 0;
                        top: clampFluid(13);
                        left: auto;
                    }
                }
                &.el4 {
                    top: clampFluid(427);
                    left: clampFluid(200);
                    flex-direction: row-reverse;
                    .right {
                        max-width: clampFluid(590);
                        margin-left: 0;
                        margin-right: clampFluid(25);
                    }
                    .img {
                        right: clampFluid(34);
                        top: clampFluid(14);
                        left: auto;
                    }
                }
            }
            &_5{
                top: clampFluid(388);
                left: clampFluid(152);
                flex-direction: row-reverse;
                .right{
                    max-width: clampFluid(280.5);
                    margin-left: 0;
                    margin-right: clampFluid(-15);
                }
                .img{
                    top: clampFluid(13);
                    right: clampFluid(-6);
                }
                &.el5 {
                    top: clampFluid(388);
                    left: clampFluid(647);
                    flex-direction: row;
                    .right {
                        max-width: clampFluid(323);
                        margin-left: clampFluid(16);
                        margin-right: 0;
                    }
                    .img {
                        top: clampFluid(13);
                        right: auto;
                        left: clampFluid(-13);
                    }
                }
            }
            &_6{
                left: clampFluid(668);
                top: clampFluid(602);
                .text{
                    max-width: clampFluid(433.5);
                }
                .img{
                    top: clampFluid(13);
                    left: clampFluid(-5);
                }
            }
            &_7{
                right: clampFluid(42);
                top: clampFluid(542);
                .right{
                    max-width: clampFluid(256.5);
                }
                .img{
                    top: clampFluid(13);
                    left: clampFluid(-11);
                }
            }
            .right{
                display: flex;
                flex-direction: column;
                margin-left: clampFluid(7);
            }
            .numb{
                @include font-100-5;
                color: $green;
            }
            .img{
                width: clampFluid(115.5);
                height: clampFluid(115.5);
                position: absolute;
                z-index: -1;
            }
            .title{
                @include font-36;
                line-height: clampFluid(39.6);
                padding-top: clampFluid(36);
            }
            .text{
                @include font-18;
                color: $text;
                line-height: clampFluid(21.6);
                padding-top: clampFluid(15);
                &:not(.el2) {
                    ul {
                        padding-left: clampFluid(28);
                        li {
                            list-style-type: disc;
                            &::marker{
                                color: $text;
                                font-size: clampFluid(10);
                            }
                        }
                    }
                }
            }
        }
        .left_text {
            position: absolute;
            left: clampFluid(7);
            top: clampFluid(672);
            &__name {
                @include font-36;
                margin-bottom: clampFluid(14);
                &:not(:first-child) {
                    margin-top: clampFluid(61);
                }
            }
            &__list {
                padding-left: clampFluid(28);
                max-width: clampFluid(634);
                li {
                    list-style-type: disc;
                    @include font-18;
                    color: $text;
                    &::marker{
                        color: $text;
                        font-size: clampFluid(10);
                    }
                }
            }
        }
        @include _1279{
            display: none;
        }
    }
    &__btn_container{
        width: 100%;
        text-align: center;
        &.el5 {
            width: auto;
            position: absolute;
            bottom: 0;
            left: auto;
            right: clampFluid(101);
            @include _1279 {
                position: initial;
                width: 100%;
            }
        }
        &.el4 {
            width: auto;
            position: absolute;
            bottom: 0;
            left: auto;
            right: clampFluid(100);
            @include _1279 {
                position: initial;
                width: 100%;
            }
        }
        &.left_text {
            width: 73%;
            margin-left: auto;
            @include _1279 {
                margin-right: auto;
                width: 100%;
            }
        }
        &.el3:not(.left_text) {
            width: auto;
            position: absolute;
            bottom: 0;
            left: auto;
            right: clampFluid(334);
            @include _1279 {
                position: initial;
                width: 100%;
            }
        }
        .btn{
            span{
                @include _1023{
                    font-size: clampFluid(17,36,320,1023);
                    line-height: clampFluid(31,39.6,320,1023);
                }
            }
            @include _1023{
                padding-left: 16px;
                padding-right: 16px;
            }
        }
        @include _1279{
            margin-top: 69px;
        }
        @include _1023{
            margin-top: clampFluid(17,69,320,1023);
        }
    }
    &__container{
        display: none;
        margin-left: 8px;
        border-left: 2px solid $green;
        position: relative;
        .item{
            position: relative;
            display: flex;
            &:not(:last-child){
                margin-bottom: 29px;
                @include _1023{
                    margin-bottom: clampFluid(7,29,320,1023);
                }
            }
            .numb{
                font-size: 69.3px;
                line-height: 69.3px;
                color: $green;
                position: relative;
                margin-left: 23px;
                .circle{
                    position: absolute;
                    left: -33px;
                    top: 47%;
                    width: 18px;
                    height: 18px;
                    background: $green;
                    border-radius: 50%;
                    @include _1023{
                        width: clampFluid(8,18,320,1023);
                        height: clampFluid(8,18,320,1023);
                        left: clampFluid(-5,-33,320,1023);
                    }
                }
                @include _1023{
                    font-size: clampFluid(30,69.3,320,1023);
                    line-height: clampFluid(30,69.3,320,1023);
                    margin-left: clampFluid(0,23,320,1023);
                }
            }
            .title{
                font-size: 40px;
                line-height: 44px;
                color: $white;
                margin-top: 19px;
                @include _1023{
                    font-size: clampFluid(17,40,320,1023);
                    line-height: clampFluid(18.7,44,320,1023);
                    margin-top: clampFluid(9,19,320,1023);
                }
            }
            .text{
                font-size: 24px;
                line-height: 28.8px;
                color: $text;
                margin-top: 14px;
                position: relative;
                @include _1023{
                    font-size: clampFluid(12,24,320,1023);
                    line-height: clampFluid(14.4,28.8,320,1023);
                    margin-top: clampFluid(6,14,320,1023);
                }
                &.el2 {
                    @include font-18;
                    p {
                        color: $green;
                        margin-bottom: clampFluid(13);
                    }
                    ul {
                        padding-left: clampFluid(18);
                        li {
                            margin-bottom: clampFluid(13);
                            list-style-type: disc;
                            &::marker{
                                color: $green;
                            }
                            span {
                                color: $text;
                            }
                        }
                        ul {
                            padding-left: 0;
                            li {
                                list-style-type: none;
                                color: $text;
                            }
                        }
                    }
                }
            }
            .img{
                width: 116px;
                height: 116px;
                position: absolute;
                z-index: -1;
                left: 22px;
                top: 0;
                @include _1023{
                    width: clampFluid(50,116,320,1023);
                    height: clampFluid(50,116,320,1023);
                    left: clampFluid(9,22,320,1023);
                }
            }
            .left{
                width: 9.3%;
                @include _1023{
                    text-align: center;
                }
            }
            .right{
                width: 89.7%;
                position: relative;
            }
        }
        .top_plug{
            position: absolute;
            top: 0;
            left: -2px;
            background: $bgc;
            height: 33px;
            width: 2px;
            @include _1023{
                height: clampFluid(15,33,320,1023);
            }
        }
        .bottom_plug{
            position: absolute;
            left: clampFluid(-88,-112,1024,1279);
            // top: -27px;
            // top: clampFluid(22,50,320,1279);
            top: clampFluid(51,50,1024,1279);
            // top: 50px;
            bottom: -10px;
            background: $bgc;
            width: 6px;
            @include _1023{
                left: clampFluid(-30,-88,320,1023);
                top: clampFluid(40,51,768,1023);
            }
            @include _767 {
                top: clampFluid(26,40,425,767);
            }
            @include _425 {
                top: clampFluid(22,26,320,425);
            }
        }
        @include _1279{
            display: block;
        }
        @include _1023{
            border-left: clampFluid(1,2,320,1023) solid $green;
            margin-left: clampFluid(2,8,320,1023);
        }
    }
    canvas{
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
    }
    @include _1279{
        padding-top: 27px;
        .left_text {
            margin-top: 30px;
            &__name {
                @include font-36;
                margin-bottom: clampFluid(14);
                &:not(:first-child) {
                    margin-top: clampFluid(61);
                    @include _1279 {
                        margin-top: 30px;
                    }
                }
            }
            &__list {
                padding-left: clampFluid(28);
                li {
                    list-style-type: disc;
                    @include font-18;
                    color: $text;
                    &::marker{
                        color: $text;
                        font-size: 10px;
                    }
                }
            }
        }
    }
    @include _1023{
        padding-bottom: clampFluid(30,100,320,1023);
        padding-top: clampFluid(10,27,320,1023);
    }
}
