@font-face {
  font-family: "Hoves Regular";
  src: local("Hoves Regular"), url("/src/scss/base/fonts/woff2/TT_Hoves_Pro_Regular.woff2") format("woff2"), url("/src/scss/base/fonts/woff/TT_Hoves_Pro_Regular.woff") format("woff"), url("/src/scss/base/fonts/ttf/TT-Hoves-Pro-Regular.ttf") format("ttf"), url("/src/scss/base/fonts/otf/TT-Hoves-Pro-Regular.otf") format("otf"), url("/src/scss/base/fonts/eot/TT_Hoves_Pro_Regular.eot") format("eot");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Hoves Italic";
  src: local("Hoves Italic"), url("/src/scss/base/fonts/woff2/TT_Hoves_Pro_Italic.woff2") format("woff2"), url("/src/scss/base/fonts/woff/TT_Hoves_Pro_Italic.woff") format("woff"), url("/src/scss/base/fonts/ttf/TT-Hoves-Pro-Italic.ttf") format("ttf"), url("/src/scss/base/fonts/otf/TT-Hoves-Pro-Italic.otf") format("otf"), url("/src/scss/base/fonts/eot/TT_Hoves_Pro_Italic.eot") format("eot");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  src: url("https://res.cloudinary.com/dr6lvwubh/raw/upload/v1529908256/CompressaPRO-GX.woff2");
  font-family: "Compressa VF";
  font-style: normal;
}
:root {
  scroll-behavior: smooth;
  font-size: calc(0.044 * 100vw + -1.28px);
}
@media (min-width: 370px) {
  :root {
    font-size: 15px;
  }
}
@media (min-width: 1280px) {
  :root {
    font-size: calc(0.0078125 * 100vw + 0px);
  }
}
@media (min-width: 1920px) {
  :root {
    font-size: 15px;
  }
}

body {
  position: relative;
  color: #333;
  background: #121B24;
  font-family: "Hoves Regular";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --link-hover-color: #41bb4c;
}
@media (max-width: 1279px) {
  body {
    padding-bottom: 106px;
  }
}
@media (max-width: 1023px) {
  body {
    padding-bottom: clamp(68px, 0.0540540541 * 100vw + 50.7027027027px, 106px);
  }
}

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

button {
  border: 0;
  color: inherit;
  background: transparent;
  cursor: pointer;
  transition-property: color, background-color, border-color;
  transition-duration: 0.4s;
}

button,
input,
textarea {
  font-family: inherit;
}

input {
  min-width: 0;
  font-size: inherit;
  border-radius: 0;
}

input[type=file i],
input[type=file i]::-webkit-file-upload-button {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}

a {
  text-decoration: none;
}

svg {
  vertical-align: middle;
  transition: fill 0.4s, stroke 0.4s;
}

img {
  user-select: none;
}

img,
iframe,
svg,
picture {
  display: block;
}

iframe {
  border: 0;
}

h1,
h2,
h3 {
  font-weight: 400;
  font-size: inherit;
}

:focus {
  outline: none;
}

main {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.app-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.tablebodytext {
  display: none !important;
}

.fancybox__content {
  background: transparent !important;
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}

.bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
}
.bg__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blur {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  transition-duration: 0.3s;
  transition-behavior: allow-discrete;
  display: none;
  opacity: 0;
}
.blur.active {
  opacity: 1;
  display: block;
}
@starting-style {
  .blur.active {
    opacity: 0;
  }
}

.global_blur {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  transition-duration: 0.3s;
  transition-behavior: allow-discrete;
  display: none;
  opacity: 0;
}
.global_blur.htmx-request {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
@starting-style {
  .global_blur.htmx-request {
    opacity: 0;
  }
}

.btn_right_container {
  position: fixed;
  top: 46.5%;
  right: 0;
  z-index: 2;
  transition: all 0.3s ease;
  height: auto;
  background: rgba(55, 66, 77, 0.6);
  border-radius: clamp(20px, 1.5625vw, 30px) 0 0 clamp(20px, 1.5625vw, 30px);
  border: 2px solid #C0FF00;
  border-right: none;
  box-shadow: 0px 16px 49.3px -6px rgba(192, 255, 0, 0.5411764706);
  padding-top: clamp(21px, 1.640625vw, 31.5px);
  padding-right: clamp(9.3333333333px, 0.7291666667vw, 14px);
  padding-bottom: clamp(17.3333333333px, 1.3541666667vw, 26px);
  padding-left: clamp(7px, 0.546875vw, 10.5px);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
.btn_right_container span {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #C0FF00;
  transition: all 0.3s ease;
  writing-mode: vertical-lr;
  rotate: 180deg;
}
@media (max-width: 1279px) {
  .btn_right_container span {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .btn_right_container span {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (hover: hover) {
  .btn_right_container:hover {
    background: #C0FF00;
    border-color: rgba(112, 120, 129, 0.2);
  }
  .btn_right_container:hover span {
    color: #121B24;
  }
}
@media (hover: none), (hover: on-demand) {
  .btn_right_container:active {
    background: #C0FF00;
    border-color: rgba(112, 120, 129, 0.2);
  }
  .btn_right_container:active span {
    color: #121B24;
  }
}
@media (max-width: 1279px) {
  .btn_right_container {
    display: none;
  }
}

.link-cover::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding: clamp(8px, 0.625vw, 12px) clamp(30px, 2.34375vw, 45px) clamp(10.6666666667px, 0.8333333333vw, 16px) clamp(30px, 2.34375vw, 45px);
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  background: linear-gradient(180deg, #C0FF00 0%, #78C100 100%);
  border: 1px solid #C0FF00;
  border-radius: clamp(34px, 2.65625vw, 51px);
  user-select: none;
  transition: all 0.3s ease;
}
.btn span {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  color: #121B24;
  position: relative;
  z-index: 1;
  text-shadow: 1.0000001192px 1.0000001192px 0px 0px #7AC301;
}
@media (max-width: 1279px) {
  .btn span {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .btn span {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
.btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #C0FF00;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: clamp(34px, 2.65625vw, 51px);
  border: 1px solid #C0FF00;
}
.btn--box-shadow {
  box-shadow: 0px 16.0000019073px 49.2999954224px -6px rgba(192, 255, 0, 0.5411764706);
}
.btn--hover-box-shadow {
  transition: box-shadow 0.3s ease;
}
.btn--hover-box-shadow:not(.disabled) {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (hover: hover) {
  .btn--hover-box-shadow:not(.disabled):hover {
    box-shadow: 0px 16.0000019073px 49.2999954224px -6px rgba(192, 255, 0, 0.5411764706);
  }
}
@media (hover: none), (hover: on-demand) {
  .btn--hover-box-shadow:not(.disabled):active {
    box-shadow: 0px 16.0000019073px 49.2999954224px -6px rgba(192, 255, 0, 0.5411764706);
  }
}
.btn--green-boder {
  border: 2px solid #C0FF00;
  background: transparent;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
.btn--green-boder span {
  transition: all 0.3s ease;
  color: #C0FF00;
  text-shadow: none;
}
@media (hover: hover) {
  .btn--green-boder:hover {
    background: #C0FF00;
    box-shadow: 0px 16.0000019073px 49.2999954224px -6px rgba(192, 255, 0, 0.5411764706);
  }
  .btn--green-boder:hover span {
    color: #121B24;
  }
}
@media (hover: none), (hover: on-demand) {
  .btn--green-boder:active {
    background: #C0FF00;
    box-shadow: 0px 16.0000019073px 49.2999954224px -6px rgba(192, 255, 0, 0.5411764706);
  }
  .btn--green-boder:active span {
    color: #121B24;
  }
}
.btn--green-boder.not-box-shadow {
  box-shadow: none;
}
.btn--small {
  padding: clamp(4.6666666667px, 0.3645833333vw, 7px) clamp(20px, 1.5625vw, 30px) clamp(8px, 0.625vw, 12px);
}
.btn--small span {
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(16px, 1.25vw, 24px);
  line-height: clamp(23.84px, 1.8625vw, 35.76px);
  color: #F2F2F6;
  color: #121B24;
}
@media (max-width: 1023px) {
  .btn--small span {
    font-size: clamp(14px, 0.0028449502 * 100vw + 13.0896159317px, 16px);
    line-height: clamp(15.4px, 0.0120056899 * 100vw + 11.5581792319px, 23.84px);
  }
}
.btn--gray-border {
  border: 2px solid #707881;
  background: transparent;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
.btn--gray-border span {
  transition: all 0.3s ease;
  color: #707881;
  text-shadow: none;
}
@media (hover: hover) {
  .btn--gray-border:hover {
    border-color: #C0FF00;
    background: transparent;
  }
  .btn--gray-border:hover span {
    color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .btn--gray-border:active {
    border-color: #C0FF00;
    background: transparent;
  }
  .btn--gray-border:active span {
    color: #C0FF00;
  }
}
.btn--gray-border::after {
  opacity: 0 !important;
}
.btn.disabled {
  user-select: none;
  pointer-events: none;
  cursor: auto;
  opacity: 0.6;
}
.btn:not(.disabled) {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (hover: hover) {
  .btn:not(.disabled):hover::after {
    opacity: 1;
  }
}
@media (hover: none), (hover: on-demand) {
  .btn:not(.disabled):active::after {
    opacity: 1;
  }
}
.btn .preloader-icon {
  display: none;
  position: absolute;
  top: clamp(6.6666666667px, 0.5208333333vw, 10px);
  width: clamp(33.3333333333px, 2.6041666667vw, 50px);
  height: clamp(33.3333333333px, 2.6041666667vw, 50px);
}
.btn.htmx-request {
  user-select: none;
  cursor: auto;
}
.btn.htmx-request span {
  color: transparent;
}
.btn.htmx-request .preloader-icon {
  display: block;
}
.btn.htmx-request::after {
  opacity: 0 !important;
}
@media (max-width: 1279px) {
  .btn {
    padding: 15.39px 50px 20.61px 50px;
    border-radius: 40px;
  }
}
@media (max-width: 1023px) {
  .btn {
    padding: clamp(6px, 0.0133570413 * 100vw + 1.7257467994px, 15.39px) clamp(30px, 0.0284495021 * 100vw + 20.8961593172px, 50px) clamp(10px, 0.0150924609 * 100vw + 5.1704125178px, 20.61px) clamp(30px, 0.0284495021 * 100vw + 20.8961593172px, 50px);
    border-radius: clamp(34px, 0.0085348506 * 100vw + 31.2688477952px, 40px);
  }
}

.input {
  border-radius: 51px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  color: rgba(255, 255, 255, 0.5);
  padding: clamp(8px, 0.625vw, 12px) clamp(30px, 2.34375vw, 45px) clamp(12px, 0.9375vw, 18px);
}
@media (max-width: 1279px) {
  .input {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .input {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
.input.error {
  border-color: rgba(255, 0, 0, 0.5);
}
.input.bgc {
  background: #212D37;
}
@media (max-width: 1279px) {
  .input {
    padding: 13px 30px 20px;
  }
}
@media (max-width: 1023px) {
  .input {
    padding: clamp(4px, 0.012802276 * 100vw + -0.0967283073px, 13px) clamp(24px, 0.0085348506 * 100vw + 21.2688477952px, 30px) clamp(9px, 0.0156472262 * 100vw + 3.9928876245px, 20px);
  }
}

.textarea {
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(16px, 1.25vw, 24px);
  line-height: clamp(23.84px, 1.8625vw, 35.76px);
  color: #F2F2F6;
  color: rgba(255, 255, 255, 0.5);
  background: #1A252F;
  resize: none;
  padding: 7px 41px;
}
@media (max-width: 1023px) {
  .textarea {
    font-size: clamp(14px, 0.0028449502 * 100vw + 13.0896159317px, 16px);
    line-height: clamp(15.4px, 0.0120056899 * 100vw + 11.5581792319px, 23.84px);
  }
}

.chechbox_custom input, .radio_custom input, .button_kp input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.chechbox_custom input:checked + div, .radio_custom input:checked + div, .button_kp input:checked + div {
  color: #C0FF00;
  border-color: #C0FF00;
}
.chechbox_custom input:checked + div span::before, .radio_custom input:checked + div span::before, .button_kp input:checked + div span::before {
  border-color: #C0FF00;
  background: #C0FF00;
}
.chechbox_custom div, .radio_custom div, .button_kp div {
  width: fit-content;
  border: 2px solid #37424D;
  border-radius: clamp(22.6666666667px, 1.7708333333vw, 34px);
  padding: clamp(5.3333333333px, 0.4166666667vw, 8px) clamp(28px, 2.1875vw, 42px);
  transition: all 0.3s ease;
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(16px, 1.25vw, 24px);
  line-height: clamp(23.84px, 1.8625vw, 35.76px);
  color: #F2F2F6;
  color: #707881;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-height: clamp(37.3333333333px, 2.9166666667vw, 56px);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (max-width: 1023px) {
  .chechbox_custom div, .radio_custom div, .button_kp div {
    font-size: clamp(14px, 0.0028449502 * 100vw + 13.0896159317px, 16px);
    line-height: clamp(15.4px, 0.0120056899 * 100vw + 11.5581792319px, 23.84px);
  }
}
@media (hover: hover) {
  .chechbox_custom div:hover, .radio_custom div:hover, .button_kp div:hover {
    color: #C0FF00;
    border-color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .chechbox_custom div:active, .radio_custom div:active, .button_kp div:active {
    color: #C0FF00;
    border-color: #C0FF00;
  }
}
@media (max-width: 1023px) {
  .chechbox_custom div, .radio_custom div, .button_kp div {
    max-height: fit-content;
    padding: 6px 19px;
  }
}
.chechbox_custom.disabled, .radio_custom.disabled, .button_kp.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.chechbox_custom div {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
.chechbox_custom div span {
  position: relative;
  margin-left: clamp(20px, 1.5625vw, 30px);
}
.chechbox_custom div span::before {
  content: "";
  position: absolute;
  top: clamp(4.6666666667px, 0.3645833333vw, 7px);
  left: clamp(-32px, -1.6666666667vw, -21.3333333333px);
  width: clamp(14.6666666667px, 1.1458333333vw, 22px);
  height: clamp(14.6666666667px, 1.1458333333vw, 22px);
  border-radius: 50%;
  border: 2px solid #37424D;
  transition: all 0.3s ease;
}
@media (hover: hover) {
  .chechbox_custom div:hover span::before {
    border-color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .chechbox_custom div:active span::before {
    border-color: #C0FF00;
  }
}

.radio_custom--small-color input:checked + div {
  color: #F2F2F6;
  border-color: var(--color);
  background: var(--color);
}
.radio_custom--small-color input:checked + div span::before {
  border-color: #C0FF00;
  background: var(--color);
}
.radio_custom--small-color div {
  padding: 5px 20px 7px;
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (max-width: 1279px) {
  .radio_custom--small-color div {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .radio_custom--small-color div {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
@media (hover: hover) {
  .radio_custom--small-color div:hover {
    color: #F2F2F6;
    border-color: var(--color);
    background: var(--color);
    box-shadow: 0px 16px 49.3px -6px var(--color);
  }
}
@media (hover: none), (hover: on-demand) {
  .radio_custom--small-color div:active {
    color: #F2F2F6;
    border-color: var(--color);
    background: var(--color);
    box-shadow: 0px 16px 49.3px -6px var(--color);
  }
}
.radio_custom--gray-bgc-color div {
  background: #37424D;
  border-color: #37424D;
}
.radio_custom--small div {
  padding: 5px 21px 4px;
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: #707881;
}
@media (max-width: 1279px) {
  .radio_custom--small div {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .radio_custom--small div {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.radio_custom--white input:checked + div {
  color: #121B24;
  border-color: #C0FF00;
  background: linear-gradient(180deg, #C0FF00 0%, #78C100 100%);
}
.radio_custom--white div {
  border-color: rgba(255, 255, 255, 0.5);
  color: #F2F2F6;
}
.yandex_rews {
  width: clamp(373.3333333333px, 29.1666666667vw, 560px);
  height: clamp(533.3333333333px, 41.6666666667vw, 800px);
  overflow: hidden;
  position: relative;
}
.yandex_rews iframe {
  width: 100%;
  height: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  box-sizing: border-box;
  user-select: none;
}
.yandex_rews a {
  box-sizing: border-box;
  text-decoration: none;
  color: #b3b3b3;
  font-size: 10px;
  font-family: YS Text, sans-serif;
  padding: 0 20px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
  left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-height: 14px;
  white-space: nowrap;
  padding: 0 16px;
  box-sizing: border-box;
}
@media (max-width: 1279px) {
  .yandex_rews {
    width: 100%;
    height: 800px;
  }
}

.google_rews {
  background: #FFF;
  width: clamp(373.3333333333px, 29.1666666667vw, 560px);
  padding: clamp(13.3333333333px, 1.0416666667vw, 20px);
  border: 1px solid #e6e6e6;
  border-radius: 8px;
}
.google_rews__top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.google_rews__top .button {
  border-radius: 8px;
  background-color: #9bbf2e;
  padding: clamp(10px, 0.78125vw, 15px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  position: relative;
  border: none;
}
.google_rews__top .button span {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  z-index: 1;
}
.google_rews__title {
  display: flex;
}
.google_rews__title span {
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(16px, 1.25vw, 24px);
  line-height: clamp(23.84px, 1.8625vw, 35.76px);
  color: #F2F2F6;
  color: #121B24;
}
@media (max-width: 1023px) {
  .google_rews__title span {
    font-size: clamp(14px, 0.0028449502 * 100vw + 13.0896159317px, 16px);
    line-height: clamp(15.4px, 0.0120056899 * 100vw + 11.5581792319px, 23.84px);
  }
}
.google_rews__reviews {
  font-weight: 500;
  margin-left: clamp(6.6666666667px, 0.5208333333vw, 10px);
}
.google_rews__rating {
  display: flex;
  align-items: center;
}
.google_rews__rating .avg {
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(16px, 1.25vw, 24px);
  line-height: clamp(23.84px, 1.8625vw, 35.76px);
  color: #F2F2F6;
  font-weight: 600;
  color: #121B24;
  margin-right: clamp(6.6666666667px, 0.5208333333vw, 10px);
}
@media (max-width: 1023px) {
  .google_rews__rating .avg {
    font-size: clamp(14px, 0.0028449502 * 100vw + 13.0896159317px, 16px);
    line-height: clamp(15.4px, 0.0120056899 * 100vw + 11.5581792319px, 23.84px);
  }
}
.google_rews__rating .stars {
  display: flex;
  align-items: center;
}
.google_rews__rating .stars svg {
  width: clamp(10.6666666667px, 0.8333333333vw, 16px);
  height: clamp(10.6666666667px, 0.8333333333vw, 16px);
  stroke: #707881;
  fill: #707881;
}
.google_rews__rating .stars svg.active {
  stroke: #ffcc00;
  fill: #ffcc00;
}
.google_rews__rating .count {
  font-size: 14px;
  color: #707881;
  margin-left: clamp(3.3333333333px, 0.2604166667vw, 5px);
}
.google_rews__items {
  padding-top: clamp(16.6666666667px, 1.3020833333vw, 25px);
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: clamp(20px, 1.5625vw, 30px);
  grid-column-gap: clamp(20px, 1.5625vw, 30px);
  max-height: clamp(457.3333333333px, 35.7291666667vw, 686px);
  overflow: auto;
}
.google_rews__item {
  display: flex;
}
.google_rews__item .avtor {
  width: clamp(33.3333333333px, 2.6041666667vw, 50px);
  height: clamp(33.3333333333px, 2.6041666667vw, 50px);
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: clamp(10px, 0.78125vw, 15px);
  flex: 0 0 auto;
  border: 1px solid #707881;
}
.google_rews__item .avtor img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.google_rews__item .rating {
  display: flex;
  align-items: center;
}
.google_rews__name {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: #121B24;
}
@media (max-width: 1279px) {
  .google_rews__name {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .google_rews__name {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.google_rews__date {
  margin-left: clamp(3.3333333333px, 0.2604166667vw, 5px);
  color: #707881;
  font-size: 16px;
}
.google_rews__stars {
  display: flex;
  align-items: center;
}
.google_rews__stars svg {
  width: clamp(10.6666666667px, 0.8333333333vw, 16px);
  height: clamp(10.6666666667px, 0.8333333333vw, 16px);
  stroke: #707881;
  fill: #707881;
}
.google_rews__stars svg.active {
  stroke: #ffcc00;
  fill: #ffcc00;
}
.google_rews__rew {
  font-size: 16px;
  padding: clamp(10px, 0.78125vw, 15px) 0;
}
@media (max-width: 1279px) {
  .google_rews {
    width: 100%;
  }
}

.collapse__items {
  display: flex;
  flex-direction: column;
}
.collapse__name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  padding: clamp(14px, 1.09375vw, 21px) 0 clamp(16.6666666667px, 1.3020833333vw, 25px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  transition: color 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (max-width: 1279px) {
  .collapse__name {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .collapse__name {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
@media (hover: hover) {
  .collapse__name:hover {
    color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .collapse__name:active {
    color: #C0FF00;
  }
}
.collapse__name svg {
  width: clamp(14.6666666667px, 1.1458333333vw, 22px);
  height: clamp(14.6666666667px, 1.1458333333vw, 22px);
  stroke: #C0FF00;
  stroke-width: 2px;
  flex: 0 0 auto;
}
@media (max-width: 1279px) {
  .collapse__name svg {
    width: 26px;
    height: 26px;
  }
}
@media (max-width: 1023px) {
  .collapse__name svg {
    width: clamp(13px, 0.0184921764 * 100vw + 7.0825035562px, 26px);
    height: clamp(13px, 0.0184921764 * 100vw + 7.0825035562px, 26px);
  }
}
.collapse__name.active {
  border-bottom: none;
}
.collapse__name.active svg {
  rotate: 270deg;
}
@media (max-width: 1279px) {
  .collapse__name {
    padding: 21px 0;
  }
}
@media (max-width: 1023px) {
  .collapse__name {
    font-size: clamp(14px, 0.0042674253 * 100vw + 12.6344238976px, 17px);
    line-height: clamp(16.8px, 0.0027027027 * 100vw + 15.9351351351px, 18.7px);
    padding-top: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    padding-bottom: clamp(14px, 0.0099573257 * 100vw + 10.813655761px, 21px);
    align-items: flex-start;
  }
}
.collapse__content {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: rgba(255, 255, 255, 0.5);
}
@media (max-width: 1279px) {
  .collapse__content {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .collapse__content {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.collapse__content p {
  width: 90%;
}
@media (max-width: 1023px) {
  .collapse__content p {
    width: 100%;
    padding-left: 21px;
  }
}
.collapse__content ul {
  padding-left: clamp(16.6666666667px, 1.3020833333vw, 25px);
}
.collapse__content ul li {
  position: relative;
  list-style-type: disc;
}
.collapse__content ul li:not(:last-child) {
  margin-bottom: clamp(10.6666666667px, 0.8333333333vw, 16px);
}
.collapse__content ul li::marker {
  color: #C0FF00;
}

.obmanka {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.personal {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: #707881;
}
@media (max-width: 1279px) {
  .personal {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .personal {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.personal a {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: #707881;
  text-decoration: underline;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (max-width: 1279px) {
  .personal a {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .personal a {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
@media (hover: hover) {
  .personal a:hover {
    text-decoration: none;
    color: #C0FF00 !important;
  }
}
@media (hover: none), (hover: on-demand) {
  .personal a:active {
    text-decoration: none;
    color: #C0FF00 !important;
  }
}
@media (max-width: 1279px) {
  .personal a {
    font-size: 22px;
    line-height: 24.2px;
  }
}
@media (max-width: 1023px) {
  .personal a {
    font-size: clamp(12px, 0.0142247511 * 100vw + 7.4480796586px, 22px);
    line-height: clamp(14.67px, 0.0135561878 * 100vw + 10.3320199147px, 24.2px);
  }
}
@media (max-width: 1279px) {
  .personal {
    font-size: 22px;
    line-height: 24.2px;
  }
}
@media (max-width: 1023px) {
  .personal {
    font-size: clamp(12px, 0.0142247511 * 100vw + 7.4480796586px, 22px);
    line-height: clamp(14.67px, 0.0135561878 * 100vw + 10.3320199147px, 24.2px);
    text-wrap: balance;
  }
}

.background_line {
  background: repeating-linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 8px, #05080C 11px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.background_line.last {
  background: repeating-linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 8px, #05080C 11px), linear-gradient(180deg, #121B24 62%, #212D37 100%);
}
.background_line.blur_top::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(360deg, rgba(18, 27, 36, 0) 0%, rgba(18, 27, 36, 0.4234068627) 37%, rgba(18, 27, 36, 0.8715861345) 60%, rgb(18, 27, 36) 100%);
}

.hashtag {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  color: rgba(255, 255, 255, 0.5);
}
@media (max-width: 1279px) {
  .hashtag {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .hashtag {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .hashtag {
    font-size: 24px;
    line-height: 26.4px;
  }
}
@media (max-width: 1023px) {
  .hashtag {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(13.2px, 0.0187766714 * 100vw + 7.1914651494px, 26.4px);
  }
}

.hide_section {
  opacity: 0;
  transition: all 0.3s ease;
}
.hide_section.visible {
  opacity: 1;
}

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: clamp(1150px, 89.84375vw, 1725px);
}
@media (max-width: 1279px) {
  .container {
    max-width: 100%;
    padding: 0 56px;
  }
}
@media (max-width: 1023px) {
  .container {
    padding: 0 clamp(18px, 0.0540540541 * 100vw + 0.7027027027px, 56px);
  }
}

.container_1920 {
  max-width: 1920px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.container_1920.mt {
  margin-top: -50px;
  position: relative;
  z-index: 0;
}

.case_container {
  max-width: 1920px;
  width: 59.4%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1279px) {
  .case_container {
    padding: 0;
  }
}
@media (max-width: 1023px) {
  .case_container {
    width: 100%;
    padding: 0 20px;
  }
}

.link:hover {
  text-decoration: underline;
}

.hover:hover {
  color: #C0FF00 !important;
}

[x-cloak] {
  display: none !important;
}

.h1 {
  font-size: 4.2666666667rem;
  line-height: 1.1;
  font-weight: 300;
}
@media (max-width: 1279px) {
  .h1 {
    font-size: 2rem;
    font-weight: normal;
    line-height: 1.15;
  }
}

.h2 {
  font-size: 2.6666666667rem;
  line-height: 1.1;
  font-weight: 300;
}
@media (max-width: 1279px) {
  .h2 {
    font-size: 2rem;
  }
}

.h3 {
  font-size: 2.1333333333rem;
  line-height: 1.2;
  font-weight: 300;
}
@media (max-width: 1279px) {
  .h3 {
    font-size: 1.2rem;
  }
}

.text1 {
  font-size: 1.6666666667rem;
  line-height: 1.2;
  font-family: "Inter", sans-serif;
}
@media (max-width: 1279px) {
  .text1 {
    font-size: 1.2rem;
  }
}

.text2 {
  font-size: 1.3333333333rem;
  line-height: 1.3;
  font-family: "Inter", sans-serif;
}
@media (max-width: 1279px) {
  .text2 {
    font-size: 0.9333333333rem;
  }
}

.font-inter {
  font-family: "Inter", sans-serif;
}

.darkgreen-color {
  color: #275f40;
}

.lightgreen-color {
  color: #41bb4c;
}

.orange-color {
  color: #faa746;
}

.breadcrumbs {
  position: absolute;
  bottom: clamp(-51px, -2.65625vw, -34px);
  width: 100%;
  overflow: hidden;
}
.breadcrumbs ul {
  margin-left: clamp(2px, 0.15625vw, 3px);
  display: flex;
  align-items: center;
}
.breadcrumbs ul li {
  white-space: nowrap;
}
.breadcrumbs ul li a {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (max-width: 1279px) {
  .breadcrumbs ul li a {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .breadcrumbs ul li a {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
@media (hover: hover) {
  .breadcrumbs ul li a:hover {
    color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .breadcrumbs ul li a:active {
    color: #C0FF00;
  }
}
.breadcrumbs ul li a.active {
  color: #C0FF00;
}
.breadcrumbs ul li .name {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: #707881;
  white-space: nowrap;
}
@media (max-width: 1279px) {
  .breadcrumbs ul li .name {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .breadcrumbs ul li .name {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.breadcrumbs .dash {
  color: #C0FF00;
  margin-left: clamp(3.3333333333px, 0.2604166667vw, 5px);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.icon-svg {
  display: inline-block;
  width: 1em;
  aspect-ratio: 1;
  -webkit-mask-image: var(--svg);
  mask-size: 100% 100%;
  mask-image: var(--svg);
  mask-repeat: no-repeat;
  background-color: currentColor;
  vertical-align: middle;
}

.pc-svg {
  width: clamp(51px, 3.984375vw, 76.5px);
  --svg: url("data:image/svg+xml,%3Csvg width='77' height='77' viewBox='0 0 77 77' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='38.2485' cy='38.2485' r='37.2485' fill='%23707881' fill-opacity='0.2' stroke='%23C0FF00' stroke-width='2'/%3E%3Cg clip-path='url(%23clip0_244_13855)'%3E%3Cpath d='M55.3639 22.668H21.1407C19.6398 22.668 18.418 23.8788 18.418 25.366V43.9757C18.418 45.4629 19.6398 46.6737 21.1407 46.6737H36.8909V48.6194H36.8768C34.4613 48.6194 32.4978 50.565 32.4978 52.9586V53.8334H44.0103V52.9586C44.0103 50.565 42.0468 48.6194 39.6313 48.6194H39.6172V46.6737H55.3674C56.8683 46.6737 58.0902 45.4629 58.0902 43.9757V25.366C58.0902 23.8788 56.8683 22.668 55.3674 22.668H55.3639ZM39.6278 50.3691C40.7614 50.3691 41.7255 51.0864 42.0892 52.0838H34.4154C34.7791 51.0829 35.7432 50.3691 36.8768 50.3691H39.6278ZM56.3209 43.9792C56.3209 44.5006 55.8936 44.9275 55.3639 44.9275H21.1407C20.6145 44.9275 20.1837 44.5041 20.1837 43.9792V25.366C20.1837 24.8446 20.611 24.4177 21.1407 24.4177H55.3639C55.8901 24.4177 56.3209 24.8411 56.3209 25.366V43.9757V43.9792Z' fill='%23C0FF00'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_244_13855'%3E%3Crect width='39.6651' height='31.1655' fill='white' transform='translate(18.4141 22.668)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.page-head {
  position: sticky;
  top: 0;
  z-index: 10;
}

.header {
  display: flex;
  flex-direction: column;
  border: 1px solid #37424D;
  z-index: 10;
  position: relative;
  background: #121B24;
}
.header__top .social {
  margin-right: clamp(18.6666666667px, 1.4583333333vw, 28px);
  display: flex;
  position: relative;
}
.header__top .social_link {
  display: flex;
  align-items: center;
  width: clamp(85px, 6.40625vw, 123px);
  border-radius: clamp(7px, 0.546875vw, 10.5px);
  border: 2px solid #C0FF00;
  padding: clamp(4.6666666667px, 0.3645833333vw, 7px) clamp(5.3333333333px, 0.4166666667vw, 8px) clamp(6px, 0.46875vw, 9px) clamp(7.3333333333px, 0.5729166667vw, 11px);
  background: transparent;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
.header__top .social_link:not(:last-child) {
  margin-right: clamp(7.3333333333px, 0.5729166667vw, 11px);
}
.header__top .social_link span {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #C0FF00;
  font-size: clamp(8px, 0.625vw, 12px);
  line-height: clamp(9.3333333333px, 0.7291666667vw, 14px);
  transition: all 0.3s ease;
}
.header__top .social_link svg {
  fill: #C0FF00;
  width: clamp(16px, 1.25vw, 24px);
  height: clamp(16px, 1.25vw, 24px);
  margin-right: clamp(5.3333333333px, 0.4166666667vw, 8px);
  transition: all 0.3s ease;
}
@media (hover: hover) {
  .header__top .social_link:hover {
    background: #C0FF00;
  }
  .header__top .social_link:hover span {
    color: #121B24;
  }
  .header__top .social_link:hover svg {
    fill: #121B24;
  }
}
@media (hover: none), (hover: on-demand) {
  .header__top .social_link:active {
    background: #C0FF00;
  }
  .header__top .social_link:active span {
    color: #121B24;
  }
  .header__top .social_link:active svg {
    fill: #121B24;
  }
}
.header__top .social_text {
  position: absolute;
  bottom: clamp(-16px, -0.8333333333vw, -10.6666666667px);
  left: auto;
  right: auto;
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #707881;
  font-size: clamp(8px, 0.625vw, 12px);
  line-height: clamp(9.3333333333px, 0.7291666667vw, 14px);
  width: 97%;
  text-align: center;
}
@media (max-width: 1279px) {
  .header__top .social {
    display: none;
  }
}
.header__top .phone {
  display: flex;
  flex-direction: column;
  margin-right: clamp(15px, 1.9791666667vw, 38px);
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  white-space: nowrap;
}
@media (max-width: 1279px) {
  .header__top .phone {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .header__top .phone {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.header__top .phone_number {
  cursor: pointer;
}
.header__top .phone a {
  color: #F2F2F6;
}
.header__top .phone .btn_modal {
  color: #C0FF00;
  border-bottom: 2px solid #C0FF00;
  transition: all 0.3s ease;
  cursor: pointer;
}
.header__top .phone .btn_modal:hover {
  border-bottom-color: transparent;
}
@media (max-width: 1279px) {
  .header__top .phone {
    display: none;
  }
}
.header__top .city {
  margin-right: auto;
  margin-left: clamp(20px, 1.5625vw, 30px);
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: #707881;
}
@media (max-width: 1279px) {
  .header__top .city {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .header__top .city {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.header__top .city span {
  color: #F2F2F6;
  border-bottom: 1px dashed #F2F2F6;
  transition: all 0.3s ease;
  cursor: pointer;
  white-space: nowrap;
}
.header__top .city span:hover {
  color: #C0FF00;
  border-bottom-color: #C0FF00;
}
@media (max-width: 1279px) {
  .header__top .city {
    display: none;
  }
}
.header_top_menu {
  display: flex;
}
.header_top_menu__link {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  transition: all 0.3s ease;
  white-space: nowrap;
}
@media (max-width: 1279px) {
  .header_top_menu__link {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .header_top_menu__link {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.header_top_menu__link:not(:last-child) {
  margin-right: clamp(28.6666666667px, 2.2395833333vw, 43px);
}
@media (max-width: 1279px) {
  .header_top_menu {
    display: none;
  }
}
.header__bottom {
  background: #212D37;
  border-top: 1px solid #37424D;
}
@media (max-width: 1279px) {
  .header__bottom {
    border: none;
  }
}
.header_bottom_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_bottom_menu > .item {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  padding-top: clamp(10.6666666667px, 0.8333333333vw, 16px);
  padding-bottom: clamp(12px, 0.9375vw, 18px);
}
@media (max-width: 1279px) {
  .header_bottom_menu > .item {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .header_bottom_menu > .item {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
.header_bottom_menu > .item:first-child {
  padding-right: clamp(30px, 2.34375vw, 45px);
  padding-left: clamp(66.6666666667px, 5.2083333333vw, 100px);
  background: linear-gradient(180deg, #C0FF00 0%, #78C100 100%);
  transition: box-shadow 0.3s ease;
  color: #121B24;
}
.header_bottom_menu > .item:first-child:hover {
  background: #C0FF00;
  box-shadow: 0px 16.0000019073px 49.2999954224px -6px rgba(192, 255, 0, 0.5411764706);
}
.header_bottom_menu > .item.parent {
  position: relative;
}
.header_bottom_menu > .item.parent .item {
  display: flex;
  align-items: center;
}
.header_bottom_menu > .item.parent .item svg {
  margin-left: clamp(5.3333333333px, 0.4166666667vw, 8px);
  margin-top: clamp(2px, 0.15625vw, 3px);
  stroke: #C0FF00;
  width: clamp(10.6666666667px, 0.8333333333vw, 16px);
  height: clamp(10px, 0.78125vw, 15px);
}
.header_bottom_menu > .item.parent a {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .header_bottom_menu > .item.parent a {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .header_bottom_menu > .item.parent a {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
.header_bottom_menu > .item.parent:hover svg {
  rotate: 270deg;
  margin-top: clamp(5.3333333333px, 0.4166666667vw, 8px);
}
.header_bottom_menu > .item.parent:hover .submenu {
  opacity: 1;
  pointer-events: all;
}
.header_bottom_menu > .item.parent .submenu {
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  position: absolute;
  border-radius: clamp(20px, 1.5625vw, 30px);
  padding: clamp(22.6666666667px, 1.7708333333vw, 34px) clamp(30.6666666667px, 2.3958333333vw, 46px) clamp(26.6666666667px, 2.0833333333vw, 40px) clamp(30.6666666667px, 2.3958333333vw, 46px);
  margin-top: clamp(12.6666666667px, 0.9895833333vw, 19px);
  display: flex;
  z-index: 1;
}
.header_bottom_menu > .item.parent .submenu a {
  white-space: nowrap;
  transition: all 0.3s ease;
}
.header_bottom_menu > .item.parent .submenu a:hover {
  color: #C0FF00;
}
.header_bottom_menu > .item.parent .submenu .right {
  width: clamp(228.6666666667px, 17.8645833333vw, 343px);
}
.header_bottom_menu > .item.parent .submenu .right .desc {
  transition: all 0.3s ease;
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  height: 100%;
  opacity: 0.9;
}
@media (max-width: 1279px) {
  .header_bottom_menu > .item.parent .submenu .right .desc {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .header_bottom_menu > .item.parent .submenu .right .desc {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.header_bottom_menu > .item.parent .submenu .left {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  padding-right: clamp(28.6666666667px, 2.2395833333vw, 43px);
  margin-right: clamp(28.6666666667px, 2.2395833333vw, 43px);
}
.header_bottom_menu > .item.parent .submenu .item:not(:last-child) {
  padding-bottom: clamp(11.3333333333px, 0.8854166667vw, 17px);
}
.header_bottom_menu > .item:nth-child(n+2) .submenu {
  left: clamp(-44px, -2.2916666667vw, -29.3333333333px);
}
.header_bottom_menu > .item:nth-child(n+5) .submenu {
  left: clamp(-470px, -24.4791666667vw, -313.3333333333px);
  flex-direction: row-reverse;
}
.header_bottom_menu > .item:nth-child(n+5) .submenu .left {
  border-right: none;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding-right: 0;
  padding-left: clamp(28.6666666667px, 2.2395833333vw, 43px);
  margin-right: 0;
  margin-left: clamp(28.6666666667px, 2.2395833333vw, 43px);
}
.header_bottom_menu > .item:nth-child(n+5) .submenu .right .desc {
  text-align: end;
}
.header_bottom_menu > .item:nth-child(8) {
  margin-right: clamp(66.6666666667px, 5.2083333333vw, 100px);
}
.header_bottom_menu > .item:nth-child(8) .submenu {
  left: -470px;
}
.header_bottom_menu > .item.more {
  position: relative;
}
.header_bottom_menu > .item.more .item {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.header_bottom_menu > .item.more .item svg {
  margin-left: clamp(5.3333333333px, 0.4166666667vw, 8px);
  margin-top: clamp(2px, 0.15625vw, 3px);
  stroke: #C0FF00;
  width: clamp(10.6666666667px, 0.8333333333vw, 16px);
  height: clamp(10px, 0.78125vw, 15px);
}
.header_bottom_menu > .item.more .menu_more {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  top: clamp(45.3333333333px, 3.5416666667vw, 68px);
  right: 0;
  z-index: 1;
}
.header_bottom_menu > .item.more:hover .menu_more {
  opacity: 1;
  pointer-events: all;
}
@media (max-width: 1279px) {
  .header_bottom_menu {
    display: none;
  }
}
.header .header_bottom_more_menu {
  display: flex;
  flex-direction: row-reverse;
  transition: all 0.3s ease;
  border-radius: clamp(20px, 1.5625vw, 30px);
  padding: clamp(22.6666666667px, 1.7708333333vw, 34px) clamp(30.6666666667px, 2.3958333333vw, 46px) clamp(26.6666666667px, 2.0833333333vw, 40px) clamp(30.6666666667px, 2.3958333333vw, 46px);
}
.header .header_bottom_more_menu .right {
  padding-left: clamp(28.6666666667px, 2.2395833333vw, 43px);
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  min-width: clamp(196.6666666667px, 15.3645833333vw, 295px);
}
.header .header_bottom_more_menu .left {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  margin-left: clamp(28.6666666667px, 2.2395833333vw, 43px);
}
.header .header_bottom_more_menu .left div {
  white-space: nowrap;
}
.header .header_bottom_more_menu .desc {
  text-align: end;
  width: clamp(230px, 17.96875vw, 345px);
  transition: all 0.3s ease;
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  height: 100%;
  opacity: 0.8;
}
@media (max-width: 1279px) {
  .header .header_bottom_more_menu .desc {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .header .header_bottom_more_menu .desc {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.header .header_bottom_more_menu .item {
  transition: all 0.3s ease;
}
.header .header_bottom_more_menu .item.active, .header .header_bottom_more_menu .item:hover {
  color: #C0FF00;
}
.header .header_bottom_more_menu .item:not(:last-child) {
  padding-bottom: clamp(11.3333333333px, 0.8854166667vw, 17px);
}
.header .header_bottom_more_menu .items {
  padding: 0 clamp(28.6666666667px, 2.2395833333vw, 43px);
}
@media (max-width: 1279px) {
  .header {
    border: none;
  }
}

.header__top, .mobile_head {
  display: flex;
  align-items: center;
  padding: clamp(9px, 0.703125vw, 13.5px) 0;
}
.header__top .logo, .mobile_head .logo {
  width: clamp(164px, 12.8125vw, 246px);
  height: clamp(30px, 2.34375vw, 45px);
  margin-right: clamp(14.6666666667px, 1.1458333333vw, 22px);
  margin-top: clamp(5.3333333333px, 0.4166666667vw, 8px);
  margin-bottom: clamp(5.3333333333px, 0.4166666667vw, 8px);
}
@media (max-width: 1279px) {
  .header__top .logo, .mobile_head .logo {
    width: 285.72px;
    height: 52.26px;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 26px;
  }
}
@media (max-width: 1023px) {
  .header__top .logo, .mobile_head .logo {
    width: clamp(164px, 0.17314367 * 100vw + 108.5940256046px, 285.72px);
    height: clamp(30px, 0.0316642959 * 100vw + 19.8674253201px, 52.26px);
    margin-right: clamp(17px, 0.012802276 * 100vw + 12.9032716927px, 26px);
  }
}
.header__top .name, .mobile_head .name {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  padding-right: clamp(20.6666666667px, 1.6145833333vw, 31px);
  border-right: 1px solid #37424D;
  white-space: nowrap;
}
@media (max-width: 1279px) {
  .header__top .name, .mobile_head .name {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .header__top .name, .mobile_head .name {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
@media (max-width: 1279px) {
  .header__top .name, .mobile_head .name {
    border: none;
    padding-right: 0;
  }
}
@media (max-width: 1279px) {
  .header__top, .mobile_head {
    padding-top: 29px;
    padding-bottom: 29px;
  }
}
@media (max-width: 1023px) {
  .header__top, .mobile_head {
    padding-top: clamp(14px, 0.0213371266 * 100vw + 7.1721194879px, 29px);
    padding-bottom: clamp(14px, 0.0213371266 * 100vw + 7.1721194879px, 29px);
  }
}

#mobile_head {
  border: 1px solid #37424D;
}

.mobile_header {
  display: none;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
  bottom: 0;
  left: 0;
  right: 0;
  background: #212D37;
  border: 1px solid #37424D;
  padding: 19px 56px 18px 56px;
}
.mobile_header__phone {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  background: linear-gradient(180deg, #C0FF00 0%, #78C100 100%);
}
.mobile_header__phone svg {
  width: 38px;
  height: 38px;
}
@media (max-width: 1023px) {
  .mobile_header__phone svg {
    width: clamp(24px, 0.0199146515 * 100vw + 17.627311522px, 38px);
    height: clamp(24px, 0.0199146515 * 100vw + 17.627311522px, 38px);
  }
}
@media (max-width: 1023px) {
  .mobile_header__phone {
    width: clamp(34px, 0.0426742532 * 100vw + 20.3442389758px, 64px);
    height: clamp(34px, 0.0426742532 * 100vw + 20.3442389758px, 64px);
    border-radius: clamp(7px, 0.0099573257 * 100vw + 3.813655761px, 14px);
  }
}
.mobile_header__link {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #F2F2F6;
  padding: 9.39px 38.5px 14.61px 38.5px;
  border-radius: 59.23px;
  font-size: 36px;
  line-height: 39.6px;
  color: #F2F2F6;
}
@media (max-width: 1023px) {
  .mobile_header__link {
    padding: clamp(8px, 0.0019772404 * 100vw + 7.3672830725px, 9.39px) clamp(18px, 0.0291607397 * 100vw + 8.6685633001px, 38.5px) clamp(11px, 0.0051351351 * 100vw + 9.3567567568px, 14.61px) clamp(18px, 0.0291607397 * 100vw + 8.6685633001px, 38.5px);
    font-size: clamp(14px, 0.0312944523 * 100vw + 3.9857752489px, 36px);
    line-height: clamp(15.4px, 0.0344238976 * 100vw + 4.3843527738px, 39.6px);
  }
}
.mobile_header__burger {
  cursor: pointer;
}
.mobile_header__burger svg {
  width: 64px;
  height: 24px;
  stroke: #C0FF00;
  stroke-width: 3px;
}
@media (max-width: 1023px) {
  .mobile_header__burger svg {
    width: clamp(34px, 0.0426742532 * 100vw + 20.3442389758px, 64px);
    height: clamp(10px, 0.0199146515 * 100vw + 3.627311522px, 24px);
    stroke-width: clamp(2px, 0.0014224751 * 100vw + 1.5448079659px, 3px);
  }
}
@media (max-width: 1279px) {
  .mobile_header {
    display: flex;
  }
}
@media (max-width: 1023px) {
  .mobile_header {
    padding: clamp(13px, 0.0085348506 * 100vw + 10.2688477952px, 19px) clamp(20px, 0.0512091038 * 100vw + 3.613086771px, 56px) clamp(15px, 0.0042674253 * 100vw + 13.6344238976px, 18px) clamp(20px, 0.0512091038 * 100vw + 3.613086771px, 56px);
  }
}

.mobile_menu {
  width: 100%;
  height: 100dvh;
  background: #121B24;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  transform: translateX(100%);
  transition: all 0.4s ease;
  overflow: auto;
  padding-bottom: 27px;
  overflow: hidden;
  display: none;
  will-change: transform;
}
.mobile_menu__container {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.mobile_menu__city_container {
  width: 100%;
  padding: 5px 0 11px;
  border: 1px solid #37424D;
  border-top: none;
  background: #212D37;
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 28.8px;
  color: #707881;
  margin-bottom: 16px;
}
.mobile_menu__city_container span {
  cursor: pointer;
  color: #C0FF00;
  border-bottom: 1px dashed #C0FF00;
  margin-left: 5px;
}
@media (max-width: 1023px) {
  .mobile_menu__city_container {
    font-size: clamp(17px, 0.0099573257 * 100vw + 13.813655761px, 24px);
    line-height: clamp(20.4px, 0.0119487909 * 100vw + 16.5763869132px, 28.8px);
  }
}
.mobile_menu__items {
  margin-top: 26px;
  height: 100%;
}
@media (max-width: 1023px) {
  .mobile_menu__items {
    margin-top: clamp(15px, 0.0156472262 * 100vw + 9.9928876245px, 26px);
  }
}
.mobile_menu__contacts_container {
  display: flex;
  align-items: flex-start;
}
.mobile_menu__contacts_container .left {
  display: flex;
  flex-direction: column;
  margin-right: 78px;
}
.mobile_menu__contacts_container .left .tel {
  font-size: 36px;
  line-height: 22.37px;
  color: #F2F2F6;
  margin-bottom: 19.39px;
}
@media (max-width: 1023px) {
  .mobile_menu__contacts_container .left .tel {
    font-size: clamp(20px, 0.0227596017 * 100vw + 12.7169274538px, 36px);
    line-height: clamp(16px, 0.0090611664 * 100vw + 13.1004267425px, 22.37px);
    margin-bottom: clamp(17px, 0.0033997155 * 100vw + 15.9120910384px, 19.39px);
  }
}
.mobile_menu__contacts_container .left .email {
  font-size: 24px;
  line-height: 26.4px;
  color: #C0FF00;
  border-bottom: 2px solid #C0FF00;
  width: min-content;
  white-space: nowrap;
}
@media (max-width: 1023px) {
  .mobile_menu__contacts_container .left .email {
    font-size: clamp(17px, 0.0099573257 * 100vw + 13.813655761px, 24px);
    line-height: clamp(18.7px, 0.0109530583 * 100vw + 15.1950213371px, 26.4px);
  }
}
@media (max-width: 1023px) {
  .mobile_menu__contacts_container .left {
    margin-right: clamp(41px, 0.0526315789 * 100vw + 24.1578947368px, 78px);
  }
}
.mobile_menu__contacts_container .right {
  display: flex;
  align-items: center;
}
.mobile_menu__contacts_container .right .social {
  width: 64px;
  height: 64px;
  background: transparent;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #C0FF00;
}
.mobile_menu__contacts_container .right .social svg {
  width: 42.67px;
  height: 42.67px;
  fill: #C0FF00;
}
@media (max-width: 1023px) {
  .mobile_menu__contacts_container .right .social svg {
    width: clamp(19.4px, 0.0331009957 * 100vw + 8.8076813656px, 42.67px);
    height: clamp(19.4px, 0.0331009957 * 100vw + 8.8076813656px, 42.67px);
  }
}
.mobile_menu__contacts_container .right .social:first-child {
  margin-right: 25px;
}
@media (max-width: 1023px) {
  .mobile_menu__contacts_container .right .social:first-child {
    margin-right: clamp(18px, 0.0099573257 * 100vw + 14.813655761px, 25px);
  }
}
@media (max-width: 1023px) {
  .mobile_menu__contacts_container .right .social {
    width: clamp(36px, 0.039829303 * 100vw + 23.2546230441px, 64px);
    height: clamp(36px, 0.039829303 * 100vw + 23.2546230441px, 64px);
    border-radius: clamp(10.5px, 0.0106685633 * 100vw + 7.086059744px, 18px);
  }
}
.mobile_menu__partners {
  margin-top: 58.61px;
}
.mobile_menu__partners__top {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .mobile_menu__partners__top {
    flex-wrap: wrap;
    margin-top: clamp(39px, 0.0437723214 * 100vw + 24.9928571429px, 58.61px);
  }
}
.mobile_menu__partners__bottom {
  margin-bottom: 18px;
}
.mobile_menu__partners__bottom span {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .mobile_menu__partners__bottom span {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .mobile_menu__partners__bottom span {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.mobile_menu__partners a {
  margin-bottom: 18px;
}
.mobile_menu__partners a:not(:first-child) img {
  height: 48px;
}
@media (max-width: 1023px) {
  .mobile_menu__partners a:not(:first-child) img {
    height: clamp(36px, 0.0170697013 * 100vw + 30.5376955903px, 48px);
  }
}
.mobile_menu__partners a:not(:last-child) {
  margin-right: 49px;
}
@media (max-width: 1023px) {
  .mobile_menu__partners a:not(:last-child) {
    margin-right: clamp(18px, 0.0440967283 * 100vw + 3.8890469417px, 49px);
  }
}
.mobile_menu__partners a img {
  width: auto;
  height: 56px;
  object-fit: contain;
  border-radius: 6.17px;
}
@media (max-width: 1023px) {
  .mobile_menu__partners a img {
    height: clamp(42px, 0.0199146515 * 100vw + 35.627311522px, 56px);
  }
}
.mobile_menu__footer span, .mobile_menu__footer a {
  font-size: 22px;
  line-height: 24.2px;
  color: #707881;
  margin-bottom: 17px;
  display: inline-block;
}
@media (max-width: 1023px) {
  .mobile_menu__footer span, .mobile_menu__footer a {
    font-size: clamp(12px, 0.0142247511 * 100vw + 7.4480796586px, 22px);
    line-height: clamp(13.2px, 0.0156472262 * 100vw + 8.1928876245px, 24.2px);
    margin-bottom: clamp(13px, 0.0056899004 * 100vw + 11.1792318634px, 17px);
  }
}
.mobile_menu__footer__container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .mobile_menu__footer__container {
    flex-direction: column;
  }
}
.mobile_menu .back {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  width: min-content;
}
.mobile_menu .back svg {
  stroke: rgba(255, 255, 255, 0.6);
  stroke-width: 2px;
  width: 34px;
  height: 34px;
  margin-right: 7px;
}
@media (max-width: 1023px) {
  .mobile_menu .back svg {
    width: clamp(25px, 0.012802276 * 100vw + 20.9032716927px, 34px);
    height: clamp(25px, 0.012802276 * 100vw + 20.9032716927px, 34px);
  }
}
@media (max-width: 1023px) {
  .mobile_menu .back {
    font-size: clamp(14px, 0.0142247511 * 100vw + 9.4480796586px, 24px);
    line-height: clamp(14px, 0.0142247511 * 100vw + 9.4480796586px, 24px);
  }
}
.mobile_menu .mobile_menu__items .mobile_main_menu .mobile_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 14px;
  border-bottom: 1px solid #37424D;
  margin-bottom: 15px;
}
.mobile_menu .mobile_menu__items .mobile_main_menu .mobile_item a {
  font-size: 24px;
  line-height: 26.4px;
  color: #F2F2F6;
}
@media (max-width: 1023px) {
  .mobile_menu .mobile_menu__items .mobile_main_menu .mobile_item a {
    font-size: clamp(17px, 0.0099573257 * 100vw + 13.813655761px, 24px);
    line-height: clamp(18.7px, 0.0109530583 * 100vw + 15.1950213371px, 26.4px);
  }
}
@media (max-width: 1023px) {
  .mobile_menu .mobile_menu__items .mobile_main_menu .mobile_item {
    margin-bottom: clamp(10px, 0.0071123755 * 100vw + 7.7240398293px, 15px);
    padding-bottom: clamp(10px, 0.0056899004 * 100vw + 8.1792318634px, 14px);
  }
}
.mobile_menu .mobile_menu__items .mobile_main_menu .open_submenu svg {
  width: 34px;
  height: 34px;
  stroke-width: 2px;
  stroke: #C0FF00;
  transform: rotate(180deg);
}
@media (max-width: 1023px) {
  .mobile_menu .mobile_menu__items .mobile_main_menu .open_submenu svg {
    width: clamp(25px, 0.012802276 * 100vw + 20.9032716927px, 34px);
    height: clamp(25px, 0.012802276 * 100vw + 20.9032716927px, 34px);
  }
}
.mobile_menu .mobile_menu__items .mobile_top_menu {
  display: flex;
  flex-direction: column;
}
.mobile_menu .mobile_menu__items .mobile_top_menu .top_menu {
  font-size: 24px;
  line-height: 24px;
  color: #F2F2F6;
  width: min-content;
  white-space: nowrap;
}
.mobile_menu .mobile_menu__items .mobile_top_menu .top_menu:not(:last-child) {
  margin-bottom: 28px;
}
@media (max-width: 1023px) {
  .mobile_menu .mobile_menu__items .mobile_top_menu .top_menu:not(:last-child) {
    margin-bottom: clamp(12px, 0.0227596017 * 100vw + 4.7169274538px, 28px);
  }
}
@media (max-width: 1023px) {
  .mobile_menu .mobile_menu__items .mobile_top_menu .top_menu {
    font-size: clamp(14px, 0.0142247511 * 100vw + 9.4480796586px, 24px);
  }
}
.mobile_menu .mobile_menu__items .mobile_submenu {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  transform: translateX(100%);
  transition: transform 0.4s ease;
  overflow: auto;
  will-change: transform;
}
.mobile_menu .mobile_menu__items .mobile_submenu.opened {
  transform: translateX(0%);
}
.mobile_menu .mobile_menu__items .mobile_submenu .back {
  margin-top: 16px;
  margin-bottom: 27px;
}
.mobile_menu .mobile_menu__items .mobile_submenu .sumenu_items {
  display: flex;
  flex-direction: column;
}
.mobile_menu .mobile_menu__items .mobile_submenu .sumenu_items .item {
  padding-top: 16px;
  color: #F2F2F6;
  font-size: 24px;
  line-height: 26.4px;
}
.mobile_menu .mobile_menu__items .mobile_submenu .sumenu_items .item:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding-bottom: 20px;
}
@media (max-width: 1023px) {
  .mobile_menu .mobile_menu__items .mobile_submenu .sumenu_items .item {
    padding-top: clamp(10px, 0.0085348506 * 100vw + 7.2688477952px, 16px);
    padding-bottom: clamp(10px, 0.0142247511 * 100vw + 5.4480796586px, 20px);
    font-size: clamp(17px, 0.0099573257 * 100vw + 13.813655761px, 24px);
    line-height: clamp(18.7px, 0.0109530583 * 100vw + 15.1950213371px, 26.4px);
  }
}
.mobile_menu .mobile_menu__items .mobile_city {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  transform: translateX(100%);
  transition: transform 0.4s ease;
  overflow: auto;
  will-change: transform;
  background: #121B24;
}
.mobile_menu .mobile_menu__items .mobile_city.opened {
  transform: translateX(0%);
}
.mobile_menu .mobile_menu__items .mobile_city__search input {
  max-width: 100%;
  width: 100%;
  text-align: center;
  padding-top: 6px;
}
.mobile_menu .mobile_menu__items .mobile_city__search span {
  display: block;
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(20px, 0.0052137643 * 100vw + 18.3315954119px, 25px);
  line-height: clamp(20px, 0.0052137643 * 100vw + 18.3315954119px, 25px);
  margin-bottom: 13px;
}
.mobile_menu .mobile_menu__items .mobile_city__items {
  columns: 3;
  column-gap: 56px;
  margin-top: 34px;
  padding-bottom: 20px;
}
.mobile_menu .mobile_menu__items .mobile_city__items .item {
  margin-bottom: 12px;
}
.mobile_menu .mobile_menu__items .mobile_city__items .item a {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(14px, 0.0062565172 * 100vw + 11.9979144943px, 20px);
  line-height: clamp(15.4px, 0.0062565172 * 100vw + 13.3979144943px, 21.4px);
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (hover: hover) {
  .mobile_menu .mobile_menu__items .mobile_city__items .item a:hover {
    color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .mobile_menu .mobile_menu__items .mobile_city__items .item a:active {
    color: #C0FF00;
  }
}
.mobile_menu .mobile_menu__items .mobile_city__items .item.now_region a {
  color: #C0FF00;
}
@media (max-width: 768px) {
  .mobile_menu .mobile_menu__items .mobile_city__items {
    columns: 2;
  }
}
.mobile_menu .mobile_menu__items .mobile_city .back {
  margin-top: 16px;
  margin-bottom: 27px;
}
.mobile_menu .btn {
  margin-top: 50px;
  margin-bottom: 64px;
  padding-top: 9.39px;
  padding-bottom: 14.61px;
  width: 100%;
}
.mobile_menu .btn span {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  color: #C0FF00;
}
@media (max-width: 1279px) {
  .mobile_menu .btn span {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .mobile_menu .btn span {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1023px) {
  .mobile_menu .btn {
    margin-top: clamp(32px, 0.0256045519 * 100vw + 23.8065433855px, 50px);
    margin-bottom: clamp(33px, 0.0440967283 * 100vw + 18.8890469417px, 64px);
    padding-top: clamp(6px, 0.0048221906 * 100vw + 4.4568990043px, 9.39px);
    padding-bottom: clamp(10px, 0.0065576102 * 100vw + 7.9015647226px, 14.61px);
  }
}
@media (max-width: 1279px) {
  .mobile_menu {
    display: block;
  }
}

.footer__top {
  background: #1A252F;
  border: 1px solid #37424D;
  padding-top: clamp(26.6666666667px, 2.0833333333vw, 40px);
  padding-bottom: clamp(35.6666666667px, 2.7864583333vw, 53.5px);
}
.footer__top__container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: clamp(21.3333333333px, 1.6666666667vw, 32px) clamp(34px, 2.65625vw, 51px);
}
.footer__top__container .parent {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  color: #707881;
  margin-bottom: clamp(5.3333333333px, 0.4166666667vw, 8px);
}
@media (max-width: 1279px) {
  .footer__top__container .parent {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .footer__top__container .parent {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .footer__top__container .parent {
    margin-bottom: 19px;
  }
}
@media (max-width: 1023px) {
  .footer__top__container .parent {
    margin-bottom: clamp(12px, 0.0099573257 * 100vw + 8.813655761px, 19px);
  }
}
.footer__top__container .item__sub {
  display: flex;
  flex-direction: column;
}
.footer__top__container .item__sub a {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .footer__top__container .item__sub a {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .footer__top__container .item__sub a {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.footer__top__container .item__sub a:not(:last-child) {
  margin-bottom: clamp(4.2666666667px, 0.3333333333vw, 6.4px);
}
@media (max-width: 1279px) {
  .footer__top__container .item__sub a:not(:last-child) {
    margin-bottom: 14px;
  }
}
@media (max-width: 1023px) {
  .footer__top__container .item__sub a:not(:last-child) {
    margin-bottom: clamp(7.5px, 0.0092460882 * 100vw + 4.5412517781px, 14px);
  }
}
@media (max-width: 1279px) {
  .footer__top__container .item__sub a {
    font-size: 24px;
    line-height: 26.4px;
  }
}
@media (max-width: 1023px) {
  .footer__top__container .item__sub a {
    font-size: clamp(14px, 0.0142247511 * 100vw + 9.4480796586px, 24px);
    line-height: clamp(15.4px, 0.0156472262 * 100vw + 10.3928876245px, 26.4px);
  }
}
.footer__top__container a {
  transition: all 0.3s ease;
  width: fit-content;
  display: block;
  text-transform: lowercase;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (hover: hover) {
  .footer__top__container a:hover {
    color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .footer__top__container a:active {
    color: #C0FF00;
  }
}
@media (max-width: 1279px) {
  .footer__top__container {
    grid-template-columns: repeat(auto-fill, minmax(clamp(200px, 0.240398293 * 100vw + 123.0725462304px, 369px), 1fr));
    justify-content: space-between;
    gap: 55px 10px;
  }
}
@media (max-width: 1023px) {
  .footer__top__container {
    row-gap: clamp(16px, 0.0554765292 * 100vw + -1.7524893314px, 55px);
  }
}
@media (max-width: 1279px) {
  .footer__top {
    padding-top: 99px;
  }
}
@media (max-width: 1023px) {
  .footer__top {
    padding-top: clamp(29px, 0.0995732575 * 100vw + -2.8634423898px, 99px);
  }
}
.footer__bottom {
  display: flex;
  justify-content: space-between;
  padding-top: clamp(29px, 2.265625vw, 43.5px);
  padding-bottom: clamp(26.6666666667px, 2.0833333333vw, 40px);
}
.footer__bottom__right svg {
  width: clamp(92px, 7.1875vw, 138px);
  height: clamp(100px, 7.8125vw, 150px);
  margin-right: clamp(-13px, -0.6770833333vw, -8.6666666667px);
}
.footer__bottom__left {
  display: flex;
  flex-direction: column;
  width: 77%;
}
.footer__bottom__left .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer__bottom__left .top .contacts {
  display: flex;
  flex-direction: column;
}
.footer__bottom__left .top .contacts .tel {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
}
@media (max-width: 1279px) {
  .footer__bottom__left .top .contacts .tel {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .footer__bottom__left .top .contacts .tel {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
.footer__bottom__left .top .contacts .email {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: #C0FF00;
  text-decoration: underline;
  margin-top: 2px;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (max-width: 1279px) {
  .footer__bottom__left .top .contacts .email {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .footer__bottom__left .top .contacts .email {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
@media (hover: hover) {
  .footer__bottom__left .top .contacts .email:hover {
    text-decoration: none;
  }
}
@media (hover: none), (hover: on-demand) {
  .footer__bottom__left .top .contacts .email:active {
    text-decoration: none;
  }
}
.footer__bottom__left .top .contacts a {
  width: fit-content;
}
.footer__bottom__left .top .partners__top {
  display: flex;
  align-items: center;
}
.footer__bottom__left .top .partners__bottom {
  max-width: clamp(478.6666666667px, 37.3958333333vw, 718px);
  margin-top: clamp(12px, 0.9375vw, 18px);
}
.footer__bottom__left .top .partners__bottom span {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .footer__bottom__left .top .partners__bottom span {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .footer__bottom__left .top .partners__bottom span {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.footer__bottom__left .top .partners a:not(:last-child) {
  margin-right: clamp(29.3333333333px, 2.2916666667vw, 44px);
}
.footer__bottom__left .top .partners a img {
  height: clamp(31.3333333333px, 2.4479166667vw, 47px);
  width: 100%;
  object-fit: contain;
  border-radius: clamp(4.6666666667px, 0.3645833333vw, 7px);
}
.footer__bottom__left .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: clamp(31.3333333333px, 2.4479166667vw, 47px);
}
.footer__bottom__left .bottom span, .footer__bottom__left .bottom a {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: #707881;
}
@media (max-width: 1279px) {
  .footer__bottom__left .bottom span, .footer__bottom__left .bottom a {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .footer__bottom__left .bottom span, .footer__bottom__left .bottom a {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.footer__bottom__left .bottom a {
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (hover: hover) {
  .footer__bottom__left .bottom a:hover {
    color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .footer__bottom__left .bottom a:active {
    color: #C0FF00;
  }
}
@media (max-width: 1279px) {
  .footer__bottom {
    display: none;
  }
}

.page_404 {
  margin-top: clamp(64px, 5vw, 96px);
  padding-bottom: clamp(66.6666666667px, 5.2083333333vw, 100px);
}
.page_404 h1 {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  margin-bottom: clamp(13.3333333333px, 1.0416666667vw, 20px);
}
@media (max-width: 1279px) {
  .page_404 h1 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .page_404 h1 {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .page_404 h1 {
    margin-bottom: 16px;
  }
}
@media (max-width: 1023px) {
  .page_404 h1 {
    font-size: 30px;
    line-height: 33px;
  }
}
.page_404 .text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #707881;
  margin-bottom: clamp(42.6666666667px, 3.3333333333vw, 64px);
}
@media (max-width: 1279px) {
  .page_404 .text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .page_404 .text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1023px) {
  .page_404 .text {
    margin-bottom: clamp(16px, 0.0378378378 * 100vw + 3.8918918919px, 42.6px);
  }
}
.page_404__container {
  display: flex;
  justify-content: space-between;
}
.page_404__container .left {
  width: 39%;
}
@media (max-width: 1279px) {
  .page_404__container .left {
    width: 100%;
  }
}
.page_404__container .right img {
  height: clamp(386px, 30.15625vw, 579px);
  width: clamp(631.3333333333px, 49.3229166667vw, 947px);
  object-fit: cover;
  object-position: bottom;
}
@media (max-width: 1279px) {
  .page_404__container .right img {
    margin-left: auto;
    margin-right: auto;
    max-width: 947px;
    max-height: 579px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
@media (max-width: 1279px) {
  .page_404__container .right {
    width: 100%;
  }
}
@media (max-width: 1279px) {
  .page_404__container {
    flex-direction: column-reverse;
  }
}
@media (max-width: 1023px) {
  .page_404 {
    padding-bottom: 40px;
  }
}

.form-input {
  display: block;
  width: 100%;
  height: 3.8rem;
  padding: 0 2rem;
  font-size: 1.3333333333rem;
  color: var(--form-text-color);
  background: var(--form-bg-color);
  border: 0;
  border-bottom: 1px solid var(--form-border-color);
  border-radius: 0.6666666667rem;
  transition: border-color 0.4s;
  font-size: 1.3333333333rem;
  line-height: 1.3;
  font-family: "Inter", sans-serif;
}
@media (max-width: 1279px) {
  .form-input {
    font-size: 0.9333333333rem;
  }
}
.form-input::placeholder {
  color: var(--form-input-placeholder-color);
}
.form-input.error {
  border-color: var(--form-error-color) !important;
}
.form-input:focus + .form-placeholder, .form-input:not(:placeholder-shown) + .form-placeholder {
  transform: translateY(-50%) scale(0.6);
  color: var(--form-placeholder-active-color);
}
.form-input.absolute {
  padding-top: 0.5333333333rem;
}
.form-input.absolute::placeholder {
  opacity: 0;
}
@media (max-width: 1279px) {
  .form-input {
    height: 3.2666666667rem;
  }
}
.form-placeholder {
  margin: 0 0 0.3333333333rem 0.6666666667rem;
  color: var(--form-placeholder-color);
  font-size: 1.2rem;
  line-height: 1.3;
  font-family: "Inter", sans-serif;
  transition-property: transform;
  transition-duration: 0.4s;
}
@media (max-width: 1279px) {
  .form-placeholder {
    font-size: 0.6666666667rem;
  }
}
.form-placeholder.absolute {
  position: absolute;
  left: 1.0666666667rem;
  top: 0;
  bottom: 0;
  height: 1em;
  margin: auto;
  line-height: 1;
}
@media (max-width: 1279px) {
  .form-placeholder {
    font-size: 0.9333333333rem;
  }
}

select.form-input {
  appearance: none;
}

.ok-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 2.8rem;
  height: 1.9333333333rem;
  margin: auto;
  transform: scale(var(--form-status-scale));
  fill: var(--form-status-icon-color);
}

.preloader-icon {
  width: 1.6rem;
  height: 1.6rem;
  animation: spin 2s linear 0s infinite normal forwards;
}

.submit-btn {
  --form-status-icon-color: #fff;
}
.submit-btn .preloader-icon,
.submit-btn .ok-icon {
  display: none;
}
.submit-btn::before {
  content: attr(data-text);
}
.submit-btn.htmx-request {
  --bg-color: #275f40;
  --border-color: #275f40;
}
.submit-btn.htmx-request .preloader-icon {
  display: block;
}
.submit-btn.htmx-request .arrow {
  display: none;
}
.submit-btn.htmx-request::before {
  content: attr(data-loading-text);
}
.submit-btn.success .ok-icon {
  display: block;
}
.submit-btn:disabled, .submit-btn.htmx-request, .submit-btn.success {
  pointer-events: none;
}
.submit-btn .preloader-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2rem;
  margin: auto;
}
.submit-btn.alt .preloader-icon {
  right: 0;
  left: 0;
}
.submit-btn.alt.htmx-request, .submit-btn.alt.success {
  --text-color: transparent;
}
@media (max-width: 1279px) {
  .submit-btn {
    padding: 0.6666666667rem 1.3333333333rem 0.7333333333rem;
    border-radius: 0.4666666667rem;
  }
  .submit-btn.has-icon {
    justify-content: center;
  }
}

.form-result {
  display: flex;
  justify-content: space-between;
  padding: 0.6666666667rem 2rem;
  margin-top: 1rem;
  border-radius: 0.6666666667rem;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(154, 156, 179, 0.2);
}
.form-result__timer {
  flex: 0 0 auto;
  color: #9db7a9;
}
.form-result.success {
  color: #41bb4c;
}

.row-input {
  padding: 1rem 2rem;
  font-size: 1.3333333333rem;
  line-height: 1.3;
  color: var(--form-text-color);
  font-family: "Inter", sans-serif;
  border: 0;
  border-bottom: 2px solid var(--form-border-color);
}
.row-input::placeholder {
  color: var(--form-placeholder-color);
}
.row-input.error {
  border-color: var(--form-error-color);
}
@media (max-width: 1279px) {
  .row-input {
    padding: 0.6666666667rem 1.4rem;
    font-size: 0.9333333333rem;
  }
}

.dropdown-list {
  padding: 2rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1.3333333333rem;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 2;
  color: #333;
  background: #fff;
  border-radius: 0 0 2rem 2rem;
  box-shadow: 0px 4px 12px 0px rgba(154, 156, 179, 0.2);
}
.dropdown-list .list-item {
  text-align: left;
}
.dropdown-list .list-item.default {
  color: #9db7a9;
}
@media (max-width: 1279px) {
  .dropdown-list {
    gap: 1rem;
  }
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
}
.modal.is-open {
  display: block;
}
.modal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15vh 0 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.5);
}
.modal__header {
  display: flex;
  padding-bottom: 60px;
}
.modal__title {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  margin-right: 56px;
}
@media (max-width: 1279px) {
  .modal__title {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .modal__title {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
.modal__search {
  max-width: clamp(330.6666666667px, 25.8333333333vw, 496px);
  background: transparent;
  margin-top: clamp(3.3333333333px, 0.2604166667vw, 5px);
  padding-bottom: clamp(10px, 0.78125vw, 15px);
  padding-left: clamp(29.3333333333px, 2.2916666667vw, 44px);
}
.modal__close {
  width: clamp(16px, 1.25vw, 24px);
  height: clamp(16px, 1.25vw, 24px);
  stroke: #C0FF00;
  stroke-width: 2px;
  cursor: pointer;
  position: absolute;
  top: clamp(18.6666666667px, 1.4583333333vw, 28px);
  right: clamp(19.3333333333px, 1.5104166667vw, 29px);
}
.modal__container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  background: #212D37;
  border-radius: clamp(20px, 1.5625vw, 30px);
  width: clamp(814.6666666667px, 63.6458333333vw, 1222px);
  padding: clamp(30.6666666667px, 2.3958333333vw, 46px) clamp(19.3333333333px, 1.5104166667vw, 29px) 0 clamp(66.6666666667px, 5.2083333333vw, 100px);
}
.modal[aria-hidden=false] .modal__overlay {
  animation: mmfadeIn 0.4s cubic-bezier(0, 0, 0.2, 1);
}
.modal[aria-hidden=false] .modal__container {
  animation: mmslideIn 0.4s cubic-bezier(0, 0, 0.2, 1);
}
.modal[aria-hidden=true] .modal__overlay {
  animation: mmfadeOut 0.4s cubic-bezier(0, 0, 0.2, 1);
}
.modal[aria-hidden=true] .modal__container {
  animation: mmslideOut 0.4s cubic-bezier(0, 0, 0.2, 1);
}

#region_modal .modal__container {
  height: 100%;
  max-height: clamp(398px, 31.09375vw, 597px);
  overflow: hidden;
}
#region_modal .modal__content {
  height: 100%;
  max-height: clamp(273.3333333333px, 21.3541666667vw, 410px);
  overflow: auto;
  padding-right: clamp(26.6666666667px, 2.0833333333vw, 40px);
}
#region_modal .modal__content::-webkit-scrollbar {
  width: 3px;
}
#region_modal .modal__content::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #212D37 49%, #37424D 50%, #212D37 51%);
  width: 1px;
}
#region_modal .modal__content::-webkit-scrollbar-thumb {
  background: #707881;
  border-radius: 7px;
  width: 100%;
  cursor: pointer;
  transition: background 0.3s ease;
}
#region_modal .modal__content::-webkit-scrollbar-thumb:hover {
  background: #C0FF00;
}
#region_modal .modal__city_items {
  columns: 4;
  column-gap: clamp(66.6666666667px, 5.2083333333vw, 100px);
  padding-bottom: clamp(20px, 1.5625vw, 30px);
}
#region_modal .modal__city_items .item {
  margin-bottom: clamp(8px, 0.625vw, 12px);
}
#region_modal .modal__city_items .item a {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (max-width: 1279px) {
  #region_modal .modal__city_items .item a {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  #region_modal .modal__city_items .item a {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (hover: hover) {
  #region_modal .modal__city_items .item a:hover {
    color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  #region_modal .modal__city_items .item a:active {
    color: #C0FF00;
  }
}
#region_modal .modal__city_items .item.now_region a {
  color: #C0FF00;
}
@media (max-width: 1279px) {
  #region_modal {
    display: none !important;
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.default-form-colors {
  --form-border-color: #fff;
  --form-text-color: #000;
  --form-input-placeholder-color: #c8c8c8;
  --form-personal-color: #000;
  --form-personal-link-color: #000;
  --form-status-icon-color: #fff;
  --form-status-scale: 1;
  --form-placeholder-color: #aaa;
  --form-error-color: #f00;
  --form-bg-color: #fff;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.custom-checkbox input {
  display: none;
}
.custom-checkbox input:checked + .checkbox {
  background: #275f40;
}
.custom-checkbox input:checked + .checkbox .checkbox-icon {
  opacity: 1;
}
.custom-checkbox .checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.4666666667rem;
  height: 1.4666666667rem;
  margin-right: 1.3333333333rem;
  border: 2px solid #275f40;
  border-radius: 2px;
}
.custom-checkbox .checkbox-icon {
  width: 1rem;
  height: 0.7333333333rem;
  fill: #fff;
  opacity: 0;
}
@media (max-width: 1279px) {
  .custom-checkbox .checkbox {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.6666666667rem;
  }
}

.password-input {
  position: relative;
}
.password-input .eye {
  width: 2.1333333333rem;
  height: 1.3333333333rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1.2666666667rem;
  margin: auto;
}
.password-input .eye-icon {
  width: 100%;
  height: 100%;
  fill: #faa746;
  stroke: #faa746;
}

.default-modal {
  --close-bg-color: #000;
  --close-icon-color: #fff;
  --container-bg-color: #aaa;
}
.default-modal .modal__container {
  max-width: 47%;
  padding: 30px 40px 40px;
  border-radius: 15px;
}
.default-modal .modal__title {
  margin-bottom: 31px;
  font-size: 36px;
  font-weight: 700;
}

.text-page {
  padding-top: clamp(73.3333333333px, 5.7291666667vw, 110px);
}
.text-page .case_container {
  width: 100%;
}
.text-page .content-block {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: clamp(1150px, 89.84375vw, 1725px);
}
@media (max-width: 1279px) {
  .text-page .content-block {
    max-width: 100%;
    padding: 0 56px;
  }
}
@media (max-width: 1023px) {
  .text-page .content-block {
    padding: 0 clamp(18px, 0.0540540541 * 100vw + 0.7027027027px, 56px);
  }
}
.text-page .content-gallery.cols-2 img {
  height: auto;
}
.text-page .content-gallery {
  margin-bottom: clamp(33.3333333333px, 2.6041666667vw, 50px);
}
@media (max-width: 1023px) {
  .text-page {
    padding-top: clamp(46px, 0.0384068279 * 100vw + 33.7098150782px, 73px);
  }
}

.content-block {
  margin-bottom: clamp(33.3333333333px, 2.6041666667vw, 50px);
}
@media (max-width: 1279px) {
  .content-block {
    margin-bottom: 36px;
  }
}

.content-text h1 {
  font-family: "Hoves Regular";
  font-size: clamp(66.6666666667px, 5.2083333333vw, 100px);
  line-height: clamp(60px, 4.6875vw, 90px);
  font-weight: 400;
  color: #F2F2F6;
  margin-bottom: clamp(40px, 3.125vw, 60px);
}
@media (max-width: 1279px) {
  .content-text h1 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .content-text h1 {
    font-size: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    line-height: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
  }
}
@media (max-width: 1023px) {
  .content-text h1 {
    margin-bottom: clamp(16px, 0.0341394026 * 100vw + 5.0753911807px, 40px);
  }
}
.content-text h2 {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  margin-bottom: clamp(16.6666666667px, 1.3020833333vw, 25px);
  margin-top: clamp(33.3333333333px, 2.6041666667vw, 50px);
}
@media (max-width: 1279px) {
  .content-text h2 {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .content-text h2 {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
@media (max-width: 1023px) {
  .content-text h2 {
    margin-bottom: clamp(16px, 0.0341394026 * 100vw + 5.0753911807px, 40px);
  }
}
.content-text h3 {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  margin-top: clamp(33.3333333333px, 2.6041666667vw, 50px);
  margin-bottom: clamp(16.6666666667px, 1.3020833333vw, 25px);
}
@media (max-width: 1279px) {
  .content-text h3 {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .content-text h3 {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1023px) {
  .content-text h3 {
    margin-top: 36px;
    margin-bottom: clamp(12px, 0.0056899004 * 100vw + 10.1792318634px, 16px);
  }
}
.content-text h4 {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  font-weight: 400;
  margin-top: clamp(33.3333333333px, 2.6041666667vw, 50px);
  margin-bottom: clamp(16.6666666667px, 1.3020833333vw, 25px);
}
@media (max-width: 1279px) {
  .content-text h4 {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .content-text h4 {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
@media (max-width: 1023px) {
  .content-text h4 {
    margin-top: 36px;
    margin-bottom: clamp(12px, 0.0056899004 * 100vw + 10.1792318634px, 16px);
  }
}
.content-text p {
  margin-bottom: clamp(16.6666666667px, 1.3020833333vw, 25px);
}
.content-text p.caption {
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(15.6px, 1.21875vw, 23.4px);
  color: #707881;
}
@media (max-width: 1279px) {
  .content-text p.caption {
    font-size: 12px;
    line-height: 14.67px;
  }
}
@media (max-width: 1023px) {
  .content-text p {
    margin-bottom: clamp(14px, 0.0028449502 * 100vw + 13.0896159317px, 16px);
  }
}
.content-text ul, .content-text ol, .content-text a, .content-text p {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .content-text ul, .content-text ol, .content-text a, .content-text p {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .content-text ul, .content-text ol, .content-text a, .content-text p {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.content-text ul, .content-text ol {
  margin-bottom: clamp(16.6666666667px, 1.3020833333vw, 25px);
  padding-left: clamp(28px, 2.1875vw, 42px);
}
.content-text ul li, .content-text ol li {
  margin-bottom: clamp(10.6666666667px, 0.8333333333vw, 16px);
}
.content-text ul li ul, .content-text ul li ol, .content-text ol li ul, .content-text ol li ol {
  margin-top: clamp(16.6666666667px, 1.3020833333vw, 25px);
}
@media (max-width: 1023px) {
  .content-text ul li ul, .content-text ul li ol, .content-text ol li ul, .content-text ol li ol {
    margin-top: clamp(8px, 0.0042674253 * 100vw + 6.6344238976px, 11px);
  }
}
@media (max-width: 1023px) {
  .content-text ul li, .content-text ol li {
    margin-bottom: clamp(8px, 0.0042674253 * 100vw + 6.6344238976px, 11px);
  }
}
.content-text ul li {
  position: relative;
  list-style-type: disc;
}
.content-text ul li::marker {
  color: #C0FF00;
}
.content-text ol {
  list-style: decimal;
}
.content-text a {
  color: #C0FF00;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
.content-text a:not(:visited) {
  text-decoration: underline;
}
@media (hover: hover) {
  .content-text a:hover {
    text-decoration: none;
  }
}
@media (hover: none), (hover: on-demand) {
  .content-text a:active {
    text-decoration: none;
  }
}
.content-text a:visited {
  color: #406AFF;
}
.content-text img {
  display: block;
  margin: clamp(40px, 3.125vw, 60px) auto;
  max-width: 100%;
  width: auto !important;
  height: auto !important;
}
.content-text .table {
  overflow: auto;
  margin-bottom: 20px;
}
.content-text table {
  min-width: 100%;
  overflow: hidden;
  border-collapse: collapse;
  border: 1px solid #37424D;
}
.content-text table td,
.content-text table th {
  padding: clamp(16.6666666667px, 1.3020833333vw, 25px) clamp(24px, 1.875vw, 36px);
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  border: 1px solid #37424D;
}
@media (max-width: 1279px) {
  .content-text table td,
  .content-text table th {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .content-text table td,
  .content-text table th {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.content-text table tr:first-child td {
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(18.6666666667px, 1.4583333333vw, 28px);
  line-height: clamp(20.5333333333px, 1.6041666667vw, 30.8px);
  color: #F2F2F6;
  background: #37424D;
}
.content-text table tr:first-child td:not(:last-child) {
  border-right: 1px solid #121B24;
}
.content-text > :first-child {
  margin-top: 0;
}
.content-text > :last-child {
  margin-bottom: 0;
}

.content-text-image {
  display: grid;
  align-items: start;
  justify-content: space-between;
  grid-template-columns: 45.8% 49.1%;
}
.content-text-image .img {
  width: 100%;
  border-radius: 4rem;
}
.content-text-image.right-image .img {
  grid-column: 2/3;
  grid-row: 1/2;
}
@media (max-width: 1279px) {
  .content-text-image {
    grid-template-columns: 100%;
    gap: 1.3333333333rem;
  }
  .content-text-image .img {
    grid-column: 1/2;
    grid-row: 2/3;
    border-radius: 0.6666666667rem;
  }
  .content-text-image.right-image .img {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}

.content-image {
  display: flex;
  justify-content: center;
}
.content-image img {
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: clamp(13.3333333333px, 1.0416666667vw, 20px);
  object-fit: contain;
}
.content-image img.original_size {
  width: auto;
}

.content-files {
  display: flex;
  flex-direction: column;
  gap: clamp(11.3333333333px, 0.8854166667vw, 17px);
  margin-top: clamp(18.6666666667px, 1.4583333333vw, 28px);
  margin-bottom: clamp(33.3333333333px, 2.6041666667vw, 50px);
}
.content-files .icon {
  width: clamp(22.6666666667px, 1.7708333333vw, 34px);
  height: clamp(28.6666666667px, 2.2395833333vw, 43px);
  fill: #37424D;
  margin-right: clamp(10.6666666667px, 0.8333333333vw, 16px);
}
.content-files .content-file-row {
  display: flex;
  position: relative;
  align-items: center;
  width: fit-content;
}
.content-files .meta {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: #707881;
  margin-right: clamp(24px, 1.875vw, 36px);
}
@media (max-width: 1279px) {
  .content-files .meta {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .content-files .meta {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.content-files a {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #F2F2F6;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (max-width: 1279px) {
  .content-files a {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .content-files a {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (hover: hover) {
  .content-files a:hover {
    color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .content-files a:active {
    color: #C0FF00;
  }
}

.content-slider {
  display: flex;
  justify-content: space-between;
  height: clamp(500px, 39.0625vw, 750px);
}
.content-slider .swiper {
  margin: 0;
}
.content-slider .left {
  width: 19.7%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1023px) {
  .content-slider .left {
    width: 100%;
    height: clamp(41.52px, 0.1834992888 * 100vw + -17.199772404px, 170.52px);
    flex-direction: row;
    align-items: center;
  }
}
.content-slider .right {
  width: 77.3%;
  height: 100%;
}
@media (max-width: 1023px) {
  .content-slider .right {
    width: 100%;
    height: clamp(156.96px, 0.3755334282 * 100vw + 36.7893029872px, 420.96px);
    margin-bottom: clamp(12px, 0.0256045519 * 100vw + 3.8065433855px, 30px);
  }
}
.content-slider .mySwiper2 {
  width: 100%;
  height: 100%;
}
.content-slider .mySwiper {
  margin: clamp(18px, 1.40625vw, 27px) 0;
  width: 100%;
  height: 100%;
}
.content-slider .mySwiper .swiper-slide {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
.content-slider .mySwiper .swiper-slide img {
  opacity: 0.6;
}
.content-slider .mySwiper .swiper-slide.swiper-slide-thumb-active img {
  opacity: 1;
}
@media (hover: hover) {
  .content-slider .mySwiper .swiper-slide:hover img {
    opacity: 1;
  }
}
@media (hover: none), (hover: on-demand) {
  .content-slider .mySwiper .swiper-slide:active img {
    opacity: 1;
  }
}
@media (max-width: 1023px) {
  .content-slider .mySwiper {
    margin: 0 20px;
  }
}
.content-slider .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: clamp(13.3333333333px, 1.0416666667vw, 20px);
  transition: all 0.3s ease;
}
@media (max-width: 1023px) {
  .content-slider .swiper-slide img {
    border-radius: 4px;
  }
}
.content-slider .prev, .content-slider .next {
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
}
.content-slider .prev svg, .content-slider .next svg {
  width: clamp(24.6666666667px, 1.9270833333vw, 37px);
  height: clamp(14.6666666667px, 1.1458333333vw, 22px);
  stroke: #F2F2F6;
  stroke-width: 3px;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (hover: hover) {
  .content-slider .prev svg:hover, .content-slider .next svg:hover {
    stroke: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .content-slider .prev svg:active, .content-slider .next svg:active {
    stroke: #C0FF00;
  }
}
@media (max-width: 1023px) {
  .content-slider .prev svg, .content-slider .next svg {
    height: 8.32px;
    width: 16px;
    stroke-width: 2px;
  }
}
.content-slider .prev.swiper-button-disabled, .content-slider .next.swiper-button-disabled {
  pointer-events: none;
}
.content-slider .prev.swiper-button-disabled svg, .content-slider .next.swiper-button-disabled svg {
  stroke: rgba(255, 255, 255, 0.5);
}
@media (max-width: 1023px) {
  .content-slider .prev, .content-slider .next {
    margin-top: 0;
  }
}
.content-slider .next {
  rotate: 180deg;
}
@media (max-width: 1023px) {
  .content-slider .next {
    rotate: 90deg;
  }
}
@media (max-width: 1023px) {
  .content-slider .prev {
    rotate: 270deg;
  }
}
@media (max-width: 1023px) {
  .content-slider {
    flex-direction: column-reverse;
    height: auto;
  }
}

.content-result-case .content-text p {
  color: #707881;
}
.content-result-case .repeater-items {
  display: flex;
  flex-wrap: wrap;
  margin-top: clamp(36px, 2.8125vw, 54px);
  gap: clamp(20px, 1.5625vw, 30px) clamp(10px, 0.78125vw, 15px);
}
.content-result-case .repeater-item {
  position: relative;
  width: clamp(180px, 14.0625vw, 270px);
}
.content-result-case .repeater-item .value {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  font-style: italic;
  margin-bottom: clamp(11px, 0.859375vw, 16.5px);
}
@media (max-width: 1279px) {
  .content-result-case .repeater-item .value {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .content-result-case .repeater-item .value {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
.content-result-case .repeater-item .text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .content-result-case .repeater-item .text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .content-result-case .repeater-item .text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.content-result-case .repeater-item svg {
  width: clamp(89.3333333333px, 6.9791666667vw, 134px);
  height: clamp(78.6666666667px, 6.1458333333vw, 118px);
  fill: #212D37;
  position: absolute;
  top: clamp(-20px, -1.0416666667vw, -13.3333333333px);
  left: clamp(-25px, -1.3020833333vw, -16.6666666667px);
  z-index: -1;
}
@media (max-width: 1279px) {
  .content-result-case .repeater-item svg {
    width: clamp(56px, 0.0347236705 * 100vw + 44.8884254432px, 89.3px);
    height: clamp(49px, 0.0308654849 * 100vw + 39.1230448384px, 78.6px);
  }
}

.content-gallery {
  display: grid;
  gap: clamp(23.3333333333px, 1.8229166667vw, 35px);
}
.content-gallery.mt {
  padding-top: clamp(60px, 4.6875vw, 90px);
}
@media (max-width: 1279px) {
  .content-gallery.mt {
    padding-top: 50px;
  }
}
.content-gallery.cols-1 {
  justify-content: center;
  grid-template-columns: 77.2%;
}
.content-gallery.cols-2 {
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 767px) {
  .content-gallery.cols-2 {
    grid-template-columns: repeat(1, 1fr);
  }
}
.content-gallery.cols-3 {
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 1023px) {
  .content-gallery.cols-3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .content-gallery.cols-3 {
    grid-template-columns: repeat(1, 1fr);
  }
}
.content-gallery.cols-4 {
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 1279px) {
  .content-gallery.cols-4 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1023px) {
  .content-gallery.cols-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .content-gallery.cols-4 {
    grid-template-columns: repeat(1, 1fr);
  }
}
.content-gallery-item__img {
  width: 100%;
}
.content-gallery-item__caption {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  line-height: clamp(15.6px, 1.21875vw, 23.4px);
  color: #707881;
  margin-top: clamp(11.3333333333px, 0.8854166667vw, 17px);
}
@media (max-width: 1279px) {
  .content-gallery-item__caption {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .content-gallery-item__caption {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
@media (max-width: 1023px) {
  .content-gallery-item__caption {
    margin-top: clamp(6px, 0.0075391181 * 100vw + 3.5874822191px, 11.3px);
  }
}
@media (max-width: 1279px) {
  .content-gallery {
    gap: 18px;
  }
}

.internal_banner__container {
  display: flex;
  padding-top: clamp(40px, 3.125vw, 60px);
}
.internal_banner__container .left {
  width: 42.2%;
  padding-top: clamp(32.6666666667px, 2.5520833333vw, 49px);
}
.internal_banner__container .left h1 {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(73.7px, 5.7578125vw, 110.55px);
}
@media (max-width: 1279px) {
  .internal_banner__container .left h1 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .internal_banner__container .left h1 {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
.internal_banner__container .left h1 span {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: clamp(129.0133333333px, 10.0791666667vw, 193.52px);
  line-height: clamp(129.0133333333px, 10.0791666667vw, 193.52px);
  color: #C0FF00;
  max-width: clamp(427.3333333333px, 33.3854166667vw, 641px);
  width: 100%;
  display: block;
  word-break: break-all;
  position: absolute;
  right: clamp(66.6666666667px, 5.2083333333vw, 100px);
  top: clamp(72.6666666667px, 5.6770833333vw, 109px);
}
@media (max-width: 1279px) {
  .internal_banner__container .left h1 span {
    top: auto;
    bottom: 22px;
    right: 50%;
    translate: 50% 0;
    font-size: clamp(90px, 0.162168926 * 100vw + 38.1059436913px, 245.52px);
    line-height: clamp(81px, 0.1715537018 * 100vw + 26.1028154327px, 245.52px);
    max-width: 815px;
  }
}
@media (max-width: 1279px) {
  .internal_banner__container .left h1 {
    font-size: clamp(30px, 0.0291970803 * 100vw + 20.6569343066px, 58px);
    line-height: clamp(33px, 0.0260688217 * 100vw + 24.6579770594px, 58px);
  }
}
.internal_banner__container .left .text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  line-height: clamp(18.2px, 1.421875vw, 27.3px);
  color: #707881;
  margin-top: clamp(22.6666666667px, 1.7708333333vw, 34px);
  max-width: 79%;
}
@media (max-width: 1279px) {
  .internal_banner__container .left .text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .internal_banner__container .left .text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1279px) {
  .internal_banner__container .left .text {
    max-width: 100%;
  }
}
@media (max-width: 1279px) {
  .internal_banner__container .left {
    width: 100%;
    padding-top: clamp(0px, 0.0333680918 * 100vw + -10.6777893639px, 32px);
  }
}
.internal_banner__container .right img {
  max-width: clamp(309.16px, 24.153125vw, 463.74px);
  max-height: clamp(412px, 32.1875vw, 618px);
  object-fit: contain;
  position: relative;
  z-index: 1;
}
@media (max-width: 1279px) {
  .internal_banner__container .right img {
    margin-left: auto;
    margin-top: 24px;
    max-width: clamp(180px, 0.2958706986 * 100vw + 85.3213764338px, 463.74px);
    max-height: clamp(240px, 0.3941605839 * 100vw + 113.8686131387px, 618px);
  }
}
@media (max-width: 1279px) {
  .internal_banner__container {
    flex-direction: column;
  }
}
.internal_banner .container_1920 {
  position: relative;
  border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
  overflow: hidden;
}
.internal_banner .background_line {
  z-index: -1;
}

.link_open_modal__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1023px) {
  .link_open_modal__container {
    position: relative;
    flex-direction: column;
    padding-top: 36px;
  }
}
.link_open_modal__title {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(66.6666666667px, 5.2083333333vw, 100px);
}
@media (max-width: 1279px) {
  .link_open_modal__title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .link_open_modal__title {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .link_open_modal__title {
    font-size: clamp(30px, 0.0291970803 * 100vw + 20.6569343066px, 58px);
    line-height: clamp(33px, 0.0260688217 * 100vw + 24.6579770594px, 58px);
  }
}
.link_open_modal__left {
  width: 68%;
}
.link_open_modal__left .btn_container {
  display: flex;
  margin-top: clamp(37.3333333333px, 2.9166666667vw, 56px);
}
.link_open_modal__left .btn_container svg {
  width: clamp(98px, 7.65625vw, 147px);
  height: clamp(98px, 7.65625vw, 147px);
  fill: transparent;
}
@media (max-width: 1279px) {
  .link_open_modal__left .btn_container svg {
    display: none;
  }
}
.link_open_modal__left .btn {
  margin-top: clamp(37.3333333333px, 2.9166666667vw, 56px);
}
.link_open_modal__left .btn.not_margin {
  margin-top: 0;
  margin-right: clamp(24.6666666667px, 1.9270833333vw, 37px);
  height: fit-content;
}
@media (max-width: 1023px) {
  .link_open_modal__left .btn.not_margin {
    margin-right: 0;
  }
}
@media (max-width: 1023px) {
  .link_open_modal__left .btn {
    position: absolute;
    right: 50%;
    bottom: clamp(35px, 0.0640113798 * 100vw + 14.5163584637px, 80px);
    translate: 50% 0;
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 425px) {
  .link_open_modal__left .btn {
    width: 100%;
  }
}
@media (max-width: 1279px) {
  .link_open_modal__left {
    width: 100%;
    max-width: 793px;
  }
}
.link_open_modal__right {
  width: 32%;
}
.link_open_modal__right img {
  width: 109%;
  height: clamp(501.3333333333px, 39.1666666667vw, 752px);
  object-fit: cover;
  object-position: bottom;
}
@media (max-width: 1023px) {
  .link_open_modal__right img {
    height: clamp(300px, 0.2859174964 * 100vw + 208.506401138px, 501px);
    width: 82%;
    margin-left: auto;
  }
}
@media (max-width: 1279px) {
  .link_open_modal__right {
    width: 100%;
    max-width: 373px;
  }
}
.link_open_modal__text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  margin-top: 44px;
}
@media (max-width: 1279px) {
  .link_open_modal__text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .link_open_modal__text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}

.kp {
  margin-top: clamp(74px, 5.78125vw, 111px);
  margin-bottom: clamp(60px, 4.6875vw, 90px);
}
.kp .left {
  width: 32%;
}
@media (max-width: 1279px) {
  .kp .left {
    width: 100%;
  }
}
.kp .right {
  width: 55%;
  padding-top: clamp(8px, 0.625vw, 12px);
}
@media (max-width: 1279px) {
  .kp .right {
    width: 100%;
  }
}
.kp__title {
  font-family: "Hoves Regular";
  font-size: clamp(66.6666666667px, 5.2083333333vw, 100px);
  line-height: clamp(60px, 4.6875vw, 90px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(72px, 5.625vw, 108px);
  text-wrap: balance;
  margin-bottom: clamp(54px, 4.21875vw, 81px);
  max-width: 80%;
}
@media (max-width: 1279px) {
  .kp__title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .kp__title {
    font-size: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    line-height: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
  }
}
@media (max-width: 1279px) {
  .kp__title {
    max-width: 100%;
  }
}
@media (max-width: 1023px) {
  .kp__title {
    margin-bottom: clamp(36px, 0.0256045519 * 100vw + 27.8065433855px, 54px);
  }
}
.kp__services {
  display: flex;
  margin-bottom: clamp(35.3333333333px, 2.7604166667vw, 53px);
}
.kp__services .right {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(6.6666666667px, 0.5208333333vw, 10px) 0;
}
@media (max-width: 1279px) {
  .kp__services {
    flex-direction: column;
  }
}
.kp__budgets {
  display: flex;
  margin-bottom: clamp(36.6666666667px, 2.8645833333vw, 55px);
}
.kp__budgets .right {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: clamp(6.6666666667px, 0.5208333333vw, 10px) 0;
}
@media (max-width: 1279px) {
  .kp__budgets {
    flex-direction: column;
  }
}
.kp__task {
  display: flex;
}
.kp__task .right {
  display: flex;
  justify-content: space-between;
  width: 68%;
}
.kp__task .right input {
  width: clamp(189.3333333333px, 14.7916666667vw, 284px);
}
.kp__task .right .input_container {
  display: flex;
  flex-direction: column;
  width: clamp(189.3333333333px, 14.7916666667vw, 284px);
}
.kp__task .right .input_container input {
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
}
.kp__task .right .input_container .drag_and_drop {
  position: relative;
  border: 3px dashed #37424D;
  border-radius: clamp(13.3333333333px, 1.0416666667vw, 20px);
  width: 100%;
  height: clamp(126.6666666667px, 9.8958333333vw, 190px);
  margin-bottom: clamp(15.3333333333px, 1.1979166667vw, 23px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: clamp(15.3333333333px, 1.1979166667vw, 23px);
  padding-right: clamp(15.3333333333px, 1.1979166667vw, 23px);
}
.kp__task .right .input_container .drag_and_drop .img_container {
  margin-bottom: clamp(10.6666666667px, 0.8333333333vw, 16px);
}
@media (max-width: 767px) {
  .kp__task .right .input_container .drag_and_drop {
    height: 190px;
  }
}
.kp__task .right .input_container .name_container {
  width: 100%;
  text-align: center;
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: #707881;
}
@media (max-width: 1279px) {
  .kp__task .right .input_container .name_container {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .kp__task .right .input_container .name_container {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.kp__task .right .input_container .name_container .file_name {
  display: flex;
  align-items: center;
  justify-content: center;
}
.kp__task .right .input_container .name_container .file_name span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.kp__task .right .input_container .name_container .file_name .button_clear {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 1px solid #707881;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
.kp__task .right .input_container .name_container .file_name .button_clear svg {
  width: 7px;
  height: 7px;
  stroke: #707881;
  transition: all 0.3s ease;
}
@media (hover: hover) {
  .kp__task .right .input_container .name_container .file_name .button_clear:hover {
    border-color: #C0FF00;
  }
  .kp__task .right .input_container .name_container .file_name .button_clear:hover svg {
    stroke: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .kp__task .right .input_container .name_container .file_name .button_clear:active {
    border-color: #C0FF00;
  }
  .kp__task .right .input_container .name_container .file_name .button_clear:active svg {
    stroke: #C0FF00;
  }
}
@media (max-width: 1279px) {
  .kp__task .right .input_container .name_container {
    font-size: 12px;
    line-height: 14.67px;
  }
}
.kp__task .right .input_container svg {
  width: clamp(50.6666666667px, 3.9583333333vw, 76px);
  height: clamp(50.6666666667px, 3.9583333333vw, 76px);
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .kp__task .right .input_container .button_kp div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .kp__task .right .input_container {
    width: 100%;
  }
}
@media (max-width: 1279px) {
  .kp__task .right {
    width: 100%;
    justify-content: flex-start;
    gap: 17px;
  }
}
@media (max-width: 767px) {
  .kp__task .right {
    flex-direction: column;
  }
}
@media (max-width: 1279px) {
  .kp__task {
    flex-direction: column;
  }
}
.kp__contacts {
  display: flex;
}
.kp__contacts__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: clamp(8px, 0.625vw, 12px) clamp(16px, 1.25vw, 24px);
}
@media (max-width: 1279px) {
  .kp__contacts__container {
    grid-template-columns: repeat(auto-fill, minmax(clamp(280px, 0.1251303441 * 100vw + 239.9582898853px, 400px), 1fr));
  }
}
.kp__contacts .btn {
  width: 100%;
}
@media (max-width: 1279px) {
  .kp__contacts .btn {
    margin-top: 17px;
  }
}
.kp__contacts .right {
  width: 50%;
}
@media (max-width: 1279px) {
  .kp__contacts .right {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.kp__contacts .personal {
  margin-top: clamp(25.3333333333px, 1.9791666667vw, 38px);
  max-width: clamp(200px, 15.625vw, 300px);
  margin-bottom: clamp(26px, 2.03125vw, 39px);
}
@media (max-width: 1279px) {
  .kp__contacts .personal {
    order: 1;
    max-width: 100%;
    text-align: center;
    margin-bottom: 0;
    margin-top: 18px;
  }
}
@media (max-width: 1279px) {
  .kp__contacts {
    flex-direction: column;
    gap: 17px;
    margin-top: 36px;
  }
}
.kp textarea {
  width: clamp(576px, 45vw, 864px);
  height: clamp(186.6666666667px, 14.5833333333vw, 280px);
}
@media (max-width: 1279px) {
  .kp textarea {
    width: clamp(460px, 0.2270058708 * 100vw + 285.6594911937px, 576px);
  }
}
@media (max-width: 767px) {
  .kp textarea {
    width: 100%;
    height: clamp(134px, 0.1163310962 * 100vw + 96.774049217px, 186px);
  }
}
.kp .input {
  background: #1A252F;
}
.kp__head {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  margin-bottom: clamp(17.3333333333px, 1.3541666667vw, 26px);
  max-width: 70%;
}
@media (max-width: 1279px) {
  .kp__head {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .kp__head {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
@media (max-width: 1279px) {
  .kp__head {
    max-width: 100%;
  }
}
@media (max-width: 1023px) {
  .kp__head {
    font-size: clamp(30px, 0.0142247511 * 100vw + 25.4480796586px, 40px);
    line-height: clamp(33px, 0.0156472262 * 100vw + 27.9928876245px, 44px);
    margin-bottom: clamp(10px, 0.0099573257 * 100vw + 6.813655761px, 17px);
  }
}
.kp__description {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: #707881;
  max-width: 80%;
}
@media (max-width: 1279px) {
  .kp__description {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .kp__description {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
@media (max-width: 1279px) {
  .kp__description {
    max-width: 100%;
    margin-bottom: 10px;
  }
}
.kp .line {
  width: 87%;
  height: 2px;
  background: #37424D;
  margin-top: clamp(18px, 1.40625vw, 27px);
  margin-bottom: clamp(14px, 1.09375vw, 21px);
}
@media (max-width: 1279px) {
  .kp .line {
    display: none;
  }
}
@media (max-width: 1279px) {
  .kp {
    margin-top: clamp(50px, 0.0250260688 * 100vw + 41.9916579771px, 74px);
  }
}

.contacts_block {
  margin-top: clamp(72px, 5.625vw, 108px);
}
.contacts_block h1 {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .contacts_block h1 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .contacts_block h1 {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
.contacts_block__head {
  display: flex;
  align-items: center;
}
.contacts_block__head .btn {
  margin-left: clamp(42.6666666667px, 3.3333333333vw, 64px);
  margin-top: clamp(13.3333333333px, 1.0416666667vw, 20px);
}
@media (max-width: 1279px) {
  .contacts_block__head .btn {
    margin-left: 0;
    margin-top: 21px;
  }
}
@media (max-width: 425px) {
  .contacts_block__head .btn {
    width: 100%;
  }
}
@media (max-width: 1279px) {
  .contacts_block__head {
    flex-direction: column;
    align-items: flex-start;
  }
}
.contacts_block__container {
  margin-top: clamp(37.3333333333px, 2.9166666667vw, 56px);
  display: grid;
  grid-template-columns: 39% 41% 10%;
  gap: clamp(54.6666666667px, 4.2708333333vw, 82px);
}
.contacts_block__container .contacts {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(26.6666666667px, 2.0833333333vw, 40px) 0;
  justify-content: space-between;
}
.contacts_block__container .contacts .position {
  display: flex;
  flex-direction: column;
}
.contacts_block__container .contacts .position .address {
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(18.6666666667px, 1.4583333333vw, 28px);
  line-height: clamp(20.5333333333px, 1.6041666667vw, 30.8px);
  color: #F2F2F6;
}
.contacts_block__container .contacts .phone {
  display: flex;
  flex-direction: column;
}
.contacts_block__container .contacts .phone a {
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(18.6666666667px, 1.4583333333vw, 28px);
  line-height: clamp(20.5333333333px, 1.6041666667vw, 30.8px);
  color: #F2F2F6;
  color: #C0FF00;
  text-decoration: underline;
  transition: all 0.3s ease;
  width: fit-content;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (hover: hover) {
  .contacts_block__container .contacts .phone a:hover {
    text-decoration: none;
  }
}
@media (hover: none), (hover: on-demand) {
  .contacts_block__container .contacts .phone a:active {
    text-decoration: none;
  }
}
.contacts_block__container .contacts .emails {
  display: flex;
  flex-direction: column;
}
.contacts_block__container .contacts .emails__container {
  display: flex;
  flex-direction: column;
}
.contacts_block__container .contacts .emails__container .item {
  display: flex;
  align-items: center;
}
.contacts_block__container .contacts .emails__container .item a {
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(18.6666666667px, 1.4583333333vw, 28px);
  line-height: clamp(20.5333333333px, 1.6041666667vw, 30.8px);
  color: #F2F2F6;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (hover: hover) {
  .contacts_block__container .contacts .emails__container .item a:hover {
    color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .contacts_block__container .contacts .emails__container .item a:active {
    color: #C0FF00;
  }
}
.contacts_block__container .contacts .emails__container .item .desc {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: #707881;
  margin-left: clamp(13.3333333333px, 1.0416666667vw, 20px);
}
@media (max-width: 1279px) {
  .contacts_block__container .contacts .emails__container .item .desc {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .contacts_block__container .contacts .emails__container .item .desc {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
@media (max-width: 425px) {
  .contacts_block__container .contacts .emails__container .item .desc {
    margin-left: 0;
  }
}
.contacts_block__container .contacts .emails__container .item:not(:last-child) {
  margin-bottom: clamp(12px, 0.9375vw, 18px);
}
@media (max-width: 425px) {
  .contacts_block__container .contacts .emails__container .item {
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
  }
}
@media (max-width: 1279px) {
  .contacts_block__container .contacts {
    flex-direction: column;
    gap: 36px;
  }
}
.contacts_block__container .requisites {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 clamp(34.6666666667px, 2.7083333333vw, 52px);
}
.contacts_block__container .requisites .head_name {
  grid-column: 1/3;
}
@media (max-width: 1279px) {
  .contacts_block__container .requisites .head_name {
    grid-column: 1/2;
  }
}
.contacts_block__container .requisites .col1, .contacts_block__container .requisites .col2 {
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  gap: clamp(6.6666666667px, 0.5208333333vw, 10px);
}
@media (max-width: 1279px) {
  .contacts_block__container .requisites .col1, .contacts_block__container .requisites .col2 {
    grid-row: auto;
  }
}
.contacts_block__container .requisites .col1 {
  grid-column: 1/2;
}
@media (max-width: 1279px) {
  .contacts_block__container .requisites .col1 {
    grid-column: auto;
  }
}
.contacts_block__container .requisites .col2 {
  grid-column: 2/3;
}
@media (max-width: 1279px) {
  .contacts_block__container .requisites .col2 {
    grid-column: 1/2;
    margin-top: 10px;
  }
}
.contacts_block__container .requisites .item {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .contacts_block__container .requisites .item {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .contacts_block__container .requisites .item {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
@media (max-width: 1279px) {
  .contacts_block__container .requisites .item:not(:last-child) {
    margin-bottom: 10px;
  }
}
@media (max-width: 1023px) {
  .contacts_block__container .requisites .item {
    line-height: 15.6px;
  }
}
@media (max-width: 1279px) {
  .contacts_block__container .requisites {
    grid-template-columns: 1fr;
  }
}
.contacts_block__container .social__items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(clamp(26.6666666667px, 2.0833333333vw, 40px), 1fr));
  gap: clamp(10px, 0.78125vw, 15px);
  max-width: clamp(108.6666666667px, 8.4895833333vw, 163px);
}
@media (max-width: 1279px) {
  .contacts_block__container .social__items {
    grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
    max-width: 100%;
    margin-top: 15px;
  }
}
.contacts_block__container .social__item {
  width: clamp(26.6666666667px, 2.0833333333vw, 40px);
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1px solid #C0FF00;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contacts_block__container .social__item svg {
  width: clamp(12.6666666667px, 0.9895833333vw, 19px);
  aspect-ratio: 1;
  fill: #F2F2F6;
}
@media (max-width: 1279px) {
  .contacts_block__container .social__item svg {
    width: 19px;
  }
}
@media (max-width: 1279px) {
  .contacts_block__container .social__item {
    width: 40px;
  }
}
.contacts_block__container .head_name {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  color: #707881;
  margin-bottom: clamp(10px, 0.78125vw, 15px);
  max-width: clamp(133.3333333333px, 10.4166666667vw, 200px);
}
@media (max-width: 1279px) {
  .contacts_block__container .head_name {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .contacts_block__container .head_name {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .contacts_block__container .head_name {
    max-width: 100%;
    margin-bottom: 7px;
  }
}
@media (max-width: 1279px) {
  .contacts_block__container {
    grid-template-columns: 1fr;
    margin-top: 36px;
  }
}
.contacts_block__map {
  width: 100%;
  height: clamp(314px, 24.53125vw, 471px);
  margin-top: clamp(40px, 3.125vw, 60px);
}

.jobs {
  margin-top: clamp(63.3333333333px, 4.9479166667vw, 95px);
}
.jobs h1 {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .jobs h1 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .jobs h1 {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
.jobs__head {
  display: flex;
  align-items: center;
}
@media (max-width: 1279px) {
  .jobs__head {
    flex-direction: column;
    align-items: flex-start;
  }
}
.jobs__text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #707881;
  margin-left: clamp(262.6666666667px, 20.5208333333vw, 394px);
  margin-top: clamp(16px, 1.25vw, 24px);
}
@media (max-width: 1279px) {
  .jobs__text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .jobs__text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1279px) {
  .jobs__text {
    margin-left: 0;
  }
}
.jobs__conditions ol {
  list-style: decimal;
  padding-left: clamp(16.6666666667px, 1.3020833333vw, 25px);
  margin-top: clamp(33.3333333333px, 2.6041666667vw, 50px);
}
.jobs__conditions ol li {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  padding-left: clamp(6.6666666667px, 0.5208333333vw, 10px);
}
@media (max-width: 1279px) {
  .jobs__conditions ol li {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .jobs__conditions ol li {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.jobs__conditions ol li:not(:last-child) {
  margin-bottom: clamp(9.3333333333px, 0.7291666667vw, 14px);
}
.jobs__opening {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(clamp(348.6666666667px, 27.2395833333vw, 523px), 1fr));
  gap: clamp(20px, 1.5625vw, 30px) clamp(33.3333333333px, 2.6041666667vw, 50px);
  margin-top: clamp(40px, 3.125vw, 60px);
  margin-bottom: clamp(20px, 1.5625vw, 30px);
}
.jobs__opening .item {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  padding: clamp(15.3333333333px, 1.1979166667vw, 23px) 0;
  border-bottom: 1px solid #37424D;
  white-space: wrap;
  text-wrap: balance;
}
@media (max-width: 1279px) {
  .jobs__opening .item {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .jobs__opening .item {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .jobs__opening .item {
    padding-top: 0;
    line-height: clamp(15.64px, 0.016016684 * 100vw + 10.5146611053px, 31px);
  }
}
@media (max-width: 1279px) {
  .jobs__opening {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    margin-top: 36px;
  }
}

.blog {
  margin-top: clamp(64px, 5vw, 96px);
  padding-bottom: clamp(66.6666666667px, 5.2083333333vw, 100px);
}
.blog h1 {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  margin-bottom: clamp(44px, 3.4375vw, 66px);
}
@media (max-width: 1279px) {
  .blog h1 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .blog h1 {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .blog h1 {
    font-size: clamp(30px, 0.0291970803 * 100vw + 20.6569343066px, 58px);
    line-height: clamp(33px, 0.0260688217 * 100vw + 24.6579770594px, 58px);
    margin-bottom: clamp(30px, 0.0145985401 * 100vw + 25.3284671533px, 44px);
  }
}
.blog__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(clamp(366.6666666667px, 28.6458333333vw, 550px), 1fr));
  gap: clamp(51.3333333333px, 4.0104166667vw, 77px) clamp(23.3333333333px, 1.8229166667vw, 35px);
}
@media (max-width: 1279px) {
  .blog__list {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    row-gap: 30px;
  }
}
.blog__item {
  display: flex;
  flex-direction: column;
}
.blog__item__img {
  width: 100%;
  height: clamp(191.3333333333px, 14.9479166667vw, 287px);
  object-fit: cover;
  border-radius: clamp(13.3333333333px, 1.0416666667vw, 20px);
}
.blog__item__name {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  transition: all 0.3s ease;
  margin-left: clamp(4.6666666667px, 0.3645833333vw, 7px);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (max-width: 1279px) {
  .blog__item__name {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .blog__item__name {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
@media (hover: hover) {
  .blog__item__name:hover {
    color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .blog__item__name:active {
    color: #C0FF00;
  }
}
@media (max-width: 1023px) {
  .blog__item__name {
    margin-left: clamp(0px, 0.2604166667vw, 5px);
  }
}
.blog__item__text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  font-family: "Hoves Italic";
  color: #707881;
  margin-left: clamp(4.6666666667px, 0.3645833333vw, 7px);
  margin-top: clamp(6.6666666667px, 0.5208333333vw, 10px);
  max-width: 95%;
}
@media (max-width: 1279px) {
  .blog__item__text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .blog__item__text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1023px) {
  .blog__item__text {
    margin-left: clamp(0px, 0.2604166667vw, 5px);
    max-width: 100%;
    margin-top: 8px;
  }
}
.blog__date {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  font-family: "Hoves Italic";
  margin-top: clamp(12px, 0.9375vw, 18px);
  margin-bottom: clamp(5.3333333333px, 0.4166666667vw, 8px);
  margin-left: clamp(4.6666666667px, 0.3645833333vw, 7px);
}
@media (max-width: 1279px) {
  .blog__date {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .blog__date {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
@media (max-width: 1023px) {
  .blog__date {
    margin-left: clamp(0px, 0.2604166667vw, 5px);
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
.blog__page {
  margin-top: clamp(72.6666666667px, 5.6770833333vw, 109px);
  margin-bottom: clamp(60px, 4.6875vw, 90px);
}
.blog__page h1 {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(73.7px, 5.7578125vw, 110.55px);
  max-width: 80%;
  margin-bottom: clamp(40px, 3.125vw, 60px);
}
@media (max-width: 1279px) {
  .blog__page h1 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .blog__page h1 {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .blog__page h1 {
    font-size: clamp(30px, 0.0291970803 * 100vw + 20.6569343066px, 58px);
    line-height: clamp(33px, 0.0260688217 * 100vw + 24.6579770594px, 58px);
    margin-bottom: clamp(30px, 0.0145985401 * 100vw + 25.3284671533px, 44px);
  }
}
.blog__page__info {
  display: flex;
  align-items: center;
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  font-family: "Hoves Italic";
  color: #707881;
  gap: 0 clamp(44.6666666667px, 3.4895833333vw, 67px);
  margin-bottom: clamp(42px, 3.28125vw, 63px);
  margin-left: clamp(10.6666666667px, 0.8333333333vw, 16px);
}
@media (max-width: 1279px) {
  .blog__page__info {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .blog__page__info {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
@media (max-width: 1279px) {
  .blog__page__info {
    row-gap: clamp(4px, 0.0085348506 * 100vw + 1.2688477952px, 10px);
  }
}
@media (max-width: 767px) {
  .blog__page__info {
    flex-direction: column;
    margin-left: 0;
    align-items: flex-start;
    margin-bottom: clamp(16px, 0.0581655481 * 100vw + -2.6129753915px, 42px);
  }
}
.blog__page__content .case_container {
  width: 100% !important;
}
.blog__page__content .case_container .content-gallery.cols-2 img {
  max-height: clamp(314.6666666667px, 24.5833333333vw, 472px);
  height: 100%;
  object-fit: cover;
}
.blog__page__share_post {
  display: flex;
  align-items: center;
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  margin-top: clamp(26.6666666667px, 2.0833333333vw, 40px);
  margin-bottom: clamp(60px, 4.6875vw, 90px);
}
@media (max-width: 1279px) {
  .blog__page__share_post {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .blog__page__share_post {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
.blog__page__share_post .item {
  width: clamp(22px, 1.71875vw, 33px);
  height: clamp(22px, 1.71875vw, 33px);
  border-radius: clamp(7px, 0.546875vw, 10.5px);
  background: linear-gradient(180deg, #C0FF00 0%, #78C100 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.blog__page__share_post .item svg {
  width: clamp(16px, 1.25vw, 24px);
  height: clamp(16px, 1.25vw, 24px);
  fill: #121B24;
}
.blog__page__share_post .item:not(:last-child) {
  margin-right: clamp(16.6666666667px, 1.3020833333vw, 25px);
}
.blog__page__share_post .item:first-child {
  margin-left: clamp(14.6666666667px, 1.1458333333vw, 22px);
}
@media (max-width: 1023px) {
  .blog__page {
    margin-top: clamp(50px, 0.0321479374 * 100vw + 39.7126600284px, 72.6px);
  }
}

.rews {
  margin-top: clamp(66.6666666667px, 5.2083333333vw, 100px);
  margin-bottom: clamp(60px, 4.6875vw, 90px);
}
.rews h1 {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .rews h1 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .rews h1 {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .rews h1 {
    font-size: clamp(30px, 0.0291970803 * 100vw + 20.6569343066px, 58px);
    line-height: clamp(33px, 0.0260688217 * 100vw + 24.6579770594px, 58px);
  }
}
.rews__tabs {
  display: flex;
  margin-top: clamp(20px, 1.5625vw, 30px);
  flex-wrap: wrap;
}
@media (max-width: 1279px) {
  .rews__tabs {
    row-gap: 10px;
  }
}
.rews__tab {
  border: 1px solid #707881;
  border-radius: clamp(22.6866666667px, 1.7723958333vw, 34.03px);
  padding: clamp(3.3333333333px, 0.2604166667vw, 5px) clamp(13.3333333333px, 1.0416666667vw, 20px) clamp(4.6666666667px, 0.3645833333vw, 7px);
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  transition: all 0.3s ease;
  user-select: none;
}
@media (max-width: 1279px) {
  .rews__tab {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .rews__tab {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.rews__tab.active {
  opacity: 0.5;
  cursor: auto;
}
.rews__tab:not(.active) {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (hover: hover) {
  .rews__tab:not(.active):hover {
    border-color: #C0FF00;
    color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .rews__tab:not(.active):active {
    border-color: #C0FF00;
    color: #C0FF00;
  }
}
@media (max-width: 1279px) {
  .rews__tab {
    padding: 8px 20px 12px;
    font-size: 14px;
    line-height: 15.4px;
  }
}
.rews__content {
  margin-top: clamp(53.3333333333px, 4.1666666667vw, 80px);
}
.rews__content.google-yandex {
  display: flex;
  justify-content: center;
  gap: clamp(20px, 1.5625vw, 30px);
}
@media (max-width: 1279px) {
  .rews__content.google-yandex {
    flex-direction: column;
    max-width: 700px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
.rews__content .scan__item {
  display: flex;
}
.rews__content .scan__item:not(:last-child) {
  margin-bottom: clamp(33.3333333333px, 2.6041666667vw, 50px);
}
@media (max-width: 1023px) {
  .rews__content .scan__item:not(:last-child) {
    margin-bottom: 36px;
  }
}
@media (max-width: 1023px) {
  .rews__content .scan__item {
    flex-direction: column;
  }
}
.rews__content .scan__img {
  margin-right: clamp(28.6666666667px, 2.2395833333vw, 43px);
  overflow: hidden;
  width: clamp(193.3333333333px, 15.1041666667vw, 290px);
  height: clamp(273.3333333333px, 21.3541666667vw, 410px);
  display: block;
}
.rews__content .scan__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: clamp(13.3333333333px, 1.0416666667vw, 20px);
}
@media (max-width: 1279px) {
  .rews__content .scan__img {
    width: clamp(260px, 0.3137254902 * 100vw + -61.2549019608px, 340px);
    width: 340px;
    height: auto;
  }
}
@media (max-width: 1023px) {
  .rews__content .scan__img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: clamp(205px, 0.1920341394 * 100vw + 143.5490753912px, 340px);
  }
}
.rews__content .scan__name {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  margin-bottom: clamp(5.3333333333px, 0.4166666667vw, 8px);
}
@media (max-width: 1279px) {
  .rews__content .scan__name {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .rews__content .scan__name {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
.rews__content .scan__author {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #707881;
  margin-bottom: clamp(13.3333333333px, 1.0416666667vw, 20px);
}
@media (max-width: 1279px) {
  .rews__content .scan__author {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .rews__content .scan__author {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.rews__content .scan__text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .rews__content .scan__text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .rews__content .scan__text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.rews__content .video__items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(clamp(366.6666666667px, 28.6458333333vw, 550px), 1fr));
  gap: clamp(33.3333333333px, 2.6041666667vw, 50px) clamp(23.3333333333px, 1.8229166667vw, 35px);
}
@media (max-width: 1279px) {
  .rews__content .video__items {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}
.rews__content .video__item iframe, .rews__content .video__item img {
  width: 100%;
  height: clamp(205.3333333333px, 16.0416666667vw, 308px);
  border-radius: clamp(13.3333333333px, 1.0416666667vw, 20px);
  margin-bottom: clamp(13.3333333333px, 1.0416666667vw, 20px);
  cursor: pointer;
}
@media (max-width: 1023px) {
  .rews__content .video__item iframe, .rews__content .video__item img {
    margin-bottom: clamp(10px, 0.0046941679 * 100vw + 8.4978662873px, 13.3px);
    height: clamp(157px, 0.0687055477 * 100vw + 135.0142247511px, 205.3px);
    border-radius: 10px;
  }
}
.rews__content .video__name {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  margin-bottom: 8px;
  margin-left: 5px;
}
@media (max-width: 1279px) {
  .rews__content .video__name {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .rews__content .video__name {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
@media (max-width: 1023px) {
  .rews__content .video__name {
    margin-left: clamp(0px, 0.0071123755 * 100vw + -2.2759601707px, 5px);
    margin-bottom: clamp(5px, 0.0042674253 * 100vw + 3.6344238976px, 8px);
  }
}
.rews__content .video__author {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #707881;
  margin-left: 5px;
}
@media (max-width: 1279px) {
  .rews__content .video__author {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .rews__content .video__author {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1023px) {
  .rews__content .video__author {
    margin-left: clamp(0px, 0.0071123755 * 100vw + -2.2759601707px, 5px);
  }
}
@media (max-width: 1279px) {
  .rews__content {
    margin-top: clamp(36px, 0.0180396246 * 100vw + 30.2273201251px, 53.3px);
  }
}
@media (max-width: 1023px) {
  .rews {
    margin-top: clamp(50px, 0.0321479374 * 100vw + 39.7126600284px, 72.6px);
  }
}

.portfolio {
  padding-top: clamp(64px, 5vw, 96px);
  padding-bottom: clamp(60px, 4.6875vw, 90px);
}
.portfolio h1 {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  margin-bottom: clamp(10.6666666667px, 0.8333333333vw, 16px);
}
@media (max-width: 1279px) {
  .portfolio h1 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .portfolio h1 {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
.portfolio__sections {
  display: flex;
  align-items: center;
  gap: clamp(6px, 0.46875vw, 9px);
  flex-wrap: wrap;
}
.portfolio__section_item {
  padding: clamp(3.3333333333px, 0.2604166667vw, 5px) clamp(14px, 1.09375vw, 21px) clamp(5.3333333333px, 0.4166666667vw, 8px);
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  border-radius: clamp(22.6666666667px, 1.7708333333vw, 34px);
  transition: all 0.3s ease;
  background: #37424D;
  text-transform: lowercase;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (max-width: 1279px) {
  .portfolio__section_item {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .portfolio__section_item {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (hover: hover) {
  .portfolio__section_item:hover {
    color: #F2F2F6;
    border-color: var(--color);
    background: var(--color);
    box-shadow: 0px 16px 49.3px -6px var(--color);
  }
}
@media (hover: none), (hover: on-demand) {
  .portfolio__section_item:active {
    color: #F2F2F6;
    border-color: var(--color);
    background: var(--color);
    box-shadow: 0px 16px 49.3px -6px var(--color);
  }
}
.portfolio__section_item.active {
  color: #F2F2F6;
  border-color: var(--color);
  background: var(--color);
}
.portfolio__sub_sections {
  margin-top: clamp(30px, 2.34375vw, 45px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.portfolio__sub_sections label {
  margin-bottom: clamp(3.3333333333px, 0.2604166667vw, 5px);
}
.portfolio__sub_sections label span {
  text-transform: lowercase;
}
.portfolio__items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(clamp(366.6666666667px, 28.6458333333vw, 550px), 1fr));
  justify-items: stretch;
  justify-content: space-between;
  gap: clamp(33.3333333333px, 2.6041666667vw, 50px) clamp(23.3333333333px, 1.8229166667vw, 35px);
  margin-top: clamp(50px, 3.90625vw, 75px);
}
@media (max-width: 1279px) {
  .portfolio__items {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}
.portfolio__item {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
.portfolio__item .name {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  margin-top: clamp(12px, 0.9375vw, 18px);
  margin-left: auto;
  margin-right: auto;
  max-width: 97%;
  transition: all 0.3s ease;
}
@media (max-width: 1279px) {
  .portfolio__item .name {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .portfolio__item .name {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
.portfolio__item .text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  line-height: clamp(18.2px, 1.421875vw, 27.3px);
  font-style: italic;
  color: #707881;
  margin-left: auto;
  margin-right: auto;
  margin-top: clamp(5.3333333333px, 0.4166666667vw, 8px);
  max-width: 97%;
}
@media (max-width: 1279px) {
  .portfolio__item .text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .portfolio__item .text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.portfolio__item img {
  width: 100%;
  height: clamp(236.6666666667px, 18.4895833333vw, 355px);
  background: #9BAEF2;
  border-radius: clamp(13.3333333333px, 1.0416666667vw, 20px);
  object-fit: cover;
}
@media (hover: hover) {
  .portfolio__item:hover .name {
    color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .portfolio__item:active .name {
    color: #C0FF00;
  }
}

.case_detail_page .case_detail {
  padding-top: clamp(72px, 5.625vw, 108px);
  background: var(--case-bgc-color);
}
.case_detail_page .case_detail.bgc {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.case_detail_page .case_detail h1 {
  width: 100%;
  text-align: center;
  font-family: "Hoves Regular";
  font-size: clamp(66.6666666667px, 5.2083333333vw, 100px);
  line-height: clamp(60px, 4.6875vw, 90px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(73.3333333333px, 5.7291666667vw, 110px);
  margin: 0 auto;
  text-wrap: balance;
}
@media (max-width: 1279px) {
  .case_detail_page .case_detail h1 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .case_detail_page .case_detail h1 {
    font-size: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    line-height: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
  }
}
.case_detail_page .case_detail h1.dark {
  color: #121B24;
}
.case_detail_page .case_detail__info_container {
  margin-top: clamp(28px, 2.1875vw, 42px);
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: clamp(11.3333333333px, 0.8854166667vw, 17px);
  opacity: 0.7;
}
.case_detail_page .case_detail__info_container .date {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  margin-right: clamp(26.6666666667px, 2.0833333333vw, 40px);
}
@media (max-width: 1279px) {
  .case_detail_page .case_detail__info_container .date {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .case_detail_page .case_detail__info_container .date {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.case_detail_page .case_detail__info_container .date.dark {
  color: rgba(18, 27, 36, 0.7);
}
.case_detail_page .case_detail__info_container .date.white {
  color: rgba(255, 255, 255, 0.7);
}
.case_detail_page .case_detail__info_container .section, .case_detail_page .case_detail__info_container .sfera {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  padding: 5px 20px 7px;
  border: 1px solid #121B24;
  border-radius: 34.03px;
  text-transform: lowercase;
}
@media (max-width: 1279px) {
  .case_detail_page .case_detail__info_container .section, .case_detail_page .case_detail__info_container .sfera {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .case_detail_page .case_detail__info_container .section, .case_detail_page .case_detail__info_container .sfera {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.case_detail_page .case_detail__info_container .section.dark, .case_detail_page .case_detail__info_container .sfera.dark {
  color: rgba(18, 27, 36, 0.7);
  border-color: rgba(18, 27, 36, 0.7);
}
.case_detail_page .case_detail__info_container .section.white, .case_detail_page .case_detail__info_container .sfera.white {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}
.case_detail_page .case_detail__info_container .site_link {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  margin-right: 40px;
  text-decoration: underline;
}
@media (max-width: 1279px) {
  .case_detail_page .case_detail__info_container .site_link {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .case_detail_page .case_detail__info_container .site_link {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.case_detail_page .case_detail__info_container .site_link.dark {
  color: rgba(18, 27, 36, 0.7);
}
.case_detail_page .case_detail__info_container .site_link.white {
  color: rgba(255, 255, 255, 0.7);
}
@media (max-width: 1023px) {
  .case_detail_page .case_detail__info_container {
    margin-top: clamp(18px, 0.0142247511 * 100vw + 13.4480796586px, 28px);
  }
}
.case_detail_page .case_detail__detail_img {
  width: 100%;
  height: auto;
  margin-top: clamp(37.3333333333px, 2.9166666667vw, 56px);
}
@media (max-width: 1023px) {
  .case_detail_page .case_detail__detail_img {
    margin-top: clamp(27px, 0.0142247511 * 100vw + 22.4480796586px, 37px);
  }
}
.case_detail_page .case_detail__detail_text {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  font-size: clamp(16.6666666667px, 1.3020833333vw, 25px);
  line-height: clamp(20px, 1.5625vw, 30px);
  padding-top: clamp(60px, 4.6875vw, 90px);
  padding-bottom: clamp(60px, 4.6875vw, 90px);
}
@media (max-width: 1279px) {
  .case_detail_page .case_detail__detail_text {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .case_detail_page .case_detail__detail_text {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
.case_detail_page .case_detail__detail_text.dark {
  color: rgba(18, 27, 36, 0.7);
}
.case_detail_page .case_detail__detail_text.white {
  color: rgba(255, 255, 255, 0.7);
}
.case_detail_page .case_detail__detail_text p:not(:last-child) {
  margin-bottom: clamp(10.6666666667px, 0.8333333333vw, 16px);
}
@media (max-width: 1023px) {
  .case_detail_page .case_detail__detail_text {
    padding-bottom: 50px;
    font-size: clamp(14px, 0.0042674253 * 100vw + 12.6344238976px, 17px);
    line-height: clamp(16.8px, 0.0027027027 * 100vw + 15.9351351351px, 18.7px);
  }
}
.case_detail_page .case_detail__similar_case {
  padding-top: clamp(60px, 4.6875vw, 90px);
  padding-bottom: clamp(60px, 4.6875vw, 90px);
}
.case_detail_page .case_detail__similar_case__title {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  width: 100%;
  text-align: center;
  margin-bottom: clamp(40px, 3.125vw, 60px);
}
@media (max-width: 1279px) {
  .case_detail_page .case_detail__similar_case__title {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .case_detail_page .case_detail__similar_case__title {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
@media (max-width: 1023px) {
  .case_detail_page .case_detail__similar_case__title {
    margin-bottom: 20px;
    font-size: 30px;
  }
}
.case_detail_page .case_detail__similar_case__items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(clamp(373.3333333333px, 29.1666666667vw, 560px), 1fr));
  gap: clamp(13.3333333333px, 1.0416666667vw, 20px);
}
.case_detail_page .case_detail__similar_case__items img {
  width: 100%;
  height: auto;
  max-height: 357px;
  object-fit: cover;
}
.case_detail_page .case_detail__similar_case__items .name {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  margin-top: 18px;
  max-width: 97%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1279px) {
  .case_detail_page .case_detail__similar_case__items .name {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .case_detail_page .case_detail__similar_case__items .name {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
@media (max-width: 1023px) {
  .case_detail_page .case_detail__similar_case__items {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}
@media (max-width: 1023px) {
  .case_detail_page .case_detail__similar_case {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 1023px) {
  .case_detail_page .case_detail {
    padding-top: clamp(46px, 0.0369843528 * 100vw + 34.1650071124px, 72px);
  }
}
.case_detail_page .content_case.mt {
  margin-top: clamp(33.3333333333px, 2.6041666667vw, 50px);
  margin-bottom: clamp(33.3333333333px, 2.6041666667vw, 50px);
}
.case_detail_page .content-image img.original_size {
  width: auto;
}
.case_detail_page .content-video-text, .case_detail_page .content-text, .case_detail_page .content-image, .case_detail_page .content-slider, .case_detail_page .repeater-items {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: clamp(1150px, 89.84375vw, 1725px);
}
@media (max-width: 1279px) {
  .case_detail_page .content-video-text, .case_detail_page .content-text, .case_detail_page .content-image, .case_detail_page .content-slider, .case_detail_page .repeater-items {
    max-width: 100%;
    padding: 0 56px;
  }
}
@media (max-width: 1023px) {
  .case_detail_page .content-video-text, .case_detail_page .content-text, .case_detail_page .content-image, .case_detail_page .content-slider, .case_detail_page .repeater-items {
    padding: 0 clamp(18px, 0.0540540541 * 100vw + 0.7027027027px, 56px);
  }
}
.case_detail_page .content-video-text.cont, .case_detail_page .content-text.cont, .case_detail_page .content-image.cont, .case_detail_page .content-slider.cont, .case_detail_page .repeater-items.cont {
  max-width: 1920px;
  width: 59.4%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1279px) {
  .case_detail_page .content-video-text.cont, .case_detail_page .content-text.cont, .case_detail_page .content-image.cont, .case_detail_page .content-slider.cont, .case_detail_page .repeater-items.cont {
    padding: 0;
  }
}
@media (max-width: 1023px) {
  .case_detail_page .content-video-text.cont, .case_detail_page .content-text.cont, .case_detail_page .content-image.cont, .case_detail_page .content-slider.cont, .case_detail_page .repeater-items.cont {
    width: 100%;
    padding: 0 20px;
  }
}

.share_case {
  background: #1A252F;
  padding-top: clamp(52.6666666667px, 4.1145833333vw, 79px);
  padding-bottom: clamp(59.3333333333px, 4.6354166667vw, 89px);
}
.share_case__container {
  display: flex;
  align-items: center;
}
.share_case__container .left {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  width: 34%;
}
@media (max-width: 1279px) {
  .share_case__container .left {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .share_case__container .left {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
@media (max-width: 1023px) {
  .share_case__container .left {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    text-align: center;
    font-size: 30px;
    line-height: 33px;
  }
}
.share_case__container .right {
  display: flex;
  align-items: center;
  margin-left: clamp(31.3333333333px, 2.4479166667vw, 47px);
  flex-wrap: wrap;
}
.share_case__container .right .item {
  width: clamp(66.6666666667px, 5.2083333333vw, 100px);
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1px solid #C0FF00;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share_case__container .right .item:not(:last-child) {
  margin-right: clamp(14px, 1.09375vw, 21px);
}
@media (max-width: 1023px) {
  .share_case__container .right .item:not(:last-child) {
    margin-right: 0;
  }
}
.share_case__container .right .item svg {
  width: clamp(33.3333333333px, 2.6041666667vw, 50px);
  aspect-ratio: 1;
  fill: #D5DCE1;
}
@media (max-width: 1023px) {
  .share_case__container .right .item svg {
    width: 27.2px;
  }
}
@media (max-width: 1023px) {
  .share_case__container .right .item {
    width: 54.39px;
  }
}
@media (max-width: 1023px) {
  .share_case__container .right {
    margin-left: 0;
    gap: 20px 17.95px;
    justify-content: space-between;
  }
}
@media (max-width: 1023px) {
  .share_case__container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 1023px) {
  .share_case {
    padding-top: clamp(27px, 0.0739687055 * 100vw + 3.3300142248px, 79px);
    padding-bottom: clamp(37px, 0.0739687055 * 100vw + 13.3300142248px, 89px);
  }
}

.full_width_img img {
  width: 100%;
  height: auto;
}
.full_width_img img.pt {
  padding-top: clamp(17.3333333333px, 1.3541666667vw, 26px);
}
@media (max-width: 1279px) {
  .full_width_img img.pt {
    padding-top: clamp(5px, 0.0125130344 * 100vw + 0.9958289885px, 17px);
  }
}
.full_width_img img.pb {
  padding-bottom: clamp(17.3333333333px, 1.3541666667vw, 26px);
}
@media (max-width: 1279px) {
  .full_width_img img.pb {
    padding-bottom: clamp(5px, 0.0125130344 * 100vw + 0.9958289885px, 17px);
  }
}

.title_text_img {
  padding-top: clamp(60px, 4.6875vw, 90px);
}
.title_text_img.pb {
  padding-bottom: clamp(60px, 4.6875vw, 90px);
}
@media (max-width: 1023px) {
  .title_text_img.pb {
    padding-bottom: 50px;
  }
}
.title_text_img .top {
  width: 49.1%;
  margin-left: auto;
  margin-right: auto;
}
.title_text_img .top .title {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(26.4px, 2.0625vw, 39.6px);
  font-style: italic;
  margin-bottom: clamp(12px, 0.9375vw, 18px);
}
@media (max-width: 1279px) {
  .title_text_img .top .title {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .title_text_img .top .title {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
.title_text_img .top .title.center {
  text-align: center;
}
.title_text_img .top .title.dark {
  color: #121B24;
}
@media (max-width: 1279px) {
  .title_text_img .top .title {
    line-height: clamp(15.64px, 0.016016684 * 100vw + 10.5146611053px, 31px);
  }
}
.title_text_img .top .text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  line-height: clamp(16.8px, 1.3125vw, 25.2px);
  color: rgba(18, 27, 36, 0.7);
}
@media (max-width: 1279px) {
  .title_text_img .top .text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .title_text_img .top .text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.title_text_img .top .text.center {
  text-align: center;
}
.title_text_img .top .text.white, .title_text_img .top .text.gray {
  color: rgba(255, 255, 255, 0.7);
}
.title_text_img .top .text p:not(:last-child) {
  margin-bottom: clamp(17.3333333333px, 1.3541666667vw, 26px);
}
@media (max-width: 1279px) {
  .title_text_img .top {
    width: 100%;
  }
}
.title_text_img img {
  width: 100%;
  height: auto;
}
.title_text_img img.mt {
  margin-top: clamp(60px, 4.6875vw, 90px);
}
.title_text_img video {
  width: 100%;
}
.title_text_img video.mt {
  margin-top: clamp(60px, 4.6875vw, 90px);
}
@media (max-width: 1023px) {
  .title_text_img video.mt {
    margin-top: 50px;
  }
}
@media (max-width: 1023px) {
  .title_text_img {
    padding-top: 50px;
  }
}

.text_on_img {
  position: relative;
}
.text_on_img__text_container {
  position: absolute;
  top: clamp(27.3333333333px, 2.1354166667vw, 41px);
  width: 100%;
}
.text_on_img__text_container .text {
  margin-left: auto;
  margin-right: auto;
  font-style: italic;
  text-align: center;
  width: 30%;
}
.text_on_img__text_container .text.size-36 {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(26.4px, 2.0625vw, 39.6px);
}
@media (max-width: 1279px) {
  .text_on_img__text_container .text.size-36 {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .text_on_img__text_container .text.size-36 {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .text_on_img__text_container .text.size-36 {
    line-height: clamp(15.64px, 0.016016684 * 100vw + 10.5146611053px, 31px);
  }
}
.text_on_img__text_container .text.size-21 {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  line-height: clamp(16.8px, 1.3125vw, 25.2px);
}
@media (max-width: 1279px) {
  .text_on_img__text_container .text.size-21 {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .text_on_img__text_container .text.size-21 {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.text_on_img__text_container .text.gray {
  color: #707881;
}
.text_on_img__text_container .text.white {
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .text_on_img__text_container .text {
    width: 75%;
  }
}
@media (max-width: 1279px) {
  .text_on_img__text_container {
    top: clamp(10px, 0.0177267987 * 100vw + 4.3274244004px, 27px);
  }
}
.text_on_img img {
  width: 100%;
  height: clamp(886.6666666667px, 69.2708333333vw, 1330px);
  object-fit: cover;
}
@media (max-width: 1279px) {
  .text_on_img img {
    height: clamp(221.67px, 0.6933576642 * 100vw + -0.2044525547px, 886.6px);
  }
}

.only_text {
  padding-top: clamp(60px, 4.6875vw, 90px);
}
.only_text.pb {
  padding-bottom: clamp(60px, 4.6875vw, 90px);
}
@media (max-width: 1023px) {
  .only_text.pb {
    padding-bottom: 50px;
  }
}
.only_text .text {
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(16.6666666667px, 1.3020833333vw, 25px);
  line-height: clamp(20px, 1.5625vw, 30px);
  color: #707881;
}
.only_text .text.slim {
  width: 49.1%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1279px) {
  .only_text .text.slim {
    width: 100%;
  }
}
.only_text .text.center {
  text-align: center;
}
.only_text .text.div {
  text-wrap: balance;
}
.only_text .text.white {
  color: rgba(255, 255, 255, 0.7);
}
.only_text .text.gray {
  color: #707881;
}
.only_text .text p:not(:last-child) {
  margin-bottom: clamp(17.3333333333px, 1.3541666667vw, 26px);
}
@media (max-width: 1023px) {
  .only_text .text {
    font-size: clamp(14px, 0.0036984353 * 100vw + 12.8165007112px, 16.6px);
    line-height: clamp(16.8px, 0.0045519203 * 100vw + 15.3433854908px, 20px);
  }
}
@media (max-width: 1023px) {
  .only_text {
    padding-top: 50px;
  }
}

.long_img {
  padding-top: clamp(60px, 4.6875vw, 90px);
}
.long_img.pb {
  padding-bottom: clamp(60px, 4.6875vw, 90px);
}
@media (max-width: 1023px) {
  .long_img.pb {
    padding-bottom: 50px;
  }
}
.long_img img {
  width: 100%;
  height: auto;
}
@media (max-width: 1023px) {
  .long_img {
    padding-top: 50px;
  }
}

.title_text {
  padding-top: clamp(60px, 4.6875vw, 90px);
}
.title_text.pb {
  padding-bottom: clamp(60px, 4.6875vw, 90px);
}
@media (max-width: 1023px) {
  .title_text.pb {
    padding-bottom: 50px;
  }
}
.title_text .title {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  font-style: italic;
  margin-bottom: clamp(24px, 1.875vw, 36px);
  width: 100%;
}
@media (max-width: 1279px) {
  .title_text .title {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .title_text .title {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
.title_text .title.center {
  text-align: center;
}
.title_text .title.font-36 {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .title_text .title.font-36 {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .title_text .title.font-36 {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .title_text .title {
    margin-bottom: clamp(18px, 0.0062565172 * 100vw + 15.9979144943px, 24px);
  }
}
.title_text .text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  line-height: clamp(18.2px, 1.421875vw, 27.3px);
  color: rgba(255, 255, 255, 0.7);
}
@media (max-width: 1279px) {
  .title_text .text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .title_text .text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.title_text .text p:not(:last-child) {
  margin-bottom: clamp(17.3333333333px, 1.3541666667vw, 26px);
}
@media (max-width: 1023px) {
  .title_text {
    padding-top: 50px;
  }
}

.text_img {
  padding-top: clamp(58.6666666667px, 4.5833333333vw, 88px);
}
.text_img.pb {
  padding-bottom: clamp(60px, 4.6875vw, 90px);
}
@media (max-width: 1023px) {
  .text_img.pb {
    padding-bottom: 50px;
  }
}
.text_img__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text_img__container.left {
  flex-direction: row;
}
@media (max-width: 1279px) {
  .text_img__container.left {
    flex-direction: column;
  }
}
.text_img__container.right {
  flex-direction: row-reverse;
}
@media (max-width: 1279px) {
  .text_img__container.right {
    flex-direction: column-reverse;
  }
}
.text_img__container img {
  width: 49.2%;
  height: clamp(348px, 27.1875vw, 522px);
  object-fit: cover;
}
@media (max-width: 1279px) {
  .text_img__container img {
    width: 100%;
    height: clamp(261px, 0.0907194995 * 100vw + 231.9697601668px, 348px);
  }
}
@media (max-width: 1279px) {
  .text_img__container {
    gap: 20px;
  }
}
.text_img__content {
  width: 45.7%;
}
.text_img__content .title {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(26.4px, 2.0625vw, 39.6px);
  font-style: italic;
  margin-bottom: clamp(12px, 0.9375vw, 18px);
}
@media (max-width: 1279px) {
  .text_img__content .title {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .text_img__content .title {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .text_img__content .title {
    line-height: clamp(18.7px, 0.0128258603 * 100vw + 14.5957247132px, 31px);
    margin-bottom: clamp(10px, 0.0020855057 * 100vw + 9.3326381648px, 12px);
  }
}
.text_img__content .text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  line-height: clamp(16.8px, 1.3125vw, 25.2px);
  color: #707881;
}
@media (max-width: 1279px) {
  .text_img__content .text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .text_img__content .text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1279px) {
  .text_img__content {
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .text_img {
    padding-top: 50px;
  }
}

.rews_text {
  padding-top: clamp(60px, 4.6875vw, 90px);
}
.rews_text.pb {
  padding-bottom: clamp(60px, 4.6875vw, 90px);
}
@media (max-width: 1023px) {
  .rews_text.pb {
    padding-bottom: 50px;
  }
}
.rews_text .title {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(26.4px, 2.0625vw, 39.6px);
  font-style: italic;
  margin-bottom: clamp(18.6666666667px, 1.4583333333vw, 28px);
}
@media (max-width: 1279px) {
  .rews_text .title {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .rews_text .title {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .rews_text .title {
    margin-bottom: clamp(16px, 0.002919708 * 100vw + 15.0656934307px, 18.8px);
  }
}
.rews_text .text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  line-height: clamp(18.2px, 1.421875vw, 27.3px);
  color: #F2F2F6;
  margin-bottom: clamp(18.6666666667px, 1.4583333333vw, 28px);
}
@media (max-width: 1279px) {
  .rews_text .text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .rews_text .text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.rews_text .text p:not(:last-child) {
  margin-bottom: clamp(17.3333333333px, 1.3541666667vw, 26px);
}
.rews_text .name {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  font-size: clamp(16.6666666667px, 1.3020833333vw, 25px);
  line-height: clamp(21.6666666667px, 1.6927083333vw, 32.5px);
  margin-bottom: clamp(5.3333333333px, 0.4166666667vw, 8px);
}
@media (max-width: 1279px) {
  .rews_text .name {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .rews_text .name {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
.rews_text .owner {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  line-height: clamp(18.2px, 1.421875vw, 27.3px);
  color: rgba(255, 255, 255, 0.5);
}
@media (max-width: 1279px) {
  .rews_text .owner {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .rews_text .owner {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1023px) {
  .rews_text {
    padding-top: 50px;
  }
}

.rews_video {
  padding-top: clamp(60px, 4.6875vw, 90px);
}
.rews_video.pb {
  padding-bottom: clamp(60px, 4.6875vw, 90px);
}
@media (max-width: 1279px) {
  .rews_video.pb {
    padding-bottom: 50px;
  }
}
.rews_video__container {
  display: flex;
  justify-content: space-between;
}
.rews_video__container .left {
  display: flex;
  flex-direction: column;
  width: 45%;
  padding-top: clamp(12px, 0.9375vw, 18px);
  padding-bottom: clamp(8.6666666667px, 0.6770833333vw, 13px);
}
.rews_video__container .left .top .title {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(26.4px, 2.0625vw, 39.6px);
  font-style: italic;
}
@media (max-width: 1279px) {
  .rews_video__container .left .top .title {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .rews_video__container .left .top .title {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
.rews_video__container .left .top .text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  line-height: clamp(16.8px, 1.3125vw, 25.2px);
  max-height: clamp(122px, 9.53125vw, 183px);
  overflow-y: scroll;
  padding-right: 10px;
  color: #707881;
  margin-top: clamp(20px, 1.5625vw, 30px);
  margin-bottom: clamp(20px, 1.5625vw, 30px);
}
@media (max-width: 1279px) {
  .rews_video__container .left .top .text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .rews_video__container .left .top .text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.rews_video__container .left .top .text::-webkit-scrollbar {
  width: 2px;
}
.rews_video__container .left .top .text::-webkit-scrollbar-track {
  background: #707881;
}
.rews_video__container .left .top .text::-webkit-scrollbar-thumb {
  background: #C0FF00;
}
.rews_video__container .left .top .text p:not(:last-child) {
  margin-bottom: clamp(17.3333333333px, 1.3541666667vw, 26px);
}
@media (max-width: 1279px) {
  .rews_video__container .left .top .text {
    max-height: 100%;
    overflow: visible;
    margin-bottom: 16px;
    margin-top: 16px;
    padding-right: 0;
  }
}
.rews_video__container .left .bottom {
  margin-top: auto;
}
.rews_video__container .left .bottom .name {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  font-size: clamp(16.6666666667px, 1.3020833333vw, 25px);
  line-height: clamp(21.6666666667px, 1.6927083333vw, 32.5px);
}
@media (max-width: 1279px) {
  .rews_video__container .left .bottom .name {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .rews_video__container .left .bottom .name {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
.rews_video__container .left .bottom .owner {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  line-height: clamp(18.2px, 1.421875vw, 27.3px);
  color: rgba(255, 255, 255, 0.5);
  margin-top: clamp(4.6666666667px, 0.3645833333vw, 7px);
}
@media (max-width: 1279px) {
  .rews_video__container .left .bottom .owner {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .rews_video__container .left .bottom .owner {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.rews_video__container .left .bottom.not_text {
  margin-left: auto;
  margin-right: auto;
}
.rews_video__container .left .bottom.not_text .name {
  text-align: right;
}
@media (max-width: 1279px) {
  .rews_video__container .left .bottom.not_text {
    margin-top: 16px;
    margin-left: 0;
    margin-right: 0;
  }
  .rews_video__container .left .bottom.not_text .name {
    text-align: left;
  }
}
.rews_video__container .left.not_text {
  padding-top: clamp(37.3333333333px, 2.9166666667vw, 56px);
  padding-bottom: clamp(46.6666666667px, 3.6458333333vw, 70px);
}
@media (max-width: 1279px) {
  .rews_video__container .left.not_text {
    padding: 0;
  }
}
@media (max-width: 1279px) {
  .rews_video__container .left {
    width: 100%;
    padding: 0;
  }
}
@media (max-width: 1279px) {
  .rews_video__container {
    flex-direction: column;
  }
}
.rews_video__video {
  width: 49%;
  height: auto;
  border-radius: 20px;
  overflow: hidden;
  margin: auto 0;
  aspect-ratio: 555/340;
}
.rews_video__video iframe {
  width: 100%;
  height: 100%;
}
@media (max-width: 1279px) {
  .rews_video__video {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
}
.rews_video__preview {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 555/340;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
.rews_video__preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transition: all 0.3s ease;
}
.rews_video__preview .play {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  display: none;
}
.rews_video__preview .play__bgc {
  width: 50px;
  aspect-ratio: 1;
  background: transparent;
  border: 1px solid #C0FF00;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
.rews_video__preview .play svg {
  fill: #C0FF00;
  width: 13px;
  height: 16px;
  transition: all 0.3s ease;
}
@media (max-width: 1279px) {
  .rews_video {
    padding-top: 50px;
  }
}

.docs_page {
  padding-top: clamp(66.6666666667px, 5.2083333333vw, 100px);
  padding-bottom: clamp(124px, 9.6875vw, 186px);
}
.docs_page h1 {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(73.3333333333px, 5.7291666667vw, 110px);
}
@media (max-width: 1279px) {
  .docs_page h1 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .docs_page h1 {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
.docs_page__items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(clamp(346.6666666667px, 27.0833333333vw, 520px), 1fr));
  gap: clamp(46.6666666667px, 3.6458333333vw, 70px);
  margin-top: clamp(53.3333333333px, 4.1666666667vw, 80px);
}
.docs_page__item .name {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  margin-bottom: clamp(8px, 0.625vw, 12px);
}
@media (max-width: 1279px) {
  .docs_page__item .name {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .docs_page__item .name {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
.docs_page__item a {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  color: #C0FF00;
  text-decoration: underline;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (max-width: 1279px) {
  .docs_page__item a {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .docs_page__item a {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
@media (hover: hover) {
  .docs_page__item a:hover {
    text-decoration: none;
  }
}
@media (hover: none), (hover: on-demand) {
  .docs_page__item a:active {
    text-decoration: none;
  }
}
.docs_page__item a:visited {
  color: #406AFF;
  text-decoration: none;
}
.docs_page__item a:not(:last-child) {
  margin-bottom: clamp(13.3333333333px, 1.0416666667vw, 20px);
}

.sitemap_page {
  padding-top: clamp(66.6666666667px, 5.2083333333vw, 100px);
  padding-bottom: clamp(66.6666666667px, 5.2083333333vw, 100px);
}
.sitemap_page h1 {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  margin-bottom: clamp(44px, 3.4375vw, 66px);
}
@media (max-width: 1279px) {
  .sitemap_page h1 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .sitemap_page h1 {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .sitemap_page h1 {
    font-size: clamp(30px, 0.0291970803 * 100vw + 20.6569343066px, 58px);
    line-height: clamp(33px, 0.0260688217 * 100vw + 24.6579770594px, 58px);
    margin-bottom: clamp(30px, 0.0145985401 * 100vw + 25.3284671533px, 44px);
  }
}

.main_banner {
  border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.main_banner h1 {
  margin-top: clamp(26.6666666667px, 2.0833333333vw, 40px);
  margin-left: clamp(-14px, -0.7291666667vw, -9.3333333333px);
  font-style: italic;
}
.main_banner h1.size_150 {
  font-family: "Hoves Italic";
  font-size: clamp(100px, 7.8125vw, 150px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .main_banner h1.size_150 {
    font-size: 80px;
    line-height: 80px;
  }
}
@media (max-width: 1023px) {
  .main_banner h1.size_150 {
    font-size: clamp(30px, 0.0711237553 * 100vw + 7.240398293px, 80px);
    line-height: clamp(30px, 0.0711237553 * 100vw + 7.240398293px, 80px);
  }
}
.main_banner h1.size_115 {
  font-family: "Hoves Regular";
  font-size: clamp(76.6666666667px, 5.9895833333vw, 115px);
  line-height: clamp(70.5333333333px, 5.5104166667vw, 105.8px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1023px) {
  .main_banner h1.size_115 {
    font-size: clamp(28px, 0.0682788051 * 100vw + 6.1507823613px, 76px);
    line-height: clamp(28px, 0.0597439545 * 100vw + 8.8819345661px, 70px);
  }
}
.main_banner h1.size_110 {
  font-family: "Hoves Regular";
  font-size: clamp(73.3333333333px, 5.7291666667vw, 110px);
  line-height: clamp(67.4666666667px, 5.2708333333vw, 101.2px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1023px) {
  .main_banner h1.size_110 {
    font-size: clamp(26px, 0.06685633 * 100vw + 4.6059743954px, 73px);
    line-height: clamp(26px, 0.0583214794 * 100vw + 7.3371266003px, 67px);
  }
}
.main_banner h1.size_100 {
  font-family: "Hoves Regular";
  font-size: clamp(66.6666666667px, 5.2083333333vw, 100px);
  line-height: clamp(60px, 4.6875vw, 90px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .main_banner h1.size_100 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .main_banner h1.size_100 {
    font-size: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    line-height: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
  }
}
.main_banner h1.size_95 {
  font-family: "Hoves Regular";
  font-size: clamp(63.3333333333px, 4.9479166667vw, 95px);
  line-height: clamp(58.2666666667px, 4.5520833333vw, 87.4px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1023px) {
  .main_banner h1.size_95 {
    font-size: clamp(22px, 0.0583214794 * 100vw + 3.3371266003px, 63px);
    line-height: clamp(22px, 0.0512091038 * 100vw + 5.613086771px, 58px);
  }
}
.main_banner h1.size_85 {
  font-family: "Hoves Regular";
  font-size: clamp(56.6666666667px, 4.4270833333vw, 85px);
  line-height: clamp(52.1333333333px, 4.0729166667vw, 78.2px);
  font-weight: 400;
  color: #F2F2F6;
  max-width: 90%;
}
@media (max-width: 1023px) {
  .main_banner h1.size_85 {
    font-size: clamp(19px, 0.0526315789 * 100vw + 2.1578947368px, 56px);
    line-height: clamp(19px, 0.0469416785 * 100vw + 3.9786628734px, 52px);
  }
}
.main_banner h1.size_84 {
  font-family: "Hoves Regular";
  font-size: clamp(56px, 4.375vw, 84px);
  line-height: clamp(51.52px, 4.025vw, 77.28px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1023px) {
  .main_banner h1.size_84 {
    font-size: clamp(20px, 0.0512091038 * 100vw + 3.613086771px, 56px);
    line-height: clamp(20px, 0.0440967283 * 100vw + 5.8890469417px, 51px);
  }
}
.main_banner h1.size_60 {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  max-width: 80%;
}
@media (max-width: 1279px) {
  .main_banner h1.size_60 {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .main_banner h1.size_60 {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
@media (max-width: 1023px) {
  .main_banner h1.size_60 {
    max-width: 100%;
  }
}
.main_banner h1 span {
  margin-top: clamp(3.3333333333px, 0.2604166667vw, 5px);
  margin-left: clamp(6.6666666667px, 0.5208333333vw, 10px);
  display: block;
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .main_banner h1 span {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .main_banner h1 span {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .main_banner h1 span {
    margin-left: 0;
    margin-top: 9px;
  }
}
@media (max-width: 1279px) {
  .main_banner h1 {
    text-align: center;
    margin-top: 123px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 1023px) {
  .main_banner h1 {
    margin-top: clamp(119px, 0.0056899004 * 100vw + 117.1792318634px, 123px);
  }
}
@media (max-width: 1279px) {
  .main_banner .container {
    position: relative;
    border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
    overflow: hidden;
    padding-bottom: 15px;
  }
}
.main_banner .background_line {
  z-index: 1;
}
.main_banner .background_line img {
  position: absolute;
  bottom: 0;
  right: clamp(46.6666666667px, 3.6458333333vw, 70px);
  width: clamp(510.6666666667px, 39.8958333333vw, 766px);
}
@media (max-width: 1279px) {
  .main_banner .background_line img {
    width: 866px;
    max-height: 845px;
    object-fit: cover;
    object-position: top;
    right: 50%;
    translate: 50% 0;
  }
}
@media (max-width: 1023px) {
  .main_banner .background_line img {
    width: clamp(408px, 0.6514935989 * 100vw + 199.5220483642px, 866px);
    max-height: clamp(434px, 0.5846372688 * 100vw + 246.9160739687px, 845px);
  }
}
@media (max-width: 1279px) {
  .main_banner .background_line {
    border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
  }
}
.main_banner__container {
  display: flex;
  position: relative;
  z-index: 1;
}
.main_banner__container .left {
  width: 75%;
  padding-bottom: clamp(22.6666666667px, 1.7708333333vw, 34px);
}
.main_banner__container .left .rent_container {
  margin-top: clamp(30.6666666667px, 2.3958333333vw, 46px);
  display: flex;
  align-items: center;
}
.main_banner__container .left .rent_container .btn {
  margin-right: clamp(44px, 3.4375vw, 66px);
  margin-left: clamp(-7px, -0.3645833333vw, -4.6666666667px);
}
@media (max-width: 1279px) {
  .main_banner__container .left .rent_container .btn {
    display: none;
  }
}
.main_banner__container .left .rent_container .rent_count {
  display: flex;
}
.main_banner__container .left .rent_container .rent_count__text {
  display: flex;
  flex-direction: column;
  margin-left: clamp(13.3333333333px, 1.0416666667vw, 20px);
  justify-content: center;
}
.main_banner__container .left .rent_container .rent_count__text.svg_none {
  margin-left: 0 !important;
}
.main_banner__container .left .rent_container .rent_count__text .text_count {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: #707881;
  margin-bottom: clamp(2.6666666667px, 0.2083333333vw, 4px);
}
@media (max-width: 1279px) {
  .main_banner__container .left .rent_container .rent_count__text .text_count {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .main_banner__container .left .rent_container .rent_count__text .text_count {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
@media (max-width: 1279px) {
  .main_banner__container .left .rent_container .rent_count__text .text_count {
    margin-bottom: 0;
  }
}
.main_banner__container .left .rent_container .rent_count__text .text_count_remained {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .main_banner__container .left .rent_container .rent_count__text .text_count_remained {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .main_banner__container .left .rent_container .rent_count__text .text_count_remained {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .main_banner__container .left .rent_container .rent_count__text {
    margin-left: 12px;
  }
}
.main_banner__container .left .rent_container .rent_count svg {
  width: clamp(51px, 3.984375vw, 76.5px);
  height: clamp(51px, 3.984375vw, 76.5px);
}
@media (max-width: 1279px) {
  .main_banner__container .left .rent_container .rent_count svg {
    width: 84px;
    height: 84px;
  }
}
@media (max-width: 1023px) {
  .main_banner__container .left .rent_container .rent_count svg {
    width: clamp(29px, 0.0782361309 * 100vw + 3.9644381223px, 84px);
    height: clamp(29px, 0.0782361309 * 100vw + 3.9644381223px, 84px);
  }
}
@media (max-width: 1279px) {
  .main_banner__container .left .rent_container {
    justify-content: center;
    margin-top: 54px;
  }
}
@media (max-width: 1023px) {
  .main_banner__container .left .rent_container {
    margin-top: clamp(22px, 0.0455192034 * 100vw + 7.4338549075px, 54px);
  }
}
.main_banner__container .left .text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #707881;
  line-height: clamp(18.2px, 1.421875vw, 27.3px);
  max-width: clamp(633.3333333333px, 49.4791666667vw, 950px);
  margin-top: clamp(42.6666666667px, 3.3333333333vw, 64px);
  margin-left: clamp(2px, 0.15625vw, 3px);
}
@media (max-width: 1279px) {
  .main_banner__container .left .text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .main_banner__container .left .text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1279px) {
  .main_banner__container .left .text {
    display: none;
  }
}
@media (max-width: 1279px) {
  .main_banner__container .left {
    width: 100%;
    padding-bottom: clamp(4px, 0.0256045519 * 100vw + -4.1934566145px, 22px);
  }
}
.main_banner__container .right {
  width: 25%;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.main_banner__container .right .background_line {
  z-index: 0;
}
.main_banner__container .right .img_text {
  z-index: 1;
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #707881;
  line-height: clamp(19.46px, 1.5203125vw, 29.19px);
  margin-bottom: clamp(18.6666666667px, 1.4583333333vw, 28px);
  margin-left: auto;
  width: fit-content;
  margin-right: clamp(60px, 4.6875vw, 90px);
}
@media (max-width: 1279px) {
  .main_banner__container .right .img_text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .main_banner__container .right .img_text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1279px) {
  .main_banner__container .right .img_text {
    width: 100%;
    text-align: center;
    margin: 0;
    bottom: 25px;
  }
}
@media (max-width: 1023px) {
  .main_banner__container .right .img_text {
    bottom: clamp(0px, 0.0355618777 * 100vw + -11.3798008535px, 25px);
  }
}
.main_banner__container .right .btn {
  display: none;
}
@media (max-width: 1279px) {
  .main_banner__container .right .btn {
    display: inline-flex;
    position: absolute;
    bottom: 110px;
  }
}
@media (max-width: 1023px) {
  .main_banner__container .right .btn {
    bottom: clamp(45px, 0.0924608819 * 100vw + 15.4125177809px, 110px);
  }
}
@media (max-width: 1279px) {
  .main_banner__container .right {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    position: relative;
    min-height: clamp(345px, 0.354535975 * 100vw + 231.5484880083px, 685px);
  }
}
@media (max-width: 1279px) {
  .main_banner__container {
    flex-direction: column;
  }
}
@media (max-width: 1279px) {
  .main_banner {
    margin-top: -110.25px;
  }
}

.container.text {
  display: none;
  font-size: 24px;
  line-height: 31.2px;
  color: #707881;
  margin-top: 39px;
}
@media (max-width: 1279px) {
  .container.text {
    display: block;
  }
}
@media (max-width: 1023px) {
  .container.text {
    font-size: clamp(14px, 0.0142247511 * 100vw + 9.4480796586px, 24px);
    line-height: clamp(18.2px, 0.0184921764 * 100vw + 12.2825035562px, 31.2px);
    margin-top: clamp(26px, 0.0184921764 * 100vw + 20.0825035562px, 39px);
  }
}

.advantages {
  position: relative;
  z-index: 1;
  margin-top: clamp(24px, 1.875vw, 36px);
  padding-bottom: clamp(55.6666666667px, 4.3489583333vw, 83.5px);
  overflow: hidden;
}
.advantages__name {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .advantages__name {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .advantages__name {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .advantages__name {
    font-size: 80px;
    line-height: 72px;
    white-space: nowrap;
  }
}
@media (max-width: 1023px) {
  .advantages__name {
    font-size: clamp(30px, 0.0711237553 * 100vw + 7.240398293px, 80px);
    line-height: clamp(30px, 0.0597439545 * 100vw + 10.8819345661px, 72px);
  }
}
.advantages__text_name {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  max-width: clamp(150px, 11.71875vw, 225px);
  margin-left: clamp(10px, 0.78125vw, 15px);
  margin-top: clamp(-8px, -0.4166666667vw, -5.3333333333px);
}
@media (max-width: 1279px) {
  .advantages__text_name {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .advantages__text_name {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
.advantages__text_name.item4 {
  max-width: clamp(173.3333333333px, 13.5416666667vw, 260px);
}
@media (max-width: 1279px) {
  .advantages__text_name {
    font-size: 24px;
    line-height: 28.8px;
    margin-top: 3px;
    max-width: max-content;
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .advantages__text_name {
    font-size: clamp(14px, 0.0142247511 * 100vw + 9.4480796586px, 24px);
    line-height: clamp(16.8px, 0.0170697013 * 100vw + 11.3376955903px, 28.8px);
    margin-left: 0;
    margin-top: clamp(3px, -0.0042674253 * 100vw + 7.3655761024px, 6px);
  }
}
.advantages__title2 {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  display: flex;
  flex-direction: column;
  margin-top: clamp(15.3333333333px, 1.1979166667vw, 23px);
}
@media (max-width: 1279px) {
  .advantages__title2 {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .advantages__title2 {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
.advantages__title2 span {
  font-style: italic;
  color: #C0FF00;
}
@media (max-width: 1279px) {
  .advantages__title2 {
    margin-top: 61px;
  }
}
@media (max-width: 1023px) {
  .advantages__title2 {
    margin-top: 0;
  }
}
.advantages__items {
  display: flex;
}
.advantages__items span {
  color: #C0FF00;
}
.advantages__items .item {
  display: flex;
  flex-direction: column;
}
.advantages__items .item:nth-child(2) {
  padding: 0 clamp(45px, 3.515625vw, 67.5px);
  border-left: 1px solid #37424D;
  border-right: 1px solid #37424D;
  margin: 0 clamp(45px, 3.515625vw, 67.5px);
}
@media (max-width: 1279px) {
  .advantages__items .item:nth-child(2) {
    padding: 0 51px;
    margin: 0 51px;
  }
}
@media (max-width: 1023px) {
  .advantages__items .item:nth-child(2) {
    border: none;
    margin: 0;
    padding: 0 clamp(32px, 0.027027027 * 100vw + 23.3513513514px, 51px);
  }
}
.advantages__items .item:not(.item4):last-child {
  margin-right: clamp(3.3333333333px, 0.2604166667vw, 5px);
}
@media (max-width: 1279px) {
  .advantages__items .item:not(.item4):last-child {
    margin-right: 0;
  }
}
.advantages__items .item.item2 {
  padding: 0 clamp(16.6666666667px, 1.3020833333vw, 25px);
  margin: 0 clamp(16.6666666667px, 1.3020833333vw, 25px);
}
.advantages__items .item.item3 {
  border-right: 1px solid #37424D;
  padding-right: clamp(16.6666666667px, 1.3020833333vw, 25px);
  margin-right: clamp(16.6666666667px, 1.3020833333vw, 25px);
}
@media (max-width: 1279px) {
  .advantages__items {
    width: min-content;
    margin: 33px auto 0;
  }
}
@media (max-width: 1023px) {
  .advantages__items {
    margin-top: clamp(28px, 0.0071123755 * 100vw + 25.7240398293px, 33px);
  }
}
.advantages__container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1279px) {
  .advantages__container {
    flex-direction: column;
  }
}
@media (max-width: 1023px) {
  .advantages__container {
    margin-top: 14px;
  }
}
.advantages .bgc {
  font-size: clamp(180px, 14.0625vw, 270px);
  line-height: clamp(162px, 12.65625vw, 243px);
  color: #212D37;
  position: absolute;
  bottom: clamp(-29px, -1.5104166667vw, -19.3333333333px);
  left: 43px;
  right: 43px;
  z-index: -1;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.advantages .bgc div:first-child {
  user-select: none;
  text-rendering: optimizeSpeed;
  text-transform: uppercase;
}
.advantages .bgc div:first-child.flex {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1279px) {
  .advantages .bgc div:first-child {
    display: none !important;
  }
}
.advantages .bgc .mob {
  display: none;
  font-size: 230px;
  line-height: 207px;
  text-align: center;
}
@media (max-width: 1279px) {
  .advantages .bgc .mob {
    display: block;
  }
}
@media (max-width: 1023px) {
  .advantages .bgc .mob {
    font-size: clamp(90px, 0.1991465149 * 100vw + 26.2731152205px, 230px);
    line-height: clamp(81px, 0.1792318634 * 100vw + 23.6458036984px, 207px);
  }
}
@media (max-width: 1279px) {
  .advantages .bgc {
    bottom: -21px;
  }
}
@media (max-width: 1023px) {
  .advantages .bgc {
    left: clamp(9px, 0.0483641536 * 100vw + -6.4765291607px, 43px);
    right: clamp(9px, 0.0483641536 * 100vw + -6.4765291607px, 43px);
    bottom: clamp(-21px, -0.0184921764 * 100vw + -2.0825035562px, -8px);
  }
}
@media (max-width: 1279px) {
  .advantages {
    padding-bottom: 67px;
  }
}
@media (max-width: 1023px) {
  .advantages {
    overflow: visible;
    margin-top: clamp(-1px, 0.0355618777 * 100vw + -12.3798008535px, 24px);
    padding-bottom: clamp(53px, 0.0199146515 * 100vw + 46.627311522px, 67px);
  }
}

.website_get {
  position: relative;
  overflow: hidden;
  background: #1A252F;
  padding-bottom: clamp(71.3333333333px, 5.5729166667vw, 107px);
}
.website_get__hashtag {
  color: rgba(255, 255, 255, 0.6);
  width: 100%;
  text-align: center;
  margin-top: clamp(36.6666666667px, 2.8645833333vw, 55px);
  position: relative;
  z-index: 1;
}
@media (max-width: 1279px) {
  .website_get__hashtag {
    margin-top: 68px;
  }
}
@media (max-width: 1023px) {
  .website_get__hashtag {
    text-align: start;
    margin-top: 26px;
  }
}
.website_get__title {
  width: 100%;
  text-align: center;
  margin-bottom: clamp(16.6666666667px, 1.3020833333vw, 25px);
  margin-top: clamp(-21px, -1.09375vw, -14px);
  position: relative;
  z-index: 1;
}
.website_get__title.size-100 {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .website_get__title.size-100 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .website_get__title.size-100 {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
.website_get__title.size-80 {
  font-family: "Hoves Regular";
  font-size: clamp(53.3333333333px, 4.1666666667vw, 80px);
  line-height: clamp(53.3333333333px, 4.1666666667vw, 80px);
  font-weight: 400;
  color: #F2F2F6;
  margin-top: clamp(6.6666666667px, 0.5208333333vw, 10px) !important;
}
@media (max-width: 1023px) {
  .website_get__title.size-80 {
    font-size: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
    line-height: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
  }
}
@media (max-width: 1279px) {
  .website_get__title {
    font-size: 58px;
    line-height: 58px;
    margin-top: 3px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1023px) {
  .website_get__title {
    font-size: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    line-height: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    text-align: start;
    margin-bottom: clamp(25px, 0.0071123755 * 100vw + 22.7240398293px, 30px);
  }
}
.website_get__items {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.website_get__items.column {
  display: flex;
  flex-direction: column;
}
.website_get__items .item {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  padding: clamp(15.3333333333px, 1.1979166667vw, 23px) 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-style: italic;
}
.website_get__items .item.size-60 {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
}
@media (max-width: 1279px) {
  .website_get__items .item.size-60 {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .website_get__items .item.size-60 {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
.website_get__items .item.size-44 {
  font-family: "Hoves Regular";
  font-size: clamp(29.3333333333px, 2.2916666667vw, 44px);
  line-height: clamp(29.3333333333px, 2.2916666667vw, 44px);
  font-weight: 400;
  color: #F2F2F6;
}
.website_get__items .item.size-40 {
  font-family: "Hoves Regular";
  font-size: clamp(26.6666666667px, 2.0833333333vw, 40px);
  line-height: clamp(26.6666666667px, 2.0833333333vw, 40px);
  font-weight: 400;
  color: #F2F2F6;
}
.website_get__items .item:not(.column):nth-child(odd) {
  margin-right: clamp(30px, 2.34375vw, 45px);
}
@media (max-width: 1279px) {
  .website_get__items .item:not(.column):nth-child(odd) {
    margin-right: 0;
  }
}
.website_get__items .item:not(.column):nth-child(even) {
  margin-left: clamp(30px, 2.34375vw, 45px);
}
@media (max-width: 1279px) {
  .website_get__items .item:not(.column):nth-child(even) {
    margin-left: 0;
  }
}
.website_get__items .item span {
  margin-left: clamp(40px, 3.125vw, 60px);
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .website_get__items .item span {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .website_get__items .item span {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1279px) {
  .website_get__items .item span {
    width: 58.5%;
    line-height: 28.8px;
  }
}
@media (max-width: 1023px) {
  .website_get__items .item span {
    width: auto;
    font-size: clamp(14px, 0.0142247511 * 100vw + 9.4480796586px, 24px);
    line-height: clamp(16.8px, 0.0170697013 * 100vw + 11.3376955903px, 28.8px);
    margin-left: 20px;
    margin-top: 5px;
  }
}
.website_get__items .item.column {
  grid-template-columns: 55% 24%;
  justify-content: space-between;
}
.website_get__items .item.column span {
  margin-left: 0;
}
.website_get__items .item.column:last-child {
  border-bottom: none;
}
@media (max-width: 1279px) {
  .website_get__items .item {
    padding: 12px 0;
    border-bottom-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: space-between;
    text-transform: lowercase;
  }
  .website_get__items .item:first-child {
    padding-top: 0;
  }
  .website_get__items .item:last-child {
    border-bottom: none;
  }
}
@media (max-width: 1023px) {
  .website_get__items .item {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 11px;
  }
}
@media (max-width: 1279px) {
  .website_get__items {
    grid-template-columns: 100%;
    margin: 0px 16px;
  }
}
@media (max-width: 1023px) {
  .website_get__items {
    margin: 0;
  }
}
.website_get .bgc_container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.website_get .bgc_container .element {
  width: clamp(299px, 23.359375vw, 448.5px);
  height: clamp(299px, 23.359375vw, 448.5px);
  border-radius: 50%;
  margin-top: clamp(-224.25px, -11.6796875vw, -149.5px);
  transition: all 1s ease;
}
.website_get .bgc_container .element.active {
  transform: scale(20);
}
@media (max-width: 1279px) {
  .website_get .bgc_container .element {
    transform: scale(10);
  }
}
.website_get .btn_container {
  margin-top: clamp(22px, 1.71875vw, 33px);
  width: 100%;
  text-align: center;
}
@media (max-width: 1279px) {
  .website_get .btn_container {
    margin-top: 36px;
  }
}
@media (max-width: 1023px) {
  .website_get .btn_container {
    margin-top: clamp(10px, 0.0369843528 * 100vw + -1.8349928876px, 36px);
  }
  .website_get .btn_container span {
    text-wrap: balance;
    line-height: 18.7px;
  }
}
@media (max-width: 1279px) {
  .website_get .container {
    max-width: 825px;
  }
}
@media (max-width: 1279px) {
  .website_get {
    padding-bottom: 84px;
  }
}
@media (max-width: 1023px) {
  .website_get {
    padding-bottom: clamp(35px, 0.0697012802 * 100vw + 12.6955903272px, 84px);
  }
}

.the_best_competition {
  padding-top: clamp(38.6666666667px, 3.0208333333vw, 58px);
  position: relative;
  padding-bottom: clamp(42.3333333333px, 3.3072916667vw, 63.5px);
}
.the_best_competition__hashtag {
  margin-left: clamp(43.3333333333px, 3.3854166667vw, 65px);
}
@media (max-width: 1279px) {
  .the_best_competition__hashtag {
    margin-left: 25px;
  }
}
@media (max-width: 1023px) {
  .the_best_competition__hashtag {
    margin-left: clamp(1px, 0.0341394026 * 100vw + -9.9246088193px, 25px);
  }
}
.the_best_competition__title {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  max-width: clamp(706.6666666667px, 55.2083333333vw, 1060px);
  margin-top: clamp(-21px, -1.09375vw, -14px);
}
@media (max-width: 1279px) {
  .the_best_competition__title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .the_best_competition__title {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
.the_best_competition__title span:first-child {
  font-style: italic;
}
.the_best_competition__title span:last-child {
  margin-left: clamp(-23px, -1.1979166667vw, -15.3333333333px);
  margin-top: clamp(-37px, -1.9270833333vw, -24.6666666667px);
  display: block;
}
@media (max-width: 1279px) {
  .the_best_competition__title span:last-child {
    margin-left: 0;
    margin-top: 0;
  }
}
@media (max-width: 1279px) {
  .the_best_competition__title {
    margin-top: 3px;
  }
}
@media (max-width: 1023px) {
  .the_best_competition__title {
    font-size: 30px;
    line-height: 30px;
    max-width: clamp(190px, 0.7382645804 * 100vw + -46.2446657183px, 709px);
  }
}
@media (max-width: 425px) {
  .the_best_competition__title {
    display: flex;
    flex-direction: column;
  }
}
.the_best_competition__img {
  position: absolute;
  top: clamp(55.3333333333px, 4.3229166667vw, 83px);
  right: clamp(66.6666666667px, 5.2083333333vw, 100px);
  width: clamp(331.3333333333px, 25.8854166667vw, 497px);
  height: clamp(476.6666666667px, 37.2395833333vw, 715px);
}
@media (max-width: 1279px) {
  .the_best_competition__img {
    width: 193px;
    height: 281px;
    top: 0;
    right: 57px;
  }
}
@media (max-width: 1023px) {
  .the_best_competition__img {
    width: clamp(113px, 0.1137980085 * 100vw + 76.5846372688px, 193px);
    height: clamp(163px, 0.1678520626 * 100vw + 109.2873399716px, 281px);
    top: clamp(0px, -0.0341394026 * 100vw + 34.9246088193px, 24px);
    right: clamp(21px, 0.0512091038 * 100vw + 4.613086771px, 57px);
  }
}
.the_best_competition__items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: clamp(20px, 1.5625vw, 30px) clamp(14px, 1.09375vw, 21px);
  max-width: 82%;
  margin-top: clamp(7.3333333333px, 0.5729166667vw, 11px);
}
@media (max-width: 1279px) {
  .the_best_competition__items {
    grid-template-columns: repeat(2, 1fr);
    max-width: 100%;
    gap: 9px 36px;
    margin-top: 17px;
  }
}
@media (max-width: 1023px) {
  .the_best_competition__items {
    gap: 11px 7px;
  }
}
.the_best_competition__item {
  padding-top: clamp(29.3333333333px, 2.2916666667vw, 44px);
  position: relative;
  z-index: 1;
}
.the_best_competition__item .name {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  font-style: italic;
}
@media (max-width: 1279px) {
  .the_best_competition__item .name {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .the_best_competition__item .name {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
@media (max-width: 1279px) {
  .the_best_competition__item .name {
    line-height: 36px;
  }
}
@media (max-width: 1023px) {
  .the_best_competition__item .name {
    line-height: clamp(18.06px, 0.0255192034 * 100vw + 9.8938549075px, 36px);
  }
}
.the_best_competition__item .text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  padding-left: clamp(10px, 0.78125vw, 15px);
  padding-right: clamp(23.3333333333px, 1.8229166667vw, 35px);
  margin-top: clamp(7.3333333333px, 0.5729166667vw, 11px);
}
@media (max-width: 1279px) {
  .the_best_competition__item .text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .the_best_competition__item .text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1279px) {
  .the_best_competition__item .text {
    line-height: 28.8px;
    margin-top: 11px;
    padding-right: 65px;
  }
}
@media (max-width: 1023px) {
  .the_best_competition__item .text {
    font-size: clamp(13px, 0.0156472262 * 100vw + 7.9928876245px, 24px);
    line-height: clamp(15.6px, 0.0187766714 * 100vw + 9.5914651494px, 28.8px);
    padding-right: clamp(5px, 0.0853485064 * 100vw + -22.3115220484px, 65px);
    padding-left: 0;
  }
}
.the_best_competition__item img {
  height: clamp(28px, 2.1875vw, 42px);
  margin-left: clamp(10px, 0.78125vw, 15px);
  margin-top: clamp(15.3333333333px, 1.1979166667vw, 23px);
}
@media (max-width: 1279px) {
  .the_best_competition__item img {
    height: 36px;
    margin-top: 5px;
  }
}
@media (max-width: 1023px) {
  .the_best_competition__item img {
    height: clamp(18px, 0.0256045519 * 100vw + 9.8065433855px, 36px);
    margin-left: clamp(0px, 0.0142247511 * 100vw + -4.5519203414px, 10px);
    margin-top: clamp(3px, 0.0028449502 * 100vw + 2.0896159317px, 5px);
  }
}
.the_best_competition__item svg {
  width: clamp(89.3333333333px, 6.9791666667vw, 134px);
  height: clamp(78px, 6.09375vw, 117px);
  fill: #212D37;
  position: absolute;
  z-index: -1;
  top: clamp(15.3333333333px, 1.1979166667vw, 23px);
  left: clamp(-3px, -0.15625vw, -2px);
}
@media (max-width: 1279px) {
  .the_best_competition__item svg {
    width: 129px;
    height: 113px;
    top: 14px;
    left: 4px;
  }
}
@media (max-width: 1023px) {
  .the_best_competition__item svg {
    width: 61px;
    height: 54px;
    top: clamp(-4px, 0.0256045519 * 100vw + -12.1934566145px, 14px);
  }
}
.the_best_competition__item:first-child svg {
  left: clamp(-23px, -1.1979166667vw, -15.3333333333px);
}
@media (max-width: 1279px) {
  .the_best_competition__item:first-child svg {
    left: 4px;
  }
}
@media (max-width: 1279px) {
  .the_best_competition__item {
    padding-top: 40px;
    padding-left: 25px;
  }
}
@media (max-width: 1023px) {
  .the_best_competition__item {
    padding-left: clamp(0px, 0.0355618777 * 100vw + -11.3798008535px, 25px);
    padding-right: 0;
    padding-top: clamp(21px, 0.027027027 * 100vw + 12.3513513514px, 40px);
  }
}
.the_best_competition__btn {
  width: 100%;
  text-align: center;
  margin-top: clamp(53.3333333333px, 4.1666666667vw, 80px);
}
@media (max-width: 1279px) {
  .the_best_competition__btn {
    margin-top: 40px;
  }
}
@media (max-width: 425px) {
  .the_best_competition__btn {
    display: none;
  }
}
.the_best_competition .background_line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.the_best_competition .background_line.last {
  border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
}
@media (max-width: 1279px) {
  .the_best_competition {
    padding-top: 65px;
  }
}
@media (max-width: 1023px) {
  .the_best_competition {
    padding-top: clamp(39px, 0.0369843528 * 100vw + 27.1650071124px, 65px);
  }
}

.cost_site {
  position: relative;
  overflow: hidden;
  padding-top: clamp(42.3333333333px, 3.3072916667vw, 63.5px);
  padding-bottom: 80px;
  z-index: 1;
}
.cost_site.mt {
  z-index: 0;
  margin-top: -50px;
  padding-top: clamp(75.6666666667px, 5.9114583333vw, 113.5px);
}
.cost_site__hashtag {
  width: 100%;
  text-align: center;
}
.cost_site__top_title {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  margin-top: clamp(6px, 0.46875vw, 9px);
  width: 100%;
  text-align: center;
  margin-left: clamp(10.6666666667px, 0.8333333333vw, 16px);
}
@media (max-width: 1279px) {
  .cost_site__top_title {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .cost_site__top_title {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
@media (max-width: 1279px) {
  .cost_site__top_title {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .cost_site__top_title {
    margin-top: clamp(8px, 0.0028449502 * 100vw + 7.0896159317px, 10px);
  }
}
.cost_site__bottom_title {
  width: 100%;
  text-align: center;
}
.cost_site__bottom_title.font-95 {
  font-family: "Hoves Regular";
  font-size: clamp(63.3333333333px, 4.9479166667vw, 95px);
  line-height: clamp(58.2666666667px, 4.5520833333vw, 87.4px);
  font-weight: 400;
  color: #F2F2F6;
  margin-top: clamp(6.6666666667px, 0.5208333333vw, 10px);
}
@media (max-width: 1023px) {
  .cost_site__bottom_title.font-95 {
    font-size: clamp(22px, 0.0583214794 * 100vw + 3.3371266003px, 63px);
    line-height: clamp(22px, 0.0512091038 * 100vw + 5.613086771px, 58px);
  }
}
.cost_site__bottom_title.font-100 {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  margin-top: clamp(-24px, -1.25vw, -16px);
}
@media (max-width: 1279px) {
  .cost_site__bottom_title.font-100 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .cost_site__bottom_title.font-100 {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .cost_site__bottom_title.font-100 {
    margin-top: 7px;
  }
}
@media (max-width: 1023px) {
  .cost_site__bottom_title.font-100 {
    font-size: 30px;
    line-height: 30px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: clamp(5px, 0.0028449502 * 100vw + 4.0896159317px, 7px);
  }
}
.cost_site__bottom_title span {
  font-style: italic;
}
.cost_site__items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(41.3333333333px, 3.2291666667vw, 62px) clamp(70px, 5.46875vw, 105px);
  max-width: 76%;
  margin: clamp(38.6666666667px, 3.0208333333vw, 58px) auto 0 auto;
  position: relative;
  z-index: 0;
}
.cost_site__items.el2 {
  grid-template-columns: repeat(2, 1fr);
  max-width: 55%;
}
.cost_site__items.el4 {
  grid-template-columns: repeat(2, 1fr);
}
.cost_site__items.el7 {
  grid-template-columns: repeat(4, 1fr);
  gap: clamp(40px, 3.125vw, 60px) clamp(33.3333333333px, 2.6041666667vw, 50px);
}
@media (max-width: 1279px) {
  .cost_site__items {
    grid-template-columns: repeat(2, 1fr);
    max-width: 100%;
    margin-top: 33px;
    gap: 43px 42px;
  }
}
@media (max-width: 1023px) {
  .cost_site__items {
    grid-template-columns: 100% !important;
    margin-top: clamp(20px, 0.0184921764 * 100vw + 14.0825035562px, 33px);
    gap: clamp(20px, 0.0327169275 * 100vw + 9.5305832148px, 43px);
  }
}
.cost_site__item {
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
.cost_site__item.el4 {
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.cost_site__item .name {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  font-style: italic;
  margin-bottom: clamp(2.6666666667px, 0.2083333333vw, 4px);
  transition: all 0.3s ease;
}
@media (max-width: 1279px) {
  .cost_site__item .name {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .cost_site__item .name {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .cost_site__item .name {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 0;
  }
}
@media (max-width: 1023px) {
  .cost_site__item .name {
    font-size: clamp(17px, 0.0327169275 * 100vw + 6.5305832148px, 40px);
    line-height: clamp(18.7px, 0.0359886202 * 100vw + 7.1836415363px, 44px);
  }
}
.cost_site__item .text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #707881;
}
@media (max-width: 1279px) {
  .cost_site__item .text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .cost_site__item .text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.cost_site__item .cost {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  color: #C0FF00;
  margin-top: clamp(15.3333333333px, 1.1979166667vw, 23px);
  white-space: nowrap;
}
@media (max-width: 1279px) {
  .cost_site__item .cost {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .cost_site__item .cost {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
.cost_site__item .cost.mta {
  margin-top: auto;
}
@media (max-width: 1279px) {
  .cost_site__item .cost {
    font-size: 40px;
    line-height: 44px;
    margin-top: 13px;
  }
}
@media (max-width: 1023px) {
  .cost_site__item .cost {
    font-size: clamp(17px, 0.0327169275 * 100vw + 6.5305832148px, 40px);
    line-height: clamp(18.7px, 0.0359886202 * 100vw + 7.1836415363px, 44px);
    margin-top: 0;
    margin-right: 2px;
  }
}
.cost_site__item .term {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #707881;
  margin-top: 4px;
}
@media (max-width: 1279px) {
  .cost_site__item .term {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .cost_site__item .term {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1023px) {
  .cost_site__item .group {
    margin-right: 25px;
  }
}
.cost_site__item .image {
  position: absolute;
  top: clamp(-31px, -1.6145833333vw, -20.6666666667px);
  right: clamp(-180px, -9.375vw, -120px);
  width: clamp(212.6666666667px, 16.6145833333vw, 319px);
  height: clamp(138px, 10.78125vw, 207px);
  display: flex;
  align-items: flex-start;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  transition: opacity 0.3s ease;
}
.cost_site__item .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: rotate 0.3s ease;
}
@media (max-width: 1279px) {
  .cost_site__item .image {
    display: none !important;
  }
}
@media (hover: hover) {
  .cost_site__item:hover {
    z-index: 2;
  }
  .cost_site__item:hover .image {
    opacity: 1;
  }
  .cost_site__item:hover .image img {
    rotate: 12deg;
  }
  .cost_site__item:hover:nth-child(3) .image img, .cost_site__item:hover:nth-child(6) .image img {
    rotate: -12deg;
  }
  .cost_site__item:hover.not_img .name {
    color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .cost_site__item:active {
    z-index: 2;
  }
  .cost_site__item:active .image {
    opacity: 1;
  }
  .cost_site__item:active .image img {
    rotate: 12deg;
  }
  .cost_site__item:active:nth-child(3) .image img, .cost_site__item:active:nth-child(6) .image img {
    rotate: -12deg;
  }
  .cost_site__item:active.not_img .name {
    color: #C0FF00;
  }
}
@media (max-width: 1023px) {
  .cost_site__item {
    display: flex;
    justify-content: space-between;
  }
}
.cost_site__btn_container {
  width: 100%;
  margin-top: 90px;
  display: flex;
  justify-content: center;
}
.cost_site.last {
  border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
  background: linear-gradient(180deg, #121B24 62%, #212D37 100%);
}
.cost_site .background_line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.cost_site .container {
  position: relative;
  z-index: 1;
}
@media (max-width: 1279px) {
  .cost_site {
    padding-top: 24px;
    padding-bottom: 86px;
  }
}
@media (max-width: 1023px) {
  .cost_site {
    padding-top: clamp(1px, 0.0327169275 * 100vw + -9.4694167852px, 24px);
    padding-bottom: clamp(30px, 0.0795454545 * 100vw + 4.5454545455px, 86px);
  }
}

.can_we_go {
  padding-top: clamp(38px, 2.96875vw, 57px);
  padding-bottom: clamp(40px, 3.125vw, 60px);
}
.can_we_go__hashtag {
  width: 100%;
  text-align: center;
}
.can_we_go__title {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  width: 100%;
  text-align: center;
  margin-top: clamp(-20px, -1.0416666667vw, -13.3333333333px);
}
@media (max-width: 1279px) {
  .can_we_go__title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__title {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
.can_we_go__title span {
  font-style: italic;
}
@media (max-width: 1279px) {
  .can_we_go__title {
    margin-top: 3px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__title {
    font-size: 30px;
    line-height: 30px;
  }
}
.can_we_go__container {
  display: flex;
  justify-content: space-between;
  margin-top: clamp(32.6666666667px, 2.5520833333vw, 49px);
}
@media (max-width: 1279px) {
  .can_we_go__container {
    flex-direction: column;
    margin-top: 32px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__container {
    margin-top: clamp(16px, 0.0227596017 * 100vw + 8.7169274538px, 32px);
  }
}
.can_we_go__left {
  width: 59.7%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(7px, 0.546875vw, 10.5px) clamp(6px, 0.46875vw, 9px);
}
@media (max-width: 1279px) {
  .can_we_go__left {
    width: 100%;
    grid-template-columns: 100%;
    gap: 0;
  }
}
.can_we_go__right {
  width: 38.5%;
  background: #1A252F;
  display: flex;
  flex-direction: column;
}
.can_we_go__right .form_title {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  padding-top: clamp(43px, 3.359375vw, 64.5px);
  padding-left: clamp(56px, 4.375vw, 84px);
  padding-right: clamp(56px, 4.375vw, 84px);
  margin-bottom: clamp(6.6666666667px, 0.5208333333vw, 10px);
}
@media (max-width: 1279px) {
  .can_we_go__right .form_title {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__right .form_title {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
@media (max-width: 1279px) {
  .can_we_go__right .form_title {
    padding-top: 44px;
    margin-bottom: 14px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 1023px) {
  .can_we_go__right .form_title {
    font-size: 30px;
    line-height: 30px;
    max-width: 90%;
    padding-top: clamp(23px, 0.0298719772 * 100vw + 13.4409672831px, 44px);
    margin-left: 2px;
  }
}
.can_we_go__right .form_text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #707881;
  padding-left: clamp(56px, 4.375vw, 84px);
  padding-right: clamp(56px, 4.375vw, 84px);
  max-width: 90%;
  margin-bottom: clamp(32px, 2.5vw, 48px);
}
@media (max-width: 1279px) {
  .can_we_go__right .form_text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__right .form_text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1279px) {
  .can_we_go__right .form_text {
    line-height: 28.8px;
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
    margin-bottom: 37px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__right .form_text {
    font-size: clamp(14px, 0.0142247511 * 100vw + 9.4480796586px, 24px);
    line-height: clamp(18.83px, 0.0141820768 * 100vw + 14.2917354196px, 28.8px);
    margin-bottom: clamp(24px, 0.0184921764 * 100vw + 18.0825035562px, 37px);
  }
}
.can_we_go__right form {
  padding: 0 clamp(56px, 4.375vw, 84px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.can_we_go__right form input:not(:last-child) {
  margin-bottom: clamp(10px, 0.78125vw, 15px);
}
@media (max-width: 1279px) {
  .can_we_go__right form input:not(:last-child) {
    margin-bottom: 0;
  }
}
@media (max-width: 1279px) {
  .can_we_go__right form input.name {
    grid-column: 1/2;
  }
  .can_we_go__right form input.phone {
    grid-column: 2/3;
  }
}
@media (max-width: 1279px) and (max-width: 1023px) {
  .can_we_go__right form input.phone {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}
.can_we_go__right form .input {
  background-color: #212D37;
  width: 100%;
}
.can_we_go__right form .btn {
  width: 100%;
  margin-top: clamp(12px, 0.9375vw, 18px);
}
@media (max-width: 1279px) {
  .can_we_go__right form .btn {
    grid-row: 2/3;
    grid-column: 1/3;
    margin-top: 0;
  }
}
@media (max-width: 1023px) {
  .can_we_go__right form .btn {
    grid-column: 1/2;
    grid-row: 3/4;
  }
}
@media (max-width: 1279px) {
  .can_we_go__right form {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__right form {
    grid-template-columns: 100%;
    gap: clamp(8px, 0.0113798009 * 100vw + 4.3584637269px, 16px);
  }
}
.can_we_go__right .personal {
  width: 100%;
  text-align: center;
  margin-top: 15px;
  padding-bottom: clamp(14px, 1.09375vw, 21px);
}
@media (max-width: 1279px) {
  .can_we_go__right .personal {
    margin-top: 66px;
    padding-bottom: 44px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__right .personal {
    margin-top: clamp(18px, 0.0682788051 * 100vw + -3.8492176387px, 66px);
    padding-bottom: clamp(35px, 0.012802276 * 100vw + 30.9032716927px, 44px);
  }
}
@media (max-width: 1279px) {
  .can_we_go__right {
    width: 100vw;
    margin-left: -56px;
    margin-top: 31px;
    padding: 0 56px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__right {
    margin-top: clamp(8px, 0.0327169275 * 100vw + -2.4694167852px, 31px);
    margin-left: clamp(-56px, -0.0512091038 * 100vw + -3.613086771px, -20px);
    padding: 0 clamp(20px, 0.0512091038 * 100vw + 3.613086771px, 56px);
  }
}
.can_we_go__item {
  height: clamp(170.6666666667px, 13.3333333333vw, 256px);
  border-radius: clamp(13px, 1.015625vw, 19.5px);
  padding: clamp(12px, 0.9375vw, 18px) clamp(21px, 1.640625vw, 31.5px) clamp(15px, 1.171875vw, 22.5px) clamp(19px, 1.484375vw, 28.5px);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  background: var(--color);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
.can_we_go__item .name {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(24px, 1.875vw, 36px);
  font-style: italic;
  margin-top: auto;
}
@media (max-width: 1279px) {
  .can_we_go__item .name {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__item .name {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .can_we_go__item .name {
    display: none;
  }
}
.can_we_go__item .desc {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: rgba(255, 255, 255, 0.6);
  margin-top: clamp(9.3333333333px, 0.7291666667vw, 14px);
}
@media (max-width: 1279px) {
  .can_we_go__item .desc {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__item .desc {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1279px) {
  .can_we_go__item .desc {
    margin-top: 9px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__item .desc {
    line-height: clamp(14.4px, 0.0204836415 * 100vw + 7.8452347084px, 28.8px);
    margin-top: clamp(6px, 0.0042674253 * 100vw + 4.6344238976px, 9px);
    margin-left: 2px;
  }
}
.can_we_go__item .term {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #C0FF00;
}
@media (max-width: 1279px) {
  .can_we_go__item .term {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__item .term {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1279px) {
  .can_we_go__item .term {
    line-height: 26.4px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__item .term {
    display: none;
    line-height: clamp(13.2px, 0.0187766714 * 100vw + 7.1914651494px, 26.4px);
    text-wrap: balance;
  }
}
.can_we_go__item .group {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  align-items: center;
}
.can_we_go__item .group .name {
  display: none;
}
@media (max-width: 1279px) {
  .can_we_go__item .group .name {
    display: block;
    font-size: 40px;
    line-height: 40px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__item .group .name {
    font-size: clamp(17px, 0.0327169275 * 100vw + 6.5305832148px, 40px);
    line-height: clamp(17px, 0.0327169275 * 100vw + 6.5305832148px, 40px);
    margin-top: 0;
  }
}
@media (max-width: 1279px) {
  .can_we_go__item .group {
    justify-content: space-between;
  }
}
@media (max-width: 1023px) {
  .can_we_go__item .group {
    flex-direction: row;
    justify-content: flex-start;
  }
}
.can_we_go__item .left {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 1023px) {
  .can_we_go__item .left {
    width: 75%;
  }
}
.can_we_go__item .right .term {
  display: none;
}
@media (max-width: 1023px) {
  .can_we_go__item .right .term {
    text-align: end;
    display: block;
  }
}
@media (max-width: 1023px) {
  .can_we_go__item .right {
    width: 24%;
  }
}
.can_we_go__item::after {
  content: "";
  transition: all 0.3s ease;
}
@media (max-width: 1023px) {
  .can_we_go__item::after {
    display: none !important;
  }
}
@media (hover: hover) {
  .can_we_go__item:hover {
    box-shadow: 0px 16px 49.3px -6px var(--color);
  }
  .can_we_go__item:hover::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
  }
}
@media (hover: none), (hover: on-demand) {
  .can_we_go__item:active {
    box-shadow: 0px 16px 49.3px -6px var(--color);
  }
  .can_we_go__item:active::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
  }
}
@media (max-width: 1279px) {
  .can_we_go__item {
    height: auto;
    padding: 25px 40px 33px;
    border-radius: 18px;
  }
  .can_we_go__item:not(:last-child) {
    margin-bottom: 13px;
  }
}
@media (max-width: 1023px) {
  .can_we_go__item {
    padding: clamp(13px, 0.0170697013 * 100vw + 7.5376955903px, 25px) clamp(12px, 0.039829303 * 100vw + -0.7453769559px, 40px) clamp(13px, 0.0284495021 * 100vw + 3.8961593172px, 33px);
    border-radius: clamp(8px, 0.0142247511 * 100vw + 3.4480796586px, 18px);
    flex-direction: row;
    justify-content: space-between;
  }
  .can_we_go__item:not(:last-child) {
    margin-bottom: clamp(5px, 0.0113798009 * 100vw + 1.3584637269px, 13px);
  }
}
@media (max-width: 1279px) {
  .can_we_go {
    padding-top: 67px;
  }
}
@media (max-width: 1023px) {
  .can_we_go {
    padding-top: clamp(30px, 0.0526315789 * 100vw + 13.1578947368px, 67px);
  }
}

.work_scheme {
  touch-action: pan-up;
  position: relative;
  padding-top: clamp(38.6666666667px, 3.0208333333vw, 58px);
  padding-bottom: clamp(94px, 7.34375vw, 141px);
}
.work_scheme .container_1920 {
  position: relative;
}
.work_scheme__hashtag {
  width: 100%;
  text-align: center;
}
@media (max-width: 1279px) {
  .work_scheme__hashtag {
    font-size: 27.72px;
    line-height: 30.49px;
  }
}
@media (max-width: 1023px) {
  .work_scheme__hashtag {
    font-size: clamp(12px, 0.0223613087 * 100vw + 4.8443812233px, 27.72px);
    line-height: clamp(13.2px, 0.0245945946 * 100vw + 5.3297297297px, 30.49px);
  }
}
.work_scheme__title {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  width: 100%;
  text-align: center;
  margin-top: clamp(-20px, -1.0416666667vw, -13.3333333333px);
}
@media (max-width: 1279px) {
  .work_scheme__title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .work_scheme__title {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .work_scheme__title {
    font-size: 69.3px;
    line-height: 69.3px;
    margin-top: 3px;
    padding-bottom: 15px;
  }
}
@media (max-width: 1023px) {
  .work_scheme__title {
    font-size: clamp(30px, 0.0559032717 * 100vw + 12.1109530583px, 69.3px);
    line-height: clamp(30px, 0.0559032717 * 100vw + 12.1109530583px, 69.3px);
    padding-bottom: clamp(10px, 0.0071123755 * 100vw + 7.7240398293px, 15px);
  }
}
.work_scheme__svg {
  width: 100%;
  height: 100%;
  position: relative;
}
.work_scheme__svg.el7, .work_scheme__svg.el5, .work_scheme__svg.el4 {
  padding-top: clamp(24px, 1.875vw, 36px);
  padding-bottom: clamp(152px, 11.875vw, 228px);
}
.work_scheme__svg.el5 {
  padding-bottom: clamp(92.6666666667px, 7.2395833333vw, 139px);
}
.work_scheme__svg.el4 {
  padding-bottom: clamp(110px, 8.59375vw, 165px);
}
.work_scheme__svg.el3:not(.left_text) {
  padding-top: clamp(49.3333333333px, 3.8541666667vw, 74px);
  padding-bottom: clamp(145.3333333333px, 11.3541666667vw, 218px);
}
.work_scheme__svg.el3:not(.left_text) svg {
  width: clamp(449.3333333333px, 35.1041666667vw, 674px);
  height: clamp(246.6666666667px, 19.2708333333vw, 370px);
  margin-left: clamp(248.6666666667px, 19.4270833333vw, 373px);
}
.work_scheme__svg.el2 {
  padding-top: clamp(62px, 4.84375vw, 93px);
  padding-bottom: clamp(274px, 21.40625vw, 411px);
}
.work_scheme__svg.left_text {
  padding-top: clamp(62px, 4.84375vw, 93px);
  padding-bottom: clamp(297.3333333333px, 23.2291666667vw, 446px);
}
.work_scheme__svg.left_text svg {
  margin-left: clamp(284.6666666667px, 22.2395833333vw, 427px);
  width: clamp(342px, 26.71875vw, 513px);
  height: clamp(304px, 23.75vw, 456px);
}
.work_scheme__svg svg.el7 {
  width: clamp(746.6666666667px, 58.3333333333vw, 1120px);
  height: clamp(388px, 30.3125vw, 582px);
  margin-left: clamp(158px, 12.34375vw, 237px);
}
.work_scheme__svg svg.el5 {
  width: clamp(707.3333333333px, 55.2604166667vw, 1061px);
  height: clamp(284.6666666667px, 22.2395833333vw, 427px);
  margin-left: clamp(158px, 12.34375vw, 237px);
}
.work_scheme__svg svg.el4 {
  width: clamp(518px, 40.46875vw, 777px);
  height: clamp(320px, 25vw, 480px);
  margin-left: clamp(302.6666666667px, 23.6458333333vw, 454px);
}
.work_scheme__svg svg.el2 {
  width: clamp(416.6666666667px, 32.5520833333vw, 625px);
  height: clamp(83.3333333333px, 6.5104166667vw, 125px);
  margin-left: clamp(195.3333333333px, 15.2604166667vw, 293px);
}
.work_scheme__svg .item {
  position: absolute;
  display: flex;
}
.work_scheme__svg .item_1 {
  left: clamp(20px, 1.5625vw, 30px);
  top: clamp(-12px, -0.625vw, -8px);
}
.work_scheme__svg .item_1 .text {
  max-width: clamp(187px, 14.609375vw, 280.5px);
}
.work_scheme__svg .item_1 .img {
  left: clamp(-28px, -1.4583333333vw, -18.6666666667px);
  top: clamp(8px, 0.625vw, 12px);
}
.work_scheme__svg .item_1.el4 .text {
  max-width: clamp(220px, 17.1875vw, 330px);
}
.work_scheme__svg .item_1.el3 {
  left: clamp(58.6666666667px, 4.5833333333vw, 88px);
  top: clamp(3.3333333333px, 0.2604166667vw, 5px);
  flex-direction: column;
}
.work_scheme__svg .item_1.el3 .text.not_title {
  padding-top: 0;
  margin-top: clamp(-6px, -0.3125vw, -4px);
  margin-left: clamp(5.3333333333px, 0.4166666667vw, 8px);
  max-width: clamp(340.6666666667px, 26.6145833333vw, 511px);
}
.work_scheme__svg .item_1.el3.left_text {
  flex-direction: row;
  left: clamp(22px, 1.71875vw, 33px);
  top: clamp(31.3333333333px, 2.4479166667vw, 47px);
}
.work_scheme__svg .item_1.el3.left_text .text {
  max-width: clamp(343.3333333333px, 26.8229166667vw, 515px);
}
.work_scheme__svg .item_1.el2 {
  left: clamp(32px, 2.5vw, 48px);
  top: clamp(28px, 2.1875vw, 42px);
}
.work_scheme__svg .item_1.el2 .text {
  max-width: clamp(405.3333333333px, 31.6666666667vw, 608px);
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .work_scheme__svg .item_1.el2 .text {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .work_scheme__svg .item_1.el2 .text {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.work_scheme__svg .item_1.el2 .text p {
  color: #C0FF00;
  margin-bottom: clamp(8.6666666667px, 0.6770833333vw, 13px);
}
.work_scheme__svg .item_1.el2 .text ul {
  padding-left: clamp(12px, 0.9375vw, 18px);
}
.work_scheme__svg .item_1.el2 .text ul li {
  margin-bottom: clamp(8.6666666667px, 0.6770833333vw, 13px);
  list-style-type: disc;
}
.work_scheme__svg .item_1.el2 .text ul li::marker {
  color: #C0FF00;
}
.work_scheme__svg .item_1.el2 .text ul li span {
  color: #707881;
}
.work_scheme__svg .item_1.el2 .text ul ul {
  padding-left: 0;
}
.work_scheme__svg .item_1.el2 .text ul ul li {
  list-style-type: none;
  color: #707881;
}
.work_scheme__svg .item_2 {
  left: clamp(324.6666666667px, 25.3645833333vw, 487px);
  top: clamp(31.3333333333px, 2.4479166667vw, 47px);
}
.work_scheme__svg .item_2 .img {
  left: clamp(-8px, -0.4166666667vw, -5.3333333333px);
  top: clamp(9.3333333333px, 0.7291666667vw, 14px);
}
.work_scheme__svg .item_2 .text {
  max-width: clamp(420px, 32.8125vw, 630px);
}
.work_scheme__svg .item_2.el4 {
  left: clamp(406px, 31.71875vw, 609px);
}
.work_scheme__svg .item_2.el4 .right {
  max-width: clamp(297.3333333333px, 23.2291666667vw, 446px);
}
.work_scheme__svg .item_2.el4 .text {
  max-width: 100%;
}
.work_scheme__svg .item_2.el3 {
  left: clamp(714px, 55.78125vw, 1071px);
  top: clamp(49.3333333333px, 3.8541666667vw, 74px);
  flex-direction: column;
}
.work_scheme__svg .item_2.el3 .text.not_title {
  padding-top: 0;
  margin-top: clamp(-7px, -0.3645833333vw, -4.6666666667px);
  margin-left: clamp(-1px, -0.0520833333vw, -0.6666666667px);
  max-width: clamp(340.6666666667px, 26.6145833333vw, 511px);
}
.work_scheme__svg .item_2.el3.left_text {
  flex-direction: row;
  left: clamp(644.6666666667px, 50.3645833333vw, 967px);
  top: clamp(40px, 3.125vw, 60px);
}
.work_scheme__svg .item_2.el3.left_text .text {
  max-width: clamp(456.6666666667px, 35.6770833333vw, 685px);
}
.work_scheme__svg .item_2.el2 {
  left: clamp(627.3333333333px, 49.0104166667vw, 941px);
  top: clamp(92.6666666667px, 7.2395833333vw, 139px);
}
.work_scheme__svg .item_2.el2 .text {
  max-width: clamp(344px, 26.875vw, 516px);
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .work_scheme__svg .item_2.el2 .text {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .work_scheme__svg .item_2.el2 .text {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.work_scheme__svg .item_2.el2 .text p {
  color: #C0FF00;
  margin-bottom: clamp(8.6666666667px, 0.6770833333vw, 13px);
}
.work_scheme__svg .item_2.el2 .text ul {
  padding-left: clamp(12px, 0.9375vw, 18px);
}
.work_scheme__svg .item_2.el2 .text ul li {
  margin-bottom: clamp(8.6666666667px, 0.6770833333vw, 13px);
  list-style-type: disc;
}
.work_scheme__svg .item_2.el2 .text ul li::marker {
  color: #C0FF00;
}
.work_scheme__svg .item_2.el2 .text ul li span {
  color: #707881;
}
.work_scheme__svg .item_2.el2 .text ul ul {
  padding-left: 0;
}
.work_scheme__svg .item_2.el2 .text ul ul li {
  list-style-type: none;
  color: #707881;
}
.work_scheme__svg .item_3 {
  top: clamp(73.3333333333px, 5.7291666667vw, 110px);
  right: clamp(28px, 2.1875vw, 42px);
}
.work_scheme__svg .item_3 .right {
  max-width: clamp(209px, 16.328125vw, 313.5px);
}
.work_scheme__svg .item_3 .img {
  top: clamp(8.6666666667px, 0.6770833333vw, 13px);
  left: clamp(-7px, -0.3645833333vw, -4.6666666667px);
}
.work_scheme__svg .item_3.el4 {
  right: clamp(4px, 0.3125vw, 6px);
}
.work_scheme__svg .item_3.el4 .right {
  max-width: clamp(273.3333333333px, 21.3541666667vw, 410px);
}
.work_scheme__svg .item_3.el3 {
  top: clamp(287.3333333333px, 22.4479166667vw, 431px);
  left: clamp(204px, 15.9375vw, 306px);
  flex-direction: column;
}
.work_scheme__svg .item_3.el3 .text.not_title {
  padding-top: 0;
  margin-top: clamp(-6px, -0.3125vw, -4px);
  margin-left: clamp(-2px, -0.1041666667vw, -1.3333333333px);
  max-width: clamp(340.6666666667px, 26.6145833333vw, 511px);
}
.work_scheme__svg .item_3.el3 .right {
  max-width: 100%;
}
.work_scheme__svg .item_3.el3.left_text {
  flex-direction: row;
  top: clamp(313.3333333333px, 24.4791666667vw, 470px);
  left: clamp(514.6666666667px, 40.2083333333vw, 772px);
}
.work_scheme__svg .item_3.el3.left_text .right {
  margin-left: clamp(16px, 1.25vw, 24px);
  max-width: 100%;
}
.work_scheme__svg .item_3.el3.left_text .text {
  max-width: clamp(416.6666666667px, 32.5520833333vw, 625px);
}
.work_scheme__svg .item_4 {
  left: clamp(385.3333333333px, 30.1041666667vw, 578px);
  top: clamp(195.3333333333px, 15.2604166667vw, 293px);
}
.work_scheme__svg .item_4 .img {
  top: clamp(8.6666666667px, 0.6770833333vw, 13px);
  left: clamp(-7px, -0.3645833333vw, -4.6666666667px);
}
.work_scheme__svg .item_4 .text {
  max-width: clamp(384px, 30vw, 576px);
}
.work_scheme__svg .item_4.el5 {
  top: clamp(201.3333333333px, 15.7291666667vw, 302px);
  left: clamp(21.3333333333px, 1.6666666667vw, 32px);
  flex-direction: row-reverse;
}
.work_scheme__svg .item_4.el5 .right {
  max-width: clamp(252.6666666667px, 19.7395833333vw, 379px);
  margin-left: 0;
  margin-right: clamp(16.6666666667px, 1.3020833333vw, 25px);
}
.work_scheme__svg .item_4.el5 .img {
  right: 0;
  top: clamp(8.6666666667px, 0.6770833333vw, 13px);
  left: auto;
}
.work_scheme__svg .item_4.el4 {
  top: clamp(284.6666666667px, 22.2395833333vw, 427px);
  left: clamp(133.3333333333px, 10.4166666667vw, 200px);
  flex-direction: row-reverse;
}
.work_scheme__svg .item_4.el4 .right {
  max-width: clamp(393.3333333333px, 30.7291666667vw, 590px);
  margin-left: 0;
  margin-right: clamp(16.6666666667px, 1.3020833333vw, 25px);
}
.work_scheme__svg .item_4.el4 .img {
  right: clamp(22.6666666667px, 1.7708333333vw, 34px);
  top: clamp(9.3333333333px, 0.7291666667vw, 14px);
  left: auto;
}
.work_scheme__svg .item_5 {
  top: clamp(258.6666666667px, 20.2083333333vw, 388px);
  left: clamp(101.3333333333px, 7.9166666667vw, 152px);
  flex-direction: row-reverse;
}
.work_scheme__svg .item_5 .right {
  max-width: clamp(187px, 14.609375vw, 280.5px);
  margin-left: 0;
  margin-right: clamp(-15px, -0.78125vw, -10px);
}
.work_scheme__svg .item_5 .img {
  top: clamp(8.6666666667px, 0.6770833333vw, 13px);
  right: clamp(-6px, -0.3125vw, -4px);
}
.work_scheme__svg .item_5.el5 {
  top: clamp(258.6666666667px, 20.2083333333vw, 388px);
  left: clamp(431.3333333333px, 33.6979166667vw, 647px);
  flex-direction: row;
}
.work_scheme__svg .item_5.el5 .right {
  max-width: clamp(215.3333333333px, 16.8229166667vw, 323px);
  margin-left: clamp(10.6666666667px, 0.8333333333vw, 16px);
  margin-right: 0;
}
.work_scheme__svg .item_5.el5 .img {
  top: clamp(8.6666666667px, 0.6770833333vw, 13px);
  right: auto;
  left: clamp(-13px, -0.6770833333vw, -8.6666666667px);
}
.work_scheme__svg .item_6 {
  left: clamp(445.3333333333px, 34.7916666667vw, 668px);
  top: clamp(401.3333333333px, 31.3541666667vw, 602px);
}
.work_scheme__svg .item_6 .text {
  max-width: clamp(289px, 22.578125vw, 433.5px);
}
.work_scheme__svg .item_6 .img {
  top: clamp(8.6666666667px, 0.6770833333vw, 13px);
  left: clamp(-5px, -0.2604166667vw, -3.3333333333px);
}
.work_scheme__svg .item_7 {
  right: clamp(28px, 2.1875vw, 42px);
  top: clamp(361.3333333333px, 28.2291666667vw, 542px);
}
.work_scheme__svg .item_7 .right {
  max-width: clamp(171px, 13.359375vw, 256.5px);
}
.work_scheme__svg .item_7 .img {
  top: clamp(8.6666666667px, 0.6770833333vw, 13px);
  left: clamp(-11px, -0.5729166667vw, -7.3333333333px);
}
.work_scheme__svg .item .right {
  display: flex;
  flex-direction: column;
  margin-left: clamp(4.6666666667px, 0.3645833333vw, 7px);
}
.work_scheme__svg .item .numb {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  color: #C0FF00;
}
@media (max-width: 1279px) {
  .work_scheme__svg .item .numb {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .work_scheme__svg .item .numb {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
.work_scheme__svg .item .img {
  width: clamp(77px, 6.015625vw, 115.5px);
  height: clamp(77px, 6.015625vw, 115.5px);
  position: absolute;
  z-index: -1;
}
.work_scheme__svg .item .title {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(26.4px, 2.0625vw, 39.6px);
  padding-top: clamp(24px, 1.875vw, 36px);
}
@media (max-width: 1279px) {
  .work_scheme__svg .item .title {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .work_scheme__svg .item .title {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
.work_scheme__svg .item .text {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: #707881;
  line-height: clamp(14.4px, 1.125vw, 21.6px);
  padding-top: clamp(10px, 0.78125vw, 15px);
}
@media (max-width: 1279px) {
  .work_scheme__svg .item .text {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .work_scheme__svg .item .text {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.work_scheme__svg .item .text:not(.el2) ul {
  padding-left: clamp(18.6666666667px, 1.4583333333vw, 28px);
}
.work_scheme__svg .item .text:not(.el2) ul li {
  list-style-type: disc;
}
.work_scheme__svg .item .text:not(.el2) ul li::marker {
  color: #707881;
  font-size: clamp(6.6666666667px, 0.5208333333vw, 10px);
}
.work_scheme__svg .left_text {
  position: absolute;
  left: clamp(4.6666666667px, 0.3645833333vw, 7px);
  top: clamp(448px, 35vw, 672px);
}
.work_scheme__svg .left_text__name {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  margin-bottom: clamp(9.3333333333px, 0.7291666667vw, 14px);
}
@media (max-width: 1279px) {
  .work_scheme__svg .left_text__name {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .work_scheme__svg .left_text__name {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
.work_scheme__svg .left_text__name:not(:first-child) {
  margin-top: clamp(40.6666666667px, 3.1770833333vw, 61px);
}
.work_scheme__svg .left_text__list {
  padding-left: clamp(18.6666666667px, 1.4583333333vw, 28px);
  max-width: clamp(422.6666666667px, 33.0208333333vw, 634px);
}
.work_scheme__svg .left_text__list li {
  list-style-type: disc;
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: #707881;
}
@media (max-width: 1279px) {
  .work_scheme__svg .left_text__list li {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .work_scheme__svg .left_text__list li {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.work_scheme__svg .left_text__list li::marker {
  color: #707881;
  font-size: clamp(6.6666666667px, 0.5208333333vw, 10px);
}
@media (max-width: 1279px) {
  .work_scheme__svg {
    display: none;
  }
}
.work_scheme__btn_container {
  width: 100%;
  text-align: center;
}
.work_scheme__btn_container.el5 {
  width: auto;
  position: absolute;
  bottom: 0;
  left: auto;
  right: clamp(67.3333333333px, 5.2604166667vw, 101px);
}
@media (max-width: 1279px) {
  .work_scheme__btn_container.el5 {
    position: initial;
    width: 100%;
  }
}
.work_scheme__btn_container.el4 {
  width: auto;
  position: absolute;
  bottom: 0;
  left: auto;
  right: clamp(66.6666666667px, 5.2083333333vw, 100px);
}
@media (max-width: 1279px) {
  .work_scheme__btn_container.el4 {
    position: initial;
    width: 100%;
  }
}
.work_scheme__btn_container.left_text {
  width: 73%;
  margin-left: auto;
}
@media (max-width: 1279px) {
  .work_scheme__btn_container.left_text {
    margin-right: auto;
    width: 100%;
  }
}
.work_scheme__btn_container.el3:not(.left_text) {
  width: auto;
  position: absolute;
  bottom: 0;
  left: auto;
  right: clamp(222.6666666667px, 17.3958333333vw, 334px);
}
@media (max-width: 1279px) {
  .work_scheme__btn_container.el3:not(.left_text) {
    position: initial;
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .work_scheme__btn_container .btn span {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1023px) {
  .work_scheme__btn_container .btn {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 1279px) {
  .work_scheme__btn_container {
    margin-top: 69px;
  }
}
@media (max-width: 1023px) {
  .work_scheme__btn_container {
    margin-top: clamp(17px, 0.0739687055 * 100vw + -6.6699857752px, 69px);
  }
}
.work_scheme__container {
  display: none;
  margin-left: 8px;
  border-left: 2px solid #C0FF00;
  position: relative;
}
.work_scheme__container .item {
  position: relative;
  display: flex;
}
.work_scheme__container .item:not(:last-child) {
  margin-bottom: 29px;
}
@media (max-width: 1023px) {
  .work_scheme__container .item:not(:last-child) {
    margin-bottom: clamp(7px, 0.0312944523 * 100vw + -3.0142247511px, 29px);
  }
}
.work_scheme__container .item .numb {
  font-size: 69.3px;
  line-height: 69.3px;
  color: #C0FF00;
  position: relative;
  margin-left: 23px;
}
.work_scheme__container .item .numb .circle {
  position: absolute;
  left: -33px;
  top: 47%;
  width: 18px;
  height: 18px;
  background: #C0FF00;
  border-radius: 50%;
}
@media (max-width: 1023px) {
  .work_scheme__container .item .numb .circle {
    width: clamp(8px, 0.0142247511 * 100vw + 3.4480796586px, 18px);
    height: clamp(8px, 0.0142247511 * 100vw + 3.4480796586px, 18px);
    left: clamp(-33px, -0.039829303 * 100vw + 7.7453769559px, -5px);
  }
}
@media (max-width: 1023px) {
  .work_scheme__container .item .numb {
    font-size: clamp(30px, 0.0559032717 * 100vw + 12.1109530583px, 69.3px);
    line-height: clamp(30px, 0.0559032717 * 100vw + 12.1109530583px, 69.3px);
    margin-left: clamp(0px, 0.0327169275 * 100vw + -10.4694167852px, 23px);
  }
}
.work_scheme__container .item .title {
  font-size: 40px;
  line-height: 44px;
  color: #F2F2F6;
  margin-top: 19px;
}
@media (max-width: 1023px) {
  .work_scheme__container .item .title {
    font-size: clamp(17px, 0.0327169275 * 100vw + 6.5305832148px, 40px);
    line-height: clamp(18.7px, 0.0359886202 * 100vw + 7.1836415363px, 44px);
    margin-top: clamp(9px, 0.0142247511 * 100vw + 4.4480796586px, 19px);
  }
}
.work_scheme__container .item .text {
  font-size: 24px;
  line-height: 28.8px;
  color: #707881;
  margin-top: 14px;
  position: relative;
}
@media (max-width: 1023px) {
  .work_scheme__container .item .text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(14.4px, 0.0204836415 * 100vw + 7.8452347084px, 28.8px);
    margin-top: clamp(6px, 0.0113798009 * 100vw + 2.3584637269px, 14px);
  }
}
.work_scheme__container .item .text.el2 {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .work_scheme__container .item .text.el2 {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .work_scheme__container .item .text.el2 {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.work_scheme__container .item .text.el2 p {
  color: #C0FF00;
  margin-bottom: clamp(8.6666666667px, 0.6770833333vw, 13px);
}
.work_scheme__container .item .text.el2 ul {
  padding-left: clamp(12px, 0.9375vw, 18px);
}
.work_scheme__container .item .text.el2 ul li {
  margin-bottom: clamp(8.6666666667px, 0.6770833333vw, 13px);
  list-style-type: disc;
}
.work_scheme__container .item .text.el2 ul li::marker {
  color: #C0FF00;
}
.work_scheme__container .item .text.el2 ul li span {
  color: #707881;
}
.work_scheme__container .item .text.el2 ul ul {
  padding-left: 0;
}
.work_scheme__container .item .text.el2 ul ul li {
  list-style-type: none;
  color: #707881;
}
.work_scheme__container .item .img {
  width: 116px;
  height: 116px;
  position: absolute;
  z-index: -1;
  left: 22px;
  top: 0;
}
@media (max-width: 1023px) {
  .work_scheme__container .item .img {
    width: clamp(50px, 0.093883357 * 100vw + 19.9573257468px, 116px);
    height: clamp(50px, 0.093883357 * 100vw + 19.9573257468px, 116px);
    left: clamp(9px, 0.0184921764 * 100vw + 3.0825035562px, 22px);
  }
}
.work_scheme__container .item .left {
  width: 9.3%;
}
@media (max-width: 1023px) {
  .work_scheme__container .item .left {
    text-align: center;
  }
}
.work_scheme__container .item .right {
  width: 89.7%;
  position: relative;
}
.work_scheme__container .top_plug {
  position: absolute;
  top: 0;
  left: -2px;
  background: #121B24;
  height: 33px;
  width: 2px;
}
@media (max-width: 1023px) {
  .work_scheme__container .top_plug {
    height: clamp(15px, 0.0256045519 * 100vw + 6.8065433855px, 33px);
  }
}
.work_scheme__container .bottom_plug {
  position: absolute;
  left: clamp(-112px, -0.0941176471 * 100vw + 8.3764705882px, -88px);
  top: clamp(50px, -0.0039215686 * 100vw + 55.0156862745px, 51px);
  bottom: -10px;
  background: #121B24;
  width: 6px;
}
@media (max-width: 1023px) {
  .work_scheme__container .bottom_plug {
    left: clamp(-88px, -0.0825035562 * 100vw + -3.5988620199px, -30px);
    top: clamp(40px, 0.0431372549 * 100vw + 6.8705882353px, 51px);
  }
}
@media (max-width: 767px) {
  .work_scheme__container .bottom_plug {
    top: clamp(26px, 0.0409356725 * 100vw + 8.6023391813px, 40px);
  }
}
@media (max-width: 425px) {
  .work_scheme__container .bottom_plug {
    top: clamp(22px, 0.0380952381 * 100vw + 9.8095238095px, 26px);
  }
}
@media (max-width: 1279px) {
  .work_scheme__container {
    display: block;
  }
}
@media (max-width: 1023px) {
  .work_scheme__container {
    border-left: clamp(1px, 0.0014224751 * 100vw + 0.5448079659px, 2px) solid #C0FF00;
    margin-left: clamp(2px, 0.0085348506 * 100vw + -0.7311522048px, 8px);
  }
}
.work_scheme canvas {
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
}
@media (max-width: 1279px) {
  .work_scheme {
    padding-top: 27px;
  }
  .work_scheme .left_text {
    margin-top: 30px;
  }
  .work_scheme .left_text__name {
    font-family: "Hoves Regular";
    font-size: clamp(24px, 1.875vw, 36px);
    line-height: clamp(31px, 2.421875vw, 46.5px);
    font-weight: 400;
    color: #F2F2F6;
    margin-bottom: clamp(9.3333333333px, 0.7291666667vw, 14px);
  }
}
@media (max-width: 1279px) and (max-width: 1279px) {
  .work_scheme .left_text__name {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1279px) and (max-width: 1023px) {
  .work_scheme .left_text__name {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .work_scheme .left_text__name:not(:first-child) {
    margin-top: clamp(40.6666666667px, 3.1770833333vw, 61px);
  }
}
@media (max-width: 1279px) and (max-width: 1279px) {
  .work_scheme .left_text__name:not(:first-child) {
    margin-top: 30px;
  }
}
@media (max-width: 1279px) {
  .work_scheme .left_text__list {
    padding-left: clamp(18.6666666667px, 1.4583333333vw, 28px);
  }
  .work_scheme .left_text__list li {
    list-style-type: disc;
    font-family: "Hoves Regular";
    font-size: clamp(12px, 0.9375vw, 18px);
    line-height: clamp(14px, 1.09375vw, 21px);
    color: #F2F2F6;
    color: #707881;
  }
}
@media (max-width: 1279px) and (max-width: 1279px) {
  .work_scheme .left_text__list li {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1279px) and (max-width: 1023px) {
  .work_scheme .left_text__list li {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
@media (max-width: 1279px) {
  .work_scheme .left_text__list li::marker {
    color: #707881;
    font-size: 10px;
  }
}
@media (max-width: 1023px) {
  .work_scheme {
    padding-bottom: clamp(30px, 0.0995732575 * 100vw + -1.8634423898px, 100px);
    padding-top: clamp(10px, 0.0241820768 * 100vw + 2.2617354196px, 27px);
  }
}

.cases .container_1920 {
  position: relative;
}
.cases .background_line {
  background: repeating-linear-gradient(90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 8px, #05080C 11px), linear-gradient(180deg, #121B24 62%, #212D37 100%);
  border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
}
.cases__tabs {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}
.cases__tabs.pb {
  padding-bottom: clamp(58.6666666667px, 4.5833333333vw, 88px);
}
.cases__tabs .item {
  padding: clamp(3.3333333333px, 0.2604166667vw, 5px) clamp(14.6666666667px, 1.1458333333vw, 22px) clamp(3.3333333333px, 0.2604166667vw, 5px);
  border: 1px solid #37424D;
  border-radius: clamp(22.6866666667px, 1.7723958333vw, 34.03px);
  margin-bottom: clamp(3.3333333333px, 0.2604166667vw, 5px);
  font-size: clamp(12.0066666667px, 0.9380208333vw, 18.01px);
  line-height: clamp(14.4133333333px, 1.1260416667vw, 21.62px);
  color: #707881;
  transition: all 0.3s ease;
  text-transform: lowercase;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
@media (hover: hover) {
  .cases__tabs .item:hover {
    border-color: #C0FF00;
    color: #C0FF00;
  }
}
@media (hover: none), (hover: on-demand) {
  .cases__tabs .item:active {
    border-color: #C0FF00;
    color: #C0FF00;
  }
}
@media (max-width: 1279px) {
  .cases__tabs .item {
    font-size: 24px;
    line-height: 28.8px;
    padding: 6px 19px 9px;
    margin-bottom: 5px;
  }
}
@media (max-width: 1023px) {
  .cases__tabs .item {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(14.4px, 0.0204836415 * 100vw + 7.8452347084px, 28.8px);
    padding: clamp(2px, 0.0056899004 * 100vw + 0.1792318634px, 6px) clamp(14px, 0.0071123755 * 100vw + 11.7240398293px, 19px) clamp(4px, 0.0071123755 * 100vw + 1.7240398293px, 9px);
    margin-bottom: clamp(2px, 0.0042674253 * 100vw + 0.6344238976px, 5px);
  }
}
@media (max-width: 425px) {
  .cases__tabs {
    justify-content: center;
    margin-left: -55px;
    margin-right: -55px;
  }
}
.cases__head {
  width: 100%;
  background: var(--block_color);
  padding-top: clamp(37.3333333333px, 2.9166666667vw, 56px);
  padding-bottom: clamp(59.3333333333px, 4.6354166667vw, 89px);
  position: relative;
  z-index: 1;
}
@media (max-width: 1279px) {
  .cases__head {
    padding-top: 47px;
  }
}
@media (max-width: 1023px) {
  .cases__head {
    padding-top: clamp(14px, 0.0469416785 * 100vw + -1.0213371266px, 47px);
    padding-bottom: clamp(22px, 0.0526315789 * 100vw + 5.1578947368px, 59px);
  }
}
.cases__hashtag {
  color: rgba(255, 255, 255, 0.5);
  margin-left: clamp(43.3333333333px, 3.3854166667vw, 65px);
}
@media (max-width: 1279px) {
  .cases__hashtag {
    margin-left: 25px;
  }
}
@media (max-width: 1023px) {
  .cases__hashtag {
    margin-left: clamp(1px, 0.0341394026 * 100vw + -9.9246088193px, 25px);
  }
}
.cases__title {
  max-width: clamp(565px, 44.140625vw, 847.5px);
}
.cases__title.size-100 {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(67px, 5.234375vw, 100.5px);
  margin-left: clamp(2px, 0.15625vw, 3px);
}
@media (max-width: 1279px) {
  .cases__title.size-100 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .cases__title.size-100 {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1023px) {
  .cases__title.size-100 {
    font-size: 30px;
    line-height: 30px;
  }
}
.cases__title.size-80 {
  font-family: "Hoves Regular";
  font-size: clamp(53.3333333333px, 4.1666666667vw, 80px);
  line-height: clamp(53.3333333333px, 4.1666666667vw, 80px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1023px) {
  .cases__title.size-80 {
    font-size: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
    line-height: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
  }
}
@media (max-width: 1279px) {
  .cases__title {
    max-width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 1023px) {
  .cases__title {
    padding-top: 3px;
    margin-left: clamp(0px, -0.0028449502 * 100vw + 2.9103840683px, 2px);
  }
}
.cases__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 22.5px;
  margin-top: clamp(-320px, -16.6666666667vw, -213.3333333333px);
  padding-bottom: clamp(30.6666666667px, 2.3958333333vw, 46px);
  max-width: 1719px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}
.cases__grid .text {
  grid-column: 1/3;
  grid-row: 2/3;
  display: flex;
  align-items: flex-end;
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  font-style: italic;
  max-width: clamp(461px, 36.015625vw, 691.5px);
  padding-bottom: clamp(32px, 2.5vw, 48px);
}
@media (max-width: 1279px) {
  .cases__grid .text {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .cases__grid .text {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
@media (max-width: 1279px) {
  .cases__grid .text {
    grid-column: 1/3;
    grid-row: 1/2;
    max-width: 100%;
    padding-top: 36px;
    padding-bottom: 19px;
  }
}
@media (max-width: 1023px) {
  .cases__grid .text {
    font-size: 17px;
    line-height: 18.7px;
    max-width: 80%;
    padding-top: clamp(14px, 0.0312944523 * 100vw + 3.9857752489px, 36px);
    padding-bottom: clamp(9px, 0.0142247511 * 100vw + 4.4480796586px, 19px);
  }
}
.cases__grid .item {
  width: 100%;
  height: clamp(178px, 13.90625vw, 267px);
  position: relative;
  max-width: clamp(274.6666666667px, 21.4583333333vw, 412px);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
.cases__grid .item_1 {
  grid-column: 3/4;
}
@media (max-width: 1279px) {
  .cases__grid .item_1 {
    grid-column: auto;
  }
}
.cases__grid .item_2 {
  grid-column: 4/5;
}
@media (max-width: 1279px) {
  .cases__grid .item_2 {
    grid-column: auto;
  }
}
.cases__grid .item .hover {
  z-index: 1;
  transform: scale(1.4466);
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}
@media (max-width: 1279px) {
  .cases__grid .item .hover {
    display: none;
  }
}
@media (hover: hover) {
  .cases__grid .item:hover .hover {
    opacity: 1;
  }
}
@media (hover: none), (hover: on-demand) {
  .cases__grid .item:active .hover {
    opacity: 1;
  }
}
.cases__grid .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: clamp(13px, 1.015625vw, 19.5px);
}
@media (max-width: 1279px) {
  .cases__grid .item img {
    border-radius: 22px;
  }
}
@media (max-width: 1023px) {
  .cases__grid .item img {
    border-radius: clamp(12px, 0.0142247511 * 100vw + 7.4480796586px, 22px);
  }
}
@media (max-width: 1279px) {
  .cases__grid .item {
    height: 288px;
    margin-bottom: 5px;
    max-width: 100%;
  }
}
@media (max-width: 1023px) {
  .cases__grid .item {
    height: clamp(88px, 0.2844950213 * 100vw + -3.0384068279px, 288px);
    margin-bottom: 0;
  }
}
@media (max-width: 1279px) {
  .cases__grid {
    margin-top: 0;
    grid-template-columns: repeat(2, 1fr);
    gap: 17px 22px;
    padding-bottom: 29px;
  }
}
@media (max-width: 1023px) {
  .cases__grid {
    gap: clamp(7px, 0.0142247511 * 100vw + 2.4480796586px, 17px) clamp(8px, 0.0199146515 * 100vw + 1.627311522px, 22px);
    padding-bottom: clamp(10px, 0.027027027 * 100vw + 1.3513513514px, 29px);
  }
}
.cases__expirienc_container {
  padding-top: clamp(122px, 9.53125vw, 183px);
  padding-bottom: clamp(136.6666666667px, 10.6770833333vw, 205px);
  max-width: clamp(547px, 42.734375vw, 820.5px);
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.cases__expirienc_container .expirience_top {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  text-align: center;
  margin-bottom: clamp(14px, 1.09375vw, 21px);
}
@media (max-width: 1279px) {
  .cases__expirienc_container .expirience_top {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .cases__expirienc_container .expirience_top {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
.cases__expirienc_container .expirience_top span {
  font-style: italic;
}
@media (max-width: 1279px) {
  .cases__expirienc_container .expirience_top {
    font-size: 58px;
    line-height: 63.8px;
    margin-bottom: 24px;
    max-width: 85%;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 1023px) {
  .cases__expirienc_container .expirience_top {
    font-size: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    line-height: clamp(33px, 0.0438122333 * 100vw + 18.9800853485px, 63.8px);
    margin-bottom: clamp(14px, 0.0142247511 * 100vw + 9.4480796586px, 24px);
  }
}
.cases__expirienc_container .expirience_bottom {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  text-align: center;
  margin-bottom: clamp(42.6666666667px, 3.3333333333vw, 64px);
}
@media (max-width: 1279px) {
  .cases__expirienc_container .expirience_bottom {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .cases__expirienc_container .expirience_bottom {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
.cases__expirienc_container .expirience_bottom span {
  color: #C0FF00;
}
@media (max-width: 1279px) {
  .cases__expirienc_container .expirience_bottom {
    max-width: 542px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 57px;
  }
}
@media (max-width: 1023px) {
  .cases__expirienc_container .expirience_bottom {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(18.36px, 0.0302133713 * 100vw + 8.6917211949px, 39.6px);
    margin-bottom: clamp(33px, 0.0341394026 * 100vw + 22.0753911807px, 57px);
    max-width: clamp(250px, 0.4153627312 * 100vw + 117.0839260313px, 542px);
  }
}
.cases__expirienc_container .btn_container {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 1023px) {
  .cases__expirienc_container .btn_container .btn {
    padding-left: clamp(17px, 0.0327169275 * 100vw + 6.5305832148px, 40px);
    padding-right: clamp(17px, 0.0327169275 * 100vw + 6.5305832148px, 40px);
  }
}
@media (max-width: 1279px) {
  .cases__expirienc_container {
    max-width: 100%;
    padding-top: 98px;
    padding-bottom: 145px;
  }
}
@media (max-width: 1023px) {
  .cases__expirienc_container {
    padding-top: clamp(40px, 0.0825035562 * 100vw + 13.5988620199px, 98px);
    padding-bottom: clamp(83px, 0.0881934566 * 100vw + 54.7780938834px, 145px);
  }
}
.cases__btn_only {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: clamp(44px, 3.4375vw, 66px);
  padding-bottom: clamp(71.3333333333px, 5.5729166667vw, 107px);
}
@media (max-width: 1279px) {
  .cases {
    padding-top: 5px;
  }
}

.reviews {
  position: relative;
  padding-top: clamp(38px, 2.96875vw, 57px);
  overflow: hidden;
}
.reviews__top {
  display: flex;
  justify-content: space-between;
}
.reviews__top .right img {
  width: clamp(195px, 15.234375vw, 292.5px);
  height: clamp(195px, 15.234375vw, 292.5px);
  margin-top: clamp(14px, 1.09375vw, 21px);
}
@media (max-width: 1279px) {
  .reviews__top .right img {
    width: 195px;
    height: 195px;
  }
}
@media (max-width: 1023px) {
  .reviews__top .right img {
    display: none;
  }
}
.reviews__bottom {
  margin-top: clamp(42.6666666667px, 3.3333333333vw, 64px);
  position: relative;
}
.reviews__bottom img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.reviews__bottom img.img_mob {
  display: none;
}
@media (max-width: 1279px) {
  .reviews__bottom img {
    height: 505px;
  }
}
@media (max-width: 1023px) {
  .reviews__bottom img {
    height: clamp(361px, 0.1977240398 * 100vw + 297.7283072546px, 500px);
  }
  .reviews__bottom img.img_pc {
    display: none;
  }
  .reviews__bottom img.img_mob {
    display: block;
    object-position: top;
  }
}
.reviews__bottom .btn_container {
  position: absolute;
  z-index: 2;
  bottom: clamp(32px, 2.5vw, 48px);
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 1023px) {
  .reviews__bottom .btn_container {
    bottom: clamp(5px, 0.0384068279 * 100vw + -7.2901849218px, 32px);
  }
}
@media (max-width: 1279px) {
  .reviews__bottom {
    margin: 55px -56px 0;
  }
}
@media (max-width: 1023px) {
  .reviews__bottom {
    margin: clamp(15px, 0.0568990043 * 100vw + -3.2076813656px, 55px) clamp(-56px, -0.0512091038 * 100vw + -3.613086771px, -20px) 0;
  }
}
.reviews__hashtag {
  margin-left: clamp(42.6666666667px, 3.3333333333vw, 64px);
}
@media (max-width: 1279px) {
  .reviews__hashtag {
    margin-left: 24px;
  }
}
@media (max-width: 1023px) {
  .reviews__hashtag {
    margin-left: clamp(0px, 0.0341394026 * 100vw + -10.9246088193px, 24px);
  }
}
.reviews__title1, .reviews__title2, .reviews__title3 {
  margin-left: clamp(2px, 0.15625vw, 3px);
}
@media (max-width: 1279px) {
  .reviews__title1, .reviews__title2, .reviews__title3 {
    margin-left: 0;
  }
}
.reviews__title1 {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  margin-top: clamp(-19px, -0.9895833333vw, -12.6666666667px);
}
@media (max-width: 1279px) {
  .reviews__title1 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .reviews__title1 {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
.reviews__title1 span {
  color: #C0FF00;
  font-style: italic;
}
@media (max-width: 1279px) {
  .reviews__title1 {
    margin-top: 2px;
  }
}
@media (max-width: 1023px) {
  .reviews__title1 {
    font-size: 30px;
    line-height: 30px;
    margin-top: 3px;
  }
}
.reviews__title2 {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  margin-top: clamp(-11px, -0.5729166667vw, -7.3333333333px);
}
@media (max-width: 1279px) {
  .reviews__title2 {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .reviews__title2 {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
@media (max-width: 1279px) {
  .reviews__title2 {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1279px) {
  .reviews__title2 {
    margin-top: 12px;
  }
}
@media (max-width: 1023px) {
  .reviews__title2 {
    font-size: 17px;
    line-height: 18.36px;
    margin-top: clamp(6px, 0.0085348506 * 100vw + 3.2688477952px, 12px);
  }
}
.reviews__title3 {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .reviews__title3 {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .reviews__title3 {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
.reviews__title3 span {
  color: #C0FF00;
  font-style: italic;
}
@media (max-width: 1279px) {
  .reviews__title3 {
    margin-top: 13px;
  }
}
@media (max-width: 1023px) {
  .reviews__title3 {
    font-size: 30px;
    line-height: 30px;
    margin-top: clamp(5px, 0.0113798009 * 100vw + 1.3584637269px, 13px);
  }
}
.reviews::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: clamp(201px, 15.703125vw, 301.5px);
  z-index: 1;
  background: linear-gradient(360deg, #121B24 0%, rgba(18, 27, 36, 0.87067) 37.03%, rgba(18, 27, 36, 0.428735) 72.94%, rgba(18, 27, 36, 0) 100%);
}
@media (max-width: 1279px) {
  .reviews::after {
    height: 274px;
  }
}
@media (max-width: 1023px) {
  .reviews::after {
    height: clamp(180px, 0.13371266 * 100vw + 137.2119487909px, 274px);
  }
}
@media (max-width: 1279px) {
  .reviews {
    padding-top: 67px;
    padding-bottom: 19px;
  }
}
@media (max-width: 1023px) {
  .reviews {
    padding-top: clamp(30px, 0.0526315789 * 100vw + 13.1578947368px, 67px);
    padding-bottom: 46px;
  }
}

.link_site_form {
  background: var(--bgc-color);
  padding-top: clamp(36.6666666667px, 2.8645833333vw, 55px);
  padding-bottom: clamp(66.6666666667px, 5.2083333333vw, 100px);
  position: relative;
  z-index: 1;
}
.link_site_form__hashtag {
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}
@media (max-width: 1279px) {
  .link_site_form__hashtag {
    text-align: start;
    margin-left: 25px;
  }
}
@media (max-width: 1023px) {
  .link_site_form__hashtag {
    text-align: center;
    margin-left: 0;
  }
}
.link_site_form__title {
  width: 100%;
  text-align: center;
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  margin-top: clamp(-20px, -1.0416666667vw, -13.3333333333px);
}
@media (max-width: 1279px) {
  .link_site_form__title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .link_site_form__title {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
.link_site_form__title.only_title {
  font-family: "Hoves Italic";
  font-size: clamp(100px, 7.8125vw, 150px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  font-family: "Hoves Regular";
  margin-top: clamp(-3px, -0.15625vw, -2px);
  margin-bottom: clamp(34px, 2.65625vw, 51px);
}
@media (max-width: 1279px) {
  .link_site_form__title.only_title {
    font-size: 80px;
    line-height: 80px;
  }
}
@media (max-width: 1023px) {
  .link_site_form__title.only_title {
    font-size: clamp(30px, 0.0711237553 * 100vw + 7.240398293px, 80px);
    line-height: clamp(30px, 0.0711237553 * 100vw + 7.240398293px, 80px);
  }
}
@media (max-width: 1279px) {
  .link_site_form__title.only_title {
    width: 100%;
    text-align: center;
    font-size: 58px;
    line-height: 58px;
    margin-top: 13px;
  }
}
@media (max-width: 1023px) {
  .link_site_form__title.only_title {
    font-size: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    line-height: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    margin-top: clamp(10px, 0.0042674253 * 100vw + 8.6344238976px, 13px);
    margin-bottom: 0;
  }
}
@media (max-width: 1279px) {
  .link_site_form__title {
    text-align: start;
    margin-top: 2px;
  }
}
@media (max-width: 1023px) {
  .link_site_form__title {
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    text-wrap: balance;
  }
}
.link_site_form form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(18.6666666667px, 1.4583333333vw, 28px) clamp(16px, 1.25vw, 24px);
  max-width: clamp(875px, 68.359375vw, 1312.5px);
  margin: clamp(22px, 1.71875vw, 33px) auto 0;
}
.link_site_form form input {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}
.link_site_form form input.placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.link_site_form form input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.link_site_form form input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.link_site_form form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.link_site_form form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.link_site_form form .name {
  grid-column: 1/2;
}
.link_site_form form .phone {
  grid-column: 2/3;
}
@media (max-width: 1023px) {
  .link_site_form form .phone {
    grid-column: auto;
  }
}
.link_site_form form .site_url {
  grid-column: 3/4;
}
@media (max-width: 1279px) {
  .link_site_form form .site_url {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}
@media (max-width: 1023px) {
  .link_site_form form .site_url {
    grid-column: auto;
    grid-row: auto;
  }
}
.link_site_form form .btn {
  grid-row: 2/3;
  grid-column: 2/3;
}
@media (max-width: 1279px) {
  .link_site_form form .btn {
    grid-row: 3/4;
    grid-column: 1/3;
    width: max-content;
    margin: 16px auto 0;
  }
}
@media (max-width: 1023px) {
  .link_site_form form .btn {
    grid-column: auto;
    grid-row: auto;
  }
}
@media (max-width: 425px) {
  .link_site_form form .btn {
    width: 100%;
  }
}
.link_site_form form .personal {
  color: rgba(255, 255, 255, 0.5);
  grid-row: 2/3;
  grid-column: 3/4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: clamp(9.3333333333px, 0.7291666667vw, 14px);
}
.link_site_form form .personal a {
  color: rgba(255, 255, 255, 0.5);
}
@media (max-width: 1279px) {
  .link_site_form form .personal a {
    margin-left: 10px;
  }
}
@media (max-width: 1023px) {
  .link_site_form form .personal a {
    margin-left: 0;
  }
}
@media (max-width: 1279px) {
  .link_site_form form .personal {
    grid-row: 4/5;
    grid-column: 1/3;
    flex-direction: row;
    margin-top: 50px;
  }
}
@media (max-width: 1023px) {
  .link_site_form form .personal {
    grid-column: auto;
    grid-row: auto;
    flex-direction: column;
    margin-top: clamp(9px, 0.0583214794 * 100vw + -9.6628733997px, 50px);
    text-align: center;
  }
}
@media (max-width: 1279px) {
  .link_site_form form {
    max-width: 100%;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 36px;
    gap: 16px clamp(16px, 1.25vw, 24px);
  }
}
@media (max-width: 1023px) {
  .link_site_form form {
    grid-template-columns: 100%;
    margin-top: clamp(20px, 0.0227596017 * 100vw + 12.7169274538px, 36px);
    gap: clamp(8px, 0.0113798009 * 100vw + 4.3584637269px, 16px);
  }
}
.link_site_form.mt {
  margin-top: clamp(-50px, -2.6041666667vw, -33.3333333333px);
  padding-top: clamp(70px, 5.46875vw, 105px);
  z-index: 0;
}
@media (max-width: 1279px) {
  .link_site_form {
    padding-top: 47px;
    padding-bottom: 45px;
  }
}
@media (max-width: 1023px) {
  .link_site_form {
    padding-top: clamp(20px, 0.0384068279 * 100vw + 7.7098150782px, 47px);
    padding-bottom: clamp(34px, 0.0156472262 * 100vw + 28.9928876245px, 45px);
  }
}

.check_mark_list {
  border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.check_mark_list__title {
  font-family: "Hoves Regular";
  font-size: clamp(66.6666666667px, 5.2083333333vw, 100px);
  line-height: clamp(60px, 4.6875vw, 90px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .check_mark_list__title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .check_mark_list__title {
    font-size: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    line-height: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
  }
}
.check_mark_list__title.font-60 {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
}
@media (max-width: 1279px) {
  .check_mark_list__title.font-60 {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .check_mark_list__title.font-60 {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
.check_mark_list__title.font-80 {
  font-family: "Hoves Regular";
  font-size: clamp(53.3333333333px, 4.1666666667vw, 80px);
  line-height: clamp(53.3333333333px, 4.1666666667vw, 80px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1023px) {
  .check_mark_list__title.font-80 {
    font-size: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
    line-height: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
  }
}
.check_mark_list__title.center {
  width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: clamp(53.3333333333px, 4.1666666667vw, 80px);
}
@media (max-width: 1023px) {
  .check_mark_list__title {
    font-size: clamp(26px, 0.0455192034 * 100vw + 11.4338549075px, 58px);
    line-height: clamp(26px, 0.0455192034 * 100vw + 11.4338549075px, 58px);
  }
}
.check_mark_list__container {
  display: flex;
  justify-content: space-between;
}
.check_mark_list__container.reverse {
  flex-direction: row-reverse;
}
@media (max-width: 1279px) {
  .check_mark_list__container {
    flex-direction: column-reverse !important;
  }
}
.check_mark_list__left {
  width: 50%;
  display: flex;
  align-items: end;
}
.check_mark_list__left.cross {
  width: 41%;
}
.check_mark_list__left img {
  width: clamp(564.3333333333px, 44.0885416667vw, 846.5px);
  height: clamp(559px, 43.671875vw, 838.5px);
  object-fit: contain;
  object-position: bottom;
}
.check_mark_list__left img.cross {
  width: clamp(472px, 36.875vw, 708px);
  height: auto;
}
@media (max-width: 1023px) {
  .check_mark_list__left img.cross {
    width: clamp(280px, 0.2731152205 * 100vw + 192.6031294452px, 472px);
  }
}
@media (max-width: 1279px) {
  .check_mark_list__left img {
    max-width: 1024px;
    width: 100%;
    height: 941px;
    margin-left: 33px;
  }
}
@media (max-width: 1023px) {
  .check_mark_list__left img {
    height: clamp(322px, 0.880512091 * 100vw + 40.2361308677px, 941px);
    margin-left: clamp(25px, 0.0113798009 * 100vw + 21.3584637269px, 33px);
  }
}
@media (max-width: 1279px) {
  .check_mark_list__left {
    justify-content: center;
    margin-left: -56px;
    margin-right: -56px;
    width: 100vw !important;
    margin-top: -32px;
  }
}
@media (max-width: 1023px) {
  .check_mark_list__left {
    margin-top: clamp(-32px, -0.0355618777 * 100vw + 4.3798008535px, -7px);
    margin-left: clamp(-56px, -0.0512091038 * 100vw + -3.613086771px, -20px);
    margin-right: clamp(-56px, -0.0512091038 * 100vw + -3.613086771px, -20px);
  }
}
.check_mark_list__right {
  width: 49%;
  padding-top: clamp(59.3333333333px, 4.6354166667vw, 89px);
}
.check_mark_list__right.cross {
  width: 55%;
}
.check_mark_list__right.not_paddint_top {
  padding-top: 0;
}
.check_mark_list__right .btn {
  margin-top: clamp(57.3333333333px, 4.4791666667vw, 86px);
  margin-bottom: clamp(40px, 3.125vw, 60px);
}
.check_mark_list__right .btn.not_margin_top {
  margin-top: 0;
}
@media (max-width: 1023px) {
  .check_mark_list__right .btn {
    margin-top: clamp(0px, 0.0810810811 * 100vw + -25.9459459459px, 57px);
  }
}
@media (max-width: 425px) {
  .check_mark_list__right .btn {
    width: 100%;
  }
}
.check_mark_list__right .btn_container {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 1279px) {
  .check_mark_list__right {
    width: 100% !important;
  }
}
.check_mark_list__items {
  display: flex;
  flex-direction: column;
  margin-left: clamp(10.6666666667px, 0.8333333333vw, 16px);
  margin-top: clamp(40.6666666667px, 3.1770833333vw, 61px);
  padding-bottom: clamp(46.6666666667px, 3.6458333333vw, 70px);
}
@media (max-width: 1279px) {
  .check_mark_list__items {
    margin-left: 0;
    margin-top: 32px;
  }
}
@media (max-width: 1023px) {
  .check_mark_list__items {
    margin-top: clamp(12px, 0.0284495021 * 100vw + 2.8961593172px, 32px);
  }
}
.check_mark_list__item {
  display: flex;
}
.check_mark_list__item svg {
  flex: 0 0 auto;
}
.check_mark_list__item svg.check {
  width: clamp(50px, 3.90625vw, 75px);
  height: clamp(45px, 3.515625vw, 67.5px);
  fill: #C0FF00;
  margin-right: clamp(14px, 1.09375vw, 21px);
}
@media (max-width: 1279px) {
  .check_mark_list__item svg.check {
    width: 75.76px;
    height: 68.19px;
    margin-right: 18px;
  }
}
@media (max-width: 1023px) {
  .check_mark_list__item svg.check {
    width: clamp(26px, 0.0707823613 * 100vw + 3.3496443812px, 75.76px);
    height: clamp(23px, 0.0642816501 * 100vw + 2.4298719772px, 68.19px);
    margin-right: clamp(9px, 0.012802276 * 100vw + 4.9032716927px, 18px);
  }
}
.check_mark_list__item svg.cross {
  width: clamp(106.6666666667px, 8.3333333333vw, 160px);
  height: clamp(110.6666666667px, 8.6458333333vw, 166px);
  margin-left: clamp(-55px, -2.8645833333vw, -36.6666666667px);
  margin-top: clamp(-35px, -1.8229166667vw, -23.3333333333px);
}
@media (max-width: 1023px) {
  .check_mark_list__item svg.cross {
    width: clamp(56px, 0.0853485064 * 100vw + 28.6884779516px, 116px);
    height: clamp(51px, 0.0853485064 * 100vw + 23.6884779516px, 111px);
    margin-left: clamp(-36px, -0.0298719772 * 100vw + -5.4409672831px, -15px);
    margin-top: clamp(-23px, -0.0184921764 * 100vw + -4.0825035562px, -10px);
  }
}
.check_mark_list__item__text {
  display: flex;
  flex-direction: column;
  max-width: clamp(369px, 28.828125vw, 553.5px);
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(26.4px, 2.0625vw, 39.6px);
  font-style: italic;
}
@media (max-width: 1279px) {
  .check_mark_list__item__text {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .check_mark_list__item__text {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
.check_mark_list__item__text span {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #707881;
  font-style: normal;
  margin-top: clamp(7.3333333333px, 0.5729166667vw, 11px);
}
@media (max-width: 1279px) {
  .check_mark_list__item__text span {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .check_mark_list__item__text span {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1279px) {
  .check_mark_list__item__text span {
    line-height: 28.8px;
    margin-top: 13px;
  }
}
@media (max-width: 1023px) {
  .check_mark_list__item__text span {
    font-size: clamp(14px, 0.0142247511 * 100vw + 9.4480796586px, 24px);
    line-height: clamp(16.8px, 0.0170697013 * 100vw + 11.3376955903px, 28.8px);
    margin-top: clamp(8px, 0.0071123755 * 100vw + 5.7240398293px, 13px);
  }
}
.check_mark_list__item__text.cross {
  margin-left: clamp(-10px, -0.5208333333vw, -6.6666666667px);
  max-width: 100%;
}
@media (max-width: 1279px) {
  .check_mark_list__item__text {
    max-width: 100%;
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .check_mark_list__item__text {
    font-size: clamp(17px, 0.0327169275 * 100vw + 6.5305832148px, 40px);
    line-height: clamp(18.7px, 0.0359886202 * 100vw + 7.1836415363px, 44px);
  }
}
.check_mark_list__item:not(:last-child) {
  margin-bottom: clamp(22.6666666667px, 1.7708333333vw, 34px);
}
@media (max-width: 1279px) {
  .check_mark_list__item:not(:last-child) {
    margin-bottom: 35px;
  }
}
@media (max-width: 1023px) {
  .check_mark_list__item:not(:last-child) {
    margin-bottom: clamp(13px, 0.0085348506 * 100vw + 10.2688477952px, 19px);
  }
}
@media (max-width: 1279px) {
  .check_mark_list__item {
    max-width: 710px;
  }
}
.check_mark_list .background_line {
  background: repeating-linear-gradient(90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 8px, #05080C 11px), linear-gradient(180deg, #121B24 62%, #212D37 100%);
  z-index: -1;
  border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
}
.check_mark_list .container_1920 {
  position: relative;
}

.advantages_text {
  position: relative;
  z-index: 1;
  margin-top: clamp(37.3333333333px, 2.9166666667vw, 56px);
  padding-bottom: clamp(55.6666666667px, 4.3489583333vw, 83.5px);
  overflow: hidden;
}
.advantages_text__name {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .advantages_text__name {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .advantages_text__name {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .advantages_text__name {
    font-size: 80px;
    line-height: 72px;
    white-space: nowrap;
  }
}
@media (max-width: 1023px) {
  .advantages_text__name {
    font-size: clamp(30px, 0.0711237553 * 100vw + 7.240398293px, 80px);
    line-height: clamp(30px, 0.0597439545 * 100vw + 10.8819345661px, 72px);
  }
}
.advantages_text__text_name {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  max-width: clamp(160.6666666667px, 12.5520833333vw, 241px);
  margin-left: clamp(10px, 0.78125vw, 15px);
  margin-top: clamp(-8px, -0.4166666667vw, -5.3333333333px);
}
@media (max-width: 1279px) {
  .advantages_text__text_name {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .advantages_text__text_name {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .advantages_text__text_name {
    font-size: 24px;
    line-height: 28.8px;
    margin-top: 3px;
    max-width: max-content;
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .advantages_text__text_name {
    font-size: clamp(14px, 0.0142247511 * 100vw + 9.4480796586px, 24px);
    line-height: clamp(16.8px, 0.0170697013 * 100vw + 11.3376955903px, 28.8px);
    margin-left: 0;
    margin-top: clamp(3px, -0.0042674253 * 100vw + 7.3655761024px, 6px);
  }
}
@media (max-width: 425px) {
  .advantages_text__text_name {
    max-width: 90%;
  }
}
.advantages_text__hashtag {
  margin-left: clamp(42.6666666667px, 3.3333333333vw, 64px);
}
@media (max-width: 1279px) {
  .advantages_text__hashtag {
    margin-left: 25px;
  }
}
@media (max-width: 1023px) {
  .advantages_text__hashtag {
    margin-left: clamp(1px, 0.0341394026 * 100vw + -9.9246088193px, 25px);
  }
}
.advantages_text__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 1023px) {
  .advantages_text__img {
    object-fit: cover;
    height: clamp(167px, 0.1721194879 * 100vw + 111.9217638691px, 288px);
  }
}
.advantages_text__title {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  margin-top: clamp(-18px, -0.9375vw, -12px);
  margin-left: clamp(2px, 0.15625vw, 3px);
}
@media (max-width: 1279px) {
  .advantages_text__title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .advantages_text__title {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
.advantages_text__title span {
  font-style: italic;
}
@media (max-width: 1279px) {
  .advantages_text__title {
    margin-left: 0;
    margin-top: 2px;
  }
}
@media (max-width: 1023px) {
  .advantages_text__title {
    font-size: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    line-height: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    margin-top: 3px;
    margin-left: 2px;
  }
}
.advantages_text__text {
  max-width: clamp(293.3333333333px, 22.9166666667vw, 440px);
  margin-top: clamp(16px, 1.25vw, 24px);
  margin-left: clamp(2px, 0.15625vw, 3px);
}
.advantages_text__text p {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .advantages_text__text p {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .advantages_text__text p {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.advantages_text__text p:not(:last-child) {
  margin-bottom: clamp(15.3333333333px, 1.1979166667vw, 23px);
}
@media (max-width: 1279px) {
  .advantages_text__text p:not(:last-child) {
    margin-bottom: 24px;
  }
}
@media (max-width: 1023px) {
  .advantages_text__text p:not(:last-child) {
    margin-bottom: clamp(13px, 0.0156472262 * 100vw + 7.9928876245px, 24px);
  }
}
@media (max-width: 1279px) {
  .advantages_text__text p {
    line-height: 28.8px;
  }
}
@media (max-width: 1023px) {
  .advantages_text__text p {
    font-size: clamp(14px, 0.0142247511 * 100vw + 9.4480796586px, 24px);
    line-height: clamp(16.8px, 0.0170697013 * 100vw + 11.3376955903px, 28.8px);
  }
}
@media (max-width: 1279px) {
  .advantages_text__text {
    margin-left: 0;
    margin-top: 7px;
    max-width: 61%;
  }
}
@media (max-width: 1023px) {
  .advantages_text__text {
    margin-top: 0;
    margin-left: 2px;
  }
}
@media (max-width: 425px) {
  .advantages_text__text {
    max-width: 100%;
  }
}
.advantages_text__items {
  display: flex;
  width: 68%;
  justify-content: flex-end;
}
.advantages_text__items span {
  color: #C0FF00;
}
.advantages_text__items .item {
  display: flex;
  flex-direction: column;
}
.advantages_text__items .item:nth-child(2) {
  padding: 0 clamp(45.3333333333px, 3.5416666667vw, 68px);
  border-left: 1px solid #37424D;
  border-right: 1px solid #37424D;
  margin: 0 clamp(45px, 3.515625vw, 67.5px);
}
@media (max-width: 1279px) {
  .advantages_text__items .item:nth-child(2) {
    padding: 0 51px;
    margin: 0 51px;
  }
}
@media (max-width: 1023px) {
  .advantages_text__items .item:nth-child(2) {
    border: none;
    margin: 0;
    padding: 0 clamp(25px, 0.0369843528 * 100vw + 13.1650071124px, 51px);
  }
}
@media (max-width: 425px) {
  .advantages_text__items .item:nth-child(2) {
    padding: 0;
  }
}
.advantages_text__items .item:last-child {
  margin-right: clamp(3.3333333333px, 0.2604166667vw, 5px);
}
@media (max-width: 1279px) {
  .advantages_text__items .item:last-child {
    margin-right: 0;
  }
}
@media (max-width: 425px) {
  .advantages_text__items .item:last-child {
    margin-top: 20px;
  }
}
@media (max-width: 1279px) {
  .advantages_text__items {
    width: min-content;
    margin: 33px auto 0;
  }
}
@media (max-width: 1023px) {
  .advantages_text__items {
    margin-top: clamp(28px, 0.0071123755 * 100vw + 25.7240398293px, 33px);
  }
}
@media (max-width: 425px) {
  .advantages_text__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
.advantages_text__container {
  display: flex;
  justify-content: space-between;
  margin-top: clamp(10.6666666667px, 0.8333333333vw, 16px);
}
@media (max-width: 1279px) {
  .advantages_text__container {
    flex-direction: column;
    margin-top: 12px;
  }
}
@media (max-width: 1023px) {
  .advantages_text__container {
    margin-top: 13px;
  }
}
.advantages_text__slider {
  transition: background 0.3s ease;
  padding-top: 36px;
  padding-bottom: 74px;
}
.advantages_text__slider .department {
  padding-bottom: 33px;
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  color: rgba(255, 255, 255, 0.6);
}
@media (max-width: 1279px) {
  .advantages_text__slider .department {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .advantages_text__slider .department {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
.advantages_text__slider .swiper {
  cursor: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDYiIGhlaWdodD0iNDciIHZpZXdCb3g9IjAgMCA0NiA0NyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjMiIGN5PSIyMy4xNjQxIiByPSIyMyIgZmlsbD0iI0MwRkYwMCIvPgo8cGF0aCBkPSJNMjYuNzEwOSAxMy44Nzg5TDM1Ljk5ODcgMjMuMTY2NkwyNi43MTA5IDMyLjQ1NDQiIHN0cm9rZT0iIzEyMUIyNCIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGQ9Ik0xOS4yODUyIDMyLjQ0OTJMOS45OTc0MiAyMy4xNjE1TDE5LjI4NTIgMTMuODczNyIgc3Ryb2tlPSIjMTIxQjI0IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==") 2 2, default;
  overflow: visible;
}
.advantages_text__slider .swiper_container {
  width: 315px;
  overflow: visible;
}
.advantages_text__slider .swiper-slide .name, .advantages_text__slider .swiper-slide .post {
  user-select: none;
}
.advantages_text__slider .swiper-slide .name {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  margin-top: 25px;
  margin-bottom: 5px;
}
@media (max-width: 1279px) {
  .advantages_text__slider .swiper-slide .name {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .advantages_text__slider .swiper-slide .name {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
.advantages_text__slider .swiper-slide .post {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: rgba(255, 255, 255, 0.6);
}
@media (max-width: 1279px) {
  .advantages_text__slider .swiper-slide .post {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .advantages_text__slider .swiper-slide .post {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.advantages_text .bgc {
  font-size: clamp(180px, 14.0625vw, 270px);
  line-height: clamp(162px, 12.65625vw, 243px);
  color: #212D37;
  position: absolute;
  bottom: clamp(-29px, -1.5104166667vw, -19.3333333333px);
  left: 43px;
  right: 43px;
  z-index: -1;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.advantages_text .bgc div:first-child {
  user-select: none;
  text-rendering: optimizeSpeed;
  text-transform: uppercase;
}
.advantages_text .bgc div:first-child.flex {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1279px) {
  .advantages_text .bgc div:first-child {
    display: none !important;
  }
}
.advantages_text .bgc .mob {
  display: none;
  font-size: 230px;
  line-height: 207px;
  text-align: center;
}
@media (max-width: 1279px) {
  .advantages_text .bgc .mob {
    display: block;
  }
}
@media (max-width: 1023px) {
  .advantages_text .bgc .mob {
    font-size: clamp(90px, 0.1991465149 * 100vw + 26.2731152205px, 230px);
    line-height: clamp(81px, 0.1792318634 * 100vw + 23.6458036984px, 207px);
  }
}
@media (max-width: 1279px) {
  .advantages_text .bgc {
    bottom: -21px;
  }
}
@media (max-width: 1023px) {
  .advantages_text .bgc {
    left: clamp(9px, 0.0483641536 * 100vw + -6.4765291607px, 43px);
    right: clamp(9px, 0.0483641536 * 100vw + -6.4765291607px, 43px);
    bottom: 36px;
  }
}
@media (max-width: 1279px) {
  .advantages_text {
    padding-bottom: 67px;
    margin-top: 67px;
  }
}
@media (max-width: 1023px) {
  .advantages_text {
    overflow: visible;
    margin-top: clamp(35px, 0.0455192034 * 100vw + 20.4338549075px, 67px);
    padding-bottom: clamp(22px, 0.0640113798 * 100vw + 1.5163584637px, 67px);
  }
}

.trust_us {
  padding-top: clamp(40.6666666667px, 3.1770833333vw, 61px);
  position: relative;
  z-index: 1;
}
.trust_us__hashtag {
  width: 100%;
  text-align: center;
  margin-left: clamp(-4px, -0.2083333333vw, -2.6666666667px);
}
@media (max-width: 1279px) {
  .trust_us__hashtag {
    margin-left: 10px;
  }
}
@media (max-width: 1023px) {
  .trust_us__hashtag {
    margin-left: 0;
  }
}
.trust_us__title {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  width: 100%;
  text-align: center;
  margin-top: clamp(-20px, -1.0416666667vw, -13.3333333333px);
  margin-left: clamp(1.3333333333px, 0.1041666667vw, 2px);
}
@media (max-width: 1279px) {
  .trust_us__title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .trust_us__title {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .trust_us__title {
    margin-top: 3px;
    margin-left: 0;
  }
}
@media (max-width: 1023px) {
  .trust_us__title {
    font-size: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    line-height: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
  }
}
.trust_us__items {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(13px, 1.015625vw, 19.5px) clamp(30px, 2.34375vw, 45px);
  margin-top: clamp(22.6666666667px, 1.7708333333vw, 34px);
  margin-left: clamp(1.3333333333px, 0.1041666667vw, 2px);
  padding-bottom: clamp(60px, 4.6875vw, 90px);
  justify-content: space-between;
}
@media (max-width: 1279px) {
  .trust_us__items {
    gap: 14.64px 33.78px;
    margin-top: 36px;
    margin-left: 0;
    padding-bottom: 92px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 155.38px);
  }
}
@media (max-width: 1023px) {
  .trust_us__items {
    gap: clamp(7px, 0.0108677098 * 100vw + 3.5223328592px, 14.64px);
    grid-template-columns: repeat(auto-fill, clamp(88px, 0.0958463727 * 100vw + 57.3291607397px, 155.38px));
    margin-top: clamp(19px, 0.0241820768 * 100vw + 11.2617354196px, 36px);
    padding-bottom: clamp(29px, 0.0896159317 * 100vw + 0.3229018492px, 92px);
  }
}
.trust_us__item img {
  width: clamp(138px, 10.78125vw, 207px);
  height: clamp(63px, 4.921875vw, 94.5px);
  border-radius: clamp(7px, 0.546875vw, 10.5px);
  object-fit: contain;
  transition: filter 0.4s;
  padding: clamp(10.36px, 0.809375vw, 15.54px) clamp(6.9px, 0.5390625vw, 10.35px) clamp(9.7933333333px, 0.7651041667vw, 14.69px);
}
.trust_us__item img:not(:hover) {
  filter: saturate(0);
}
@media (max-width: 1279px) {
  .trust_us__item img {
    height: 70.93px;
    width: 100%;
    border-radius: 7px;
  }
}
@media (max-width: 1023px) {
  .trust_us__item img {
    height: clamp(41px, 0.0425746799 * 100vw + 27.3761024182px, 70.93px);
    border-radius: clamp(4px, 0.0042674253 * 100vw + 2.6344238976px, 7px);
  }
}
.trust_us .container_1920 {
  border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
  overflow: hidden;
  background: linear-gradient(180deg, #121B24 21.88%, #212D37 100%);
}
@media (max-width: 1279px) {
  .trust_us {
    padding-top: 69px;
  }
}
@media (max-width: 1023px) {
  .trust_us {
    padding-top: clamp(29px, 0.0568990043 * 100vw + 10.7923186344px, 69px);
  }
}

.seo_block {
  padding-top: clamp(38px, 2.96875vw, 57px);
  position: relative;
}
.seo_block .background_line {
  z-index: -1;
}
.seo_block.brb {
  border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
  overflow: hidden;
}
.seo_block__container {
  display: flex;
  justify-content: space-between;
}
.seo_block__container.reverse {
  flex-direction: row-reverse;
}
@media (max-width: 1279px) {
  .seo_block__container {
    flex-direction: column !important;
  }
}
.seo_block__left {
  width: 46%;
  padding-bottom: clamp(40px, 3.125vw, 60px);
  margin-top: auto;
  margin-bottom: auto;
}
.seo_block__left .btn {
  margin-top: clamp(44.6666666667px, 3.4895833333vw, 67px);
}
@media (max-width: 1279px) {
  .seo_block__left {
    width: 100%;
  }
}
.seo_block__right {
  width: 49%;
  display: flex;
  align-items: end;
  justify-content: flex-end;
}
.seo_block__right img {
  width: 100%;
  height: auto;
  max-height: 985.62px;
  object-fit: contain;
}
@media (max-width: 1279px) {
  .seo_block__right img {
    max-height: 1014px;
  }
}
@media (max-width: 1279px) {
  .seo_block__right {
    width: 100%;
    justify-content: center;
    margin-top: -20px;
    width: 100vw;
    margin-left: -56px;
    margin-right: -56px;
  }
}
@media (max-width: 1023px) {
  .seo_block__right {
    margin-left: clamp(-56px, -0.0512091038 * 100vw + -3.613086771px, -20px);
    margin-right: clamp(-56px, -0.0512091038 * 100vw + -3.613086771px, -20px);
    margin-top: clamp(-20px, -0.0113798009 * 100vw + -8.3584637269px, -12px);
  }
}
.seo_block__hashtag {
  margin-left: clamp(42px, 3.28125vw, 63px);
}
@media (max-width: 1279px) {
  .seo_block__hashtag {
    margin-left: 25px;
  }
}
@media (max-width: 1023px) {
  .seo_block__hashtag {
    margin-left: clamp(1px, 0.0341394026 * 100vw + -9.9246088193px, 25px);
  }
}
.seo_block__title {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(67px, 5.234375vw, 100.5px);
  margin-left: clamp(2px, 0.15625vw, 3px);
  margin-bottom: clamp(29.3333333333px, 2.2916666667vw, 44px);
}
@media (max-width: 1279px) {
  .seo_block__title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .seo_block__title {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
.seo_block__title.font-60 {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
}
@media (max-width: 1279px) {
  .seo_block__title.font-60 {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .seo_block__title.font-60 {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
.seo_block__title.font-80 {
  font-family: "Hoves Regular";
  font-size: clamp(53.3333333333px, 4.1666666667vw, 80px);
  line-height: clamp(53.3333333333px, 4.1666666667vw, 80px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1023px) {
  .seo_block__title.font-80 {
    font-size: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
    line-height: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
  }
}
.seo_block__title span {
  font-style: italic;
}
@media (max-width: 1279px) {
  .seo_block__title {
    margin-top: 2px;
    margin-left: 0;
    margin-bottom: 19px;
  }
}
@media (max-width: 1023px) {
  .seo_block__title {
    font-size: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    line-height: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    margin-top: 3px;
    margin-left: 2px;
    margin-bottom: clamp(13px, 0.0085348506 * 100vw + 10.2688477952px, 19px);
  }
}
.seo_block__text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  line-height: clamp(18.2px, 1.421875vw, 27.3px);
  margin-left: 2px;
}
@media (max-width: 1279px) {
  .seo_block__text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .seo_block__text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.seo_block__text h3 {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  margin-bottom: clamp(28px, 2.1875vw, 42px);
}
@media (max-width: 1279px) {
  .seo_block__text h3 {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .seo_block__text h3 {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
.seo_block__text p, .seo_block__text ul, .seo_block__text ol {
  margin-bottom: clamp(12.6666666667px, 0.9895833333vw, 19px);
}
@media (max-width: 1279px) {
  .seo_block__text p, .seo_block__text ul, .seo_block__text ol {
    margin-bottom: 20px;
  }
}
@media (max-width: 1023px) {
  .seo_block__text p, .seo_block__text ul, .seo_block__text ol {
    margin-bottom: clamp(12px, 0.0113798009 * 100vw + 8.3584637269px, 20px);
  }
}
.seo_block__text p span {
  color: #C0FF00;
}
.seo_block__text ul {
  padding-left: clamp(28px, 2.1875vw, 42px);
  margin-top: clamp(-8px, -0.4166666667vw, -5.3333333333px);
}
.seo_block__text ul li {
  position: relative;
  list-style-type: disc;
}
.seo_block__text ul li::marker {
  color: #C0FF00;
}
.seo_block__text ul li p {
  margin-bottom: 0;
}
.seo_block__text ul li:not(:last-child) {
  margin-bottom: clamp(7.3333333333px, 0.5729166667vw, 11px);
}
@media (max-width: 1279px) {
  .seo_block__text ul li:not(:last-child) {
    margin-bottom: 12px;
  }
}
@media (max-width: 1023px) {
  .seo_block__text ul li:not(:last-child) {
    margin-bottom: clamp(9px, 0.0042674253 * 100vw + 7.6344238976px, 12px);
  }
}
@media (max-width: 1279px) {
  .seo_block__text ul {
    padding-left: 48px;
  }
}
@media (max-width: 1023px) {
  .seo_block__text ul {
    padding-left: clamp(28px, 0.0284495021 * 100vw + 18.8961593172px, 48px);
  }
}
.seo_block__text ul, .seo_block__text ol {
  margin-top: clamp(22px, 1.71875vw, 33px);
}
.seo_block__text ul li:not(:last-child), .seo_block__text ol li:not(:last-child) {
  margin-bottom: clamp(19.3333333333px, 1.5104166667vw, 29px);
}
.seo_block__text ul li span, .seo_block__text ol li span {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #707881;
  margin-top: clamp(6.6666666667px, 0.5208333333vw, 10px);
}
@media (max-width: 1279px) {
  .seo_block__text ul li span, .seo_block__text ol li span {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .seo_block__text ul li span, .seo_block__text ol li span {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1279px) {
  .seo_block__text {
    margin-left: 0;
    line-height: 31.2px;
  }
}
@media (max-width: 1023px) {
  .seo_block__text {
    font-size: clamp(14px, 0.0142247511 * 100vw + 9.4480796586px, 24px);
    line-height: clamp(17px, 0.0201991465 * 100vw + 10.5362731152px, 31.2px);
    margin-left: 2px;
  }
}
@media (max-width: 1279px) {
  .seo_block {
    padding-top: 67px;
  }
}
@media (max-width: 1023px) {
  .seo_block {
    padding-top: clamp(30px, 0.0526315789 * 100vw + 13.1578947368px, 67px);
  }
}

.faq {
  padding-top: clamp(40px, 3.125vw, 60px);
  padding-bottom: clamp(63.3333333333px, 4.9479166667vw, 95px);
  background: var(--bgc_color);
}
.faq__title {
  width: 100%;
  text-align: center;
  max-width: clamp(666.6666666667px, 52.0833333333vw, 1000px);
  margin-left: auto;
  margin-right: auto;
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
}
@media (max-width: 1279px) {
  .faq__title {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .faq__title {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
@media (max-width: 1023px) {
  .faq__title {
    font-size: clamp(17px, 0.0327169275 * 100vw + 6.5305832148px, 40px);
    line-height: clamp(18.7px, 0.0359886202 * 100vw + 7.1836415363px, 44px);
  }
}
.faq .collapse__items {
  max-width: clamp(800px, 62.5vw, 1200px);
  margin-left: auto;
  margin-right: auto;
  margin-top: clamp(14px, 1.09375vw, 21px);
}
@media (max-width: 1279px) {
  .faq .collapse__items {
    max-width: 100%;
    margin-top: 22px;
  }
}
@media (max-width: 1023px) {
  .faq .collapse__items {
    margin-top: clamp(11px, 0.0156472262 * 100vw + 5.9928876245px, 22px);
  }
}
@media (max-width: 1279px) {
  .faq {
    padding-top: 68px;
    padding-bottom: 126px;
  }
}
@media (max-width: 1023px) {
  .faq {
    padding-top: clamp(31px, 0.0526315789 * 100vw + 14.1578947368px, 68px);
    padding-bottom: clamp(40px, 0.1223328592 * 100vw + 0.853485064px, 126px);
  }
}

.check_list {
  padding-top: clamp(42px, 3.28125vw, 63px);
  padding-bottom: clamp(58px, 4.53125vw, 87px);
  background: #37424D;
}
.check_list.mt {
  z-index: 0;
  position: relative;
  margin-top: -50px;
  padding-top: clamp(75.3333333333px, 5.8854166667vw, 113px);
}
.check_list__container {
  padding-top: clamp(10.6666666667px, 0.8333333333vw, 16px);
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: clamp(6.6666666667px, 0.5208333333vw, 10px);
  position: relative;
  z-index: 1;
}
@media (max-width: 1279px) {
  .check_list__container {
    grid-template-rows: auto;
  }
}
.check_list__top_text {
  grid-row: 2/3;
  grid-column: 1/10;
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(26.4px, 2.0625vw, 39.6px);
  font-style: italic;
  text-align: right;
}
@media (max-width: 1279px) {
  .check_list__top_text {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .check_list__top_text {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .check_list__top_text {
    grid-column: 1/25;
    grid-row: 2/3;
    text-align: left;
    margin-top: clamp(11px, 0.0041710115 * 100vw + 9.6652763295px, 15px);
    line-height: clamp(18.7px, 0.0128258603 * 100vw + 14.5957247132px, 31px);
  }
}
.check_list__bottom_text {
  grid-row: 3/4;
  grid-column: 4/10;
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  line-height: clamp(18.7px, 1.4609375vw, 28.05px);
  color: rgba(255, 255, 255, 0.5);
  text-align: right;
  margin-right: clamp(12px, 0.9375vw, 18px);
}
@media (max-width: 1279px) {
  .check_list__bottom_text {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .check_list__bottom_text {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .check_list__bottom_text {
    grid-column: 1/13;
    text-align: left;
    margin-top: clamp(29px, 0.0323253389 * 100vw + 18.6558915537px, 60px);
  }
}
@media (max-width: 1023px) {
  .check_list__bottom_text {
    font-size: clamp(12px, 0.0071123755 * 100vw + 9.7240398293px, 17px);
    line-height: clamp(13.2px, 0.0078236131 * 100vw + 10.6964438122px, 18.7px);
  }
}
.check_list__left_title {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  grid-column: 1/10;
  text-align: right;
  grid-row: 1/2;
}
@media (max-width: 1279px) {
  .check_list__left_title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .check_list__left_title {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .check_list__left_title {
    display: none;
  }
}
.check_list__right_title {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  grid-column: 16/25;
  grid-row: 1/2;
}
@media (max-width: 1279px) {
  .check_list__right_title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .check_list__right_title {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .check_list__right_title {
    display: none;
  }
}
.check_list__title {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  display: none;
}
@media (max-width: 1279px) {
  .check_list__title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .check_list__title {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .check_list__title {
    display: block;
    grid-column: 1/25;
    grid-row: 1/2;
  }
}
@media (max-width: 1023px) {
  .check_list__title {
    font-size: 30px;
    line-height: 30px;
  }
}
.check_list__img {
  grid-column: 10/16;
  grid-row: 1/4;
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: auto 0;
  padding: 0 20px;
}
@media (max-width: 1279px) {
  .check_list__img {
    grid-row: 3/4;
    grid-column: 13/25;
    max-height: 360px;
    height: 100%;
    padding: 0;
  }
}
.check_list .form_container {
  grid-column: 16/22;
  grid-row: 3/4;
}
.check_list .form_container form {
  display: flex;
  flex-direction: column;
  gap: clamp(17.3333333333px, 1.3541666667vw, 26px) 0;
  margin-top: auto;
}
.check_list .form_container form .btn {
  width: fit-content;
  height: fit-content;
}
@media (max-width: 1279px) {
  .check_list .form_container form .btn {
    width: 100%;
  }
}
.check_list .form_container form input {
  background: #212D37;
}
@media (max-width: 1279px) {
  .check_list .form_container form {
    gap: clamp(15px, 0.0031282586 * 100vw + 13.9989572471px, 18px);
  }
}
@media (max-width: 1279px) {
  .check_list .form_container {
    grid-column: 1/25;
    grid-row: 4/5;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    width: 100%;
    margin-top: clamp(25px, 0.0104275287 * 100vw + 21.6631908238px, 35px);
  }
}
@media (max-width: 1279px) {
  .check_list {
    padding-top: clamp(26px, 0.0166840459 * 100vw + 20.661105318px, 42px);
    padding-bottom: clamp(31px, 0.0281543274 * 100vw + 21.9906152242px, 58px);
  }
}

.tariff {
  position: relative;
  padding-bottom: 100px;
  z-index: 1;
}
.tariff.mt {
  z-index: 0;
  margin-top: -50px;
}
.tariff__head {
  position: relative;
  padding-top: clamp(38px, 2.96875vw, 57px);
  padding-bottom: clamp(58px, 4.53125vw, 87px);
  background: var(--head-color);
}
.tariff__head.mt {
  padding-top: clamp(71.3333333333px, 5.5729166667vw, 107px);
}
@media (max-width: 1279px) {
  .tariff__head {
    background: transparent;
    padding-bottom: clamp(31px, 0.0281543274 * 100vw + 21.9906152242px, 58px);
  }
}
.tariff__hashtag {
  width: 100%;
  text-align: center;
  margin-bottom: clamp(10px, 0.78125vw, 15px);
}
.tariff__title {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-family: "Hoves Regular";
  font-size: clamp(53.3333333333px, 4.1666666667vw, 80px);
  line-height: clamp(53.3333333333px, 4.1666666667vw, 80px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1023px) {
  .tariff__title {
    font-size: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
    line-height: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
  }
}
@media (max-width: 1023px) {
  .tariff__title {
    width: 100%;
  }
}
.tariff__items {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  padding-top: clamp(26px, 2.03125vw, 39px);
}
.tariff__items.items_3 {
  gap: clamp(18px, 1.40625vw, 27px);
}
@media (max-width: 1279px) {
  .tariff__items.items_3 {
    max-width: 600px;
    width: 100%;
    gap: 45px;
  }
}
.tariff__items.items_2 {
  gap: clamp(35.3333333333px, 2.7604166667vw, 53px);
  width: 60%;
}
@media (max-width: 1279px) {
  .tariff__items.items_2 {
    max-width: 600px;
    width: 100%;
  }
}
@media (max-width: 1279px) {
  .tariff__items {
    padding-top: 0;
    flex-direction: column;
  }
}
.tariff__item {
  border: 1px solid #707881;
  border-radius: clamp(13.3333333333px, 1.0416666667vw, 20px);
  padding-left: clamp(20px, 1.5625vw, 30px);
  padding-right: clamp(20px, 1.5625vw, 30px);
  padding-top: clamp(11.3333333333px, 0.8854166667vw, 17px);
  padding-bottom: clamp(44.6666666667px, 3.4895833333vw, 67px);
  position: relative;
  width: min-content;
  min-width: clamp(266.6666666667px, 20.8333333333vw, 400px);
}
.tariff__item.items_2 {
  height: fit-content;
}
.tariff__item .name {
  width: 100%;
  text-align: center;
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  font-style: italic;
  margin-top: clamp(8px, 0.625vw, 12px);
}
@media (max-width: 1279px) {
  .tariff__item .name {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .tariff__item .name {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .tariff__item .name {
    margin-top: 0;
  }
}
@media (max-width: 1023px) {
  .tariff__item .name {
    font-size: clamp(20px, 0.0227596017 * 100vw + 12.7169274538px, 36px);
    line-height: clamp(22px, 0.0250355619 * 100vw + 13.9886201991px, 39.6px);
  }
}
.tariff__item .cost {
  width: 100%;
  text-align: center;
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  font-style: italic;
  color: #C0FF00;
  margin-top: clamp(6px, 0.46875vw, 9px);
  white-space: nowrap;
}
@media (max-width: 1279px) {
  .tariff__item .cost {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .tariff__item .cost {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
.tariff__item .cost.bottom_line {
  border-bottom: 1px solid #707881;
  margin-bottom: clamp(18.6666666667px, 1.4583333333vw, 28px);
  padding-bottom: clamp(12.6666666667px, 0.9895833333vw, 19px);
}
.tariff__item .cost.font-36 {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  font-style: italic;
  color: #C0FF00;
}
@media (max-width: 1279px) {
  .tariff__item .cost.font-36 {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .tariff__item .cost.font-36 {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1023px) {
  .tariff__item .cost.font-36 {
    font-size: clamp(18px, 0.0256045519 * 100vw + 9.8065433855px, 36px);
    line-height: clamp(20px, 0.0278805121 * 100vw + 11.0782361309px, 39.6px);
  }
}
.tariff__item .cost span {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #707881;
}
@media (max-width: 1279px) {
  .tariff__item .cost span {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .tariff__item .cost span {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.tariff__item .cost__plus {
  width: 100%;
  text-align: center;
  font-family: "Hoves Regular";
  font-size: clamp(26.6666666667px, 2.0833333333vw, 40px);
  line-height: clamp(26.6666666667px, 2.0833333333vw, 40px);
  font-weight: 400;
  color: #F2F2F6;
  font-style: italic;
  color: #C0FF00;
  white-space: nowrap;
}
.tariff__item .cost__plus.bottom_line {
  border-bottom: 1px solid #707881;
  margin-bottom: clamp(18.6666666667px, 1.4583333333vw, 28px);
  padding-bottom: clamp(12.6666666667px, 0.9895833333vw, 19px);
}
.tariff__item .points .list {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .tariff__item .points .list {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .tariff__item .points .list {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.tariff__item .points .list > ul {
  margin-left: clamp(16.6666666667px, 1.3020833333vw, 25px);
}
.tariff__item .points .list ol li, .tariff__item .points .list ul li {
  margin-bottom: clamp(9.3333333333px, 0.7291666667vw, 14px);
}
.tariff__item .points .list ol li span, .tariff__item .points .list ul li span {
  color: #C0FF00;
}
.tariff__item .points .list ol > li, .tariff__item .points .list ul > li {
  position: relative;
}
.tariff__item .points .list ol > li::before, .tariff__item .points .list ul > li::before {
  content: "";
  position: absolute;
  top: clamp(-5px, -0.2604166667vw, -3.3333333333px);
  left: clamp(-37px, -1.9270833333vw, -24.6666666667px);
  width: clamp(30px, 2.34375vw, 45px);
  height: clamp(28.6666666667px, 2.2395833333vw, 43px);
  background: url('data:image/svg+xml;utf8,<svg viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d_2047_3468)"><path d="M19.3005 20.482C20.4153 18.3181 21.4732 16.8184 22.9455 15.1953C25.278 12.6244 28.4108 10.3881 31.6601 9.02105C31.9231 8.91046 32.1289 9.26325 31.9034 9.43724C26.244 13.7951 22.6122 18.8538 19.9624 25.7864C19.8113 26.1822 19.259 26.2101 19.068 25.8318C17.3781 22.483 16.1365 20.0342 13.1894 18.1431C12.8858 17.9481 12.9621 17.4891 13.312 17.3989C15.7768 16.7639 17.43 18.101 19.3005 20.482Z" fill="%23C0FF00"/></g><defs><filter id="filter0_d_2047_3468" x="0.510532" y="0.56391" width="43.9789" height="42.0785" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4.05338"/><feGaussianBlur stdDeviation="6.24473"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0.751724 0 0 0 0 1 0 0 0 0 0 0 0 0 1 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2047_3468"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2047_3468" result="shape"/></filter></defs></svg>') no-repeat center center;
  background-size: contain;
}
@media (max-width: 1279px) {
  .tariff__item .points .list ol > li::before, .tariff__item .points .list ul > li::before {
    width: 45px;
    height: 43px;
    top: -5px;
    left: -37px;
  }
}
.tariff__item .points .list ol ol li, .tariff__item .points .list ol ul li, .tariff__item .points .list ul ol li, .tariff__item .points .list ul ul li {
  color: #707881;
}
.tariff__item .points .list ol ol li::before, .tariff__item .points .list ol ul li::before, .tariff__item .points .list ul ol li::before, .tariff__item .points .list ul ul li::before {
  content: "";
  display: none;
}
.tariff__item .points .list p:not(:first-child) {
  margin-top: clamp(9.3333333333px, 0.7291666667vw, 14px);
}
.tariff__item .points .list p span {
  color: #C0FF00;
}
.tariff__item .points .list div {
  margin-top: clamp(17.3333333333px, 1.3541666667vw, 26px);
  color: #707881;
}
@media (max-width: 1023px) {
  .tariff__item .points .list {
    font-size: 14px;
    line-height: 15.4px;
  }
}
.tariff__item .point_text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  margin-left: clamp(-5px, -0.2604166667vw, -3.3333333333px);
}
@media (max-width: 1279px) {
  .tariff__item .point_text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .tariff__item .point_text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.tariff__item .btn_container {
  position: absolute;
  bottom: clamp(-34px, -1.7708333333vw, -22.6666666667px);
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.tariff__item.items_3:nth-child(even) {
  background: #1A252F;
  margin-top: clamp(-77px, -4.0104166667vw, -51.3333333333px);
  height: fit-content;
}
@media (max-width: 1279px) {
  .tariff__item.items_3:nth-child(even) {
    margin-top: 0;
  }
}
@media (max-width: 1279px) {
  .tariff__item.items_3.item-2 {
    grid-row: 3/4;
  }
}
@media (max-width: 1279px) {
  .tariff__item {
    min-width: 0;
    width: 100%;
  }
}
.tariff__bottom_text {
  margin-top: clamp(40px, 3.125vw, 60px);
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  color: #707881;
}
@media (max-width: 1279px) {
  .tariff__bottom_text {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .tariff__bottom_text {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
@media (max-width: 1023px) {
  .tariff__bottom_text {
    font-size: clamp(12px, 0.0071123755 * 100vw + 9.7240398293px, 17px);
    line-height: clamp(14.4px, 0.006116643 * 100vw + 12.4426742532px, 18.7px);
    text-align: center;
  }
}
.tariff.last {
  border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
  overflow: hidden;
}
.tariff .background_line {
  z-index: -1;
}

.setting_tariff {
  padding-top: clamp(33.3333333333px, 2.6041666667vw, 50px);
  padding-bottom: clamp(36.6666666667px, 2.8645833333vw, 55px);
  background: linear-gradient(180deg, #121B24 21.88%, #212D37 100%);
  position: relative;
  z-index: 1;
  height: clamp(480px, 37.5vw, 720px);
}
.setting_tariff .container {
  height: 100%;
}
.setting_tariff__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.setting_tariff__title {
  font-family: "Hoves Regular";
  font-size: clamp(53.3333333333px, 4.1666666667vw, 80px);
  line-height: clamp(53.3333333333px, 4.1666666667vw, 80px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(53.3333333333px, 4.1666666667vw, 80px);
  margin-bottom: clamp(46.6666666667px, 3.6458333333vw, 70px);
  max-width: 78%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media (max-width: 1023px) {
  .setting_tariff__title {
    font-size: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
    line-height: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
  }
}
.setting_tariff__title span {
  font-style: italic;
}
@media (max-width: 1023px) {
  .setting_tariff__title {
    max-width: 100%;
  }
}
.setting_tariff__left {
  display: flex;
  flex-direction: column;
}
.setting_tariff__right img {
  width: clamp(335.3333333333px, 26.1979166667vw, 503px);
  height: clamp(336.6666666667px, 26.3020833333vw, 505px);
  margin-top: clamp(128px, 10vw, 192px);
  margin-right: clamp(33.3333333333px, 2.6041666667vw, 50px);
  margin-bottom: clamp(23.3333333333px, 1.8229166667vw, 35px);
}
.setting_tariff__count_tab {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.setting_tariff__count_tab .circle {
  border-radius: 50%;
  cursor: pointer;
}
.setting_tariff__count_tab .circle.prev {
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 0.3);
}
.setting_tariff__count_tab .circle.active {
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 0.5);
}
.setting_tariff__count_tab .circle.next {
  width: 8px;
  height: 8px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.setting_tariff__count_tab .circle:not(:last-child) {
  margin-right: 10px;
}
.setting_tariff__count_tab .prev_btn {
  display: none;
}
@media (max-width: 1279px) {
  .setting_tariff__count_tab .prev_btn {
    display: block;
    position: absolute;
    top: clamp(-15px, -0.0104275287 * 100vw + -1.6631908238px, -5px);
    left: 0;
  }
}
@media (max-width: 1279px) {
  .setting_tariff__count_tab {
    position: relative;
  }
}
.setting_tariff__btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  position: relative;
}
.setting_tariff__btn_container .btn span {
  white-space: nowrap;
}
@media (max-width: 1279px) {
  .setting_tariff__btn_container .prev_btn {
    display: none;
  }
}
.setting_tariff__btn_prev {
  position: absolute;
  left: 0;
  bottom: 22px;
  cursor: pointer;
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  user-select: none;
}
@media (max-width: 1279px) {
  .setting_tariff__btn_prev {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .setting_tariff__btn_prev {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
.setting_tariff__tab .title {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  margin-top: clamp(19.3333333333px, 1.5104166667vw, 29px);
  margin-bottom: clamp(20px, 1.5625vw, 30px);
  width: 100%;
  text-align: center;
}
@media (max-width: 1279px) {
  .setting_tariff__tab .title {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .setting_tariff__tab .title {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
.setting_tariff__tab .horizontal_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: clamp(6.6666666667px, 0.5208333333vw, 10px);
}
@media (max-width: 1279px) {
  .setting_tariff__tab .horizontal_container label div {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.setting_tariff__tab .input_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(16px, 1.25vw, 24px);
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 77%;
}
.setting_tariff__tab .input_container .btn {
  grid-column: 2/3;
  grid-row: 2/3;
}
@media (max-width: 1279px) {
  .setting_tariff__tab .input_container .btn {
    grid-column: auto;
    grid-row: auto;
  }
}
.setting_tariff__tab .input_container .personal_container {
  grid-column: 3/4;
  grid-row: 2/3;
  margin-left: 30px;
}
.setting_tariff__tab .input_container .personal_container .personal {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1279px) {
  .setting_tariff__tab .input_container .personal_container .personal {
    align-items: center;
  }
}
@media (max-width: 1279px) {
  .setting_tariff__tab .input_container .personal_container {
    grid-column: auto;
    grid-row: auto;
    margin-left: 0;
  }
}
.setting_tariff__tab .input_container input {
  background: rgba(255, 255, 255, 0.3);
}
@media (max-width: 1279px) {
  .setting_tariff__tab .input_container {
    grid-template-columns: repeat(1, 1fr);
    max-width: 900px;
    width: 100%;
    gap: 9px;
  }
}
.setting_tariff__tab.slider {
  max-width: 700px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.setting_tariff__tab.slider .minmax_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: clamp(16px, 1.25vw, 24px);
}
.setting_tariff__tab.slider .minmax_container span {
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(16px, 1.25vw, 24px);
  line-height: clamp(23.84px, 1.8625vw, 35.76px);
  color: #F2F2F6;
  color: rgba(255, 255, 255, 0.5);
}
@media (max-width: 1023px) {
  .setting_tariff__tab.slider .minmax_container span {
    font-size: clamp(14px, 0.0028449502 * 100vw + 13.0896159317px, 16px);
    line-height: clamp(15.4px, 0.0120056899 * 100vw + 11.5581792319px, 23.84px);
  }
}
@media (max-width: 1023px) {
  .setting_tariff__tab.slider {
    max-width: 87%;
  }
}
@media (max-width: 1279px) {
  .setting_tariff__tab.tab1, .setting_tariff__tab.tab2, .setting_tariff__tab.tab3 {
    margin-top: clamp(0px, 0.1042752868 * 100vw + -33.3680917623px, 100px);
  }
}
.setting_tariff__personal_container {
  margin-left: clamp(14px, 1.09375vw, 21px);
}
.setting_tariff .personal {
  color: rgba(255, 255, 255, 0.7);
}
.setting_tariff .personal a {
  color: rgba(255, 255, 255, 0.7);
}
.setting_tariff__bgc_text {
  position: absolute;
  z-index: -1;
  bottom: clamp(53.3333333333px, 4.1666666667vw, 80px);
  width: 100%;
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: clamp(196.32px, 15.3375vw, 294.48px);
  line-height: clamp(141.2933333333px, 11.0385416667vw, 211.94px);
  text-align: right;
  color: #212D37;
  user-select: none;
}
.setting_tariff .noUi-horizontal {
  height: 2px;
}
@media (max-width: 1279px) {
  .setting_tariff .noUi-origin {
    left: -10px;
  }
}
.setting_tariff .noUi-target {
  margin-top: clamp(93.3333333333px, 7.2916666667vw, 140px);
  background: rgba(255, 255, 255, 0.5);
  border: none;
  box-shadow: none;
}
.setting_tariff .noUi-horizontal .noUi-handle {
  width: clamp(13.3333333333px, 1.0416666667vw, 20px);
  height: clamp(13.3333333333px, 1.0416666667vw, 20px);
  top: clamp(-10px, -0.5208333333vw, -6.6666666667px);
  border-radius: 50%;
  border: none;
  background: linear-gradient(180deg, #C0FF00 0%, #78C100 100%);
  box-shadow: none;
}
.setting_tariff .noUi-horizontal .noUi-handle::before, .setting_tariff .noUi-horizontal .noUi-handle::after {
  display: none;
}
.setting_tariff .noUi-tooltip {
  background: linear-gradient(180deg, #C0FF00 0%, #78C100 100%);
  border-radius: clamp(33.3333333333px, 2.6041666667vw, 50px);
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(16px, 1.25vw, 24px);
  line-height: clamp(23.84px, 1.8625vw, 35.76px);
  color: #F2F2F6;
  color: #121B24;
  padding: clamp(5.3333333333px, 0.4166666667vw, 8px) clamp(13.3333333333px, 1.0416666667vw, 20px);
  border-color: #C0FF00;
  border-width: 2px;
}
@media (max-width: 1023px) {
  .setting_tariff .noUi-tooltip {
    font-size: clamp(14px, 0.0028449502 * 100vw + 13.0896159317px, 16px);
    line-height: clamp(15.4px, 0.0120056899 * 100vw + 11.5581792319px, 23.84px);
  }
}
@media (max-width: 1279px) {
  .setting_tariff {
    height: clamp(587px, 0.2221063608 * 100vw + 515.9259645464px, 800px);
  }
}

.text_message {
  position: relative;
}
.text_message .background_line {
  z-index: -1;
}
.text_message.brb {
  border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
  overflow: hidden;
}
.text_message__mess {
  position: relative;
  background: #37424D;
  padding: clamp(16px, 1.25vw, 24px) clamp(20.6666666667px, 1.6145833333vw, 31px) clamp(18.6666666667px, 1.4583333333vw, 28px) clamp(22.6666666667px, 1.7708333333vw, 34px);
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  border-radius: clamp(13.3333333333px, 1.0416666667vw, 20px);
  width: fit-content;
  max-width: clamp(364.6666666667px, 28.4895833333vw, 547px);
}
@media (max-width: 1279px) {
  .text_message__mess {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .text_message__mess {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
.text_message__mess svg {
  position: absolute;
  bottom: clamp(-21px, -1.09375vw, -14px);
  width: clamp(28px, 2.1875vw, 42px);
  height: clamp(42px, 3.28125vw, 63px);
  fill: #37424D;
}
.text_message__mess.mess1 {
  margin-right: auto;
  margin-bottom: clamp(26.6666666667px, 2.0833333333vw, 40px);
}
.text_message__mess.mess1 svg {
  left: clamp(-7px, -0.3645833333vw, -4.6666666667px);
}
.text_message__mess.mess2 {
  margin-left: auto;
  font-style: italic;
}
.text_message__mess.mess2 svg {
  right: clamp(-7px, -0.3645833333vw, -4.6666666667px);
  transform: scaleX(-1);
}
@media (max-width: 1023px) {
  .text_message__mess {
    font-size: clamp(30px, 0.0142247511 * 100vw + 25.4480796586px, 40px);
    line-height: clamp(30px, 0.0199146515 * 100vw + 23.627311522px, 44px);
    padding-left: clamp(15px, 0.0099573257 * 100vw + 11.813655761px, 22px);
    padding-right: clamp(15px, 0.0099573257 * 100vw + 11.813655761px, 22px);
  }
}
.text_message__left {
  width: 49%;
  padding-top: clamp(78px, 6.09375vw, 117px);
}
@media (max-width: 1279px) {
  .text_message__left {
    width: 100%;
    padding-top: clamp(42px, 0.0375391032 * 100vw + 29.9874869656px, 78px);
  }
}
.text_message__right {
  display: flex;
  align-items: flex-end;
}
.text_message__right img {
  width: 100%;
  height: clamp(690px, 53.90625vw, 1035px);
  object-fit: contain;
  margin-right: clamp(20px, 1.5625vw, 30px);
}
@media (max-width: 1279px) {
  .text_message__right img {
    height: clamp(400px, 0.3023983316 * 100vw + 303.2325338895px, 690px);
    margin-right: 0;
  }
}
.text_message__container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1279px) {
  .text_message__container {
    flex-direction: column;
  }
}
.text_message__items {
  margin-top: clamp(46.6666666667px, 3.6458333333vw, 70px);
  width: 90%;
}
@media (max-width: 1023px) {
  .text_message__items {
    width: 100%;
  }
}
.text_message__item {
  display: flex;
}
.text_message__item svg {
  flex: 0 0 auto;
  margin-left: clamp(-47px, -2.4479166667vw, -31.3333333333px);
  margin-top: clamp(-25px, -1.3020833333vw, -16.6666666667px);
  width: clamp(116.6666666667px, 9.1145833333vw, 175px);
  height: clamp(111.3333333333px, 8.6979166667vw, 167px);
}
@media (max-width: 1279px) {
  .text_message__item svg {
    width: clamp(80px, 0.0375391032 * 100vw + 67.9874869656px, 116px);
    height: clamp(75px, 0.0375391032 * 100vw + 62.9874869656px, 111px);
    margin-left: clamp(-47px, -0.0229405631 * 100vw + -17.6590198123px, -25px);
    margin-top: clamp(-25px, -0.0104275287 * 100vw + -11.6631908238px, -15px);
  }
}
.text_message__item__content {
  display: flex;
  flex-direction: column;
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  font-style: italic;
  margin-left: clamp(-31px, -1.6145833333vw, -20.6666666667px);
}
@media (max-width: 1279px) {
  .text_message__item__content {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .text_message__item__content {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
.text_message__item__content span {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #707881;
  font-style: normal;
  margin-top: clamp(4.6666666667px, 0.3645833333vw, 7px);
}
@media (max-width: 1279px) {
  .text_message__item__content span {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .text_message__item__content span {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1279px) {
  .text_message__item__content {
    line-height: clamp(18.7px, 0.0128258603 * 100vw + 14.5957247132px, 31px);
  }
}
.text_message__item:not(:last-child) {
  margin-bottom: clamp(8.6666666667px, 0.6770833333vw, 13px);
}
.text_message__text {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  color: #707881;
  margin-top: 18px;
  padding-bottom: clamp(50.6666666667px, 3.9583333333vw, 76px);
}
@media (max-width: 1279px) {
  .text_message__text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .text_message__text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 1023px) {
  .text_message__text {
    padding-bottom: clamp(16px, 0.0483641536 * 100vw + 0.5234708393px, 50px);
  }
}

.text_btn {
  position: relative;
  padding-top: clamp(66.6666666667px, 5.2083333333vw, 100px);
  padding-bottom: clamp(133.3333333333px, 10.4166666667vw, 200px);
  z-index: 1;
}
.text_btn.slim {
  padding-top: clamp(54px, 4.21875vw, 81px);
  padding-bottom: clamp(58px, 4.53125vw, 87px);
}
.text_btn.slim.mt {
  padding-top: clamp(86.6666666667px, 6.7708333333vw, 130px);
}
.text_btn.last {
  border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
  overflow: hidden;
  background: linear-gradient(180deg, #121B24 62%, #212D37 100%);
}
.text_btn__container {
  width: fit-content;
  max-width: clamp(733.3333333333px, 57.2916666667vw, 1100px);
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.text_btn__title {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
  text-align: center;
}
@media (max-width: 1279px) {
  .text_btn__title {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .text_btn__title {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
.text_btn__title_italic span {
  font-style: italic;
}
.text_btn__title_green span {
  color: #C0FF00;
}
.text_btn__text {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: clamp(16px, 1.25vw, 24px);
  text-align: center;
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
}
@media (max-width: 1279px) {
  .text_btn__text {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .text_btn__text {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
.text_btn__text span {
  color: #C0FF00;
}
.text_btn__title s, .text_btn__text s {
  color: gray;
}
.text_btn__slim_text {
  max-width: clamp(352px, 27.5vw, 528px);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  margin-top: clamp(16.6666666667px, 1.3020833333vw, 25px);
}
@media (max-width: 1279px) {
  .text_btn__slim_text {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .text_btn__slim_text {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
.text_btn__btn_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: clamp(44.6666666667px, 3.4895833333vw, 67px);
}
.text_btn__btn_container.slim {
  margin-top: clamp(29.3333333333px, 2.2916666667vw, 44px);
}

.radio_btn_form {
  padding-top: clamp(74.6666666667px, 5.8333333333vw, 112px);
  padding-bottom: clamp(70px, 5.46875vw, 105px);
  position: relative;
}
.radio_btn_form .background_line {
  z-index: -1;
}
.radio_btn_form.brb {
  border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
  overflow: hidden;
}
.radio_btn_form__title {
  font-family: "Hoves Regular";
  font-size: clamp(66.6666666667px, 5.2083333333vw, 100px);
  line-height: clamp(60px, 4.6875vw, 90px);
  font-weight: 400;
  color: #F2F2F6;
  margin-bottom: clamp(46px, 3.59375vw, 69px);
}
@media (max-width: 1279px) {
  .radio_btn_form__title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .radio_btn_form__title {
    font-size: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    line-height: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
  }
}
@media (max-width: 1023px) {
  .radio_btn_form__title {
    margin-bottom: 41px;
  }
}
.radio_btn_form__step1, .radio_btn_form__step2 {
  display: flex;
  gap: clamp(60.6666666667px, 4.7395833333vw, 91px);
}
.radio_btn_form__step1 .step, .radio_btn_form__step2 .step {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  color: #707881;
  margin-bottom: clamp(8px, 0.625vw, 12px);
}
@media (max-width: 1279px) {
  .radio_btn_form__step1 .step, .radio_btn_form__step2 .step {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .radio_btn_form__step1 .step, .radio_btn_form__step2 .step {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
@media (max-width: 1023px) {
  .radio_btn_form__step1 .step, .radio_btn_form__step2 .step {
    font-size: clamp(12px, 0.0071123755 * 100vw + 9.7240398293px, 17px);
    line-height: clamp(13.2px, 0.0078236131 * 100vw + 10.6964438122px, 18.7px);
    margin-bottom: 12px;
  }
}
.radio_btn_form__step1 .name, .radio_btn_form__step2 .name {
  font-family: "Hoves Regular";
  font-weight: 400;
  color: #F2F2F6;
  font-size: clamp(40px, 3.125vw, 60px);
  line-height: clamp(44px, 3.4375vw, 66px);
}
@media (max-width: 1279px) {
  .radio_btn_form__step1 .name, .radio_btn_form__step2 .name {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (max-width: 1023px) {
  .radio_btn_form__step1 .name, .radio_btn_form__step2 .name {
    font-size: clamp(20px, 0.0284495021 * 100vw + 10.8961593172px, 40px);
    line-height: clamp(22px, 0.0312944523 * 100vw + 11.9857752489px, 44px);
  }
}
@media (max-width: 1023px) {
  .radio_btn_form__step1 .name, .radio_btn_form__step2 .name {
    font-size: clamp(26px, 0.0199146515 * 100vw + 19.627311522px, 40px);
    line-height: clamp(26px, 0.0256045519 * 100vw + 17.8065433855px, 44px);
  }
}
.radio_btn_form__step1 .left, .radio_btn_form__step2 .left {
  width: 27%;
}
@media (max-width: 1279px) {
  .radio_btn_form__step1 .left, .radio_btn_form__step2 .left {
    width: 100%;
    max-width: 466px;
  }
}
.radio_btn_form__step1 .right, .radio_btn_form__step2 .right {
  width: 62%;
}
@media (max-width: 1279px) {
  .radio_btn_form__step1 .right, .radio_btn_form__step2 .right {
    width: 100%;
  }
}
.radio_btn_form__step1 #complext_seo_form, .radio_btn_form__step2 #complext_seo_form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(clamp(279.3333333333px, 21.8229166667vw, 419px), 1fr));
  gap: clamp(8px, 0.625vw, 12px) clamp(16px, 1.25vw, 24px);
}
.radio_btn_form__step1 #complext_seo_form input, .radio_btn_form__step2 #complext_seo_form input {
  background: #1A252F;
}
@media (max-width: 1279px) {
  .radio_btn_form__step1 #complext_seo_form, .radio_btn_form__step2 #complext_seo_form {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 1023px) {
  .radio_btn_form__step1 #complext_seo_form, .radio_btn_form__step2 #complext_seo_form {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 1279px) {
  .radio_btn_form__step1, .radio_btn_form__step2 {
    flex-direction: column;
    gap: clamp(31px, 0.0302398332 * 100vw + 21.3232533889px, 60px);
  }
}
.radio_btn_form__step1 {
  margin-bottom: clamp(8px, 0.625vw, 12px);
  margin-top: clamp(7.3333333333px, 0.5729166667vw, 11px);
}
@media (max-width: 1279px) {
  .radio_btn_form__step1 {
    margin-top: 0;
    margin-bottom: 41px;
  }
}
.radio_btn_form__step2 .right {
  width: 50%;
  margin-top: clamp(40px, 3.125vw, 60px);
}
.radio_btn_form__step2 .right .personal, .radio_btn_form__step2 .right .btn {
  grid-column: 1/3;
}
@media (max-width: 1023px) {
  .radio_btn_form__step2 .right .personal, .radio_btn_form__step2 .right .btn {
    grid-column: auto;
  }
}
@media (max-width: 1023px) {
  .radio_btn_form__step2 .right .personal {
    order: 1;
    text-align: center;
    margin-top: 18px;
  }
}
@media (max-width: 1279px) {
  .radio_btn_form__step2 .right {
    width: 100%;
    margin-top: 0;
  }
}
.radio_btn_form__items {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(6px, 0.46875vw, 9px);
}
@media (max-width: 1023px) {
  .radio_btn_form__items label div {
    padding-left: clamp(12px, 0.0227596017 * 100vw + 4.7169274538px, 28px);
    padding-right: clamp(12px, 0.0227596017 * 100vw + 4.7169274538px, 28px);
    max-height: 100%;
  }
}
@media (max-width: 1279px) {
  .radio_btn_form {
    padding-top: clamp(36px, 0.039624609 * 100vw + 23.3201251303px, 74px);
    padding-bottom: clamp(36px, 0.0354535975 * 100vw + 24.6548488008px, 70px);
  }
}

.number_points {
  padding-top: clamp(40px, 3.125vw, 60px);
  padding-bottom: clamp(47.3333333333px, 3.6979166667vw, 71px);
}
.number_points .hashtag {
  text-align: center;
}
@media (max-width: 1279px) {
  .number_points .hashtag {
    margin-bottom: 9px;
  }
}
.number_points__title {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(67px, 5.234375vw, 100.5px);
  margin-bottom: clamp(54.6666666667px, 4.2708333333vw, 82px);
  text-align: center;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-wrap: balance;
  margin-top: clamp(-13px, -0.6770833333vw, -8.6666666667px);
}
@media (max-width: 1279px) {
  .number_points__title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .number_points__title {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1279px) {
  .number_points__title {
    margin-top: 0;
    margin-bottom: clamp(31px, 0.023983316 * 100vw + 23.3253388947px, 54px);
  }
}
@media (max-width: 425px) {
  .number_points__title {
    text-align: left;
    max-width: 100%;
  }
}
.number_points__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: clamp(94px, 7.34375vw, 141px);
}
@media (max-width: 1279px) {
  .number_points__container {
    grid-template-columns: 1fr;
    gap: clamp(27px, 0.0698644421 * 100vw + 4.6433785193px, 94px);
  }
}
.number_points__left {
  text-align: right;
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(25.92px, 2.025vw, 38.88px);
  text-wrap: balance;
}
@media (max-width: 1279px) {
  .number_points__left {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .number_points__left {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .number_points__left {
    text-align: left;
    font-size: clamp(14px, 0.0031282586 * 100vw + 12.9989572471px, 17px);
    line-height: clamp(14px, 0.0177267987 * 100vw + 8.3274244004px, 31px);
  }
}
.number_points__right {
  display: flex;
  flex-direction: column;
  gap: clamp(26.6666666667px, 2.0833333333vw, 40px);
}
.number_points__right .btn {
  margin-top: clamp(51.3333333333px, 4.0104166667vw, 77px);
  width: fit-content;
}
@media (max-width: 1279px) {
  .number_points__right .btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: clamp(15px, 0.0375391032 * 100vw + 2.9874869656px, 51px);
  }
}
@media (max-width: 425px) {
  .number_points__right .btn {
    width: 100%;
  }
}
.number_points__point {
  display: flex;
  gap: clamp(28px, 2.1875vw, 42px);
  align-items: center;
}
.number_points__point .numb {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(67.3333333333px, 5.2604166667vw, 101px);
}
@media (max-width: 1279px) {
  .number_points__point .numb {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .number_points__point .numb {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1023px) {
  .number_points__point .numb {
    font-size: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
    line-height: clamp(30px, 0.039829303 * 100vw + 17.2546230441px, 58px);
  }
}
.number_points__point .text {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(25.92px, 2.025vw, 38.88px);
  text-wrap: balance;
}
@media (max-width: 1279px) {
  .number_points__point .text {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .number_points__point .text {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1279px) {
  .number_points__point .text {
    font-size: clamp(14px, 0.0031282586 * 100vw + 12.9989572471px, 17px);
    line-height: clamp(14px, 0.0177267987 * 100vw + 8.3274244004px, 31px);
  }
}

.cols_two_points {
  padding-top: clamp(66.6666666667px, 5.2083333333vw, 100px);
  padding-bottom: clamp(50.6666666667px, 3.9583333333vw, 76px);
}
.cols_two_points__title {
  font-family: "Hoves Regular";
  font-size: clamp(53.3333333333px, 4.1666666667vw, 80px);
  line-height: clamp(53.3333333333px, 4.1666666667vw, 80px);
  font-weight: 400;
  color: #F2F2F6;
  text-align: center;
  text-wrap: balance;
  margin-bottom: clamp(46.6666666667px, 3.6458333333vw, 70px);
}
@media (max-width: 1023px) {
  .cols_two_points__title {
    font-size: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
    line-height: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
  }
}
@media (max-width: 1023px) {
  .cols_two_points__title {
    margin-bottom: clamp(30px, 0.0236130868 * 100vw + 22.4438122333px, 46.6px);
  }
}
@media (max-width: 767px) {
  .cols_two_points__title {
    text-align: left;
  }
}
.cols_two_points__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(clamp(466.6666666667px, 36.4583333333vw, 700px), 1fr));
  gap: 40px clamp(106.6666666667px, 8.3333333333vw, 160px);
}
@media (max-width: 1279px) {
  .cols_two_points__container {
    grid-template-columns: 1fr;
  }
}
.cols_two_points__col .name {
  font-family: "Hoves Regular";
  font-size: clamp(29.3333333333px, 2.2916666667vw, 44px);
  line-height: clamp(29.3333333333px, 2.2916666667vw, 44px);
  font-weight: 400;
  color: #F2F2F6;
  font-style: italic;
  text-align: center;
  text-wrap: balance;
  margin-bottom: clamp(33.3333333333px, 2.6041666667vw, 50px);
}
@media (max-width: 1279px) {
  .cols_two_points__col .name {
    text-align: left;
  }
}
@media (max-width: 1023px) {
  .cols_two_points__col .name {
    font-size: clamp(24px, 0.0071123755 * 100vw + 21.7240398293px, 29px);
    line-height: clamp(24px, 0.0071123755 * 100vw + 21.7240398293px, 29px);
    margin-bottom: clamp(20px, 0.0184921764 * 100vw + 14.0825035562px, 33px);
  }
}
.cols_two_points__items {
  display: flex;
  flex-direction: column;
  gap: clamp(33.3333333333px, 2.6041666667vw, 50px) 0;
}
@media (max-width: 1279px) {
  .cols_two_points__items {
    gap: clamp(14px, 0.0201251303 * 100vw + 7.5599582899px, 33.3px);
  }
}
.cols_two_points__item {
  display: flex;
}
.cols_two_points__item svg {
  flex: 0 0 auto;
  margin-top: clamp(-25px, -1.3020833333vw, -16.6666666667px);
  margin-left: clamp(-45px, -2.34375vw, -30px);
}
.cols_two_points__item svg.cross {
  width: clamp(106.6666666667px, 8.3333333333vw, 160px);
  height: clamp(110.6666666667px, 8.6458333333vw, 166px);
}
@media (max-width: 1023px) {
  .cols_two_points__item svg.cross {
    width: clamp(56px, 0.0711237553 * 100vw + 33.240398293px, 106px);
    height: clamp(60px, 0.0711237553 * 100vw + 37.240398293px, 110px);
  }
}
.cols_two_points__item svg.check {
  width: clamp(116.6666666667px, 9.1145833333vw, 175px);
  height: clamp(111.3333333333px, 8.6979166667vw, 167px);
}
@media (max-width: 1023px) {
  .cols_two_points__item svg.check {
    width: clamp(56px, 0.0853485064 * 100vw + 28.6884779516px, 116px);
    height: clamp(51px, 0.0853485064 * 100vw + 23.6884779516px, 111px);
  }
}
@media (max-width: 1023px) {
  .cols_two_points__item svg {
    margin-top: clamp(-16px, -0.0085348506 * 100vw + -7.2688477952px, -10px);
    margin-left: clamp(-30px, -0.0213371266 * 100vw + -8.1721194879px, -15px);
  }
}
.cols_two_points__item .text {
  display: flex;
  flex-direction: column;
  gap: clamp(5.3333333333px, 0.4166666667vw, 8px) 0;
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  font-style: italic;
  margin-left: clamp(-20px, -1.0416666667vw, -13.3333333333px);
}
@media (max-width: 1279px) {
  .cols_two_points__item .text {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .cols_two_points__item .text {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
.cols_two_points__item .text span {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  font-style: normal;
  color: #707881;
}
@media (max-width: 1279px) {
  .cols_two_points__item .text span {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .cols_two_points__item .text span {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
@media (max-width: 1023px) {
  .cols_two_points__item .text {
    line-height: clamp(17px, 0.0199146515 * 100vw + 10.627311522px, 31px);
    margin-left: clamp(-13px, -0.0071123755 * 100vw + -5.7240398293px, -8px);
  }
}
.cols_two_points .btn_container {
  margin-top: clamp(70.6666666667px, 5.5208333333vw, 106px);
  display: flex;
  justify-content: center;
}
@media (max-width: 1279px) {
  .cols_two_points .btn_container {
    margin-top: clamp(40px, 0.031282586 * 100vw + 29.9895724713px, 70px);
  }
}
@media (max-width: 1279px) {
  .cols_two_points {
    padding-top: clamp(41px, 0.0260688217 * 100vw + 32.6579770594px, 66px);
    padding-bottom: clamp(41px, 0.0093847758 * 100vw + 37.9968717414px, 50px);
  }
}

.scroll_place {
  padding-top: clamp(52px, 4.0625vw, 78px);
  padding-bottom: clamp(116.6666666667px, 9.1145833333vw, 175px);
  position: relative;
  z-index: 1;
}
.scroll_place.brb {
  border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
  overflow: hidden;
}
.scroll_place .background_line {
  z-index: -1;
}
.scroll_place__title {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(67px, 5.234375vw, 100.5px);
  text-align: center;
  text-wrap: balance;
}
@media (max-width: 1279px) {
  .scroll_place__title {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .scroll_place__title {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
.scroll_place__title span {
  font-style: italic;
}
.scroll_place__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(20px, 1.5625vw, 30px);
  margin-top: clamp(60.6666666667px, 4.7395833333vw, 91px);
}
@media (max-width: 1279px) {
  .scroll_place__container {
    grid-template-columns: 100%;
    gap: 34px;
  }
}
.scroll_place__item {
  background: #1A252F;
  border: 1px solid #37424D;
  border-radius: clamp(13.3333333333px, 1.0416666667vw, 20px);
  padding-right: clamp(13.3333333333px, 1.0416666667vw, 20px);
  padding-bottom: clamp(26.6666666667px, 2.0833333333vw, 40px);
  padding-left: clamp(20px, 1.5625vw, 30px);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}
.scroll_place__item .numb {
  font-family: "Hoves Regular";
  font-size: clamp(67px, 5.234375vw, 100.5px);
  line-height: clamp(92px, 7.1875vw, 138px);
  font-weight: 400;
  color: #F2F2F6;
  line-height: clamp(67px, 5.234375vw, 100.5px);
  color: #C0FF00;
  font-style: italic;
  margin-bottom: clamp(10px, 0.78125vw, 15px);
  margin-top: clamp(-55px, -2.8645833333vw, -36.6666666667px);
}
@media (max-width: 1279px) {
  .scroll_place__item .numb {
    font-size: 58px;
    line-height: 58px;
  }
}
@media (max-width: 1023px) {
  .scroll_place__item .numb {
    font-size: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
    line-height: clamp(19px, 0.0554765292 * 100vw + 1.2475106686px, 58px);
  }
}
@media (max-width: 1023px) {
  .scroll_place__item .numb {
    font-size: 50px;
    line-height: 50px;
    margin-top: clamp(-36px, -0.0156472262 * 100vw + -19.9928876245px, -25px);
  }
}
.scroll_place__item .name {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  margin-bottom: auto;
}
@media (max-width: 1279px) {
  .scroll_place__item .name {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .scroll_place__item .name {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1023px) {
  .scroll_place__item .name {
    line-height: clamp(18.7px, 0.0174964438 * 100vw + 13.1011379801px, 31px);
  }
}
.scroll_place__item .text {
  font-family: "Hoves Regular";
  font-size: clamp(12px, 0.9375vw, 18px);
  line-height: clamp(14px, 1.09375vw, 21px);
  color: #F2F2F6;
  color: rgba(255, 255, 255, 0.5);
  margin-top: clamp(26.6666666667px, 2.0833333333vw, 40px);
}
@media (max-width: 1279px) {
  .scroll_place__item .text {
    font-size: 21px;
    line-height: 24.39px;
  }
}
@media (max-width: 1023px) {
  .scroll_place__item .text {
    font-size: clamp(12px, 0.012802276 * 100vw + 7.9032716927px, 21px);
    line-height: clamp(14px, 0.0147795164 * 100vw + 9.2705547653px, 24.39px);
  }
}
.scroll_place__item.active {
  background: var(--active-color);
  border-color: var(--active-color);
  transform: translateY(clamp(-30px, -1.5625vw, -20px));
}
@media (max-width: 1279px) {
  .scroll_place__item.active {
    transform: none;
  }
}
@media (max-width: 1279px) {
  .scroll_place {
    padding-top: clamp(41px, 0.0114702815 * 100vw + 37.3295099062px, 52px);
    padding-bottom: clamp(50px, 0.0688216893 * 100vw + 27.9770594369px, 116px);
  }
}

.maintaining_network {
  position: relative;
  z-index: 1;
  padding-bottom: clamp(44.6666666667px, 3.4895833333vw, 67px);
}
.maintaining_network .background_line {
  z-index: -1;
}
.maintaining_network .background_line.brb {
  border-radius: 0 0 clamp(40px, 3.125vw, 60px) clamp(40px, 3.125vw, 60px);
}
.maintaining_network .container {
  max-width: clamp(874px, 68.28125vw, 1311px);
}
.maintaining_network__head {
  background: var(--block_color);
  padding-top: clamp(17.3333333333px, 1.3541666667vw, 26px);
  padding-bottom: clamp(31.3333333333px, 2.4479166667vw, 47px);
}
.maintaining_network__top_text {
  font-family: "Hoves Regular";
  font-size: clamp(17px, 1.328125vw, 25.5px);
  line-height: clamp(22px, 1.71875vw, 33px);
  color: #F2F2F6;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: clamp(4.6666666667px, 0.3645833333vw, 7px);
}
@media (max-width: 1279px) {
  .maintaining_network__top_text {
    font-size: 28px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .maintaining_network__top_text {
    font-size: clamp(17px, 0.0156472262 * 100vw + 11.9928876245px, 28px);
    line-height: clamp(18.7px, 0.0297297297 * 100vw + 9.1864864865px, 39.6px);
  }
}
.maintaining_network__title {
  font-family: "Hoves Regular";
  font-size: clamp(53.3333333333px, 4.1666666667vw, 80px);
  line-height: clamp(53.3333333333px, 4.1666666667vw, 80px);
  font-weight: 400;
  color: #F2F2F6;
  text-wrap: balance;
  max-width: 60%;
}
@media (max-width: 1023px) {
  .maintaining_network__title {
    font-size: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
    line-height: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
  }
}
.maintaining_network__container {
  display: flex;
  justify-content: space-between;
  padding-top: clamp(42px, 3.28125vw, 63px);
}
@media (max-width: 1279px) {
  .maintaining_network__container {
    flex-direction: column;
  }
}
.maintaining_network__left {
  width: 38%;
}
@media (max-width: 1279px) {
  .maintaining_network__left {
    width: 100%;
  }
}
.maintaining_network__items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(clamp(126px, 9.84375vw, 189px), 1fr));
  gap: clamp(38px, 2.96875vw, 57px) clamp(76.6666666667px, 5.9895833333vw, 115px);
}
@media (max-width: 1279px) {
  .maintaining_network__items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .maintaining_network__items {
    grid-template-columns: 1fr;
    gap: clamp(17px, 0.0469798658 * 100vw + 1.966442953px, 38px);
  }
}
.maintaining_network__item .name {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  font-style: italic;
  margin-bottom: clamp(6.6666666667px, 0.5208333333vw, 10px);
}
@media (max-width: 1279px) {
  .maintaining_network__item .name {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .maintaining_network__item .name {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 767px) {
  .maintaining_network__item .name {
    margin-bottom: 4px;
  }
}
.maintaining_network__item .plus {
  font-family: "Hoves Regular";
  font-size: clamp(14px, 1.09375vw, 21px);
  line-height: clamp(16px, 1.25vw, 24px);
  color: #F2F2F6;
  margin-bottom: clamp(16.6666666667px, 1.3020833333vw, 25px);
}
@media (max-width: 1279px) {
  .maintaining_network__item .plus {
    font-size: 24px;
    line-height: 33.36px;
  }
}
@media (max-width: 1023px) {
  .maintaining_network__item .plus {
    font-size: clamp(12px, 0.0170697013 * 100vw + 6.5376955903px, 24px);
    line-height: clamp(16.68px, 0.0237268848 * 100vw + 9.0873968706px, 33.36px);
  }
}
@media (max-width: 767px) {
  .maintaining_network__item .plus {
    margin-bottom: 0;
  }
}
.maintaining_network__item .cost {
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  color: #C0FF00;
}
@media (max-width: 1279px) {
  .maintaining_network__item .cost {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .maintaining_network__item .cost {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 767px) {
  .maintaining_network__item {
    display: flex;
    justify-content: space-between;
  }
}
.maintaining_network__right {
  position: relative;
}
.maintaining_network__right img {
  position: absolute;
  bottom: clamp(-180px, -9.375vw, -120px);
  right: clamp(-100px, -5.2083333333vw, -66.6666666667px);
  width: clamp(525.3333333333px, 41.0416666667vw, 788px);
  height: clamp(516.6666666667px, 40.3645833333vw, 775px);
  object-fit: contain;
}
@media (max-width: 1279px) {
  .maintaining_network__right img {
    position: relative;
    width: clamp(373px, 0.1584984359 * 100vw + 322.2805005214px, 525px);
    height: clamp(410px, 0.110531804 * 100vw + 374.629822732px, 516px);
    right: clamp(-10px, 0.0104275287 * 100vw + -13.3368091762px, 0px);
    margin-top: -108px;
  }
}
@media (max-width: 1279px) {
  .maintaining_network__right {
    display: flex;
    justify-content: center;
    height: fit-content;
  }
}

.niches {
  padding-bottom: clamp(132px, 10.3125vw, 198px);
  position: relative;
  z-index: 1;
}
.niches.mt {
  z-index: 0;
  margin-top: -50px;
}
.niches__head {
  padding-top: clamp(86.6666666667px, 6.7708333333vw, 130px);
  padding-bottom: clamp(36px, 2.8125vw, 54px);
  background: var(--block-color);
}
.niches__head.mt {
  padding-top: clamp(120px, 9.375vw, 180px);
}
@media (max-width: 1279px) {
  .niches__head.mt {
    padding-top: clamp(80px, 0.0417101147 * 100vw + 66.6527632951px, 120px);
  }
}
@media (max-width: 1279px) {
  .niches__head {
    padding-top: clamp(46.6px, 0.0417101147 * 100vw + 33.2527632951px, 86.6px);
    padding-bottom: clamp(22px, 0.0145985401 * 100vw + 17.3284671533px, 36px);
  }
}
.niches__title {
  font-family: "Hoves Regular";
  font-size: clamp(46.6666666667px, 3.6458333333vw, 70px);
  line-height: clamp(46.6666666667px, 3.6458333333vw, 70px);
  font-weight: 400;
  color: #F2F2F6;
  max-width: 50%;
  text-wrap: balance;
}
@media (max-width: 1023px) {
  .niches__title {
    font-size: clamp(30px, 0.0236130868 * 100vw + 22.4438122333px, 46.6px);
    line-height: clamp(30px, 0.0236130868 * 100vw + 22.4438122333px, 46.6px);
  }
}
@media (max-width: 425px) {
  .niches__title {
    max-width: 100%;
  }
}
.niches__items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(clamp(275px, 21.484375vw, 412.5px), 1fr));
  gap: clamp(29.6666666667px, 2.3177083333vw, 44.5px) clamp(15px, 1.171875vw, 22.5px);
  margin-top: clamp(-230px, -11.9791666667vw, -153.3333333333px);
}
@media (max-width: 1279px) {
  .niches__items {
    grid-template-columns: repeat(auto-fill, minmax(clamp(280px, 0.0729927007 * 100vw + 256.6423357664px, 350px), 1fr));
    margin-top: 28px;
    gap: 20px;
  }
}
.niches__item {
  display: flex;
  flex-direction: column;
  gap: clamp(8.6666666667px, 0.6770833333vw, 13px);
}
.niches__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: clamp(13.3333333333px, 1.0416666667vw, 20px);
}
.niches__item .name {
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(16px, 1.25vw, 24px);
  line-height: clamp(23.84px, 1.8625vw, 35.76px);
  color: #F2F2F6;
}
@media (max-width: 1023px) {
  .niches__item .name {
    font-size: clamp(14px, 0.0028449502 * 100vw + 13.0896159317px, 16px);
    line-height: clamp(15.4px, 0.0120056899 * 100vw + 11.5581792319px, 23.84px);
  }
}
.niches__item1 {
  grid-column: 1/2;
}
@media (max-width: 1279px) {
  .niches__item1 {
    display: none;
  }
}
.niches__item2 {
  grid-column: 2/3;
}
@media (max-width: 1279px) {
  .niches__item2 {
    display: none;
  }
}
@media (max-width: 1279px) {
  .niches {
    padding-bottom: clamp(41px, 0.0948905109 * 100vw + 10.6350364964px, 132px);
  }
}

.budget_formed {
  padding-top: clamp(56px, 4.375vw, 84px);
  padding-bottom: clamp(56.6666666667px, 4.4270833333vw, 85px);
  position: relative;
  z-index: 1;
  background: #1A252F;
}
.budget_formed__title {
  width: 100%;
  text-align: center;
  font-family: "Hoves Regular";
  font-size: clamp(53.3333333333px, 4.1666666667vw, 80px);
  line-height: clamp(53.3333333333px, 4.1666666667vw, 80px);
  font-weight: 400;
  color: #F2F2F6;
  margin-bottom: clamp(23.3333333333px, 1.8229166667vw, 35px);
}
@media (max-width: 1023px) {
  .budget_formed__title {
    font-size: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
    line-height: clamp(26px, 0.0388335704 * 100vw + 13.573257468px, 53.3px);
  }
}
@media (max-width: 1023px) {
  .budget_formed__title {
    font-size: clamp(30px, 0.03314367 * 100vw + 19.3940256046px, 53.3px);
    line-height: clamp(30px, 0.03314367 * 100vw + 19.3940256046px, 53.3px);
  }
}
@media (max-width: 425px) {
  .budget_formed__title {
    text-align: left;
  }
}
.budget_formed__subtitle {
  width: 100%;
  text-align: center;
  font-family: "Hoves Regular";
  font-size: clamp(24px, 1.875vw, 36px);
  line-height: clamp(31px, 2.421875vw, 46.5px);
  font-weight: 400;
  color: #F2F2F6;
  font-style: italic;
  margin-bottom: clamp(35.3333333333px, 2.7604166667vw, 53px);
}
@media (max-width: 1279px) {
  .budget_formed__subtitle {
    font-size: 36px;
    line-height: 39.6px;
  }
}
@media (max-width: 1023px) {
  .budget_formed__subtitle {
    font-size: clamp(17px, 0.027027027 * 100vw + 8.3513513514px, 36px);
    line-height: clamp(31px, 0.0122332859 * 100vw + 27.0853485064px, 39.6px);
  }
}
@media (max-width: 1023px) {
  .budget_formed__subtitle {
    font-size: clamp(20px, 0.0227596017 * 100vw + 12.7169274538px, 36px);
    line-height: clamp(22px, 0.0250355619 * 100vw + 13.9886201991px, 39.6px);
  }
}
@media (max-width: 425px) {
  .budget_formed__subtitle {
    text-align: left;
  }
}
.budget_formed__items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: clamp(13.3333333333px, 1.0416666667vw, 20px) clamp(20px, 1.5625vw, 30px);
  margin-bottom: clamp(28.6666666667px, 2.2395833333vw, 43px);
}
.budget_formed__items .plus {
  font-family: "Hoves Regular";
  font-size: clamp(26.6666666667px, 2.0833333333vw, 40px);
  line-height: clamp(26.6666666667px, 2.0833333333vw, 40px);
  font-weight: 400;
  color: #F2F2F6;
  color: #C0FF00;
}
.budget_formed__items .plus:last-child {
  display: none;
}
@media (max-width: 425px) {
  .budget_formed__items {
    justify-content: flex-start;
  }
}
.budget_formed__item {
  border: 1px solid #37424D;
  border-radius: clamp(22.6666666667px, 1.7708333333vw, 34px);
  padding: clamp(5.3333333333px, 0.4166666667vw, 8px) clamp(28px, 2.1875vw, 42px) clamp(8px, 0.625vw, 12px);
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(16px, 1.25vw, 24px);
  line-height: clamp(23.84px, 1.8625vw, 35.76px);
  color: #F2F2F6;
  color: #707881;
  width: fit-content;
}
@media (max-width: 1023px) {
  .budget_formed__item {
    font-size: clamp(14px, 0.0028449502 * 100vw + 13.0896159317px, 16px);
    line-height: clamp(15.4px, 0.0120056899 * 100vw + 11.5581792319px, 23.84px);
  }
}
.budget_formed__item span {
  color: #C0FF00;
}
@media (max-width: 1023px) {
  .budget_formed__item {
    padding-left: clamp(20px, 0.0113798009 * 100vw + 16.3584637269px, 28px);
    padding-right: clamp(20px, 0.0113798009 * 100vw + 16.3584637269px, 28px);
  }
}
@media (max-width: 767px) {
  .budget_formed__item {
    max-width: 85%;
    width: fit-content;
  }
}
.budget_formed .result {
  width: 100%;
  text-align: center;
  font-family: "Hoves Regular";
  font-size: clamp(26.6666666667px, 2.0833333333vw, 40px);
  line-height: clamp(26.6666666667px, 2.0833333333vw, 40px);
  font-weight: 400;
  color: #F2F2F6;
  color: #C0FF00;
  margin-bottom: clamp(21.3333333333px, 1.6666666667vw, 32px);
}
.budget_formed__results {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(clamp(366px, 28.59375vw, 549px), 1fr));
  gap: 35px clamp(99.3333333333px, 7.7604166667vw, 149px);
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.budget_formed__results .text p {
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(16px, 1.25vw, 24px);
  line-height: clamp(23.84px, 1.8625vw, 35.76px);
  color: #F2F2F6;
  margin-bottom: clamp(12px, 0.9375vw, 18px);
}
@media (max-width: 1023px) {
  .budget_formed__results .text p {
    font-size: clamp(14px, 0.0028449502 * 100vw + 13.0896159317px, 16px);
    line-height: clamp(15.4px, 0.0120056899 * 100vw + 11.5581792319px, 23.84px);
  }
}
.budget_formed__results .text ul {
  padding-left: clamp(10px, 0.78125vw, 15px);
}
.budget_formed__results .text ul li {
  list-style-type: disc;
  font-family: "Hoves Regular";
  font-weight: 400;
  font-size: clamp(16px, 1.25vw, 24px);
  line-height: clamp(23.84px, 1.8625vw, 35.76px);
  color: #F2F2F6;
  line-height: clamp(23.84px, 1.8625vw, 35.76px);
  color: #707881;
}
.budget_formed__results .text ul li::marker {
  color: #C0FF00;
}
@media (max-width: 1023px) {
  .budget_formed__results .text ul li {
    font-size: clamp(14px, 0.0028449502 * 100vw + 13.0896159317px, 16px);
    line-height: clamp(15.4px, 0.0120056899 * 100vw + 11.5581792319px, 23.84px);
  }
}
@media (max-width: 1279px) {
  .budget_formed__results {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}
@media (max-width: 767px) {
  .budget_formed__results {
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .budget_formed {
    padding-top: clamp(32px, 0.0341394026 * 100vw + 21.0753911807px, 56px);
  }
}