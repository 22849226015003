.advantages_text{
    position: relative;
    z-index: 1;
    margin-top: clampFluid(56);
    padding-bottom: clampFluid(83.5);
    overflow: hidden;
    &__name{
        @include font-100-5;
        @include _1279{
            font-size: 80px;
            line-height: 72px;
            white-space: nowrap;
        }
        @include _1023{
            font-size: clampFluid(30,80,320,1023);
            line-height: clampFluid(30,72,320,1023);
        }
    }
    &__text_name{
        @include font-25-5;
        max-width: clampFluid(241);  
        margin-left: clampFluid(15);
        margin-top: clampFluid(-8);
        @include _1279{
            font-size: 24px;
            line-height: 28.8px;
            margin-top: 3px;
            max-width: max-content;
            width: 100%;
        }
        @include _1023{
            font-size: clampFluid(14,24,320,1023);
            line-height: clampFluid(16.8,28.8,320,1023);
            margin-left: 0;
            margin-top: clampFluid(6,3,320,1023);
        }
        @include _425{
            max-width: 90%;
        }
    }
    &__hashtag{
        margin-left: clampFluid(64);
        @include _1279{
            margin-left: 25px;
        }
        @include _1023{
            margin-left: clampFluid(1,25,320,1023);
        }
    }
    &__img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include _1023{
            object-fit: cover;
            height: clampFluid(167,288,320,1023);
        }
    }
    &__title{
        @include font-100-5;
        margin-top: clampFluid(-18);
        margin-left: clampFluid(3);
        span{
            font-style: italic;
        }
        @include _1279{
            margin-left: 0;
            margin-top: 2px;
        }
        @include _1023{
            font-size: clampFluid(30,58,320,1023);
            line-height: clampFluid(30,58,320,1023);
            margin-top: 3px;
            margin-left: 2px;
        }
    }
    &__text{
        max-width: clampFluid(440);
        margin-top: clampFluid(24);
        margin-left: clampFluid(3);
        p{
            @include font-21;
            &:not(:last-child) {
                margin-bottom: clampFluid(23);
                @include _1279{
                    margin-bottom: 24px;
                }
                @include _1023{
                    margin-bottom: clampFluid(13,24,320,1023);
                }
            }
            @include _1279{
                line-height: 28.8px;
            }
            @include _1023{
                font-size: clampFluid(14,24,320,1023);
                line-height: clampFluid(16.8,28.8,320,1023);
            }
        }
        @include _1279{
            margin-left: 0;
            margin-top: 7px;
            max-width: 61%;
        }
        @include _1023{
            margin-top: 0;
            margin-left: 2px;
        }
        @include _425{
            max-width: 100%;
        }
    }
    &__items{
        display: flex;
        width: 68%;
        justify-content: flex-end;
        span{
            color: $green;
        }
        .item{
            display: flex;
            flex-direction: column;
            &:nth-child(2){
                padding: 0 clampFluid(68);
                border-left: 1px solid $line;
                border-right: 1px solid $line;
                margin: 0 clampFluid(67.5);
                @include _1279{
                    padding: 0 51px;
                    margin: 0 51px;
                }
                @include _1023{
                    border: none;
                    margin: 0;
                    padding: 0 clampFluid(25,51,320,1023);
                }
                @include _425{
                    padding: 0;
                }
            }
            &:last-child{
                margin-right: clampFluid(5);
                @include _1279{
                    margin-right: 0;
                }
                @include _425{
                    margin-top: 20px;
                }
            }
        }
        @include _1279{
            width: min-content;
            margin: 33px auto 0;
        }
        @include _1023{
            margin-top: clampFluid(28,33,320,1023);
        }
        @include _425{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-left: 0;
            margin-right: 0;
            width: 100%;
        }
    }
    &__container{
        display: flex;
        justify-content: space-between;
        margin-top: clampFluid(16);
        @include _1279{
            flex-direction: column;
            margin-top: 12px;
        }
        @include _1023{
            margin-top: 13px;
        }
    }
    &__slider{
        transition: background $transition ease;
        padding-top: 36px;
        padding-bottom: 74px;
        .department{
            padding-bottom: 33px;
            @include font-25-5;
            color: $white06;
        }
        .swiper{
            cursor: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDYiIGhlaWdodD0iNDciIHZpZXdCb3g9IjAgMCA0NiA0NyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjMiIGN5PSIyMy4xNjQxIiByPSIyMyIgZmlsbD0iI0MwRkYwMCIvPgo8cGF0aCBkPSJNMjYuNzEwOSAxMy44Nzg5TDM1Ljk5ODcgMjMuMTY2NkwyNi43MTA5IDMyLjQ1NDQiIHN0cm9rZT0iIzEyMUIyNCIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGQ9Ik0xOS4yODUyIDMyLjQ0OTJMOS45OTc0MiAyMy4xNjE1TDE5LjI4NTIgMTMuODczNyIgc3Ryb2tlPSIjMTIxQjI0IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==") 2 2, default;
            overflow: visible;
            &_container{
                width: 315px;
                overflow: visible;
            }
            &-slide{
                .name, .post{
                    user-select: none;
                }
                .name{
                    @include font-25-5;
                    margin-top: 25px;
                    margin-bottom: 5px;
                }
                .post{
                    @include font-18;
                    color: $white06;
                }
            }
        }
    }
    .bgc{
        font-size: clampFluid(270);
        line-height: clampFluid(243);
        color: $tone2;
        position: absolute;
        bottom: clampFluid(-29);
        left: 43px;
        right: 43px;
        z-index: -1;
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        font-weight: 900;
        font-style: normal;
        font-variation-settings: "slnt" 0;
        div{
            &:first-child{
                user-select: none;
                text-rendering: optimizeSpeed;
                text-transform: uppercase;
                &.flex {
                    display: flex;
                    justify-content: space-between;
                }
                @include _1279{
                    display: none !important;
                }
            }
        }
        .mob{
            display: none;
            font-size: 230px;
            line-height: 207px;
            text-align: center;
            @include _1279{
                display: block;
            }
            @include _1023{
                font-size: clampFluid(90,230,320,1023);
                line-height: clampFluid(81,207,320,1023);
            }
        }
        @include _1279{
            bottom: -21px;
        }
        @include _1023{
            left: clampFluid(9,43,320,1023);
            right: clampFluid(9,43,320,1023);
            bottom: 36px;
        }
    }
    @include _1279{
        padding-bottom: 67px;
        margin-top: 67px;
    }
    @include _1023{
        overflow: visible;
        margin-top: clampFluid(35,67,320,1023);
        padding-bottom: clampFluid(22,67,320,1023);
    }
}