.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    &.is-open {
        display: block;
    }
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15vh 0 0;
        overflow: auto;
        background: rgba(#000, 0.5);
    }
    &__header{
        display: flex;
        padding-bottom: 60px;
    }
    &__title{
        @include font-60;
        margin-right: 56px;
    }
    &__search{
        max-width: clampFluid(496);
        background: transparent;
        margin-top: clampFluid(5);
        padding-bottom: clampFluid(15);
        padding-left: clampFluid(44);
    }
    &__close {
        width: clampFluid(24);
        height: clampFluid(24);
        stroke: $green;
        stroke-width: 2px;
        cursor: pointer;
        // margin-left: auto;
        position: absolute;
        top: clampFluid(28);
        right: clampFluid(29);
    }
    &__container {
        width: 100%;
        margin: 0 auto;
        position: relative;
        background: $tone2;
        border-radius: clampFluid(30);
        width: clampFluid(1222);
        padding: clampFluid(46) clampFluid(29) 0 clampFluid(100);
    }
    &[aria-hidden="false"] {
        .modal {
            &__overlay {
                animation: mmfadeIn $tr-time cubic-bezier(0, 0, 0.2, 1);
            }
            &__container {
                animation: mmslideIn $tr-time cubic-bezier(0, 0, 0.2, 1);
            }
        }
    }
    &[aria-hidden="true"] {
        .modal {
            &__overlay {
                animation: mmfadeOut $tr-time cubic-bezier(0, 0, 0.2, 1);
            }
            &__container {
                animation: mmslideOut $tr-time cubic-bezier(0, 0, 0.2, 1);
            }
        }
    }
}
#region_modal{
    .modal{
        &__container{
            height: 100%;
            max-height: clampFluid(597);
            overflow: hidden;
        }
        &__content{
            height: 100%;
            max-height: clampFluid(410);
            overflow: auto;
            padding-right: clampFluid(40);
            &::-webkit-scrollbar{
                width: 3px;
            }
            &::-webkit-scrollbar-track{
                background: linear-gradient(90deg, $tone2 49%, $line 50%, $tone2 51%);
                width: 1px;
            }
            &::-webkit-scrollbar-thumb{
                background: $text;
                border-radius: 7px;
                width: 100%;
                cursor: pointer;
                transition: background $transition ease;
                &:hover{
                    background: $green;
                }
            }
        }
        &__city_items{
            columns: 4;
            // column-gap: clampFluid(137);
            column-gap: clampFluid(100);
            padding-bottom: clampFluid(30);
            .item{
                margin-bottom: clampFluid(12);
                a{
                    @include font-21;
                    transition: all $transition ease;
                    @include hover{
                        color: $green;
                    }
                }
                &.now_region{
                    a{
                        color: $green;
                    }
                }
            }
        }
    }
    @include _1279{
        display: none !important;
    }
}
@keyframes mmfadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes mmfadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@keyframes mmslideIn {
    from {
        transform: translateY(15%);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes mmslideOut {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-10%);
    }
}