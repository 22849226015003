.check_list {
    // padding-top: clampFluid(100);
    padding-top: clampFluid(63);
    padding-bottom: clampFluid(87);
    background: $line;
    &.mt {
        z-index: 0;
        position: relative;
        margin-top: -50px;
        padding-top: clampFluid(113);
    }
    &__container {
        padding-top: clampFluid(16);
        display: grid;
        grid-template-columns: repeat(24, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: clampFluid(10);
        position: relative;
        z-index: 1;
        @include _1279 {
            grid-template-rows: auto;
        }
    }
    &__top_text {
        grid-row: 2/3;
        grid-column: 1/10;
        @include font-36;
        line-height: clampFluid(39.6);
        font-style: italic;
        text-align: right;
        @include _1279 {
            grid-column: 1/25;
            grid-row: 2/3;
            text-align: left;
            margin-top: clampFluid(11,15,320,1279);
            line-height: clampFluid(18.7,31,320,1279);
        }
    }
    &__bottom_text {
        grid-row: 3/4;
        grid-column: 4/10;
        @include font-25-5;
        line-height: clampFluid(28.05);
        color: $white05;
        text-align: right;
        margin-right: clampFluid(18);
        @include _1279 {
            grid-column: 1/13;
            text-align: left;
            margin-top: clampFluid(29,60,320,1279);
        }
        @include _1023 {
            font-size: clampFluid(12,17,320,1023);
            line-height: clampFluid(13.2,18.7,320,1023);
        }
    }
    &__left_title {
        @include font-100-5;
        grid-column: 1/10;
        text-align: right;
        grid-row: 1/2;
        @include _1279 {
            display: none;
        }
    }
    &__right_title {
        @include font-100-5;
        grid-column: 16/25;
        grid-row: 1/2;
        @include _1279 {
            display: none;
        }
    }
    &__title {
        @include font-100-5;
        display: none;
        @include _1279 {
            display: block;
            grid-column: 1/25;
            grid-row: 1/2;
        }
        @include _1023 {
            font-size: 30px;
            line-height: 30px;
        }
    }
    &__img {
        grid-column: 10/16;
        grid-row: 1/4;
        width: 100%;
        height: auto;
        object-fit: contain;
        margin: auto 0;
        padding: 0 20px;
        @include _1279 {
            grid-row: 3/4;
            grid-column: 13/25;
            max-height: 360px;
            height: 100%;
            padding: 0;
        }
    }
    .form_container {
        grid-column: 16/22;
        grid-row: 3/4;
        form {
            display: flex;
            flex-direction: column;
            gap: clampFluid(26) 0;
            margin-top: auto;
            .btn {
                width: fit-content;
                height: fit-content;
                @include _1279 {
                    width: 100%;
                }
            }
            input {
                background: $tone2;
            }
            @include _1279 {
                gap: clampFluid(15,18,320,1279);
            }
        }
        @include _1279 {
            grid-column: 1/25;
            grid-row: 4/5;
            margin-left: auto;
            margin-right: auto;
            max-width: 700px;
            width: 100%;
            margin-top: clampFluid(25,35,320,1279);
        }
    }
    @include _1279 {
        padding-top: clampFluid(26,42,320,1279);
        padding-bottom: clampFluid(31,58,320,1279);
    }
}
