.docs_page {
    padding-top: clampFluid(100);
    padding-bottom: clampFluid(186);
    h1 {
        @include font-100-5;
        line-height: clampFluid(110);
    }
    &__items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(clampFluid(520), 1fr));
        gap: clampFluid(70);
        margin-top: clampFluid(80);
    }
    &__item {
        
        .name {
            @include font-60;
            margin-bottom: clampFluid(12);
        }
        a {
            @include font-25-5;
            color: $green;
            text-decoration: underline;
            transition: all $transition ease;
            @include hover {
                text-decoration: none;
            }
            &:visited {
                color: #406AFF;
                text-decoration: none;
            }
            &:not(:last-child) {
                margin-bottom: clampFluid(20);
            }
        }
    }
}