.maintaining_network {
    position: relative;
    z-index: 1;
    padding-bottom: clampFluid(67);
    .background_line {
        z-index: -1;
        &.brb {
            border-radius: 0 0 clampFluid(60) clampFluid(60);
        }
    }
    .container {
        max-width: clampFluid(1311);
    }
    &__head {
        background: var(--block_color);
        padding-top: clampFluid(26);
        padding-bottom: clampFluid(47);
    }
    &__top_text {
        @include font-25-5;
        color: $white05;
        margin-bottom: clampFluid(7);
    }
    &__title {
        @include font-80;
        text-wrap: balance;
        max-width: 60%;
    }
    &__container {
        display: flex;
        justify-content: space-between;
        padding-top: clampFluid(63);
        @include _1279 {
            flex-direction: column;
        }
    }
    &__left {
        width: 38%;
        @include _1279 {
            width: 100%;
        }
    }
    &__items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(clampFluid(189), 1fr));
        gap: clampFluid(57) clampFluid(115);
        @include _1279 {
            grid-template-columns: repeat(2, 1fr);
        }
        @include _767 {
            grid-template-columns: 1fr;
            gap: clampFluid(17,38,320,767);
        }
    }
    &__item {
        .name {
            @include font-36;
            font-style: italic;
            margin-bottom: clampFluid(10);
            @include _767 {
                margin-bottom: 4px;
            }
        }
        .plus {
            @include font-21;
            margin-bottom: clampFluid(25);
            @include _767 {
                margin-bottom: 0;
            }
        }
        .cost {
            @include font-36;
            color: $green;
        }
        @include _767 {
            display: flex;
            justify-content: space-between;
        }
    }
    &__right {
        position: relative;
        img {
            position: absolute;
            bottom: clampFluid(-180);
            right: clampFluid(-100);
            width: clampFluid(788);
            height: clampFluid(775);
            object-fit: contain;
            @include _1279 {
                position: relative;
                width: clampFluid(373,525,320,1279);
                height: clampFluid(410,516,320,1279);
                right: clampFluid(-10,0,320,1279);
                margin-top: -108px;
            }
        }
        @include _1279 {
            display: flex;
            justify-content: center;
            height: fit-content;
        }
    }
}
