.link_site_form{
    background: var(--bgc-color);
    padding-top: clampFluid(55);
    padding-bottom: clampFluid(100);
    position: relative;
    z-index: 1;
    &__hashtag{
        width: 100%;
        text-align: center;
        color: rgba(255,255,255,0.5);
        @include _1279{
            text-align: start;
            margin-left: 25px;
        }
        @include _1023{
            text-align: center;
            margin-left: 0;
        }
    }
    &__title{
        width: 100%;
        text-align: center;
        @include font-100-5;
        margin-top: clampFluid(-20);
        &.only_title{
            @include font-150;
            font-family: "Hoves Regular";
            margin-top: clampFluid(-3);
            margin-bottom: clampFluid(51);
            @include _1279{
                width: 100%;
                text-align: center;
                font-size: 58px;
                line-height: 58px;
                margin-top: 13px;
            }
            @include _1023{
                font-size: clampFluid(30,58,320,1023);
                line-height: clampFluid(30,58,320,1023);
                margin-top: clampFluid(10,13,320,1023);
                margin-bottom: 0;
            }
        }
        @include _1279{
            text-align: start;
            margin-top: 2px;
        }
        @include _1023{
            font-size: 30px;
            line-height: 30px;
            text-align: center;
            text-wrap: balance;
        }
    }
    form{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: clampFluid(28) clampFluid(24);
        max-width: clampFluid(1312.5);
        margin: clampFluid(33) auto 0;
        input{
            background: rgba(255,255,255,0.1);
            border-color: rgba(255,255,255,0.2);
            @include ph('') {
                color: rgba(255,255,255,0.5);
            }
        }
        .name{
            grid-column: 1/2;
        }
        .phone{
            grid-column: 2/3;
            @include _1023{
                grid-column: auto;
            }
        }
        .site_url{
            grid-column: 3/4;
            @include _1279{
                grid-column: 1/2;
                grid-row: 2/3;
            }
            @include _1023{
                grid-column: auto;
                grid-row: auto;
            }
        }
        .btn{
            grid-row: 2/3;
            grid-column: 2/3;
            @include _1279{
                grid-row: 3/4;
                grid-column: 1/3;
                width: max-content;
                margin: 16px auto 0;
            }
            @include _1023{
                grid-column: auto;
                grid-row: auto;
            }
            @include _425{
                width: 100%;
            }
        }
        .personal{
            color: rgba(255,255,255,0.5);
            grid-row: 2/3;
            grid-column: 3/4;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: clampFluid(14);
            a{
                color: rgba(255,255,255,0.5);
                @include _1279{
                    margin-left: 10px;
                }
                @include _1023{
                    margin-left: 0;
                }
            }
            @include _1279{
                grid-row: 4/5;
                grid-column: 1/3;
                flex-direction: row;
                margin-top: 50px;
            }
            @include _1023{
                grid-column: auto;
                grid-row: auto;
                flex-direction: column;
                margin-top: clampFluid(9,50,320,1023);
                text-align: center;
            }
        }
        @include _1279{
            max-width: 100%;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 36px;
            gap: 16px clampFluid(24);
        }
        @include _1023{
            grid-template-columns: 100%;
            margin-top: clampFluid(20,36,320,1023);
            gap: clampFluid(8,16,320,1023);
        }
    }
    &.mt {
        margin-top: clampFluid(-50);
        padding-top: clampFluid(105);
        z-index: 0;
    }
    @include _1279{
        padding-top: 47px;
        padding-bottom: 45px;
    }
    @include _1023{
        padding-top: clampFluid(20,47,320,1023);
        padding-bottom: clampFluid(34,45,320,1023);
    }
}