.faq{
    padding-top: clampFluid(60);
    padding-bottom: clampFluid(95);
    background: var(--bgc_color);
    &__title{
        width: 100%;
        text-align: center;
        max-width: clampFluid(1000);
        margin-left: auto;
        margin-right: auto;
        @include font-60;
        @include _1023{
            font-size: clampFluid(17,40,320,1023);
            line-height: clampFluid(18.7,44,320,1023);
        }
    }
    .collapse__items{
        max-width: clampFluid(1200);
        margin-left: auto;
        margin-right: auto;
        margin-top: clampFluid(21);
        @include _1279{
            max-width: 100%;
            margin-top: 22px;
        }
        @include _1023{
            margin-top: clampFluid(11,22,320,1023);
        }
    }
    @include _1279{
        padding-top: 68px;
        padding-bottom: 126px;
    }
    @include _1023{
        padding-top: clampFluid(31,68,320,1023);
        padding-bottom: clampFluid(40,126,320,1023);
    }
}
