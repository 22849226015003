.overflow {
  &-hidden {
    overflow: hidden;
  }
  &-visible {
    overflow: visible;
  }
}

.bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// .collapse {
//   overflow: hidden;
//   transition: height var(--collapse-time, #{$tr-time * 2});
//   &.is-collapsed {
//     height: 0;
//   }
// }

.blur {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  transition-duration: $transition;
  transition-behavior: allow-discrete;
  display: none;
  opacity: 0;
  &.active{
    opacity: 1;
    display: block;
    @starting-style {
      opacity: 0;
    }
  }
}
.global_blur {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  transition-duration: $transition;
  transition-behavior: allow-discrete;
  display: none;
  opacity: 0;
  &.htmx-request{
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @starting-style {
      opacity: 0;
    }
  }
}

.btn_right_container{
  position: fixed;
  top: 46.5%;
  right: 0;
  z-index: 2;
  transition: all $transition ease;
  height: auto;
  // background: transparent;
  background: #37424D99;
  border-radius: clampFluid(30) 0 0 clampFluid(30);
  border: 2px solid $green;
  border-right: none;
  box-shadow: 0px 16px 49.3px -6px #C0FF008A;
  padding-top: clampFluid(31.5);
  padding-right: clampFluid(14);
  padding-bottom: clampFluid(26);
  padding-left: clampFluid(10.5);
  span{
    @include font-21;
    color: $green;
    transition: all $transition ease;
    writing-mode: vertical-lr;
    rotate: 180deg;
  }
  @include hover{
    background: $green;
    border-color: #70788133;
    span{
      color: $bgc;
    }
  }
  @include _1279{
    display: none;
  }
}

.link-cover {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.btn{
  $box_shadow: 0px 16.000001907348633px 49.29999542236328px -6px #C0FF008A;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding: clampFluid(12) clampFluid(45) clampFluid(16) clampFluid(45);
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  background: $gradient;
  border: 1px solid $green;
  border-radius: clampFluid(51);
  user-select: none;
  transition: all $transition ease;
  span{
    @include font-36;
    color: $bgc;
    position: relative;
    z-index: 1;
    text-shadow: 1.0000001192092896px 1.0000001192092896px 0px 0px #7AC301;
  }
  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $green;
    opacity: 0;
    transition: opacity $transition ease;
    border-radius: clampFluid(51);
    border: 1px solid $green;
  }
  &--box-shadow{
    box-shadow: $box_shadow;
  }
  &--hover-box-shadow{
    transition: box-shadow $transition ease;
    &:not(.disabled){
      @include hover{
        box-shadow: $box_shadow;
      }
    }
  }
  &--green-boder{
    border: 2px solid $green;
    background: transparent;
    transition: all $transition ease;
    span{
      transition: all $transition ease;
      color: $green;
      text-shadow: none;
    }
    @include hover{
      background: $green;
      box-shadow: $box_shadow;
      span{
        color: $bgc;
      }
    }
    &.not-box-shadow {
      box-shadow: none;
    }
  }
  &--small {
    padding: clampFluid(7) clampFluid(30) clampFluid(12);
    span {
      @include font-24;
      color: $bgc;
    }
  }
  &--gray-border {
    border: 2px solid $text;
    background: transparent;
    transition: all $transition ease;
    span{
      transition: all $transition ease;
      color: $text;
      text-shadow: none;
    }
    @include hover {
      border-color: $green;
      background: transparent;
      span{
        color: $green;
      }
    }
    &::after{
      opacity: 0 !important;
    }
  }
  &.disabled{
    user-select: none;
    pointer-events: none;
    cursor: auto;
    opacity: 0.6;
  }
  &:not(.disabled){
    @include hover{
      &::after{
        opacity: 1;
      }
    } 
  }
  .preloader-icon{
    display: none;
    position: absolute;
    top: clampFluid(10);
    width: clampFluid(50);
    height: clampFluid(50);
  }
  &.htmx-request{
    user-select: none;
    cursor: auto;
    span{
      color: transparent;
    }
    .preloader-icon{
      display: block;
    }
    &::after{
      opacity: 0 !important;
    }
  }
  @include _1279{
    padding: 15.39px 50px 20.61px 50px;
    border-radius: 40px;
  }
  @include _1023{
    padding: clampFluid(6,15.39,320,1023) clampFluid(30,50,320,1023) clampFluid(10,20.61,320,1023) clampFluid(30,50,320,1023);
    border-radius: clampFluid(34,40,320,1023);
  }
}
.input{
  border-radius: 51px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  @include font-36;
  color: rgba(255, 255, 255, 0.5);
  padding: clampFluid(12) clampFluid(45) clampFluid(18);
  &.error{
    border-color: rgba(255, 0, 0, 0.5);
  }
  &.bgc {
    background: $tone2;
  }
  @include _1279{
    padding: 13px 30px 20px;
  }
  @include _1023{
    padding: clampFluid(4,13,320,1023) clampFluid(24,30,320,1023) clampFluid(9,20,320,1023);
  }
}
.textarea{
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  @include font-24;
  color: rgba(255, 255, 255, 0.5);
  background: $tone;
  resize: none;
  padding: 7px 41px;
}

.chechbox_custom, .radio_custom, .button_kp {
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked + div {
    color: $green;
    border-color: $green;
    span::before {
      border-color: $green;
      background: $green;
    }
  }
  div {
    width: fit-content;
    border: 2px solid $line;
    border-radius: clampFluid(34);
    padding: clampFluid(8) clampFluid(42);
    transition: all $transition ease;
    @include font-24;
    color: $text;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-height: clampFluid(56);
    @include hover {
      color: $green;
      border-color: $green;
    }
    @include _1023 {
      max-height: fit-content;
      padding: 6px 19px;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}
.chechbox_custom {
  div {
    span {
      position: relative;
      margin-left: clampFluid(30);
      &::before {
        content: "";
        position: absolute;
        top: clampFluid(7);
        left: clampFluid(-32);
        width: clampFluid(22);
        height: clampFluid(22);
        border-radius: 50%;
        border: 2px solid $line;
        transition: all $transition ease;
      }
    }
    @include hover {
      span::before {
        border-color: $green;
      }
    }
  }
}
.radio_custom {
  &--small-color {
    input:checked + div {
      color: $white;
      border-color: var(--color);
      background: var(--color);
      span::before {
        border-color: $green;
        background: var(--color);
      }
    }
    div {
      padding: 5px 20px 7px;
      @include font-18;
      @include hover {
        color: $white;
        border-color: var(--color);
        background: var(--color);
        box-shadow: 0px 16px 49.3px -6px var(--color);
      }
    }
  }
  &--gray-bgc-color {
    div {
      background: $line;
      border-color: $line;
    }
  }
  &--small {
    div {
      padding: 5px 21px 4px;
      @include font-18;
      color: $text;
    }
  }
  &--white {
    input:checked + div {
      color: $bgc;
      border-color: #C0FF00;
      // border-width: 1px;
      background: $gradient;
      // span::before {
      //   border-color: $green;
      //   background: $green;
      // }
    }
    div {
      border-color: $white05;
      color: $white;
      span {
      }
    }
  }
}

.yandex_rews {
  width:clampFluid(560);
  height:clampFluid(800);
  overflow:hidden;
  position:relative;
  iframe {
    width:100%;
    height:100%;
    border:1px solid #e6e6e6;
    border-radius:8px;
    box-sizing:border-box;
    user-select: none;
  }
  a {
    box-sizing:border-box;
    text-decoration:none;
    color:#b3b3b3;
    font-size:10px;
    font-family:YS Text,sans-serif;
    padding:0 20px;
    position:absolute;
    bottom:8px;
    width:100%;
    text-align:center;
    left:0;
    overflow:hidden;
    text-overflow:ellipsis;
    display:block;
    max-height:14px;
    white-space:nowrap;
    padding:0 16px;
    box-sizing:border-box;
  }
  @include _1279 {
    width: 100%;
    height: 800px;
  }
}

.google_rews {
  background: #FFF;
  width: clampFluid(560);
  padding: clampFluid(20);
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .button {
      border-radius: 8px;
      background-color: #9bbf2e;
      padding: clampFluid(15);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      max-width: 100%;
      position: relative;
      border: none;
      span {
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        position: relative;
        z-index: 1;
      }
    }
  }
  &__title {
    display: flex;
    span {
      @include font-24;
      color: $bgc;
    }
  }
  &__reviews {
    font-weight: 500;
    margin-left: clampFluid(10);
  }
  &__rating {
    display: flex;
    align-items: center;
    .avg {
      @include font-24;
      font-weight: 600;
      color: $bgc;
      margin-right: clampFluid(10);
    }
    .stars {
      display: flex;
      align-items: center;
      svg {
        width: clampFluid(16);
        height: clampFluid(16);
        stroke: $text;
        fill: $text;
        &.active {
          stroke: #ffcc00;
          fill: #ffcc00;
        }
      }
    }
    .count {
      font-size: 14px;
      color: $text;
      margin-left: clampFluid(5);
    }
  }
  &__items {
    padding-top: clampFluid(25);
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: clampFluid(30);
    grid-column-gap: clampFluid(30);
    max-height: clampFluid(686);
    overflow: auto;
  }
  &__item {
    display: flex;
    .avtor {
      width: clampFluid(50);
      height: clampFluid(50);
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: clampFluid(15);
      flex: 0 0 auto;
      border: 1px solid $text;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .rating {
      display: flex;
      align-items: center;
    }
  }
  &__name {
    @include font-18;
    color: $bgc;
  }
  &__date {
    margin-left: clampFluid(5);
    color: $text;
    font-size: 16px;
  }
  &__stars {
    display: flex;
    align-items: center;
    svg {
      width: clampFluid(16);
      height: clampFluid(16);
      stroke: $text;
      fill: $text;
      &.active {
        stroke: #ffcc00;
        fill: #ffcc00;
      }
    }
  }
  &__rew {
    font-size: 16px;
    padding: clampFluid(15) 0;
  }
  @include _1279 {
    width: 100%;
  }
}

.collapse {
  &__items {
    display: flex;
    flex-direction: column;
  }
  &__name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include font-25-5;
    padding: clampFluid(21) 0 clampFluid(25);
    border-bottom: 1px solid $white03;
    transition: color $transition ease;
    @include hover {
        color: $green;
    }
    svg {
        width: clampFluid(22);
        height: clampFluid(22);
        stroke: $green;
        stroke-width: 2px;
        flex: 0 0 auto;
        @include _1279 {
            width: 26px;
            height: 26px;
        }
        @include _1023 {
            width: clampFluid(13,26,320,1023);
            height: clampFluid(13,26,320,1023);
        }
    }
    &.active {
        border-bottom: none;
        svg {
            rotate: 270deg;
        }
    }
    @include _1279 {
        padding: 21px 0;
    }
    @include _1023 {
        font-size: clampFluid(14,17,320,1023);
        line-height: clampFluid(16.8,18.7,320,1023);
        padding-top: clampFluid(12,21,320,1023);
        padding-bottom: clampFluid(14,21,320,1023);
        align-items: flex-start;
    }
  }
  &__content {
    @include font-21;
    color: $white05;
    p {
        width: 90%;
        @include _1023 {
            width: 100%;
            padding-left: 21px;
        }
    }
    ul {
      padding-left: clampFluid(25);
      li {
        position: relative;
        list-style-type: disc;
        &:not(:last-child) {
          margin-bottom: clampFluid(16);
        }
        &::marker {
          color: #C0FF00;
        }
      }
    }
  }
}
.obmanka{
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.personal{
  @include font-18;
  color: $text;
  a{
    @include font-18;
    color: $text;
    text-decoration: underline;
    transition: all $transition ease;
    @include hover{
      text-decoration: none;
      color: $green !important;
    }
    @include _1279{
      font-size: 22px;
      line-height: 24.2px;
    }
    @include _1023{
      font-size: clampFluid(12,22,320,1023);
      line-height: clampFluid(14.67,24.2,320,1023);
    }
  }
  @include _1279{
    font-size: 22px;
    line-height: 24.2px;
  }
  @include _1023{
    font-size: clampFluid(12,22,320,1023);
    line-height: clampFluid(14.67,24.2,320,1023);
    text-wrap: balance;
  }
}
.background_line{
  background: repeating-linear-gradient(
    90deg, 
    #00000000, 
    #00000000 8px, 
    #05080C 11px
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  // https://moonback.ru/page/stripes-css
  &.last{
    background: 
    repeating-linear-gradient(
      90deg, 
      rgba(0, 0, 0, 0), 
      rgba(0, 0, 0, 0) 8px, 
      #05080C 11px), 
    linear-gradient(
      180deg, 
      #121B24 62%, 
      #212D37 100%
    );
  }
  &.blur_top {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100px;
      background: linear-gradient(360deg, rgba(18, 27, 36, 0) 0%, rgba(18, 27, 36, 0.4234068627) 37%, rgba(18, 27, 36, 0.8715861345) 60%, rgb(18, 27, 36) 100%);
    }
  }
}
.hashtag {
  @include font-25-5;
  // color: $text;
  color: $white05;
  @include _1279 {
    font-size: 24px;
    line-height: 26.4px;
  }
  @include _1023 {
    font-size: clampFluid(12,24,320,1023);
    line-height: clampFluid(13.2,26.4,320,1023);
  }
}
.hide_section{
  opacity: 0;
  transition: all $transition ease;
  &.visible{
    opacity: 1;
  }
}
.container {
  // margin-left: auto;
  // margin-right: auto;
  // width: 100%;
  // max-width: clampFluid(1725);
  // @include _1279{
  //   max-width: 100%;
  //   padding: 0 56px;
  // }
  // @include _1023{
  //   padding: 0 clampFluid(18,56,320,1023);
  // }

  @include container;
}
.container_1920{
  max-width: 1920px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  &.mt {
    margin-top: -50px;
    position: relative;
    z-index: 0;
  }
}
.case_container {
  @include case_container;
}

.link {
  &:hover {
    text-decoration: underline;
  }
}
.hover{
  &:hover{
    color: $green !important;
  }
}
[x-cloak] { display: none !important; }

.h1 {
  @include h1;
}

.h2 {
  @include h2;
}

.h3 {
  @include h3;
}

.text1 {
  @include text1;
}

.text2 {
  @include text2;
}

// .caption {
//   @include caption;
// }

.font-inter {
  font-family: "Inter", sans-serif;
}

.darkgreen-color {
  color: $dark-green;
}

.lightgreen-color {
  color: $light-green;
}

.orange-color {
  color: $orange;
}
.breadcrumbs{
  position: absolute;
  bottom: clampFluid(-51);
  width: 100%;
  overflow: hidden;
  ul{
    margin-left: clampFluid(3);
    display: flex;
    align-items: center;
    li{
      white-space: nowrap;
      a{
        @include font-18;
        // font-family: "Inter", sans-serif;
        transition: all $transition ease;
        @include hover{
          color: $green;
        }
        &.active {
          color: $green;
        }
      }
      .name {
        @include font-18;
        // font-family: "Inter", sans-serif;
        color: $text;
        white-space: nowrap;
      }
    }
  }
  .dash{
    color: $green;
    margin-left: clampFluid(5);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.icon-svg {
  display: inline-block;
  width: 1em;
  aspect-ratio: 1;
  // height: 1em;
  -webkit-mask-image: var(--svg);
  mask-size: 100% 100%;
  mask-image: var(--svg);
  mask-repeat: no-repeat;
  background-color: currentColor;
  vertical-align: middle;
}

.pc-svg {
  width: clampFluid(76.5);
  --svg: url("data:image/svg+xml,%3Csvg width='77' height='77' viewBox='0 0 77 77' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='38.2485' cy='38.2485' r='37.2485' fill='%23707881' fill-opacity='0.2' stroke='%23C0FF00' stroke-width='2'/%3E%3Cg clip-path='url(%23clip0_244_13855)'%3E%3Cpath d='M55.3639 22.668H21.1407C19.6398 22.668 18.418 23.8788 18.418 25.366V43.9757C18.418 45.4629 19.6398 46.6737 21.1407 46.6737H36.8909V48.6194H36.8768C34.4613 48.6194 32.4978 50.565 32.4978 52.9586V53.8334H44.0103V52.9586C44.0103 50.565 42.0468 48.6194 39.6313 48.6194H39.6172V46.6737H55.3674C56.8683 46.6737 58.0902 45.4629 58.0902 43.9757V25.366C58.0902 23.8788 56.8683 22.668 55.3674 22.668H55.3639ZM39.6278 50.3691C40.7614 50.3691 41.7255 51.0864 42.0892 52.0838H34.4154C34.7791 51.0829 35.7432 50.3691 36.8768 50.3691H39.6278ZM56.3209 43.9792C56.3209 44.5006 55.8936 44.9275 55.3639 44.9275H21.1407C20.6145 44.9275 20.1837 44.5041 20.1837 43.9792V25.366C20.1837 24.8446 20.611 24.4177 21.1407 24.4177H55.3639C55.8901 24.4177 56.3209 24.8411 56.3209 25.366V43.9757V43.9792Z' fill='%23C0FF00'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_244_13855'%3E%3Crect width='39.6651' height='31.1655' fill='white' transform='translate(18.4141 22.668)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}