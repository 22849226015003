.the_best_competition{
    padding-top: clampFluid(58);
    position: relative;
    padding-bottom: clampFluid(63.5);
    &__hashtag{
        margin-left: clampFluid(65);
        @include _1279{
            margin-left: 25px;
        }
        @include _1023{
            margin-left: clampFluid(1,25,320,1023);
        }
    }
    &__title{
        @include font-100-5;
        max-width: clampFluid(1060);
        margin-top: clampFluid(-21);
        span {
            &:first-child{
                font-style: italic;
            }
            &:last-child{
                margin-left: clampFluid(-23);
                margin-top: clampFluid(-37);
                display: block;
                @include _1279{
                    margin-left: 0;
                    margin-top: 0;
                }
            }
        }
        @include _1279{
            margin-top: 3px;
        }
        @include _1023{
            font-size: 30px;
            line-height: 30px;
            max-width: clampFluid(190,709,320,1023);
        }
        @include _425{
            display: flex;
            flex-direction: column;
        }
    }
    &__img{
        position: absolute;
        top: clampFluid(83);
        right: clampFluid(100);
        width: clampFluid(497);
        height: clampFluid(715);
        @include _1279{
            width: 193px;
            height: 281px;
            top: 0;
            right: 57px;
        }
        @include _1023{
            width: clampFluid(113,193,320,1023);
            height: clampFluid(163,281,320,1023);
            top: clampFluid(24,0,320,1023);
            right: clampFluid(21,57,320,1023);
        }
    }
    &__items{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: clampFluid(30) clampFluid(21);
        max-width: 82%;
        margin-top: clampFluid(11);
        @include _1279{
            grid-template-columns: repeat(2, 1fr);
            max-width: 100%;
            gap: 9px 36px;
            margin-top: 17px;
        }
        @include _1023{
            gap: 11px 7px;
        }
    }
    &__item{
        padding-top: clampFluid(44);
        position: relative;
        z-index: 1;
        .name{
            @include font-60;
            font-style: italic;
            @include _1279{
                line-height: 36px;
            }
            @include _1023{
                line-height: clampFluid(18.06,36,320,1023);
            }
        }
        .text{
            @include font-21;
            padding-left: clampFluid(15);
            padding-right: clampFluid(35);
            margin-top: clampFluid(11);
            @include _1279{
                line-height: 28.8px;
                margin-top: 11px;
                padding-right: 65px;
            }
            @include _1023{
                font-size: clampFluid(13,24,320,1023);
                line-height: clampFluid(15.6,28.8,320,1023);
                padding-right: clampFluid(5,65,320,1023);
                padding-left: 0;
            }
        }
        img{
            height: clampFluid(42);
            margin-left: clampFluid(15);
            margin-top: clampFluid(23);
            @include _1279{
                height: 36px;
                margin-top: 5px;
            }
            @include _1023{
                height: clampFluid(18,36,320,1023);
                margin-left: clampFluid(0,10,320,1023);
                margin-top: clampFluid(3,5,320,1023);
            }
        }
        svg{
            width: clampFluid(134);
            height: clampFluid(117);
            fill: $tone2;
            position: absolute;
            z-index: -1;
            top: clampFluid(23);
            left: clampFluid(-3);
            @include _1279{
                width: 129px;
                height: 113px;
                top: 14px;
                left: 4px;
            }
            @include _1023{
                width: 61px;
                height: 54px;
                top: clampFluid(-4,14,320,1023);
            }
        }
        &:first-child{
            svg{
                left: clampFluid(-23);
                @include _1279{
                    left: 4px;
                }
            }
        }
        @include _1279{
            padding-top: 40px;
            padding-left: 25px;
        }
        @include _1023{
            padding-left: clampFluid(0,25,320,1023);
            padding-right: 0;
            padding-top: clampFluid(21,40,320,1023);
        }
    }
    &__btn{
        width: 100%;
        text-align: center;
        margin-top: clampFluid(80);
        @include _1279{
            margin-top: 40px;
        }
        @include _425{
            display: none;
        }
    }
    .background_line{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        &.last{
            border-radius: 0 0 clampFluid(60) clampFluid(60);
        }
    }
    @include _1279{
        padding-top: 65px;
    }
    @include _1023{
        padding-top: clampFluid(39,65,320,1023);
    }
}