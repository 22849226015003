.portfolio {
    padding-top: clampFluid(96);
    padding-bottom: clampFluid(90);
    h1 {
        @include font-100-5;
        margin-bottom: clampFluid(16);
    }
    &__sections {
        display: flex;
        align-items: center;
        gap: clampFluid(9);
        flex-wrap: wrap;
    }
    &__section_item {
        padding: clampFluid(5) clampFluid(21) clampFluid(8);
        @include font-21;
        border-radius: clampFluid(34);
        transition: all $transition ease;
        background: $line;
        text-transform: lowercase;
        @include hover {
            color: $white;
            border-color: var(--color);
            background: var(--color);
            box-shadow: 0px 16px 49.3px -6px var(--color);
        }
        &.active {
            color: $white;
            border-color: var(--color);
            background: var(--color);
        }
    }
    &__sub_sections {
        margin-top: clampFluid(45);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        label {
            margin-bottom: clampFluid(5);
            span {
                text-transform: lowercase;
            }
        }
    }
    &__items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(clampFluid(550), 1fr));
        justify-items: stretch;
        justify-content: space-between;
        gap: clampFluid(50) clampFluid(35);
        margin-top: clampFluid(75);
        @include _1279 {
            grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        }
    }
    &__item {
        .name {
            @include font-25-5;
            margin-top: clampFluid(18);
            margin-left: auto;
            margin-right: auto;
            max-width: 97%;
            transition: all $transition ease;
        }
        .text {
            @include font-21;
            line-height: clampFluid(27.3);
            font-style: italic;
            color: $text;
            margin-left: auto;
            margin-right: auto;
            margin-top: clampFluid(8);
            max-width: 97%;
        }
        img {
            width: 100%;
            height: clampFluid(355);
            background: #9BAEF2;
            border-radius: clampFluid(20);
            object-fit: cover;
        }
        @include hover {
            .name {
                color: $green;
            }
        }
    }
}