.case_detail_page {
    .case_detail {
        padding-top: clampFluid(108);
        // padding-bottom: 100px;
        background: var(--case-bgc-color);
        &.bgc {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
        h1 {
            width: 100%;
            text-align: center;
            @include font-100;
            line-height: clampFluid(110);
            margin: 0 auto;
            text-wrap: balance;
            &.dark {
                color: $bgc;
            }
        }
        &__info_container {
            margin-top: clampFluid(42);
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            width: fit-content;
            flex-wrap: wrap;
            justify-content: center;
            row-gap: clampFluid(17);
            opacity: .7;
            .date {
                @include font-18;
                margin-right: clampFluid(40);
                &.dark {
                    color: $bgc07;
                }
                &.white {
                    color: $white07;
                }
            }
            .section, .sfera {
                @include font-18;
                padding: 5px 20px 7px;
                border: 1px solid $bgc;
                border-radius: 34.03px;
                text-transform: lowercase;
                &.dark {
                    color: $bgc07;
                    border-color: $bgc07;
                }
                &.white {
                    color: $white07;
                    border-color: $white07
                }
            }
            .site_link {
                @include font-18;
                margin-right: 40px;
                text-decoration: underline;
                &.dark {
                    color: $bgc07;
                }
                &.white {
                    color: $white07;
                }
            }
            @include _1023 {
                margin-top: clampFluid(18,28,320,1023);
            }
        }
        &__detail_img {
            width: 100%;
            height: auto;
            // height: 966px;
            // object-fit: cover;
            margin-top: clampFluid(56);
            @include _1023 {
                margin-top: clampFluid(27,37,320,1023);
            }
        }
        &__detail_text {
            @include font-25-5;
            font-size: clampFluid(25);
            line-height: clampFluid(30);
            padding-top: clampFluid(90);
            padding-bottom: clampFluid(90);
            &.dark {
                color: $bgc07;
            }
            &.white {
                color: $white07;
            }
            p {
                &:not(:last-child) {
                    margin-bottom: clampFluid(16);
                }
            }
            @include _1023 {
                padding-bottom: 50px;
                font-size: clampFluid(14,17,320,1023);
                line-height: clampFluid(16.8,18.7,320,1023);
            }
        }
        &__similar_case {
            padding-top: clampFluid(90);
            padding-bottom: clampFluid(90);
            &__title {
                @include font-60;
                width: 100%;
                text-align: center;
                margin-bottom: clampFluid(60);
                @include _1023 {
                    margin-bottom: 20px;
                    font-size: 30px;
                }
            }
            &__items {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(clampFluid(560), 1fr));
                gap: clampFluid(20);
                img {
                    width: 100%;
                    // height: clampFluid(357);
                    height: auto;
                    max-height: 357px;
                    object-fit: cover;
                }
                .name {
                    @include font-25-5;
                    margin-top: 18px;
                    max-width: 97%;
                    margin-left: auto;
                    margin-right: auto;
                }
                @include _1023 {
                    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
                }
            }
            @include _1023 {
                padding-top: 50px;
                padding-bottom: 50px;
            }
        }
        @include _1023 {
            padding-top: clampFluid(46,72,320,1023);
        }
    }
    .content_case {
        &.mt {
            margin-top: clampFluid(50);
            margin-bottom: clampFluid(50);
        }
    }
    .content-image {
        img {
            &.original_size {
                width: auto;
            }
        }
    }
    .content-video-text, .content-text, .content-image, .content-slider, .repeater-items {
        @include container;
        &.cont {
            @include case_container;
        }
    }
}
.share_case {
    background: $tone;
    padding-top: clampFluid(79);
    padding-bottom: clampFluid(89);
    &__container {
        display: flex;
        align-items: center;
        .left {
            @include font-60;
            width: 34%;
            @include _1023 {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 24px;
                text-align: center;
                font-size: 30px;
                line-height: 33px;
            }
        }
        .right {
            display: flex;
            align-items: center;
            margin-left: clampFluid(47);
            flex-wrap: wrap;
            .item {
                width: clampFluid(100);
                aspect-ratio: 1;
                border-radius: 50%;
                border: 1px solid $green;
                display: flex;
                align-items: center;
                justify-content: center;
                &:not(:last-child) {
                    margin-right: clampFluid(21);
                    @include _1023 {
                        margin-right: 0;
                    }
                }
                svg {
                    width: clampFluid(50);
                    aspect-ratio: 1;
                    fill: #D5DCE1;
                    @include _1023 {
                        width: 27.2px;
                    }
                }
                @include _1023 {
                    width: 54.39px;
                }
            }
            @include _1023 {
                margin-left: 0;
                gap: 20px 17.95px;
                justify-content: space-between;
            }
        }
        @include _1023 {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
    @include _1023 {
        padding-top: clampFluid(27,79,320,1023);
        padding-bottom: clampFluid(37,89,320,1023);
    }
}

.full_width_img {
    img {
        width: 100%;
        height: auto;
        &.pt {
            padding-top: clampFluid(26);
            @include _1279 {
                padding-top: clampFluid(5,17,320,1279);
            }
        }
        &.pb {
            padding-bottom: clampFluid(26);
            @include _1279 {
                padding-bottom: clampFluid(5,17,320,1279);
            }
        }
    }
}
.title_text_img {
    padding-top: clampFluid(90);
    &.pb {
        padding-bottom: clampFluid(90);
        @include _1023 {
            padding-bottom: 50px;
        }
    }
    .top {
        width: 49.1%;
        margin-left: auto;
        margin-right: auto;
        .title {
            @include font-36;
            line-height: clampFluid(39.6);
            font-style: italic;
            margin-bottom: clampFluid(18);
            &.center {
                text-align: center
            }
            &.dark {
                color: $bgc;
            }
            @include _1279 {
                line-height: clampFluid(15.64,31,320,1279);
            }
        }
        .text {
            @include font-21;
            line-height: clampFluid(25.2);
            color: $bgc07;
            &.center {
                text-align: center
            }
            &.white, &.gray {
                color: $white07;
            }
            p {
                &:not(:last-child) {
                    margin-bottom: clampFluid(26);
                }
            }
        }
        @include _1279 {
            width: 100%;
        }
    }
    img {
        width: 100%;
        height: auto;
        &.mt {
            margin-top: clampFluid(90);
        }
    }
    video {
        width: 100%;
        &.mt {
            margin-top: clampFluid(90);
            @include _1023 {
                margin-top: 50px;
            }
        }
    }
    @include _1023 {
        padding-top: 50px;
    }
}
.text_on_img {
    position: relative;
    &__text_container {
        position: absolute;
        top: clampFluid(41);
        width: 100%;
        .text {
            margin-left: auto;
            margin-right: auto;
            &.size-36 {
                @include font-36;
                line-height: clampFluid(39.6);
                @include _1279 {
                    line-height: clampFluid(15.64,31,320,1279);
                }
            }
            &.size-21 {
                @include font-21;
                line-height: clampFluid(25.2);
            }
            &.gray {
                color: $text;
            }
            &.white {
                color: #F2F2F6;
            }
            font-style: italic;
            text-align: center;
            width: 30%;
            @include _1279 {
                width: 75%;
            }
        }
        @include _1279 {
            top: clampFluid(10,27,320,1279);
        }
    }
    img {
        width: 100%;
        height: clampFluid(1330);
        object-fit: cover;
        @include _1279 {
            height: clampFluid(221.67,886.6,320,1279);
        }
    }
}
.only_text {
    padding-top: clampFluid(90);
    &.pb {
        padding-bottom: clampFluid(90);
        @include _1023 {
            padding-bottom: 50px;
        }
    }
    .text {
        font-family: 'Hoves Regular';
        font-weight: 400;
        font-size: clampFluid(25);
        line-height: clampFluid(30);
        color: $text;
        &.slim {
            width: 49.1%;
            margin-left: auto;
            margin-right: auto;
            @include _1279 {
                width: 100%;
            }
        }
        &.center {
            text-align: center;
        }
        &.div {
            text-wrap: balance;
        }
        &.white {
            color: $white07;
        }
        &.gray {
            color: $text;
        }
        p {
            &:not(:last-child) {
                margin-bottom: clampFluid(26);
            }
        }
        @include _1023 {
            font-size: clampFluid(14,16.6,320,1023);
            line-height: clampFluid(16.8,20,320,1023);
        }
    }
    @include _1023 {
        padding-top: 50px;
    }
}
.long_img {
    padding-top: clampFluid(90);
    &.pb {
        padding-bottom: clampFluid(90);
        @include _1023 {
            padding-bottom: 50px;
        }
    }
    img {
        width: 100%;
        height: auto;
    }
    @include _1023 {
        padding-top: 50px;
    }
}
.title_text {
    padding-top: clampFluid(90);
    &.pb {
        padding-bottom: clampFluid(90);
        @include _1023 {
            padding-bottom: 50px;
        }
    }
    .title {
        @include font-60;
        font-style: italic;
        margin-bottom: clampFluid(36);
        width: 100%;
        &.center {
            text-align: center;
        }
        &.font-36 {
            @include font-36;
        }
        @include _1279 {
            margin-bottom: clampFluid(18,24,320,1279);
        }
    }
    .text {
        @include font-21;
        line-height: clampFluid(27.3);
        color: $white07;
        p {
            &:not(:last-child) {
                margin-bottom: clampFluid(26);
            }
        }
    }
    @include _1023 {
        padding-top: 50px;
    }
}
.text_img {
    padding-top: clampFluid(88);
    &.pb {
        padding-bottom: clampFluid(90);
        @include _1023 {
            padding-bottom: 50px;
        }
    }
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.left {
            flex-direction: row;
            @include _1279 {
                flex-direction: column;
            }
        }
        &.right {
            flex-direction: row-reverse;
            @include _1279 {
                flex-direction: column-reverse;
            }
        }
        img {
            width: 49.2%;
            height: clampFluid(522);
            object-fit: cover;
            @include _1279 {
                width: 100%;
                height: clampFluid(261,348,320,1279);
            }
        }
        @include _1279 {
            gap: 20px;
        }
    }
    &__content {
        width: 45.7%;
        .title {
            @include font-36;
            line-height: clampFluid(39.6);
            font-style: italic;
            margin-bottom: clampFluid(18);
            @include _1279 {
                line-height: clampFluid(18.7,31,320,1279);
                margin-bottom: clampFluid(10,12,320,1279);
            }
        }
        .text {
            @include font-21;
            line-height: clampFluid(25.2);
            color: $text;
        }
        @include _1279 {
            width: 100%;
        }
    }
    @include _1023 {
        padding-top: 50px;
    }
}
.rews_text {
    padding-top: clampFluid(90);
    &.pb {
        padding-bottom: clampFluid(90);
        @include _1023 {
            padding-bottom: 50px;
        }
    }
    .title {
        @include font-36;
        line-height: clampFluid(39.6);
        font-style: italic;
        margin-bottom: clampFluid(28);
        @include _1279 {
            margin-bottom: clampFluid(16,18.8,320,1279);
        }
    }
    .text {
        @include font-21;
        line-height: clampFluid(27.3);
        color: $white;
        margin-bottom: clampFluid(28);
        p {
            &:not(:last-child) {
                margin-bottom: clampFluid(26);
            }
        }
    }
    .name {
        @include font-25-5;
        font-size: clampFluid(25);
        line-height: clampFluid(32.5);
        margin-bottom: clampFluid(8);
    }
    .owner {
        @include font-21;
        line-height: clampFluid(27.3);
        color: $white05;
    }
    @include _1023 {
        padding-top: 50px;
    }
}
.rews_video {
    padding-top: clampFluid(90);
    &.pb {
        padding-bottom: clampFluid(90);
        @include _1279 {
            padding-bottom: 50px;
        }
    }
    &__container {
        display: flex;
        justify-content: space-between;
        .left {
            display: flex;
            flex-direction: column;
            width: 45%;
            padding-top: clampFluid(18);
            padding-bottom: clampFluid(13);
            .top {
                .title {
                    @include font-36;
                    line-height: clampFluid(39.6);
                    font-style: italic;
                }
                .text {
                    @include font-21;
                    line-height: clampFluid(25.2);
                    max-height: clampFluid(183);
                    overflow-y: scroll;
                    padding-right: 10px;
                    &::-webkit-scrollbar{
                        width: 2px;
                    }
                    &::-webkit-scrollbar-track{
                        background: $text;
                    }
                    &::-webkit-scrollbar-thumb{
                        background: $green;
                    }
                    color: $text;
                    margin-top: clampFluid(30);
                    margin-bottom: clampFluid(30);
                    p {
                        &:not(:last-child) {
                            margin-bottom: clampFluid(26);
                        }
                    }
                    @include _1279 {
                        max-height: 100%;
                        overflow: visible;
                        margin-bottom: 16px;
                        margin-top: 16px;
                        padding-right: 0;
                    }
                }
            }
            .bottom {
                margin-top: auto;
                .name {
                    @include font-25-5;
                    font-size: clampFluid(25);
                    line-height: clampFluid(32.5);
                }
                .owner {
                    @include font-21;
                    line-height: clampFluid(27.3);
                    color: $white05;
                    margin-top: clampFluid(7);
                }
                &.not_text {
                    margin-left: auto;
                    margin-right: auto;
                    .name {
                        text-align: right;
                    }
                    @include _1279 {
                        margin-top: 16px;
                        margin-left: 0;
                        margin-right: 0;
                        .name {
                            text-align: left;
                        }
                    }
                }
            }
            &.not_text {
                padding-top: clampFluid(56);
                padding-bottom: clampFluid(70);
                @include _1279 {
                    padding: 0;
                }
            }
            @include _1279 {
                width: 100%;
                padding: 0;
            }
        }
        @include _1279 {
            flex-direction: column;
        }
    }
    &__video {
        width: 49%;
        height: auto;
        border-radius: 20px;
        overflow: hidden;
        margin: auto 0;
        aspect-ratio: 555 / 340;
        iframe {
            width: 100%;
            height: 100%;
        }
        @include _1279 {
            width: 100%;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;
        }
    }
    &__preview {
        position: relative;
        width: 100%;
        height: auto;
        aspect-ratio: 555 / 340;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            transition: all $transition ease;
        }
        .play {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            display: none;
            &__bgc {
                width: 50px;
                aspect-ratio: 1;
                background: transparent;
                border: 1px solid $green;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all $transition ease;
            }
            svg {
                fill: $green;
                width: 13px;
                height: 16px;
                transition: all $transition ease;
            }
        }
        @include hover {
        //     img {
        //         filter: blur(1px);
        //     }
        //     .play {
        //         &__bgc {
        //             background: $green;
        //         }
        //         svg {
        //             fill: $white;
        //         }
        //     }
        }
    }
    @include _1279 {
        padding-top: 50px;
    }
}
// .content-gallery {
    // display: grid;
    // gap: clampFluid(20);
    // &.cols-1 {
    //     grid-template-columns: repeat(1, 1fr);
    // }
    // &.cols-2 {
    //     grid-template-columns: repeat(2, 1fr);
    //     img {
    //         height: clampFluid(308);
    //     }
    // }
    // &.cols-3 {
    //     grid-template-columns: repeat(3, 1fr);
    // }
    // &.cols-4 {
    //     grid-template-columns: repeat(4, 1fr);
    // }
    // &-item {
    //     &__img {
    //         width: 100%;
    //     }
    //     &__caption {
    //         @include font-18;
    //         line-height: clampFluid(23.4);
    //         color: $text;
    //         margin-top: clampFluid(17);
    //     }
    // }
// }