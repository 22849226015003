.budget_formed {
    padding-top: clampFluid(84);
    padding-bottom: clampFluid(85);
    position: relative;
    z-index: 1;
    background: $tone;
    &__title {
        width: 100%;
        text-align: center;
        @include font-80;
        margin-bottom: clampFluid(35);
        @include _1023 {
            font-size: clampFluid(30,53.3,320,1023);
            line-height: clampFluid(30,53.3,320,1023);
        }
        @include _425 {
            text-align: left;
        }
    }
    &__subtitle {
        width: 100%;
        text-align: center;
        @include font-36;
        font-style: italic;
        margin-bottom: clampFluid(53);
        @include _1023 {
            font-size: clampFluid(20,36,320,1023);
            line-height: clampFluid(22,39.6,320,1023);
        }
        @include _425 {
            text-align: left;
        }
    }
    &__items {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: clampFluid(20) clampFluid(30);
        margin-bottom: clampFluid(43);
        .plus {
            @include font-40;
            color: $green;
            &:last-child {
                display: none;
            }
        }
        @include _425 {
            justify-content: flex-start;
        }
    }
    &__item {
        border: 1px solid $line;
        border-radius: clampFluid(34);
        padding: clampFluid(8) clampFluid(42) clampFluid(12);
        @include font-24;
        color: $text;
        width: fit-content;
        span {
            color: $green;
        }
        @include _1023 {
            padding-left: clampFluid(20,28,320,1023);
            padding-right: clampFluid(20,28,320,1023);
        }
        @include _767 {
            max-width: 85%;
            width: fit-content;
        }
    }
    .result {
        width: 100%;
        text-align: center;
        @include font-40;
        color: $green;
        margin-bottom: clampFluid(32);
    }
    &__results {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(clampFluid(549), 1fr));
        gap: 35px clampFluid(149);
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        .text {
            p {
                @include font-24;
                margin-bottom: clampFluid(18);
            }
            ul {
                padding-left: clampFluid(15);
                li {
                    list-style-type: disc;
                    &::marker{
                        color: $green;
                    }
                    @include font-24;
                    line-height: clampFluid(35.76);
                    color: $text;
                }
            }
        }
        @include _1279 {
            grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        }
        @include _767 {
            width: 100%;
        }
    }
    @include _1023 {
        padding-top: clampFluid(32,56,320,1023);
    }
}