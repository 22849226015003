.default-form-colors {
    --form-border-color: #fff;
    --form-text-color: #000;
    --form-input-placeholder-color: #c8c8c8;
    --form-personal-color: #000;
    --form-personal-link-color: #000;
    --form-status-icon-color: #fff;
    --form-status-scale: 1;
    --form-placeholder-color: #aaa;
    --form-error-color: #f00;
    --form-bg-color: #fff;
}