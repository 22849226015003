.page-head {
    position: sticky;
    top: 0;
    z-index: 10;
}
.header {
    display: flex;
    flex-direction: column;
    border: 1px solid $line;
    z-index: 10;
    position: relative;
    background: $bgc;
    &__top{
        .social{
            margin-right: clampFluid(28);
            display: flex;
            position: relative;
            &_link{
                display: flex;
                align-items: center;
                width: clampFluid(85,123);
                border-radius: clampFluid(10.5);
                border: 2px solid $green;
                padding: clampFluid(7) clampFluid(8) clampFluid(9) clampFluid(11);
                background: transparent;
                transition: all $transition ease;
                &:not(:last-child){
                    margin-right: clampFluid(11);
                }
                span{
                    font-family: 'Hoves Regular';
                    font-weight: 400;
                    color: $green;
                    font-size: clampFluid(12);
                    line-height: clampFluid(14);
                    transition: all $transition ease;
                }
                svg{
                    fill: $green;
                    width: clampFluid(24);
                    height: clampFluid(24);
                    margin-right: clampFluid(8);
                    transition: all $transition ease;
                }
                @include hover{
                    background: $green;
                    span{
                        color: $bgc;
                    }
                    svg{
                        fill: $bgc;
                    }
                }
            }
            &_text{
                position: absolute;
                bottom: clampFluid(-16);
                left: auto;
                right: auto;
                font-family: 'Hoves Regular';
                font-weight: 400;
                color: $text;
                font-size: clampFluid(12);
                line-height: clampFluid(14);
                width: 97%;
                text-align: center;
            }
            @include _1279{
                display: none;
            }
        }

        .phone{
            display: flex;
            flex-direction: column;
            margin-right: clampFluid(15,38);
            @include font-21;
            white-space: nowrap;
            &_number{
                cursor: pointer;
            }
            a{
                color: $white;
            }
            .btn_modal{
                color: $green;
                border-bottom: 2px solid $green;
                transition: all $transition ease;
                cursor: pointer;
                &:hover{
                    border-bottom-color: transparent;
                }
            }
            @include _1279{
                display: none;
            }
        }
        .city{
            margin-right: auto;
            margin-left: clampFluid(30);
            @include font-18;
            color: $text;
            span{
                color: $white;
                border-bottom: 1px dashed $white;
                transition: all $transition ease;
                cursor: pointer;
                white-space: nowrap;
                &:hover{
                    color: $green;
                    border-bottom-color: $green;
                }
            }
            @include _1279{
                display: none;
            }
        }
    }
    &_top_menu{
        display: flex;
        &__link{
            @include font-21;
            transition: all $transition ease;
            white-space: nowrap;
            &:not(:last-child){
                margin-right: clampFluid(43);
            }
        }
        @include _1279{
            display: none;
        }
    }
    &__bottom{
        background: $tone2;
        border-top: 1px solid $line;
        @include _1279{
            border: none;
        }
    }
    &_bottom_menu{
        display: flex;
        align-items: center;
        justify-content: space-between;
        >.item{
            @include font-25-5;
            padding-top: clampFluid(16);
            padding-bottom: clampFluid(18);
            &:first-child{
                padding-right: clampFluid(45);
                padding-left: clampFluid(100);
                background: $gradient;
                transition: box-shadow $transition ease;
                color: $bgc;
                &:hover{
                    background: $green;
                    box-shadow: 0px 16.000001907348633px 49.29999542236328px -6px #C0FF008A;
                }
            }
            &.parent{
                position: relative;
                .item{
                    display: flex;
                    align-items: center;
                    svg{
                        margin-left: clampFluid(8);
                        margin-top: clampFluid(3);
                        stroke: $green;
                        width: clampFluid(16);
                        height: clampFluid(15);
                    }
                }
                a{
                    @include font-25-5;
                }
                &:hover{
                    svg{
                        rotate: 270deg;
                        margin-top: clampFluid(8);
                    }
                    .submenu{
                        opacity: 1;
                        pointer-events: all;
                    }
                }
                .submenu{
                    opacity: 0;
                    pointer-events: none;
                    transition: all $transition ease;
                    position: absolute;
                    border-radius: clampFluid(30);
                    padding: clampFluid(34) clampFluid(46) clampFluid(40) clampFluid(46);
                    margin-top: clampFluid(19);
                    display: flex;
                    z-index: 1;
                    a{
                        white-space: nowrap;
                        transition: all $transition ease;
                        &:hover{
                            color: $green;
                        }
                    }
                    .right{
                        width: clampFluid(343);
                        .desc{
                            transition: all $transition ease;
                            @include font-18;
                            height: 100%;
                            opacity: 0.9;
                            // mix-blend-mode: difference;
                        }
                    }
                    .left{
                        border-right: 1px solid rgba(255, 255, 255, 0.2);
                        padding-right: clampFluid(43);
                        margin-right: clampFluid(43);
                    }
                    .item{
                        &:not(:last-child){
                            padding-bottom: clampFluid(17);
                        }
                    }
                }
            }
            &:nth-child(n+2) {
                .submenu{
                    left: clampFluid(-44);
                }
            }
            &:nth-child(n+5) {
                .submenu{
                    left: clampFluid(-470);
                    flex-direction: row-reverse;
                    .left{
                        border-right: none;
                        border-left: 1px solid rgba(255, 255, 255, 0.2);
                        padding-right: 0;
                        padding-left: clampFluid(43);
                        margin-right: 0;
                        margin-left: clampFluid(43);
                    }
                    .right{
                        .desc{
                            text-align: end;
                        }
                    }
                }
            }
            &:nth-child(8) {
                margin-right: clampFluid(100);
                .submenu{
                    left: -470px;
                }
            }
            &.more{
                position: relative;
                .item{
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    svg{
                        margin-left: clampFluid(8);
                        margin-top: clampFluid(3);
                        stroke: $green;
                        width: clampFluid(16);
                        height: clampFluid(15);
                    }
                }
                .menu_more{
                    position: absolute;
                    opacity: 0;
                    pointer-events: none;
                    transition: all $transition ease;
                    top: clampFluid(68);
                    right: 0;
                    z-index: 1;
                }
                &:hover{
                    .menu_more{
                        opacity: 1;
                        pointer-events: all;
                    }
                }
            }
        }
        @include _1279{
            display: none;
        }
    }
    .header_bottom_more_menu{
        display: flex;
        flex-direction: row-reverse;
        transition: all $transition ease;
        border-radius: clampFluid(30);
        padding: clampFluid(34) clampFluid(46) clampFluid(40) clampFluid(46);
        .right{
            padding-left: clampFluid(43);
            border-left: 1px solid rgba(255, 255, 255, 0.2);
            min-width: clampFluid(295);
        }
        .left{
            // padding: 0 clampFluid(43);
            border-left: 1px solid rgba(255, 255, 255, 0.2);
            margin-left: clampFluid(43);
            div{
                white-space: nowrap;
            }
        }
        .desc{
            text-align: end;
            width: clampFluid(345);
            transition: all $transition ease;
            @include font-18;
            height: 100%;
            opacity: 0.8;
        }
        .item{
            transition: all $transition ease;
            &.active, &:hover{
                color: $green;
            }
            &:not(:last-child){
                padding-bottom: clampFluid(17);
            }
        }
        .items{
            padding: 0 clampFluid(43);
        }
    }
    @include _1279{
        border: none;
        // background: transparent;
    }
}
.header__top, .mobile_head{
    display: flex;
    align-items: center;
    padding: clampFluid(13.5) 0;
    .logo{
        width: clampFluid(246);
        height: clampFluid(45);
        margin-right: clampFluid(22);
        margin-top: clampFluid(8);
        margin-bottom: clampFluid(8);
        @include _1279{
            width:  285.72px;
            height: 52.26px;
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 26px;
        }
        @include _1023{
            width: clampFluid(164,285.72,320,1023);
            height: clampFluid(30,52.26,320,1023);
            margin-right: clampFluid(17,26,320,1023);
        }
    }
    .name{
        @include font-18;
        padding-right: clampFluid(31);
        border-right: 1px solid $line;
        white-space: nowrap;
        @include _1279{
            border: none;
            padding-right: 0;
        }
    }
    @include _1279{
        padding-top: 29px;
        padding-bottom: 29px;
    }
    @include _1023{
        padding-top: clampFluid(14,29,320,1023);
        padding-bottom: clampFluid(14,29,320,1023);
    }
}
#mobile_head{
    border: 1px solid $line;
}
.mobile_header{
    display: none;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    z-index: 9;
    bottom: 0;
    left: 0;
    right: 0;
    // height: 101px;
    background: $tone2;
    border: 1px solid $line;
    padding: 19px 56px 18px 56px;
    &__phone{
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 14px;
        background: $gradient;
        svg{
            width: 38px;
            height: 38px;
            @include _1023{
                width: clampFluid(24,38,320,1023);
                height: clampFluid(24,38,320,1023);
            }
        }
        @include _1023{
            width: clampFluid(34,64,320,1023);
            height: clampFluid(34,64,320,1023);
            border-radius: clampFluid(7,14,320,1023);
        }
    }
    &__link{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $white;
        padding: 9.39px 38.5px 14.61px 38.5px;
        border-radius: 59.23px;
        font-size: 36px;
        line-height: 39.6px;
        color: $white;
        @include _1023{
            padding: clampFluid(8,9.39,320,1023) clampFluid(18,38.5,320,1023) clampFluid(11,14.61,320,1023) clampFluid(18,38.5,320,1023);
            font-size: clampFluid(14,36,320,1023);
            line-height: clampFluid(15.4,39.6,320,1023);
        }
    }
    &__burger{
        cursor: pointer;
        svg{
            width: 64px;
            height: 24px;
            stroke: $green;
            stroke-width: 3px;
            @include _1023{
                width: clampFluid(34,64,320,1023);
                height: clampFluid(10,24,320,1023);
                stroke-width: clampFluid(2,3,320,1023);
            }
        }
    }
    @include _1279{
        display: flex;
    }
    @include _1023{
        // height: clampFluid(62,101,320,1020);
        padding: clampFluid(13,19,320,1023) clampFluid(20,56,320,1023) clampFluid(15,18,320,1023) clampFluid(20,56,320,1023);
    }
}
.mobile_menu{
    width: 100%;
    height: 100dvh;
    background: $bgc;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    transform: translateX(100%);
    transition: all .4s ease;
    overflow: auto;
    padding-bottom: 27px;
    overflow: hidden;
    display: none;
    will-change: transform;
    &__container{
        width: 100%;
        height: 100%;
        overflow: auto;
    }
    &__city_container{
        width: 100%;
        padding: 5px 0 11px;
        border: 1px solid $line;
        border-top: none;
        background: $tone2;
        display: flex;
        align-items: center;
        font-size: 24px;
        line-height: 28.8px;
        color: $text;
        margin-bottom: 16px;
        span{
            cursor: pointer;
            color: $green;
            border-bottom: 1px dashed $green;
            margin-left: 5px;
        }
        @include _1023{
            font-size: clampFluid(17,24,320,1023);
            line-height: clampFluid(20.4,28.8,320,1023);
        }
    }
    &__items{
        margin-top: 26px;
        height: 100%;
        @include _1023{
            margin-top: clampFluid(15,26,320,1023);
        }
    }
    &__contacts_container{
        display: flex;
        align-items: flex-start;
        .left{
            display: flex;
            flex-direction: column;
            margin-right: 78px;
            .tel{
                font-size: 36px;
                line-height: 22.37px;
                color: $white;
                margin-bottom: 19.39px;
                @include _1023{
                    font-size: clampFluid(20,36,320,1023);
                    line-height: clampFluid(16,22.37,320,1023);
                    margin-bottom: clampFluid(17,19.39,320,1023);
                }
            }
            .email{
                font-size: 24px;
                line-height: 26.4px;
                color: $green;
                border-bottom: 2px solid $green;
                width: min-content;
                white-space: nowrap;
                @include _1023{
                    font-size: clampFluid(17,24,320,1023);
                    line-height: clampFluid(18.7,26.4,320,1023);
                }
            }
            @include _1023{
                margin-right: clampFluid(41,78,320,1023);
            }
        }
        .right{
            display: flex;
            align-items: center;
            .social{
                width: 64px;
                height: 64px;
                // background: $gradient;
                background: transparent;
                border-radius: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid $green;
                svg{
                    width: 42.67px;
                    height: 42.67px;
                    fill: $green;
                    @include _1023{
                        width: clampFluid(19.4,42.67,320,1023);
                        height: clampFluid(19.4,42.67,320,1023);
                    }
                }
                &:first-child{
                    margin-right: 25px;
                    @include _1023{
                        margin-right: clampFluid(18,25,320,1023);
                    }
                }
                @include _1023{
                    width: clampFluid(36,64,320,1023);
                    height: clampFluid(36,64,320,1023);
                    border-radius: clampFluid(10.5,18,320,1023);
                }
            }
        }
    }
    &__partners {
        margin-top: 58.61px;
        &__top {
            display: flex;
            align-items: center;
            @include _768{
                flex-wrap: wrap;
                margin-top: clampFluid(39,58.61,320,768);
            }
        }
        &__bottom {
            margin-bottom: 18px;
            span {
                @include font-18;
            }
        }
        a{
            margin-bottom: 18px;
            &:not(:first-child){
                img{
                    height: 48px;
                    @include _1023{
                        height: clampFluid(36,48,320,1023);
                    }
                }
            }
            &:not(:last-child){
                margin-right: 49px;
                @include _1023{
                    margin-right: clampFluid(18,49,320,1023);
                }
            }
            img{
                width: auto;
                height: 56px;
                object-fit: contain;
                border-radius: 6.17px;
                @include _1023{
                    height: clampFluid(42,56,320,1023);
                }
            }
        }
    }
    &__footer{
        span, a{
            font-size: 22px;
            line-height: 24.2px;
            color: $text;
            margin-bottom: 17px;
            display: inline-block;
            @include _1023{
                font-size: clampFluid(12,22,320,1023);
                line-height: clampFluid(13.2,24.2,320,1023);
                margin-bottom: clampFluid(13,17,320,1023);
            }
        }
        &__container{
            display: flex;
            justify-content: space-between;
            @include _1024{
                flex-direction: column;
            }
        }
    }
    .back{
        display: flex;
        align-items: center;
        font-size: 24px;
        line-height: 24px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
        width: min-content;
        svg{
            stroke: rgba(255, 255, 255, 0.6);
            stroke-width: 2px;
            width: 34px;
            height: 34px;
            margin-right: 7px;
            @include _1023{
                width: clampFluid(25,34,320,1023);
                height: clampFluid(25,34,320,1023);
            }
        }
        @include _1023{
            font-size: clampFluid(14,24,320,1023);
            line-height: clampFluid(14,24,320,1023);
        }
    }
    .mobile_menu__items{
        .mobile_main_menu{
            .mobile_item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 14px;
                border-bottom: 1px solid $line;
                margin-bottom: 15px;
                a{
                    font-size: 24px;
                    line-height: 26.4px;
                    color: $white;
                    @include _1023{
                        font-size: clampFluid(17,24,320,1023);
                        line-height: clampFluid(18.7,26.4,320,1023);
                    }
                }
                @include _1023{
                    margin-bottom: clampFluid(10,15,320,1023);
                    padding-bottom: clampFluid(10,14,320,1023);
                }
            }
            .open_submenu{
                svg{
                    width: 34px;
                    height: 34px;
                    stroke-width: 2px;
                    stroke: $green;
                    transform: rotate(180deg);
                    @include _1023{
                        width: clampFluid(25,34,320,1023);
                        height: clampFluid(25,34,320,1023);
                    }
                }
            }
        }
        .mobile_top_menu{
            display: flex;
            flex-direction: column;
            .top_menu{
                font-size: 24px;
                line-height: 24px;
                color: $white;
                width: min-content;
                white-space: nowrap;
                &:not(:last-child){
                    margin-bottom: 28px;
                    @include _1023{
                        margin-bottom: clampFluid(12,28,320,1023);
                    }
                }
                @include _1023{
                    font-size: clampFluid(14,24,320,1023);
                }
            }
        }
        .mobile_submenu{
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 12;
            transform: translateX(100%);
            transition: transform .4s ease;
            overflow: auto;
            will-change: transform;
            &.opened{
                transform: translateX(0%);
            }
            .back{
                margin-top: 16px;
                margin-bottom: 27px;
            }
            .sumenu_items{
                display: flex;
                flex-direction: column;
                .item{
                    padding-top: 16px;
                    color: $white;
                    font-size: 24px;
                    line-height: 26.4px;
                    &:not(:last-child){
                        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
                        padding-bottom: 20px;
                    }
                    @include _1023{
                        padding-top: clampFluid(10,16,320,1023);
                        padding-bottom: clampFluid(10,20,320,1023);
                        font-size: clampFluid(17,24,320,1023);
                        line-height: clampFluid(18.7,26.4,320,1023);
                    }
                }
            }
        }
        .mobile_city{
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 12;
            transform: translateX(100%);
            transition: transform .4s ease;
            overflow: auto;
            will-change: transform;
            background: $bgc;
            &.opened{
                transform: translateX(0%);
            }
            &__search{
                input{
                    max-width: 100%;
                    width: 100%;
                    text-align: center;
                    padding-top: 6px;
                }
                span{
                    display: block;
                    font-family: 'Hoves Regular';
                    font-weight: 400;
                    color: $white;
                    font-size: clampFluid(20,25,320,1279);
                    line-height: clampFluid(20,25,320,1279);
                    margin-bottom: 13px;
                }
            }
            &__items{
                columns: 3;
                column-gap: 56px;
                margin-top: 34px;
                padding-bottom: 20px;
                .item{
                    margin-bottom: 12px;
                    a{
                        font-family: 'Hoves Regular';
                        font-weight: 400;
                        color: $white;
                        font-size: clampFluid(14,20,320,1279);
                        line-height: clampFluid(15.4,21.4,320,1279);
                        transition: all $transition ease;
                        @include hover{
                            color: $green;
                        }
                    }
                    &.now_region{
                        a{
                            color: $green;
                        }
                    }
                }
                @include _768{
                    columns: 2;
                }
            }
            .back{
                margin-top: 16px;
                margin-bottom: 27px;
            }
        }
    }
    .btn{
        margin-top: 50px;
        margin-bottom: 64px;
        padding-top: 9.39px;
        padding-bottom: 14.61px;
        width: 100%;
        span{   
            @include font-36;
            color: $green;
        }
        @include _1023{
            margin-top: clampFluid(32,50,320,1023);
            margin-bottom: clampFluid(33,64,320,1023);
            padding-top: clampFluid(6,9.39,320,1023);
            padding-bottom: clampFluid(10,14.61,320,1023);
        }
    }
    @include _1279{
        display: block;
    }
}