.sitemap_page {
    padding-top: clampFluid(100);
    padding-bottom: clampFluid(100);
    h1 {
        @include font-100-5;
        margin-bottom: clampFluid(66);
        @include _1279 {
            font-size: clampFluid(30,58,320,1279);
            line-height: clampFluid(33,58,320,1279);
            margin-bottom: clampFluid(30,44,320,1279);
        }
    }
}