.blog {
    margin-top: clampFluid(96);
    padding-bottom: clampFluid(100);
    h1 {
        @include font-100-5;
        margin-bottom: clampFluid(66);
        @include _1279 {
            font-size: clampFluid(30,58,320,1279);
            line-height: clampFluid(33,58,320,1279);
            margin-bottom: clampFluid(30,44,320,1279);
        }
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(clampFluid(550), 1fr));
        gap: clampFluid(77) clampFluid(35);
        @include _1279 {
            grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
            row-gap: 30px;
        }
    }
    &__item {
        display: flex;
        flex-direction: column;
        &__img {
            // width: clampFluid(550);
            width: 100%;
            height: clampFluid(287);
            object-fit: cover;
            border-radius: clampFluid(20);
        }
        &__name {
            @include font-25-5;
            transition: all $transition ease;
            margin-left: clampFluid(7);
            @include hover {
                color: $green
            }
            @include _1023 {
                margin-left: clampFluid(0,5);
            }
        }
        &__text {
            @include font-21;
            font-family: 'Hoves Italic';
            color: $text;
            margin-left: clampFluid(7);
            margin-top: clampFluid(10);
            max-width: 95%;
            @include _1023 {
                margin-left: clampFluid(0,5);
                max-width: 100%;
                margin-top: 8px;
            }
        }
    }
    &__date {
        @include font-18;
        font-family: 'Hoves Italic';
        margin-top: clampFluid(18);
        margin-bottom: clampFluid(8);
        margin-left: clampFluid(7);
        @include _1023 {
            margin-left: clampFluid(0,5);
            margin-top: 15px;
            margin-bottom: 10px;
        }
    }
    &__page {
        margin-top: clampFluid(109);
        margin-bottom: clampFluid(90);
        h1 {
            @include font-100-5;
            line-height: clampFluid(110.55);
            max-width: 80%;
            margin-bottom: clampFluid(60);
            @include _1279 {
                font-size: clampFluid(30,58,320,1279);
                line-height: clampFluid(33,58,320,1279);
                margin-bottom: clampFluid(30,44,320,1279);
            }
        }
        &__info {
            display: flex;
            align-items: center;
            @include font-18;
            font-family: 'Hoves Italic';
            color: $text;
            gap: 0 clampFluid(67);
            margin-bottom: clampFluid(63);
            margin-left: clampFluid(16);
            @include _1279 {
                row-gap: clampFluid(4,10,320,1023);
            }
            @include _767 {
                flex-direction: column;
                margin-left: 0;
                align-items: flex-start;
                margin-bottom: clampFluid(16,42,320,767);
            }
        }
        &__content {
        //     margin-left: clampFluid(7);
        //     @include font-21;
        //     line-height: clampFluid(27.3);
        //     p {
        //         max-width: 88%;
        //         margin-bottom: clampFluid(25);
        //         // &:not(:last-child) {
        //         // }
        //     }
        //     // img {
        //     //     width: 100%;
        //     //     height: 100%;
        //     //     object-fit: contain;
        //     //     // margin: clampFluid(30) auto;
        //     //     margin-left: auto;
        //     //     margin-right: auto;
        //     // }
        //     ul {
        //         margin-bottom: clampFluid(25);
        //         padding-left: clampFluid(42);
        //         li {
        //             // &:first-child {
        //             //     padding-top: clampFluid(16);
        //             // }
        //             margin-bottom: clampFluid(16);
        //             position: relative;
        //             list-style-type: disc;
        //             &::marker{
        //                 color: $green;
        //             }
        //         }
        //     }
        //     ol {
        //         margin-bottom: clampFluid(25);
        //         padding-left: clampFluid(42);
        //         list-style: decimal;
        //         li {
        //             // &:first-child {
        //             //     padding-top: clampFluid(16);
        //             // }
        //             margin-bottom: clampFluid(16);
        //         }
        //     }
        //     a:not(.link-cover) {
        //         color: $green;
        //         text-decoration: underline;
        //         transition: all $transition ease;
        //         @include hover {
        //             text-decoration: none;
        //         }
        //         &:visited {
        //             color: #406AFF;
        //             text-decoration: none;
        //         }
        //     }
        //     h2 {
        //         @include font-60;
        //         margin-bottom: clampFluid(25);
        //     }
        //     h3 {
        //         @include font-36;
        //         margin-bottom: clampFluid(25);
        //     }
        //     h4 {
        //         @include font-25-5;
        //         margin-bottom: clampFluid(25);
        //         font-weight: 400;
        //     }
        //     .sp-image {
        //         img {
        //             width: auto;
        //             height: auto;
        //             max-width: 1920px;
        //             object-fit: contain;
        //             margin-left: auto;
        //             margin-right: auto;
        //             margin-top: clampFluid(50);
        //             margin-bottom: clampFluid(50);
        //             @include _1279 {
        //                 width: 100%;
        //             }
        //         }
        //     }
        //     .sp-block-table {

            // }
            .case_container {
                width: 100% !important;
                .content-gallery {
                    &.cols-2 {
                        img {
                            max-height: clampFluid(472);
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
        &__share_post {
            display: flex;
            align-items: center;
            @include font-25-5;
            margin-top: clampFluid(40);
            margin-bottom: clampFluid(90);
            .item {
                width: clampFluid(33);
                height: clampFluid(33);
                border-radius: clampFluid(10.5);
                background: $gradient;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                svg {
                    width: clampFluid(24);
                    height: clampFluid(24);
                    fill: $bgc;
                }
                &:not(:last-child) {
                    margin-right: clampFluid(25);
                }
                &:first-child {
                    margin-left: clampFluid(22);
                }
            }
        }
        @include _1023 {
            margin-top: clampFluid(50,72.6,320,1023);
        }
    }
}