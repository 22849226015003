.number_points {
    padding-top: clampFluid(60);
    padding-bottom: clampFluid(71);
    .hashtag {
        text-align: center;
        @include _1279 {
            margin-bottom: 9px;
        }
    }
    &__title {
        @include font-100-5;
        line-height: clampFluid(100.5);
        margin-bottom: clampFluid(82);
        text-align: center;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        text-wrap: balance;
        margin-top: clampFluid(-13);
        @include _1279 {
            margin-top: 0;
            margin-bottom: clampFluid(31,54,320,1279);
        }
        @include _425 {
            text-align: left;
            max-width: 100%;
        }
    }
    &__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: clampFluid(141);
        @include _1279 {
            grid-template-columns: 1fr;
            gap: clampFluid(27,94,320,1279);
        }
    }
    &__left {
        text-align: right;
        @include font-36;
        line-height: clampFluid(38.88);
        text-wrap: balance;
        @include _1279 {
            text-align: left;
            font-size: clampFluid(14,17,320,1279);
            line-height: clampFluid(14,31,320,1279);
        }
    }
    &__right {
        display: flex;
        flex-direction: column;
        gap: clampFluid(40);
        .btn {
            margin-top: clampFluid(77);
            width: fit-content;
            @include _1279 {
                margin-left: auto;
                margin-right: auto;
                margin-top: clampFluid(15,51,320,1279);
            }
            @include _425 {
                width: 100%;
            }
        }
    }
    &__point {
        display: flex;
        gap: clampFluid(42);
        align-items: center;
        .numb {
            @include font-100-5;
            line-height: clampFluid(101);
            @include _1023 {
                font-size: clampFluid(30,58,320,1023);
                line-height: clampFluid(30,58,320,1023);
            }
        }
        .text {
            @include font-36;
            line-height: clampFluid(38.88);
            text-wrap: balance;
            @include _1279 {
                font-size: clampFluid(14,17,320,1279);
                line-height: clampFluid(14,31,320,1279);
            }
        }
    }
}