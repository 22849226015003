.text_message {
    position: relative;
    .background_line {
        z-index: -1;
    }
    &.brb {
        border-radius: 0 0 clampFluid(60) clampFluid(60);
        overflow: hidden;
    }
    &__mess {
        position: relative;
        background: $line;
        padding: clampFluid(24) clampFluid(31) clampFluid(28) clampFluid(34);
        @include font-60;
        border-radius: clampFluid(20);
        width: fit-content;
        max-width: clampFluid(547);
        svg {
            position: absolute;
            bottom: clampFluid(-21);
            width: clampFluid(42);
            height: clampFluid(63);
            fill: $line;
        }
        &.mess1 {
            margin-right: auto;
            margin-bottom: clampFluid(40);
            svg {
                left: clampFluid(-7);
            }
        }
        &.mess2 {
            margin-left: auto;
            font-style: italic;
            svg {
                right: clampFluid(-7);
                transform: scaleX(-1);
            }
        }
        @include _1023 {
            font-size: clampFluid(30,40,320,1023);
            line-height: clampFluid(30,44,320,1023);
            padding-left: clampFluid(15,22,320,1023);
            padding-right: clampFluid(15,22,320,1023);
        }
    }
    &__left {
        width: 49%;
        padding-top: clampFluid(117);
        @include _1279 {
            width: 100%;
            padding-top: clampFluid(42,78,320,1279);
        }
    }
    &__right {
        display: flex;
        align-items: flex-end;
        img {
            width: 100%;
            height: clampFluid(1035);
            object-fit: contain;
            margin-right: clampFluid(30);
            @include _1279 {
                height: clampFluid(400,690,320,1279);
                margin-right: 0;
            }
        }
    }
    &__container {
        display: flex;
        justify-content: space-between;
        @include _1279 {
            flex-direction: column;
            
        }
    }
    &__items {
        margin-top: clampFluid(70);
        width: 90%;
        @include _1023 {
            width: 100%;
        }
    }
    &__item {
        display: flex;
        svg {
            flex: 0 0 auto;
            margin-left: clampFluid(-47);
            margin-top: clampFluid(-25);
            width: clampFluid(175);
            height: clampFluid(167);
            @include _1279 {
                width: clampFluid(80,116,320,1279);
                height: clampFluid(75,111,320,1279);
                margin-left: clampFluid(-25,-47,320,1279);
                margin-top: clampFluid(-15,-25,320,1279);
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            @include font-36;
            font-style: italic;
            margin-left: clampFluid(-31);
            span {
                @include font-21;
                color: $text;
                font-style: normal;
                margin-top: clampFluid(7);
                // @include _1279 {
                //     line-height: clampFluid(16.8,);
                // }
            }
            @include _1279 {
                line-height: clampFluid(18.7,31,320,1279);
            }
        }
        &:not(:last-child) {
            margin-bottom: clampFluid(13);
        }
    }
    &__text {
        @include font-21;
        color: $text;
        margin-top: 18px;
        padding-bottom: clampFluid(76);
        @include _1023 {
            padding-bottom: clampFluid(16,50,320,1023);
        }
    }
}