.reviews{
    position: relative;
    padding-top: clampFluid(57);
    overflow: hidden;
    &__top{
        display: flex;
        justify-content: space-between;
        .right{
            img{
                width: clampFluid(292.5);
                height: clampFluid(292.5);
                margin-top: clampFluid(21);
                @include _1279{
                    width: 195px;
                    height: 195px;
                }
                @include _1023{
                    display: none;
                }
            }
        }
    }
    &__bottom{
        margin-top: clampFluid(64);
        position: relative;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            &.img_mob {
                display: none;
            }
            @include _1279{
                // height: 410px;
                height: 505px;
            }
            @include _1023{
                height: clampFluid(361,500,320,1023);
                &.img_pc {
                    display: none;
                }
                &.img_mob {
                    display: block;
                    object-position: top;
                }
            }
        }
        .btn_container{
            position: absolute;
            z-index: 2;
            bottom: clampFluid(48);
            left: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            @include _1023{
                bottom: clampFluid(5,32,320,1023);
            }
        }
        @include _1279{
            margin: 55px -56px 0;
        }
        @include _1023{
            margin: clampFluid(15,55,320,1023) clampFluid(-20,-56,320,1023) 0;
        }
    }
    &__hashtag{
        margin-left: clampFluid(64);
        @include _1279{
            margin-left: 24px;
        }
        @include _1023{
            margin-left: clampFluid(0,24,320,1023);
        }
    }
    &__title1, &__title2, &__title3{
        margin-left: clampFluid(3);
        @include _1279{
            margin-left: 0;
        }
    }
    &__title1{
        @include font-100-5;
        margin-top: clampFluid(-19);
        span{
            color: $green;
            font-style: italic;
        }
        @include _1279{
            margin-top: 2px;
        }
        @include _1023{
            font-size: 30px;
            line-height: 30px;
            margin-top: 3px;
        }
    }
    &__title2{
        @include font-60;
        margin-top: clampFluid(-11);
        @include _1279{
            font-size: 36px;
            line-height: 39.6px;
        }
        @include _1279{
            margin-top: 12px;
        }
        @include _1023{
            font-size: 17px;
            line-height: 18.36px;
            margin-top: clampFluid(6,12,320,1023);
        }
    }
    &__title3{
        @include font-100-5;
        span{
            color: $green;
            font-style: italic;
        }
        @include _1279{
            margin-top: 13px;
        }
        @include _1023{
            font-size: 30px;
            line-height: 30px;
            margin-top: clampFluid(5,13,320,1023);
        }
    }
    &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: clampFluid(301.5);
        z-index: 1;
        background: linear-gradient(360deg, #121B24 0%, rgba(18, 27, 36, 0.87067) 37.03%, rgba(18, 27, 36, 0.428735) 72.94%, rgba(18, 27, 36, 0) 100%);
        @include _1279{
            height: 274px;
        }
        @include _1023{
            height: clampFluid(180,274,320,1023);
        }
    }
    @include _1279{
        padding-top: 67px;
        padding-bottom: 19px;
    }
    @include _1023{
        padding-top: clampFluid(30,67,320,1023);
        padding-bottom: 46px;
    }
}