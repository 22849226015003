$transition: .3s;

// Colors
$bgc: #121B24; // 0-1
$bgc07: rgb(18 27 36 / 70%);
// $bgc: #0e161d;
$tone: #1A252F; // 0-2
$tone2: #212D37; // 0-3
$line: #37424D; // 0-4
$text: #707881; // 0-5
// $text: rgba(255,255,255, 0.5); 
$green: #C0FF00; // 0-6
$gradient: linear-gradient(180deg, #C0FF00 0%, #78C100 100%);
$white: #F2F2F6; // 0-7
$white01: rgba(255,255,255, 0.1);
$white02: rgba(255,255,255, 0.2);
$white03: rgba(255,255,255, 0.3);
$white04: rgba(255,255,255, 0.4);
$white05: rgba(255,255,255, 0.5);
$white06: rgba(255,255,255, 0.6);
$white07: rgba(255,255,255, 0.7);

$tr-time: 0.4s;
$black: #333;
$dark-green: #275f40;
$light-green: #41bb4c;
$gray-green: #9db7a9;
$pale-green: #d9f1db;
$orange: #faa746;
$shadow: #333;