.content-slider {
    display: flex;
    justify-content: space-between;
    height: clampFluid(750);
    .swiper {
        margin: 0;
    }
    .left {
        width: 19.7%;
        display: flex;
        flex-direction: column;
        @include _1023 {
            width: 100%;
            // height: 41.52px;
            height: clampFluid(41.52,170.52,320,1023);
            flex-direction: row;
            align-items: center;
        }
    }
    .right {
        width: 77.3%;
        height: 100%;
        @include _1023 {
            width: 100%;
            // height: 156.96px;
            height: clampFluid(156.96,420.96,320,1023);
            margin-bottom: clampFluid(12,30,320,1023);
        }
    }
    .mySwiper2 {
        width: 100%;
        height: 100%;
    }
    .mySwiper {
        margin: clampFluid(27) 0;
        width: 100%;
        height: 100%;
        .swiper-slide {
            img {
                opacity: 0.6;
            }
            &.swiper-slide-thumb-active {
                img {
                    opacity: 1;
                }
            }
            @include hover {
                img {
                    opacity: 1;
                }
            }
        }
        @include _1023 {
            margin: 0 20px;
        }
    }
    .swiper-slide {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: clampFluid(20);
            transition: all $transition ease;
            @include _1023 {
                border-radius: 4px;
            }
        }
    }
    .prev, .next {
        width: fit-content;
        margin: 0 auto;
        cursor: pointer;
        svg {
            width: clampFluid(37);
            height: clampFluid(22);
            stroke: $white;
            stroke-width: 3px;
            transition: all $transition ease;
            @include hover {
                stroke: $green;
            }
            @include _1023 {
                height: 8.32px;
                width: 16px;
                stroke-width: 2px;
            }
        }
        &.swiper-button-disabled {
            pointer-events: none;
            svg {
                stroke: $white05;
            }
        }
        @include _1023 {
            margin-top: 0;
        }
    }
    .next {
        rotate: 180deg;
        @include _1023 {
            rotate: 90deg;
            // margin-top: 20px;
        }
    }
    .prev {
        @include _1023 {
            rotate: 270deg;
            // margin-bottom: 20px;
        }
    }
    @include _1023 {
        flex-direction: column-reverse;
        height: auto;
    }
}
