.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  input {
    display: none;
    &:checked + .checkbox {
      background: $dark-green;
      .checkbox-icon {
        opacity: 1;
      }
    }
  }
  .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: r(22);
    height: r(22);
    margin-right: r(20);
    border: 2px solid $dark-green;
    border-radius: 2px;
    &-icon {
      width: r(15);
      height: r(11);
      fill: #fff;
      opacity: 0;
    }
  }
  @media (max-width: 1279px) {
    .checkbox {
      width: r(18);
      height: r(18);
      margin-right: r(10);
    }
  }
}
