.niches {
    padding-bottom: clampFluid(198);
    position: relative;
    z-index: 1;
    &.mt {
        z-index: 0;
        margin-top: -50px;
    }
    &__head {
        padding-top: clampFluid(130);
        padding-bottom: clampFluid(54);
        background: var(--block-color);
        &.mt {
            padding-top: clampFluid(180);
            @include _1279 {
                padding-top: clampFluid(80,120,320,1279);
            }
        }
        @include _1279 {
            padding-top: clampFluid(46.6,86.6,320,1279);
            padding-bottom: clampFluid(22,36,320,1279);
        }
    }
    &__title {
        @include font-70;
        max-width: 50%;
        text-wrap: balance;
        @include _1023 {
            font-size: clampFluid(30,46.6,320,1023);
            line-height: clampFluid(30,46.6,320,1023);
        }
        @include _425 {
            max-width: 100%;
        }
    }
    &__items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(clampFluid(412.5), 1fr));
        gap: clampFluid(44.5) clampFluid(22.5);
        margin-top: clampFluid(-230);
        @include _1279 {
            grid-template-columns: repeat(auto-fill, minmax(clampFluid(280,350,320,1279), 1fr));
            margin-top: 28px;
            gap: 20px;
        }
    }
    &__item {
        display: flex;
        flex-direction: column;
        gap: clampFluid(13);
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: clampFluid(20);
        }
        .name {
            @include font-24;
        }
    }
    &__item1 {
        grid-column: 1/2;
        @include _1279 {
            display: none;
        }
    }
    &__item2 {
        grid-column: 2/3;
        @include _1279 {
            display: none;
        }
    }
    @include _1279 {
        padding-bottom: clampFluid(41,132,320,1279);
    }
}