.row-input {
  padding: r(15) r(30);
  font-size: r(20);
  line-height: 1.3;
  color: var(--form-text-color);
  font-family: "Inter", sans-serif;
  border: 0;
  border-bottom: 2px solid var(--form-border-color);
  &::placeholder {
    color: var(--form-placeholder-color);
  }
  &.error {
    border-color: var(--form-error-color);
  }
  @media (max-width: 1279px) {
    padding: r(10) r(21);
    font-size: r(14);
  }
}
