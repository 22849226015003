.cols_two_points {
    padding-top: clampFluid(100);
    padding-bottom: clampFluid(76);
    &__title {
        @include font-80;
        text-align: center;
        text-wrap: balance;
        margin-bottom: clampFluid(70);
        @include _1023 {
            margin-bottom: clampFluid(30,46.6,320,1023);
        }
        @include _767 {
            text-align: left;
        }
    }
    &__container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(clampFluid(700), 1fr));
        gap: 40px clampFluid(160);
        @include _1279 {
            grid-template-columns: 1fr;
        }
    }
    &__col {
        .name {
            @include font-44;
            font-style: italic;
            text-align: center;
            text-wrap: balance;
            margin-bottom: clampFluid(50);
            @include _1279 {
                text-align: left;
            }
            @include _1023 {
                font-size: clampFluid(24,29,320,1023);
                line-height: clampFluid(24,29,320,1023);
                margin-bottom: clampFluid(20,33,320,1023);
            }
        }
    }
    &__items {
        display: flex;
        flex-direction: column;
        gap: clampFluid(50) 0;
        @include _1279 {
            gap: clampFluid(14,33.3,320,1279);
        }
    }
    &__item {
        display: flex;
        svg {
            flex: 0 0 auto;
            margin-top: clampFluid(-25);
            margin-left: clampFluid(-45);
            &.cross {
                width: clampFluid(160);
                height: clampFluid(166);
                @include _1023 {
                    width: clampFluid(56,106,320,1023);
                    height: clampFluid(60,110,320,1023);
                }
            }
            &.check {
                width: clampFluid(175);
                height: clampFluid(167);
                @include _1023 {
                    width: clampFluid(56,116,320,1023);
                    height: clampFluid(51,111,320,1023);
                }
            }
            @include _1023 {
                margin-top: clampFluid(-10,-16,320,1023);
                margin-left: clampFluid(-15,-30,320,1023);
            }
        }
        .text {
            display: flex;
            flex-direction: column;
            gap: clampFluid(8) 0;
            @include font-36;
            font-style: italic;
            margin-left: clampFluid(-20);
            span {
                @include font-18;
                font-style: normal;
                color: $text;
            }
            @include _1023 {
                line-height: clampFluid(17,31,320,1023);
                margin-left: clampFluid(-8,-13,320,1023);
            }
        }
    }
    .btn_container {
        margin-top: clampFluid(106);
        display: flex;
        justify-content: center;
        @include _1279 {
            margin-top: clampFluid(40,70,320,1279);
        }
    }
    @include _1279 {
        padding-top: clampFluid(41,66,320,1279);
        padding-bottom: clampFluid(41,50,320,1279);
    }
}