.contacts_block{
    margin-top: clampFluid(108);
    h1{
        @include font-100-5;
    }
    &__head{
        display: flex;
        align-items: center;
        .btn{
            margin-left: clampFluid(64);
            margin-top: clampFluid(20);
            @include _1279 {
                margin-left: 0;
                margin-top: 21px;
            }
            @include _425 {
                width: 100%;
            }
        }
        @include _1279 {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    &__container{
        margin-top: clampFluid(56);
        display: grid;
        grid-template-columns: 39% 41% 10%;
        gap: clampFluid(82);
        .contacts {
            display: flex;
            flex-wrap: wrap;
            gap: clampFluid(40) 0;
            justify-content: space-between;
            .position{
                display: flex;
                flex-direction: column;
                .address{
                    @include font-28;
                }
            }
            .phone{
                display: flex;
                flex-direction: column;
                a{
                    @include font-28;
                    color: $green;
                    text-decoration: underline;
                    transition: all $transition ease;
                    width: fit-content;
                    @include hover {
                        text-decoration: none;
                    }
                }
            }
            .emails{
                display: flex;
                flex-direction: column;
                &__container{
                    display: flex;
                    flex-direction: column;
                    .item{
                        display: flex;
                        align-items: center;
                        a{
                            @include font-28;
                            transition: all $transition ease;
                            @include hover {
                                color: $green;
                            }
                        }
                        .desc{
                            @include font-18;
                            color: $text;
                            margin-left: clampFluid(20);
                            @include _425 {
                                margin-left: 0;
                            }
                        }
                        &:not(:last-child){
                            margin-bottom: clampFluid(18);
                        }
                        @include _425 {
                            flex-direction: column;
                            gap: 4px;
                            align-items: flex-start;
                        }
                    }
                }
            }
            @include _1279 {
                flex-direction: column;
                gap: 36px;
            }
        }
        .requisites{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0 clampFluid(52);
            .head_name {
                grid-column: 1/3;
                @include _1279 {
                    grid-column: 1/2;
                }
            }
            .col1, .col2 {
                grid-row: 2/3;
                display: flex;
                flex-direction: column;
                gap: clampFluid(10);
                @include _1279 {
                    grid-row: auto;
                }
            }
            .col1 {
                grid-column: 1/2;
                @include _1279 {
                    grid-column: auto;
                }
            }
            .col2 {
                grid-column: 2/3;
                @include _1279 {
                    grid-column: 1/2;
                    margin-top: 10px;
                }
            }
            .item {
                @include font-18;
                @include _1279 {
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
                @include _1023 {
                    line-height: 15.6px;
                }
            }
            @include _1279 {
                grid-template-columns: 1fr;
            }
        }
        .social{
            &__items{
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(clampFluid(40), 1fr));
                gap: clampFluid(15);
                max-width: clampFluid(163);
                @include _1279 {
                    grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
                    max-width: 100%;
                    margin-top: 15px;
                }
            }
            &__item{
                width: clampFluid(40);
                // height: clampFluid(40);
                aspect-ratio: 1;
                border-radius: 50%;
                border: 1px solid $green;
                display: flex;
                align-items: center;
                justify-content: center;
                svg{
                    width: clampFluid(19);
                    aspect-ratio: 1;
                    // height: clampFluid(19);
                    fill: $white;
                    @include _1279 {
                        width: 19px;
                    }
                }
                @include _1279 {
                    width: 40px;
                }
            }
        }
        .head_name{
            @include font-25-5;
            color: $text;
            margin-bottom: clampFluid(15);
            max-width: clampFluid(200);
            @include _1279 {
                max-width: 100%;
                margin-bottom: 7px;
            }
        }
        @include _1279 {
            grid-template-columns: 1fr;
            margin-top: 36px;
        }
    }
    &__map{
        width: 100%;
        height: clampFluid(471);
        margin-top: clampFluid(60);
    }

}
