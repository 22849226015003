.content-gallery {
  display: grid;
  gap: clampFluid(35);
  &.mt {
    padding-top: clampFluid(90);
    @include _1279 {
      padding-top: 50px;
    }
  }
  &.cols-1 {
    justify-content: center;
    grid-template-columns: 77.2%;
  }
  &.cols-2 {
    grid-template-columns: repeat(2, 1fr);
    @include _767 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &.cols-3 {
    grid-template-columns: repeat(3, 1fr);
    @include _1023 {
      grid-template-columns: repeat(2, 1fr);
    }
    @include _767 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &.cols-4 {
    grid-template-columns: repeat(4, 1fr);
    @include _1279 {
      grid-template-columns: repeat(3, 1fr);
    }
    @include _1023 {
      grid-template-columns: repeat(2, 1fr);
    }
    @include _767 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &-item {
    &__img {
        width: 100%;
    }
    &__caption {
        @include font-18;
        line-height: clampFluid(23.4);
        color: $text;
        margin-top: clampFluid(17);
        @include _1023 {
          margin-top: clampFluid(6,11.3,320,1023);
        }
    }
  }
  @include _1279 {
    gap: 18px;
  }
}