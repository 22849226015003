.setting_tariff {
    padding-top: clampFluid(50);
    padding-bottom: clampFluid(55);
    background: linear-gradient(180deg, #121B24 21.88%, #212D37 100%);
    // border-radius: 0 0 clampFluid(60) clampFluid(60);
    position: relative;
    z-index: 1;
    height: clampFluid(720);
    .container {
        height: 100%;
    }
    &__container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &__title {
        @include font-80;
        line-height: clampFluid(80);
        margin-bottom: clampFluid(70);
        max-width: 78%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        span {
            font-style: italic;
        }
        @include _1023 {
            max-width: 100%;
        }
    }
    &__left {
        // width: 56%;
        // margin-left: clampFluid(208);
        display: flex;
        flex-direction: column;
    }
    &__right {
        img {
            width: clampFluid(503);
            height: clampFluid(505);
            margin-top: clampFluid(192);
            margin-right: clampFluid(50);
            margin-bottom: clampFluid(35);
        }
    }
    &__count_tab {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        .circle {
            border-radius: 50%;
            cursor: pointer;
            &.prev {
                width: 8px;
                height: 8px;
                background: $white03;
            }
            &.active {
                width: 12px;
                height: 12px;
                background: $white05;
            }
            &.next {
                width: 8px;
                height: 8px;
                background: transparent;
                border: 1px solid $white07;
            }
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
        .prev_btn {
            display: none;
            @include _1279 {
                display: block;
                position: absolute;
                top: clampFluid(-5,-15,320,1279);
                left: 0;
            }
        }
        @include _1279 {
            position: relative;
        }
    }
    &__btn_container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;
        position: relative;
        .btn {
            span {
                white-space: nowrap;
            }
        }
        .prev_btn {
            @include _1279 {
                display: none;
            }
        }
    }
    &__btn_prev {
        position: absolute;
        left: 0;
        bottom: 22px;
        cursor: pointer;
        @include font-25-5;
        user-select: none;
    }
    &__tab {
        .title {
            @include font-60;
            margin-top: clampFluid(29);
            margin-bottom: clampFluid(30);
            width: 100%;
            text-align: center;
        }
        .horizontal_container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            row-gap: clampFluid(10);
            label {
                div {
                    @include _1279 {
                        padding-left: 16px;
                        padding-right: 16px;
                    }
                }
            }
        }
        .input_container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: clampFluid(24);
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            width: 77%;
            .btn {
                grid-column: 2/3;
                grid-row: 2/3;
                @include _1279 {
                    grid-column: auto;
                    grid-row: auto;
                }
            }
            .personal_container {
                grid-column: 3/4;
                grid-row: 2/3;
                margin-left: 30px;
                .personal {
                    display: flex;
                    flex-direction: column;
                    @include _1279 {
                        align-items: center;
                    }
                }
                @include _1279 {
                    grid-column: auto;
                    grid-row: auto;
                    margin-left: 0;
                }
            }
            input {
                background: $white03;
            }
            @include _1279 {
                grid-template-columns: repeat(1, 1fr);
                max-width: 900px;
                width: 100%;
                gap: 9px;
            }
        }
        &.slider {
            // width: 73%;
            max-width: 700px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            .minmax_container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: clampFluid(24);
                span {
                    @include font-24;
                    color: $white05;
                }
            }
            @include _1023 {
                max-width: 87%;
            }
        }
        @include _1279 {
            &.tab1, &.tab2, &.tab3 {
                margin-top: clampFluid(0,100,320,1279);
            }
        }
    }
    &__personal_container {
        margin-left: clampFluid(21);
    }
    .personal {
        color: $white07;
        a{
            color: $white07;
        }
    }
    &__bgc_text {
        position: absolute;
        z-index: -1;
        bottom: clampFluid(80);
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 900;
        // font-size: 235.48px;
        font-size: clampFluid(294.48);
        line-height: clampFluid(211.94);
        text-align: right;
        color: $tone2;
        user-select: none;
    }
    .noUi-horizontal {
        height: 2px;
    }
    .noUi-origin {
        @include _1279 {
            left: -10px;
        }
    }
    .noUi-target {
        margin-top: clampFluid(140);
        background: $white05;
        border: none;
        box-shadow: none;
    }
    .noUi-horizontal .noUi-handle {
        width: clampFluid(20);
        height: clampFluid(20);
        top: clampFluid(-10);
        border-radius: 50%;
        border: none;
        background: $gradient;
        box-shadow: none;
        &::before, &::after {
            display: none;
        }
    }
    .noUi-tooltip {
        background: $gradient;
        border-radius: clampFluid(50);
        @include font-24;
        color: $bgc;
        padding: clampFluid(8) clampFluid(20);
        border-color: #C0FF00;
        border-width: 2px;
    }
    @include _1279 {
        height: clampFluid(587,800,320,1279);
    }
}
