.cases{
    .container_1920{
        position: relative;
    }
    .background_line{
        background: 
            repeating-linear-gradient(90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 8px, #05080C 11px),
            linear-gradient(180deg, #121B24 62%, #212D37 100%);
            border-radius: 0 0 clampFluid(60) clampFluid(60);
    }
    &__tabs{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;
        &.pb {
            padding-bottom: clampFluid(88);
        }
        .item{
            padding: clampFluid(5) clampFluid(22) clampFluid(5);
            border: 1px solid $line;
            border-radius: clampFluid(34.03);
            margin-bottom: clampFluid(5);
            font-size: clampFluid(18.01);
            line-height: clampFluid(21.62);
            color: $text;
            transition: all $transition ease;
            text-transform: lowercase;
            @include hover{
                border-color: $green;
                color: $green;
            }
            @include _1279{
                font-size: 24px;
                line-height: 28.8px;
                padding: 6px 19px 9px;
                margin-bottom: 5px;
            }
            @include _1023{
                font-size: clampFluid(12,24,320,1023);
                line-height: clampFluid(14.4,28.8,320,1023);
                padding: clampFluid(2,6,320,1023) clampFluid(14,19,320,1023) clampFluid(4,9,320,1023);
                margin-bottom: clampFluid(2,5,320,1023);
            }
        }
        @include _425{
            justify-content: center;
            margin-left: -55px;
            margin-right: -55px;
        }
    }
    &__head{
        width: 100%;
        background: var(--block_color);
        padding-top: clampFluid(56);
        padding-bottom: clampFluid(89);
        position: relative;
        z-index: 1;
        @include _1279{
            padding-top: 47px;
        }
        @include _1023{
            padding-top: clampFluid(14,47,320,1023);
            padding-bottom: clampFluid(22,59,320,1023);
        }
    }
    &__hashtag{
        color: rgba(255,255,255,0.5);
        margin-left: clampFluid(65);
        @include _1279{
            margin-left: 25px;
        }
        @include _1023{
            margin-left: clampFluid(1,25,320,1023);
        }
    }
    &__title{
        max-width: clampFluid(847.5);
        &.size-100 {
            @include font-100-5;
            line-height: clampFluid(100.5);
            margin-left: clampFluid(3);
            @include _1023 {
                font-size: 30px;
                line-height: 30px;
            }
        }
        &.size-80 {
            @include font-80;
        }
        @include _1279{
            max-width: 100%;
            margin-left: 0;
        }
        @include _1023{
            padding-top: 3px;
            margin-left: clampFluid(2,0,320,1023);
        }
    }
    &__grid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        // gap: clampFluid(22.5);
        gap: 22.5px ;
        margin-top: clampFluid(-320);
        padding-bottom: clampFluid(46);
        max-width: 1719px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 1;
        .text{
            grid-column: 1/3;
            grid-row: 2/3;
            display: flex;
            align-items: flex-end;
            @include font-60;
            font-style: italic;
            max-width: clampFluid(691.5);
            padding-bottom: clampFluid(48);
            @include _1279{
                grid-column: 1/3;
                grid-row: 1/2;
                max-width: 100%;
                padding-top: 36px;
                padding-bottom: 19px;
            }
            @include _1023{
                font-size: 17px;
                line-height: 18.7px;
                max-width: 80%;
                padding-top: clampFluid(14,36,320,1023);
                padding-bottom: clampFluid(9,19,320,1023);
            }
        }
        .item{
            width: 100%;
            height: clampFluid(267);
            position: relative;
            max-width: clampFluid(412);
            &_1{
                grid-column: 3/4;
                @include _1279{
                    grid-column: auto;
                }
            }
            &_2{
                grid-column: 4/5;
                @include _1279{
                    grid-column: auto;
                }
            }
            .hover{
                z-index: 1;
                // transform: scale(1.437);
                transform: scale(1.4466);
                width: 100%;
                height: 100%;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                pointer-events: none;
                transition: all $transition ease;
                img{
                    // background: $green;
                }
                @include _1279{
                    display: none;
                }
            }
            @include hover{
                .hover{
                    opacity: 1;
                }
            }
            >img{
                // background: #9BAEF2;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: clampFluid(19.5);
                @include _1279{
                    border-radius: 22px;
                }
                @include _1023{
                    border-radius: clampFluid(12,22,320,1023);
                }
            }
            @include _1279{
                height: 288px;
                margin-bottom: 5px;
                max-width: 100%;
            }
            @include _1023{
                height: clampFluid(88,288,320,1023);
                margin-bottom: 0;
            }
        }
        @include _1279{
            margin-top: 0;
            grid-template-columns: repeat(2, 1fr);
            gap: 17px 22px;
            padding-bottom: 29px;
        }
        @include _1023{
            gap: clampFluid(7,17,320,1023) clampFluid(8,22,320,1023);
            padding-bottom: clampFluid(10,29,320,1023);
        }
    }
    &__expirienc_container{
        padding-top: clampFluid(183);
        padding-bottom: clampFluid(205);
        max-width: clampFluid(820.5);
        margin: 0 auto;
        position: relative;
        z-index: 1;
        .expirience_top{
            @include font-60;
            text-align: center;
            margin-bottom: clampFluid(21);
            span{
                font-style: italic;
            }
            @include _1279{
                font-size: 58px;
                line-height: 63.8px;
                margin-bottom: 24px;
                max-width: 85%;
                margin-right: auto;
                margin-left: auto;
            }
            @include _1023{
                font-size: clampFluid(30,58,320,1023);
                line-height: clampFluid(33,63.8,320,1023);
                margin-bottom: clampFluid(14,24,320,1023);
            }
        }
        .expirience_bottom{
            @include font-36;
            text-align: center;
            margin-bottom: clampFluid(64);
            span{
                color: $green;
            }
            @include _1279{
                max-width: 542px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 57px;
            }
            @include _1023{
                font-size: clampFluid(17,36,320,1023);
                line-height: clampFluid(18.36,39.6,320,1023);
                margin-bottom: clampFluid(33,57,320,1023);
                max-width: clampFluid(250,542,320,1023);
            }
        }
        .btn_container{
            width: 100%;
            display: flex;
            justify-content: center;
            .btn{
                @include _1023{
                    padding-left: clampFluid(17,40,320,1023);
                    padding-right: clampFluid(17,40,320,1023);
                }
            }
        }
        @include _1279{
            max-width: 100%;
            padding-top: 98px;
            padding-bottom: 145px;
        }
        @include _1023{
            padding-top: clampFluid(40,98,320,1023);
            padding-bottom: clampFluid(83,145,320,1023);
        }
    }
    &__btn_only {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: clampFluid(66);
        padding-bottom: clampFluid(107);
    }
    @include _1279{
        padding-top: 5px;
    }
}
