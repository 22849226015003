.trust_us{
    padding-top: clampFluid(61);
    position: relative;
    z-index: 1;
    &__hashtag{
        width: 100%;
        text-align: center;
        margin-left: clampFluid(-4);
        @include _1279{
            margin-left: 10px;
        }
        @include _1023{
            margin-left: 0;
        }
    }
    &__title{
        @include font-100-5;
        width: 100%;
        text-align: center;
        margin-top: clampFluid(-20);
        margin-left: clampFluid(2);
        @include _1279{
            margin-top: 3px;
            margin-left: 0;
        }
        @include _1023{
            font-size: clampFluid(30,58,320,1023);
            line-height: clampFluid(30,58,320,1023);
        }
    }
    &__items{
        display: flex;
        flex-wrap: wrap;
        gap: clampFluid(19.5) clampFluid(45);
        margin-top: clampFluid(34);
        margin-left: clampFluid(2);
        padding-bottom: clampFluid(90);
        justify-content: space-between;
        @include _1279{
            gap: 14.64px 33.78px;
            margin-top: 36px;
            margin-left: 0;
            padding-bottom: 92px;
            display: grid;
            grid-template-columns: repeat(auto-fill, 155.38px);
        }
        @include _1023{
            gap: clampFluid(7,14.64,320,1023);
            grid-template-columns: repeat(auto-fill, clampFluid(88,155.38,320,1023));
            margin-top: clampFluid(19,36,320,1023);
            padding-bottom: clampFluid(29,92,320,1023);
        }
    }
    &__item {
        img{
            width: clampFluid(207);
            height: clampFluid(94.5);
            border-radius: clampFluid(10.5);
            object-fit: contain;
            transition: filter 0.4s;
            padding: clampFluid(15.54) clampFluid(10.35) clampFluid(14.69);
            &:not(:hover) {
                filter: saturate(0);
            }
            @include _1279{
                height: 70.93px;
                width: 100%;
                border-radius: 7px;
            }
            @include _1023{
                height: clampFluid(41,70.93,320,1023);
                border-radius: clampFluid(4,7,320,1023);
            }
        }
    }
    .container_1920{
        border-radius: 0 0 clampFluid(60) clampFluid(60);
        overflow: hidden;
        background: linear-gradient(180deg, $bgc 21.88%, #212D37 100%);
    }
    @include _1279{
        padding-top: 69px;
    }
    @include _1023{
        padding-top: clampFluid(29,69,320,1023);
    }
}