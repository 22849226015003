.dropdown-list {
  padding: r(30);
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: r(20);
  top: 100%;
  left: 0;
  right: 0;
  z-index: 2;
  color: $black;
  background: #fff;
  border-radius: 0 0 r(30) r(30);
  box-shadow: 0px 4px 12px 0px rgba(154, 156, 179, 0.2);
  .list-item {
    text-align: left;
    &.default {
      color: $gray-green;
    }
  }
  @media (max-width: 1279px) {
    gap: r(15);
  }
}
