.jobs {
    margin-top: clampFluid(95);
    h1 {
        @include font-100-5;
    }
    &__head {
        display: flex;
        align-items: center;
        @include _1279 {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    &__text {
        @include font-21;
        color: $text;
        // max-width: clampFluid(560);
        margin-left: clampFluid(394);
        margin-top: clampFluid(24);
        @include _1279 {
            margin-left: 0;
        }
    }
    &__conditions {
        ol {
            list-style: decimal;
            padding-left: clampFluid(25);
            margin-top: clampFluid(50);
            li {
                @include font-21;
                padding-left: clampFluid(10);
                &:not(:last-child) {
                    margin-bottom: clampFluid(14);
                }
            }
        }
    }
    &__opening {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(clampFluid(523), 1fr));
        gap: clampFluid(30) clampFluid(50);
        margin-top: clampFluid(60);
        margin-bottom: clampFluid(30);
        .item {
            @include font-36;
            padding: clampFluid(23) 0;
            border-bottom: 1px solid $line;
            white-space: wrap;
            text-wrap: balance;
            @include _1279 {
                padding-top: 0;
                line-height: clampFluid(15.64,31,320,1279);
            }
        }
        @include _1279 {
            grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
            margin-top: 36px;
        }
    }
}
