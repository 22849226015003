.link_open_modal{
    &__container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include _1023 {
            position: relative;
            flex-direction: column;
            padding-top: 36px;
        }
    }
    &__title{
        @include font-100-5;
        line-height: clampFluid(100);
        @include _1279 {
            font-size: clampFluid(30,58,320,1279);
            line-height: clampFluid(33,58,320,1279);
        }
    }
    &__left{
        width: 68%;
        .btn_container {
            display: flex;
            margin-top: clampFluid(56);
            svg {
                width: clampFluid(147);
                height: clampFluid(147);
                fill: transparent;
                @include _1279 {
                    display: none;
                }
            }
        }
        .btn{
            margin-top: clampFluid(56);
            &.not_margin {
                margin-top: 0;
                margin-right: clampFluid(37);
                height: fit-content;
                @include _1023 {
                    margin-right: 0;
                }
            }
            @include _1023 {
                position: absolute;
                right: 50%;
                bottom: clampFluid(35,80,320,1023);
                translate: 50% 0;
                padding-left: 25px;
                padding-right: 25px;
            }
            @include _425 {
                width: 100%;
            }
        }
        @include _1279 {
            width: 100%;
            max-width: 793px;
        }
    }
    &__right{
        width: 32%;
        img{
            width: 109%;
            height: clampFluid(752);
            object-fit: cover;
            object-position: bottom;
            @include _1023 {
                height: clampFluid(300,501,320,1023);
                width: 82%;
                margin-left: auto;
            }
        }
        @include _1279 {
            width: 100%;
            max-width: 373px;
        }
    }
    &__text{
        @include font-21;
        margin-top: 44px;
    }
}