.can_we_go{
    padding-top: clampFluid(57);
    padding-bottom: clampFluid(60);
    &__hashtag{
        width: 100%;
        text-align: center;
    }
    &__title{
        @include font-100-5;
        width: 100%;
        text-align: center;
        margin-top: clampFluid(-20);
        span{
            font-style: italic;
        }
        @include _1279{
            margin-top: 3px;
        }
        @include _1023{
            font-size: 30px;
            line-height: 30px;
        }
    }
    &__container{
        display: flex;
        justify-content: space-between;
        margin-top: clampFluid(49);
        @include _1279{
            flex-direction: column;
            margin-top: 32px;
        }
        @include _1023{
            margin-top: clampFluid(16,32,320,1023);
        }
    }
    &__left{
        width: 59.7%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: clampFluid(10.5) clampFluid(9);
        @include _1279{
            width: 100%;
            grid-template-columns: 100%;
            gap: 0;
        }
    }
    &__right{
        width: 38.5%;
        background: $tone;
        display: flex;
        flex-direction: column;
        .form_title{
            @include font-60;
            padding-top: clampFluid(64.5);
            padding-left: clampFluid(84);
            padding-right: clampFluid(84);
            margin-bottom: clampFluid(10);
            @include _1279{
                padding-top: 44px;
                margin-bottom: 14px;
                padding-left: 0;
                padding-right: 0;
            }
            @include _1023{
                font-size: 30px;
                line-height: 30px;
                max-width: 90%;
                padding-top: clampFluid(23,44,320,1023);
                margin-left: 2px;
            }
        }
        .form_text{
            @include font-21;
            color: $text;
            padding-left: clampFluid(84);
            padding-right: clampFluid(84);
            max-width: 90%;
            margin-bottom: clampFluid(48);
            @include _1279{
                line-height: 28.8px;
                padding-left: 0;
                padding-right: 0;
                max-width: 100%;
                margin-bottom: 37px;
            }
            @include _1023{
                font-size: clampFluid(14,24,320,1023);
                line-height: clampFluid(18.83,28.8,320,1023);
                margin-bottom: clampFluid(24,37,320,1023);
            }
        }
        form{
            padding: 0 clampFluid(84);
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            input{
                &:not(:last-child){
                    margin-bottom: clampFluid(15);
                    @include _1279{
                        margin-bottom: 0;
                    }
                }
                @include _1279{
                    &.name{
                        grid-column: 1/2;
                    }
                    &.phone{
                        grid-column: 2/3;
                        @include _1023{
                            grid-column: 1/2;
                            grid-row: 2/3;
                        }
                    }
                }
            }
            .input{
                background-color: #212D37;
                width: 100%;
            }
            .btn{
                width: 100%;
                margin-top: clampFluid(18);
                @include _1279{
                    grid-row: 2/3;
                    grid-column: 1/3;
                    margin-top: 0;
                }
                @include _1023{
                    grid-column: 1/2;
                    grid-row: 3/4;
                }
            }
            @include _1279{
                padding: 0;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 16px;
            }
            @include _1023{
                grid-template-columns: 100%;
                gap: clampFluid(8,16,320,1023);
            }
        }
        .personal{
            width: 100%;
            text-align: center;
            // margin-top: auto;
            margin-top: 15px;
            padding-bottom: clampFluid(21);
            @include _1279{
                margin-top: 66px;
                padding-bottom: 44px;
            }
            @include _1023{
                margin-top: clampFluid(18,66,320,1023);
                padding-bottom: clampFluid(35,44,320,1023);
            }
        }
        @include _1279{
            width: 100vw;
            margin-left: -56px;
            margin-top: 31px;
            padding: 0 56px;
        }
        @include _1023{
            margin-top: clampFluid(8,31,320,1023);
            margin-left: clampFluid(-20,-56,320,1023);
            padding: 0 clampFluid(20,56,320,1023);
        }
    }
    &__item{
        height: clampFluid(256);
        border-radius: clampFluid(19.5);
        padding: clampFluid(18) clampFluid(31.5) clampFluid(22.5) clampFluid(28.5);
        display: flex;
        flex-direction: column;
        transition: all $transition ease;
        background: var(--color);
        position: relative;
        overflow: hidden;
        .name{
            @include font-36;
            line-height: clampFluid(36);
            font-style: italic;
            margin-top: auto;
            @include _1279{
                display: none;
            }
        }
        .desc{
            @include font-21;
            color: rgba(255,255,255,0.6);
            margin-top: clampFluid(14);
            @include _1279{
                margin-top: 9px;
            }
            @include _1023{
                line-height: clampFluid(14.4,28.8,320,1023);
                margin-top: clampFluid(6,9,320,1023);
                margin-left: 2px;
            }
        }
        .term{
            @include font-21;
            color: $green;
            @include _1279{
                line-height: 26.4px;
            }
            @include _1023{
                display: none;
                line-height: clampFluid(13.2,26.4,320,1023);
                text-wrap: balance;
            }
        }
        .group{
            display: flex;
            justify-content: flex-end;
            flex-direction: row-reverse;
            align-items: center;
            .name{
                display: none;
                @include _1279{
                    display: block;
                    font-size: 40px;
                    line-height: 40px;
                }
                @include _1023{
                    font-size: clampFluid(17,40,320,1023);
                    line-height: clampFluid(17,40,320,1023);
                    margin-top: 0;
                }
            }
            @include _1279{
                justify-content: space-between;
            }
            @include _1023{
                flex-direction: row;
                justify-content: flex-start;
            }
        }
        .left{
            display: flex;
            flex-direction: column;
            height: 100%;
            @include _1023{
                width: 75%;
                // width: auto;
            }
        }
        .right{
            .term{
                display: none;
                @include _1023{
                    text-align: end;
                    display: block;
                }
            }
            @include _1023{
                width: 24%;
            }
        }
        &::after{
            content: '';
            transition: all $transition ease;
            @include _1023{
                display: none !important;
            }
        }
        @include hover{
            box-shadow: 0px 16px 49.3px -6px var(--color);
            &::after{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(255,255,255,0.2);
            }
        }
        @include _1279{
            height: auto;
            padding: 25px 40px 33px;
            border-radius: 18px;
            &:not(:last-child){
                margin-bottom: 13px;
            }
        }
        @include _1023{
            padding: clampFluid(13,25,320,1023) clampFluid(12,40,320,1023) clampFluid(13,33,320,1023);
            border-radius: clampFluid(8,18,320,1023);
            flex-direction: row;
            justify-content: space-between;
            &:not(:last-child){
                margin-bottom: clampFluid(5,13,320,1023);
            }
        }
    }
    @include _1279{
        padding-top: 67px;
    }
    @include _1023{
        padding-top: clampFluid(30,67,320,1023);
    }
}
