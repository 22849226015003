.content-files {
    display: flex;
    flex-direction: column;
    gap: clampFluid(17);
    margin-top: clampFluid(28);
    margin-bottom: clampFluid(50);
    .icon {
        width: clampFluid(34);
        height: clampFluid(43);
        fill: $line;
        margin-right: clampFluid(16);
    }
    .content-file-row {
        display: flex;
        position: relative;
        align-items: center;
        width: fit-content;
    }
    .meta {
        @include font-18;
        color: $text;
        margin-right: clampFluid(36);
    }
    a {
        @include font-21;
        color: $white;
        text-decoration: none;
        transition: all $transition ease;
        @include hover {
            color: $green;
        }
    }
}