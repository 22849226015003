.text_btn {
    position: relative;
    padding-top: clampFluid(100);
    padding-bottom: clampFluid(200);
    z-index: 1;
    &.slim {
        padding-top: clampFluid(81);
        padding-bottom: clampFluid(87);
        &.mt {
            padding-top: clampFluid(130);
        }
    }
    &.last {
        border-radius: 0 0 clampFluid(60) clampFluid(60);
        overflow: hidden;
        background: linear-gradient(180deg, #121B24 62%, #212D37 100%);
    }
    &__container {
        width: fit-content;
        max-width: clampFluid(1100);
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }
    &__title {
        @include font-60;
        text-align: center;
        &_italic {
            span {
                font-style: italic;
            }
        }
        &_green {
            span {
                color: $green;
            }
        }
    }
    &__text {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-top: clampFluid(24);
        text-align: center;
        @include font-36;
        span {
            color: $green;
        }
    }
    &__title, &__text {
        s {
            color: gray;
        }
    }
    &__slim_text {
        max-width: clampFluid(528);
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        @include font-21;
        margin-top: clampFluid(25);
    }
    &__btn_container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: clampFluid(67);
        &.slim {
            margin-top: clampFluid(44);
        }
    }
}


