.kp{
    margin-top: clampFluid(111);
    margin-bottom: clampFluid(90);
    .left{
        width: 32%;
        @include _1279 {
            width: 100%;
        }
    }
    .right{
        width: 55%;
        padding-top: clampFluid(12);
        @include _1279 {
            width: 100%;
        }
    }
    &__title{
        @include font-100;
        line-height: clampFluid(108);
        text-wrap: balance;
        margin-bottom: clampFluid(81);
        max-width: 80%;
        @include _1279 {
            max-width: 100%;
        }
        @include _1023 {
            margin-bottom: clampFluid(36,54,320,1023);
        }
    }
    &__services{
        display: flex;
        margin-bottom: clampFluid(53);
        .right{
            display: flex;
            flex-wrap: wrap;
            gap: clampFluid(10) 0;
        }
        @include _1279 {
            flex-direction: column;
        }
    }
    &__budgets{
        display: flex;
        margin-bottom: clampFluid(55);
        .right{
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            gap: clampFluid(10) 0;
        }
        @include _1279 {
            flex-direction: column;
        }
    }
    &__task{
        display: flex;
        .right{
            display: flex;
            justify-content: space-between;
            width: 68%;
            input{
                width: clampFluid(284);
            }
            .input_container{
                display: flex;
                flex-direction: column;
                width: clampFluid(284);
                input{
                    height: 100%;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                }
                .drag_and_drop{
                    position: relative;
                    border: 3px dashed $line;
                    border-radius: clampFluid(20);
                    width: 100%;
                    height: clampFluid(190);
                    margin-bottom: clampFluid(23);
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-left: clampFluid(23);
                    padding-right: clampFluid(23);
                    .img_container{
                        margin-bottom: clampFluid(16);
                    }
                    @include _767 {
                        height: 190px;
                    }
                }
                .name_container {
                    width: 100%;
                    text-align: center;
                    @include font-18;
                    color: $text;
                    .file_name {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        span {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 200px;
                        }
                        .button_clear {
                            width: 17px;
                            height: 17px;
                            border-radius: 50%;
                            border: 1px solid $text;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: 6px;
                            transition: all $transition ease;
                            svg {
                                width: 7px;
                                height: 7px;
                                stroke: $text;
                                transition: all $transition ease;
                            }
                            @include hover {
                                border-color: $green;
                                svg {
                                    stroke: $green;
                                }
                            }
                        }
                    }
                    @include _1279 {
                        font-size: 12px;
                        line-height: 14.67px;
                    }
                }
                svg{
                    width: clampFluid(76);
                    height: clampFluid(76);
                    margin-left: auto;
                    margin-right: auto;
                }
                .button_kp {
                    div {
                        @include _767 {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
                @include _767 {
                    width: 100%;
                }
            }
            @include _1279 {
                width: 100%;
                justify-content: flex-start;
                gap: 17px;
            }
            @include _767 {
                flex-direction: column;
            }
        }
        @include _1279 {
            flex-direction: column;
        }
    }
    &__contacts{
        display: flex;
        &__container{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: clampFluid(12) clampFluid(24);
            @include _1279 {
                grid-template-columns: repeat(auto-fill, minmax(clampFluid(280,400,320,1279), 1fr));
            }
        }
        .btn{
            width: 100%;
            @include _1279 {
                margin-top: 17px;
            }
        }
        .right{
            width: 50%;
            @include _1279 {
                width: 100%;
                display: flex;
                flex-direction: column;
            }
        }
        .personal{
            margin-top: clampFluid(38);
            max-width: clampFluid(300);
            margin-bottom: clampFluid(39);
            @include _1279 {
                order: 1;
                max-width: 100%;
                text-align: center;
                margin-bottom: 0;
                margin-top: 18px;
            }
        }
        @include _1279 {
            flex-direction: column;
            gap: 17px;
            margin-top: 36px;
        }
    }
    textarea{
        width: clampFluid(864);
        height: clampFluid(280);
        @include _1279 {
            width: clampFluid(460,576,768,1279);
        }
        @include _767 {
            width: 100%;
            height: clampFluid(134,186,320,767);
        }
    }
    .input{
        background: $tone;
        // padding: clampFluid(7) clampFluid(42) clampFluid(9);
        // @include font-24;
    }
    &__head{
        @include font-60;
        margin-bottom: clampFluid(26);
        max-width: 70%;
        @include _1279 {
            max-width: 100%
        }
        @include _1023 {
            font-size: clampFluid(30,40,320,1023);
            line-height: clampFluid(33,44,320,1023);
            margin-bottom: clampFluid(10,17,320,1023);
        }
    }
    &__description{
        @include font-18;
        color: $text;
        max-width: 80%;
        @include _1279 {
            max-width: 100%;
            margin-bottom: 10px;
        }
    }
    .line{
        width: 87%;
        height: 2px;
        background: $line;
        margin-top: clampFluid(27);
        margin-bottom: clampFluid(21);
        @include _1279 {
            display: none;
        }
    }
    @include _1279 {
        margin-top: clampFluid(50,74,320,1279);
    }
}
