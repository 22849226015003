@font-face {
  font-family: 'Hoves Regular';
  src: local('Hoves Regular'),
    url('/src/scss/base/fonts/woff2/TT_Hoves_Pro_Regular.woff2') format('woff2'), 
    url('/src/scss/base/fonts/woff/TT_Hoves_Pro_Regular.woff') format('woff'),
    url('/src/scss/base/fonts/ttf/TT-Hoves-Pro-Regular.ttf') format('ttf'),
    url('/src/scss/base/fonts/otf/TT-Hoves-Pro-Regular.otf') format('otf'),
    url('/src/scss/base/fonts/eot/TT_Hoves_Pro_Regular.eot') format('eot');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Hoves Italic';
  src: local('Hoves Italic'),
    url('/src/scss/base/fonts/woff2/TT_Hoves_Pro_Italic.woff2') format('woff2'), 
    url('/src/scss/base/fonts/woff/TT_Hoves_Pro_Italic.woff') format('woff'),
    url('/src/scss/base/fonts/ttf/TT-Hoves-Pro-Italic.ttf') format('ttf'),
    url('/src/scss/base/fonts/otf/TT-Hoves-Pro-Italic.otf') format('otf'),
    url('/src/scss/base/fonts/eot/TT_Hoves_Pro_Italic.eot') format('eot');
  font-weight: 400;
  font-style: italic;
}
@font-face {
	src: url('https://res.cloudinary.com/dr6lvwubh/raw/upload/v1529908256/CompressaPRO-GX.woff2');
	font-family:'Compressa VF';
	font-style: normal;
}

@mixin h1 {
  font-size: r(64);
  line-height: 1.1;
  font-weight: 300;
  @media (max-width: 1279px) {
    font-size: r(30);
    font-weight: normal;
    line-height: 1.15;
  }
}
@mixin h2 {
  font-size: r(40);
  line-height: 1.1;
  font-weight: 300;
  @media (max-width: 1279px) {
    font-size: r(30);
  }
}
@mixin h3 {
  font-size: r(32);
  line-height: 1.2;
  font-weight: 300;
  @media (max-width: 1279px) {
    font-size: r(18);
  }
}
@mixin font-150 {
  font-family: 'Hoves Italic';
  font-size: clampFluid(150);
  line-height: clampFluid(138);
  font-weight: 400;
  color: $white;
  @include _1279{
    font-size: 80px;
    line-height: 80px;
  }
  @include _1023{
    font-size: clampFluid(30,80,320,1023);
    line-height: clampFluid(30,80,320,1023);
  }
}
@mixin font-115 {
  font-family: 'Hoves Regular';
  font-size: clampFluid(115);
  line-height: clampFluid(105.8);
  font-weight: 400;
  color: $white;
  @include _1023 {
    font-size: clampFluid(28,76,320,1023);
    line-height: clampFluid(28,70,320,1023);
  }
}
@mixin font-110 {
  font-family: 'Hoves Regular';
  font-size: clampFluid(110);
  line-height: clampFluid(101.2);
  font-weight: 400;
  color: $white;
  @include _1023 {
    font-size: clampFluid(26,73,320,1023);
    line-height: clampFluid(26,67,320,1023);
  }
}
@mixin font-100-5 {
  font-family: 'Hoves Regular';
  font-size: clampFluid(100.5);
  line-height: clampFluid(138);
  font-weight: 400;
  color: $white;
  @include _1279{
    font-size: 58px;
    line-height: 58px;
  }
  @include _1023{
    font-size: clampFluid(19,58,320,1023);
    line-height: clampFluid(19,58,320,1023);
  }
}
@mixin font-100 {
  font-family: 'Hoves Regular';
  font-size: clampFluid(100);
  line-height: clampFluid(90);
  font-weight: 400;
  color: $white;
  @include _1279{
    font-size: 58px;
    line-height: 58px;
  }
  @include _1023{
    font-size: clampFluid(30,58,320,1023);
    line-height: clampFluid(30,58,320,1023);
  }
}
@mixin font-95 {
  font-family: 'Hoves Regular';
  font-size: clampFluid(95);
  line-height: clampFluid(87.4);
  font-weight: 400;
  color: $white;
  @include _1023 {
    font-size: clampFluid(22,63,320,1023);
    line-height: clampFluid(22,58,320,1023);
  }
}
@mixin font-85 {
  font-family: 'Hoves Regular';
  font-size: clampFluid(85);
  line-height: clampFluid(78.2);
  font-weight: 400;
  color: $white;
  @include _1023 {
    font-size: clampFluid(19,56,320,1023);
    line-height: clampFluid(19,52,320,1023);
  }
}
@mixin font-84 {
  font-family: 'Hoves Regular';
  font-size: clampFluid(84);
  line-height: clampFluid(77.28);
  font-weight: 400;
  color: $white;
  @include _1023 {
    font-size: clampFluid(20,56,320,1023);
    line-height: clampFluid(20,51,320,1023);
  }
}
@mixin font-82 {
  font-family: "Inter", sans-serif;
  font-size: clampFluid(82);
  line-height: clampFluid(89.38);
  font-weight: 900;
  color: $tone2;
}
@mixin font-80 {
  font-family: 'Hoves Regular';
  font-size: clampFluid(80);
  line-height: clampFluid(80);
  font-weight: 400;
  color: $white;
  @include _1023 {
    font-size: clampFluid(26,53.3,320,1023);
    line-height: clampFluid(26,53.3,320,1023);
  }
}
@mixin font-70 {
  font-family: 'Hoves Regular';
  font-size: clampFluid(70);
  line-height: clampFluid(70);
  font-weight: 400;
  color: $white;
}
@mixin font-60 {
  font-family: 'Hoves Regular';
  font-weight: 400;
  color: $white;
  font-size: clampFluid(60);
  line-height: clampFluid(66);
  @include _1279{
    font-size: 40px;
    line-height: 44px;
  }
  @include _1023{
    font-size: clampFluid(20,40,320,1023);
    line-height: clampFluid(22,44,320,1023);
  }
}
@mixin font-52 {
  font-family: "Inter", sans-serif;
  font-size: clampFluid(52);
  line-height: clampFluid(62.13);
  font-weight: 900;
  color: $tone2;
}
@mixin font-44 {
  font-family: 'Hoves Regular';
  font-size: clampFluid(44);
  line-height: clampFluid(44);
  font-weight: 400;
  color: $white;
}
@mixin font-40 {
  font-family: 'Hoves Regular';
  font-size: clampFluid(40);
  line-height: clampFluid(40);
  font-weight: 400;
  color: $white;
}
@mixin font-36 {
  font-family: 'Hoves Regular';
  font-size: clampFluid(36);
  line-height: clampFluid(46.5);
  font-weight: 400;
  color: $white;
  @include _1279{
    font-size: 36px;
    line-height: 39.6px;
  }
  @include _1023{
    font-size: clampFluid(17,36,320,1023);
    line-height: clampFluid(31,39.6,320,1023);
  }
}
@mixin font-28 {
  font-family: 'Hoves Regular';
  font-weight: 400;
  font-size: clampFluid(28);
  line-height: clampFluid(30.8);
  color: $white;
}
@mixin font-25-5 {
  font-family: 'Hoves Regular';
  font-size: clampFluid(25.5);
  line-height: clampFluid(33);
  color: $white;
  @include _1279{
    // font-size: 36px;
    font-size: 28px;
    line-height: 39.6px;
  }
  @include _1023{
    // font-size: clampFluid(17,36,320,1023);
    font-size: clampFluid(17,28,320,1023);
    line-height: clampFluid(18.7, 39.6,320,1023);
  }
}
@mixin font-24 {
  font-family: 'Hoves Regular';
  font-weight: 400;
  font-size: clampFluid(24);
  line-height: clampFluid(35.76);
  color: $white;
  @include _1023 {
    font-size: clampFluid(14,16,320,1023);
    line-height: clampFluid(15.4,23.84,320,1023);
  }
}
@mixin font-21 {
  font-family: 'Hoves Regular';
  font-size: clampFluid(21);
  line-height: clampFluid(24);
  color: $white;
  @include _1279{
    font-size: 24px;
    line-height: 33.36px;
  }
  @include _1023{
    font-size: clampFluid(12,24,320,1023);
    line-height: clampFluid(16.68,33.36,320,1023);
  }
}
@mixin font-18 {
  font-family: 'Hoves Regular';
  font-size: clampFluid(18);
  line-height: clampFluid(21);
  color: $white;
  @include _1279{
    font-size: 21px;
    line-height: 24.39px;
  }
  @include _1023{
    font-size: clampFluid(12,21,320,1023);
    line-height: clampFluid(14,24.39,320,1023);
  }
}


@mixin text1 {
  font-size: r(25);
  line-height: 1.2;
  font-family: "Inter", sans-serif;
  @media (max-width: 1279px) {
    @include mob-text1;
  }
}

@mixin mob-text1 {
  font-size: r(18);
}

@mixin text2 {
  font-size: r(20);
  line-height: 1.3;
  font-family: "Inter", sans-serif;
  @media (max-width: 1279px) {
    @include mob-text2;
  }
}

@mixin mob-text2 {
  font-size: r(14);
}

@mixin caption {
  font-size: r(18);
  line-height: 1.3;
  font-family: "Inter", sans-serif;
  @media (max-width: 1279px) {
    font-size: r(10);
  }
}
