.footer {
    &__top{
        background: $tone;
        border: 1px solid $line;
        padding-top: clampFluid(40);
        padding-bottom: clampFluid(53.5);
        &__container{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: clampFluid(32) clampFluid(51);
            .parent{
                @include font-25-5;
                color: $text;
                margin-bottom: clampFluid(8);
                @include _1279{
                    margin-bottom: 19px;
                }
                @include _1023{
                    margin-bottom: clampFluid(12,19,320,1023);
                }
            }
            .item{
                &__sub{
                    display: flex;
                    flex-direction: column;
                    a{
                        @include font-18;
                        &:not(:last-child) {
                            margin-bottom: clampFluid(6.4);
                            @include _1279{
                                margin-bottom: 14px;
                            }
                            @include _1023{
                                margin-bottom: clampFluid(7.5,14,320,1023);
                            }
                        }
                        @include _1279{
                            font-size: 24px;
                            line-height: 26.4px;
                        }
                        @include _1023{
                            font-size: clampFluid(14,24,320,1023);
                            line-height: clampFluid(15.4,26.4, 320,1023);
                        }
                    }
                }
            }
            a{
                transition: all $transition ease;
                width: fit-content;
                display: block;
                text-transform: lowercase;
                @include hover{
                    color: $green;
                }
            }
            @include _1279{
                grid-template-columns: repeat(auto-fill, minmax(clampFluid(200,369,320,1023), 1fr));
                justify-content: space-between;
                gap: 55px 10px;
            }
            @include _1023{
                row-gap: clampFluid(16,55,320,1023);
            }
        }
        @include _1279{
            padding-top: 99px;
        }
        @include _1023{
            padding-top: clampFluid(29,99,320,1023);
        }
    }
    &__bottom{
        display: flex;
        justify-content: space-between;
        padding-top: clampFluid(43.5);
        padding-bottom: clampFluid(40);
        &__right{
            svg{
                width: clampFluid(138);
                height: clampFluid(150);
                margin-right: clampFluid(-13);
            }
        }
        &__left{
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            width: 77%;
            .top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .contacts{
                    display: flex;
                    flex-direction: column;
                    .tel{
                        @include font-60;
                    }
                    .email{
                        @include font-18;
                        color: $green;
                        text-decoration: underline;
                        margin-top: 2px;
                        transition: all $transition ease;
                        @include hover {
                            text-decoration: none;
                        }
                    }
                    a{
                        width: fit-content;
                    }
                }
                .partners {
                    &__top {
                        display: flex;
                        align-items: center;
                    }
                    &__bottom {
                        max-width: clampFluid(718);
                        margin-top: clampFluid(18);
                        span {
                            @include font-18;
                        }
                    }
                    a{
                        &:not(:last-child){
                            margin-right: clampFluid(44);
                        }
                        img{
                            height: clampFluid(47);
                            width: 100%;
                            object-fit: contain;
                            border-radius: clampFluid(7);
                        }
                    }
                }
            }
            .bottom{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: clampFluid(47);
                span, a{
                    @include font-18;
                    color: $text;
                }
                a{
                    transition: all $transition ease;
                    @include hover{
                        color: $green;
                    }
                }
            }
        }
        @include _1279{
            display: none;
        }
    }
}
