.password-input {
  position: relative;
  .eye {
    width: r(32);
    height: r(20);
    position: absolute;
    top: 0;
    bottom: 0;
    right: r(19);
    margin: auto;
    &-icon {
      width: 100%;
      height: 100%;
      fill: $orange;
      stroke: $orange;
    }
  }
}
