.main_banner{
    border-radius: 0 0 clampFluid(60) clampFluid(60);
    overflow: hidden;
    position: relative;
    z-index: 1;
    h1{
        margin-top: clampFluid(40);
        margin-left: clampFluid(-14);
        &.size {
            &_150 { 
                @include font-150; 
            }
            &_115 { 
                @include font-115; 
            }
            &_110 { 
                @include font-110; 
            }
            &_100 { 
                @include font-100; 
            }
            &_95 { 
                @include font-95; 
            }
            &_85 { 
                @include font-85; 
                max-width: 90%;
            }
            &_84 { 
                @include font-84; 
            }
            &_60 { 
                @include font-60; 
                max-width: 80%;
                @include _1023 {
                    max-width: 100%;
                }
            }
        }
        
        font-style: italic;
        span{
            margin-top: clampFluid(5);
            margin-left: clampFluid(10);
            display: block;
            @include font-100-5;
            @include _1279{
                margin-left: 0;
                margin-top: 9px;
            }
        }
        @include _1279{
            text-align: center;
            margin-top: 123px;
            margin-left: auto;
            margin-right: auto;
        }
        @include _1023{
            margin-top: clampFluid(119,123,320,1023);
        }
    }
    .container{
        @include _1279{
            position: relative;
            border-radius: 0 0 clampFluid(60) clampFluid(60);
            overflow: hidden;
            padding-bottom: 15px;
        }
    }
    .background_line {
        img {
            position: absolute;
            bottom: 0;
            right: clampFluid(70);
            width: clampFluid(766);
            @include _1279 {
                width: 866px;
                max-height: 845px;
                object-fit: cover;
                object-position: top;
                right: 50%;
                translate: 50% 0;
                // right: clampFluid(-55,190,320,1279);
            }
            @include _1023 {
                width: clampFluid(408,866,320,1023);
                max-height: clampFluid(434,845,320,1023);
            }
        }
        z-index: 1;
        @include _1279 {
            // margin-top: 17px;
            border-radius: 0 0 clampFluid(60) clampFluid(60);
        }
    }
    &__container {
        display: flex;
        position: relative;
        z-index: 1;
        .left{
            width: 75%;
            padding-bottom: clampFluid(34);
            .rent_container{
                margin-top: clampFluid(46);
                display: flex;
                align-items: center;
                .btn{
                    margin-right: clampFluid(66);
                    margin-left: clampFluid(-7);
                    @include _1279{
                        display: none;
                    }
                }
                .rent_count{
                    display: flex;
                    &__text{
                        display: flex;
                        flex-direction: column;
                        margin-left: clampFluid(20);
                        justify-content: center;
                        &.svg_none {
                            margin-left: 0 !important;
                        }
                        .text_count{
                            @include font-18;
                            color: $text;
                            margin-bottom: clampFluid(4);
                            @include _1279{
                                margin-bottom: 0;
                            }
                        }
                        .text_count_remained{
                            @include font-25-5;
                            color: $white;
                        }
                        @include _1279{
                            margin-left: 12px;
                        }
                    }
                    svg{
                        width: clampFluid(76.5);
                        height: clampFluid(76.5);
                        @include _1279{
                            width: 84px;
                            height: 84px;
                        }
                        @include _1023{
                            width: clampFluid(29,84,320,1023);
                            height: clampFluid(29,84,320,1023);
                        }
                    }
                }
                @include _1279{
                    justify-content: center;
                    margin-top: 54px;
                }
                @include _1023{
                    margin-top: clampFluid(22,54,320,1023);
                }
            }
            .text{
                @include font-21;
                color: $text;
                line-height: clampFluid(27.3);
                max-width: clampFluid(950);
                margin-top: clampFluid(64);
                margin-left: clampFluid(3);
                @include _1279{
                    display: none;
                }
            }
            @include _1279{
                width: 100%;
                padding-bottom: clampFluid(4,22,320,1023);
            }
        }
        .right{
            width: 25%;
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            .background_line{
                z-index: 0;
            }
            .img_text {
                z-index: 1;
                @include font-21;
                color: $text;
                line-height: clampFluid(29.19);
                margin-bottom: clampFluid(28);
                // width: 100%;
                margin-left: auto;
                width: fit-content;
                margin-right: clampFluid(90);
                @include _1279 {
                    width: 100%;
                    text-align: center;
                    margin: 0;
                    // position: absolute;
                    bottom: 25px;
                }
                @include _1023{ 
                    bottom: clampFluid(0,25,320,1023);
                }
            }
            // img {
            //     position: absolute;
            //     left: clampFluid(-332);
            //     top: auto;
            //     bottom: 0px;
            //     width: clampFluid(766);
            //     z-index: 0;
            //     @include _1279 {
            //         position: absolute;
            //         left: 0;
            //         top: 17px;
            //         // top: 48.5px;
            //         // top: clampFluid(48.5,82,320,1279);
            //         // bottom: 25px;
            //         // margin-bottom: clampFluid(-35,-66,320,1279);
            //         bottom: 0;
            //         margin-bottom: -31px;
            //         width: 866px;
            //         max-height: 845px;
            //         object-fit: cover;
            //         object-position: top;
            //     }
            //     @include _1023 {
            //         width: clampFluid(408,866,320,1023);
            //         max-height: clampFluid(434,845,320,1023);
            //     }
            // }
            .btn{
                display: none;
                @include _1279{
                    display: inline-flex;
                    position: absolute;
                    bottom: 110px;
                }
                @include _1023{
                    bottom: clampFluid(45,110,320,1023);
                }
            }
            @include _1279{
                width: 100%;
                flex-direction: column;
                display: flex;
                align-items: center;
                position: relative;
                min-height: clampFluid(345,685,320,1279);
            }
        }
        @include _1279{
            flex-direction: column;
        }
    }
    @include _1279{
        margin-top: -110.25px;
    }
}
.container.text{
    display: none;
    font-size: 24px;
    line-height: 31.2px;
    color: $text;
    margin-top: 39px;
    @include _1279{
        display: block;
    }
    @include _1023{
        font-size: clampFluid(14,24,320,1023);
        line-height: clampFluid(18.2,31.2,320,1023);
        margin-top: clampFluid(26,39,320,1023);
    }
}