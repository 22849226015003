.rews {
    margin-top: clampFluid(100);
    margin-bottom: clampFluid(90);
    h1 {
        @include font-100-5;
        @include _1279 {
            font-size: clampFluid(30,58,320,1279);
            line-height: clampFluid(33,58,320,1279);
        }
    }
    &__tabs {
        display: flex;
        margin-top: clampFluid(30);
        flex-wrap: wrap;
        @include _1279 {
            row-gap: 10px;
        }
    }
    &__tab {
        border: 1px solid $text;
        border-radius: clampFluid(34.03);
        padding: clampFluid(5) clampFluid(20) clampFluid(7);
        @include font-18;
        transition: all $transition ease;
        user-select: none;
        &.active {
            opacity: 0.5;
            cursor: auto;
        }
        &:not(.active) {
            @include hover {
                border-color: $green;
                color: $green;
            }
        }
        @include _1279 {
            padding: 8px 20px 12px;
            font-size: 14px;
            line-height: 15.4px;
        }
    }
    &__content {
        margin-top: clampFluid(80);
        &.google-yandex {
            display: flex;
            justify-content: center;
            gap: clampFluid(30);
            @include _1279 {
                flex-direction: column;
                max-width: 700px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .scan {
            &__item {
                display: flex;
                &:not(:last-child) {
                    margin-bottom: clampFluid(50);
                    @include _1023 {
                        margin-bottom: 36px;
                    }
                }
                @include _1023 {
                    flex-direction: column;
                }
            }
            &__img {
                margin-right: clampFluid(43);
                overflow: hidden;
                width: clampFluid(290);
                height: clampFluid(410);
                display: block;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: clampFluid(20);
                }
                @include _1279 {
                    width: clampFluid(260,340,1024,1279);
                    width: 340px;
                    // height: 410px;
                    height: auto;
                }
                @include _1023 {
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 20px;
                    width: clampFluid(205,340,320,1023);
                }
            }
            &__name {
                @include font-25-5;
                margin-bottom: clampFluid(8);
            }
            &__author {
                @include font-21;
                color: $text;
                margin-bottom: clampFluid(20);
            }
            &__text {
                @include font-21;
            }
        }
        .video {
            &__items {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(clampFluid(550), 1fr));
                gap: clampFluid(50) clampFluid(35);
                @include _1279 {
                    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
                }
            }
            &__item {
                iframe, img {
                    width: 100%;
                    height: clampFluid(308);
                    border-radius: clampFluid(20);
                    margin-bottom: clampFluid(20);
                    cursor: pointer;
                    @include _1023 {
                        margin-bottom: clampFluid(10,13.3,320,1023);
                        height: clampFluid(157,205.3,320,1023);
                        border-radius: 10px;
                    }
                }
            }
            &__name {
                @include font-25-5;
                margin-bottom: 8px;
                margin-left: 5px;
                @include _1023 {
                    margin-left: clampFluid(0,5,320,1023);
                    margin-bottom: clampFluid(5,8,320,1023);
                }
            }
            &__author {
                @include font-21;
                color: $text;
                margin-left: 5px;
                @include _1023 {
                    margin-left: clampFluid(0,5,320,1023);
                }
            }
        }
        @include _1279 {
            margin-top: clampFluid(36,53.3,320,1279);
        }
    }
    @include _1023 {
        margin-top: clampFluid(50,72.6,320,1023);
    }
}