.check_mark_list{
    border-radius: 0 0 clampFluid(60) clampFluid(60);
    overflow: hidden;
    position: relative;
    z-index: 1;
    &__title{
        @include font-100;
        &.font-60 {
            @include font-60;
        }
        &.font-80 {
            @include font-80;
        }
        &.center {
            width: 80%;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            padding-top: clampFluid(80);
        }
        @include _1023 {
            font-size: clampFluid(26,58,320,1023);
            line-height: clampFluid(26,58,320,1023);
        }
    }
    &__container{
        display: flex;
        justify-content: space-between;
        &.reverse {
            flex-direction: row-reverse;
        }
        @include _1279{
            flex-direction: column-reverse !important;
        }
    }
    &__left{
        width: 50%;
        display: flex;
        align-items: end;
        &.cross {
            width: 41%;
        }
        img{
            width: clampFluid(846.5);
            height: clampFluid(838.5);
            object-fit: contain;
            object-position: bottom;
            &.cross {
                width: clampFluid(708);
                height: auto;
                @include _1023 {
                    width: clampFluid(280,472,320,1023);
                }
            }
            @include _1279{
                max-width: 1024px;
                width: 100%;
                height: 941px;
                margin-left: 33px;
            }
            @include _1023{
                height: clampFluid(322,941,320,1023);
                margin-left: clampFluid(25,33,320,1023);
            }
        }
        @include _1279{
            // width: 100%;
            justify-content: center;
            margin-left: -56px;
            margin-right: -56px;
            width: 100vw !important;
            margin-top: -32px;
        }
        @include _1023{
            margin-top: clampFluid(-7,-32,320,1023);
            margin-left: clampFluid(-20,-56,320,1023);
            margin-right: clampFluid(-20,-56,320,1023);
        }
    }
    &__right{
        width: 49%;
        padding-top: clampFluid(89);
        &.cross {
            width: 55%;
        }
        &.not_paddint_top {
            padding-top: 0;
        }
        .btn {
            margin-top: clampFluid(86);
            margin-bottom: clampFluid(60);
            &.not_margin_top {
                margin-top: 0;
            }
            @include _1023 {
                margin-top: clampFluid(0,57,320,1023);
            }
            @include _425 {
                width: 100%;
            }
        }
        .btn_container {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        @include _1279{
            width: 100% !important;
        }
    }
    &__items{
        display: flex;
        flex-direction: column;
        margin-left: clampFluid(16);
        margin-top: clampFluid(61);
        padding-bottom: clampFluid(70);
        @include _1279{
            margin-left: 0;
            margin-top: 32px;
        }
        @include _1023{
            margin-top: clampFluid(12,32,320,1023);
        }
    }
    &__item{
        display: flex;
        svg{
            flex: 0 0 auto;
            &.check {
                width: clampFluid(75);
                height: clampFluid(67.5);
                fill: $green;
                margin-right: clampFluid(21);
                @include _1279{
                    width: 75.76px;
                    height: 68.19px;
                    margin-right: 18px;
                }
                @include _1023{
                    width: clampFluid(26,75.76,320,1023);
                    height: clampFluid(23,68.19,320,1023);
                    margin-right: clampFluid(9,18,320,1023);
                }
            }
            &.cross {
                width: clampFluid(160);
                height: clampFluid(166);
                margin-left: clampFluid(-55);
                margin-top: clampFluid(-35);
                @include _1023 {
                    width: clampFluid(56,116,320,1023);
                    height: clampFluid(51,111,320,1023);
                    margin-left: clampFluid(-15,-36,320,1023);
                    margin-top: clampFluid(-10,-23,320,1023);
                }
            }
        }
        &__text{
            display: flex;
            flex-direction: column;
            max-width: clampFluid(553.5);
            @include font-36;
            line-height: clampFluid(39.6);
            font-style: italic;
            span{
                @include font-21;
                color: $text;
                font-style: normal;
                margin-top: clampFluid(11);
                @include _1279{
                    line-height: 28.8px;
                    margin-top: 13px;
                }
                @include _1023{
                    font-size: clampFluid(14,24,320,1023);
                    line-height: clampFluid(16.8,28.8,320,1023);
                    margin-top: clampFluid(8,13,320,1023);
                }
            }
            &.cross {
                margin-left: clampFluid(-10);
                max-width: 100%;
            }
            @include _1279{
                max-width: 100%;
                font-size: 40px;
                line-height: 44px;
            }
            @include _1023{
                font-size: clampFluid(17,40,320,1023);
                line-height: clampFluid(18.7,44,320,1023);
            }
        }
        &:not(:last-child){
            margin-bottom: clampFluid(34);
            @include _1279{
                margin-bottom: 35px;
            }
            @include _1023{
                margin-bottom: clampFluid(13,19,320,1023);
            }
        }
        @include _1279{
            max-width: 710px;
        }
    }
    .background_line{
        background: 
            repeating-linear-gradient(90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 8px, #05080C 11px), 
            linear-gradient(180deg, #121B24 62%, #212D37 100%);
        z-index: -1;
        border-radius: 0 0 clampFluid(60) clampFluid(60);
        // position: relative;
        // &.top_blur {
        //     &::before {
        //         content: '';
        //         position: absolute;
        //         top: 0;
        //         width: 100%;
        //         height: 100px;
        //         // background: linear-gradient(360deg, #121B24 0%, rgba(18, 27, 36, 0.87067) 37.03%, rgba(18, 27, 36, 0.428735) 72.94%, rgba(18, 27, 36, 0) 100%);
        //         background: linear-gradient(360deg, rgba(18, 27, 36, 0) 0%, rgba(18, 27, 36, 0.4234068627) 37%, rgba(18, 27, 36, 0.8715861345) 60%, rgb(18, 27, 36) 100%);
        //     }
        // }
    }
    .container_1920{
        position: relative;
    }
}