.radio_btn_form {
    padding-top: clampFluid(112);
    padding-bottom: clampFluid(105);
    position: relative;
    .background_line {
        z-index: -1;
    }
    &.brb {
        border-radius: 0 0 clampFluid(60) clampFluid(60);
        overflow: hidden;
    }
    &__title {
        @include font-100;
        margin-bottom: clampFluid(69);
        @include _1023 {
            margin-bottom: 41px;
        }
    }
    &__step1, &__step2 {
        display: flex;
        gap: clampFluid(91);
        .step {
            @include font-25-5;
            color: $text;
            margin-bottom: clampFluid(12);
            @include _1023 {
                font-size: clampFluid(12,17,320,1023);
                line-height: clampFluid(13.2,18.7,320,1023);
                margin-bottom: 12px;
            }
        }
        .name {
            @include font-60;
            @include _1023 {
                font-size: clampFluid(26,40,320,1023);
                line-height: clampFluid(26,44,320,1023);
            }
        }
        .left {
            width: 27%;
            @include _1279 {
                width: 100%;
                max-width: 466px;
            }
        }
        .right {
            width: 62%;
            @include _1279 {
                width: 100%;
            }
        }
        #complext_seo_form {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(clampFluid(419), 1fr));
            // grid-template-columns: repeat(4, 1fr);
            gap: clampFluid(12) clampFluid(24);
            input {
                background: $tone;
            }
            @include _1279 {
                grid-template-columns: repeat(2, 1fr);
            }
            @include _1023 {
                grid-template-columns: 1fr;
            }
        }
        @include _1279 {
            flex-direction: column;
            gap: clampFluid(31,60,320,1279);
        }
    }
    &__step1 {
        margin-bottom: clampFluid(12);
        margin-top: clampFluid(11);
        @include _1279 {
            margin-top: 0;
            margin-bottom: 41px;
        }
    }
    &__step2 {
        .right {
            width: 50%;
            margin-top: clampFluid(60);
            .personal, .btn {
                grid-column: 1/3;
                @include _1023 {
                    grid-column: auto;
                }
            }
            .personal {
                @include _1023 {
                    order: 1;
                    text-align: center;
                    margin-top: 18px;
                }
            }
            @include _1279 {
                width: 100%;
                margin-top: 0;
            }
        }
    }
    &__items {
        display: flex;
        flex-wrap: wrap;
        gap: clampFluid(9);
        label {
            div {
                @include _1023 {
                    padding-left: clampFluid(12,28,320,1023);
                    padding-right: clampFluid(12,28,320,1023);
                    max-height: 100%;
                }
            }
        }
    }
    @include _1279 {
        padding-top: clampFluid(36,74,320,1279);
        padding-bottom: clampFluid(36,70,320,1279);
    }
}