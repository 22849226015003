.text-page {
  padding-top: clampFluid(110);
  // padding-bottom: clampFluid(90);
  .case_container {
    width: 100%;
  }
  .content-block {
    @include container;
  }
  .content-gallery.cols-2 img {
    height: auto;
  }
  .content-gallery {
    margin-bottom: clampFluid(50);
  }
  @include _1023 {
    padding-top: clampFluid(46,73,320,1023);
  }
}
