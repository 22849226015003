.website_get{
    position: relative;
    overflow: hidden;
    background: $tone;
    padding-bottom: clampFluid(107);
    &__hashtag{
        color: $white06;
        width: 100%;
        text-align: center;
        margin-top: clampFluid(55);
        position: relative;
        z-index: 1;
        @include _1279{
            margin-top: 68px;
        }
        @include _1023{
            text-align: start;
            margin-top: 26px;
        }
    }
    &__title{
        width: 100%;
        text-align: center;
        margin-bottom: clampFluid(25);
        margin-top: clampFluid(-21);
        position: relative;
        z-index: 1;
        &.size-100 { @include font-100-5; }
        &.size-80 { 
            @include font-80;
            margin-top: clampFluid(10) !important;
        }
        @include _1279{
            font-size: 58px;
            line-height: 58px;
            margin-top: 3px;
            margin-bottom: 30px;
        }
        @include _1023{
            font-size: clampFluid(30,58,320,1023);
            line-height: clampFluid(30,58,320,1023);
            text-align: start;
            margin-bottom: clampFluid(25,30,320,1023);
        }
    }
    &__items {
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);  
        &.column {
            display: flex;
            flex-direction: column;
        }
        .item {
            display: grid;
            align-items: center;
            grid-template-columns: repeat(2, 1fr);
            padding: clampFluid(23) 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            // gap: 0 clampFluid(60);
            &.size-60 { @include font-60; }
            &.size-44 { @include font-44; }
            &.size-40 { @include font-40; }
            font-style: italic;
            &:not(.column) {
                &:nth-child(odd){
                    margin-right: clampFluid(45);
                    @include _1279{
                        margin-right: 0;
                    }
                }
                &:nth-child(even){
                    margin-left: clampFluid(45);
                    @include _1279{
                        margin-left: 0;
                    }
                }
            } 
            span {
                margin-left: clampFluid(60);
                @include font-21;
                @include _1279{
                    width: 58.5%;
                    line-height: 28.8px;
                }
                @include _1023{
                    width: auto;
                    font-size: clampFluid(14,24,320,1023);
                    line-height: clampFluid(16.8,28.8,320,1023);
                    margin-left: 20px;
                    margin-top: 5px;
                }
            }
            &.column {
                grid-template-columns: 55% 24%;
                justify-content: space-between;
                span {
                    margin-left: 0;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
            // .text{
            //     @include font-21;
            //     @include _1279{
            //         width: 58.5%;
            //         line-height: 28.8px;
            //     }
            //     @include _1023{
            //         width: auto;
            //         font-size: clampFluid(14,24,320,1023);
            //         line-height: clampFluid(16.8,28.8,320,1023);
            //         margin-left: 20px;
            //         margin-top: 5px;
            //     }
            // }
            @include _1279{
                padding: 12px 0;
                border-bottom-color: rgba(255, 255, 255, 0.2);
                display: flex;
                justify-content: space-between;
                text-transform: lowercase;
                &:first-child{
                    padding-top: 0;
                }
                &:last-child{
                    border-bottom: none;
                }
            }
            @include _1023{
                flex-direction: column;
                align-items: flex-start;
                padding-top: 11px;
            }
        }
        @include _1279{
            grid-template-columns: 100%;
            margin: 0px 16px;
        }
        @include _1023{
            margin: 0;
        }
    }
    .bgc_container{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: 100%;
        .element{
            width: clampFluid(448.5);
            height: clampFluid(448.5);
            border-radius: 50%;
            margin-top: clampFluid(calc((448.5/2)*(-1)));
            transition: all 1s ease;
            &.active{
                transform: scale(20);
            }
            @include _1279{
                transform: scale(10);
            }
        }
    }
    .btn_container{
        margin-top: clampFluid(33);
        width: 100%;
        text-align: center;
        @include _1279{
            margin-top: 36px;
        }
        @include _1023{
            margin-top: clampFluid(10,36,320,1023);
            span{
                text-wrap: balance;
                line-height: 18.7px;
            }
        }
    }
    .container{
        @include _1279{
            max-width: 825px;
        }
    }
    @include _1279{
        padding-bottom: 84px;
    }
    @include _1023{
        padding-bottom: clampFluid(35,84,320,1023);
    }
}