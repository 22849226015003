.cost_site{
    position: relative;
    overflow: hidden;
    padding-top: clampFluid(63.5);
    padding-bottom: 80px;
    z-index: 1;
    &.mt {
        z-index: 0;
        margin-top: -50px;
        padding-top: clampFluid(113.5);
    }
    &__hashtag{
        width: 100%;
        text-align: center;
    }
    &__top_title{
        @include font-60;
        margin-top: clampFluid(9);
        width: 100%;
        text-align: center;
        margin-left: clampFluid(16);
        @include _1279{
            margin-left: 0;
            margin-top: 10px;
        }
        @include _1023{
            margin-top: clampFluid(8,10,320,1023);
        }
    }
    &__bottom_title{
        &.font-95 {
            @include font-95;
            margin-top: clampFluid(10);
        }
        &.font-100 {
            @include font-100-5;
            margin-top: clampFluid(-24);
            @include _1279 {
                margin-top: 7px;
            }
            @include _1023 {
                font-size: 30px;
                line-height: 30px;
                max-width: 90%;
                margin-left: auto;
                margin-right: auto;
                margin-top: clampFluid(5,7,320,1023);
            }
        }
        
        width: 100%;
        text-align: center;
        span{
            font-style: italic;
        }
    }
    &__items{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: clampFluid(62) clampFluid(105);
        max-width: 76%;
        margin: clampFluid(58) auto 0 auto;
        position: relative;
        z-index: 0;
        &.el2 {
            grid-template-columns: repeat(2, 1fr);
            max-width: 55%;
        }
        &.el4 {
            grid-template-columns: repeat(2, 1fr);
        }
        &.el7 {
            grid-template-columns: repeat(4, 1fr);
            gap: clampFluid(60) clampFluid(50);
        }
        @include _1279{
            grid-template-columns: repeat(2, 1fr);
            max-width: 100%;
            margin-top: 33px;
            gap: 43px 42px;
        }
        @include _1023{
            grid-template-columns: 100% !important;
            margin-top: clampFluid(20,33,320,1023);
            gap: clampFluid(20,43,320,1023);
        }
    }
    &__item{
        cursor: pointer;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        &.el4 {
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;
        }
        .name{
            @include font-36;
            font-style: italic;
            margin-bottom: clampFluid(4);
            transition: all $transition ease;
            @include _1279{
                font-size: 40px;
                line-height: 44px;
                margin-bottom: 0;
            }
            @include _1023{
                font-size: clampFluid(17,40,320,1023);
                line-height: clampFluid(18.7,44,320,1023);
            }
        }
        .text{
            @include font-21;
            color: $text;
        }
        .cost{
            @include font-36;
            color: $green;
            margin-top: clampFluid(23);
            white-space: nowrap;
            &.mta {
                margin-top: auto;
            }
            @include _1279{
                font-size: 40px;
                line-height: 44px;
                margin-top: 13px;
            }
            @include _1023{
                font-size: clampFluid(17,40,320,1023);
                line-height: clampFluid(18.7,44,320,1023);
                margin-top: 0;
                margin-right: 2px;
            }
        }
        .term {
            @include font-21;
            color: $text;
            margin-top: 4px;
        }
        .group{
            @include _1023{
                margin-right: 25px;
            }
        }
        .image{
            position: absolute;
            top: clampFluid(-31);
            right: clampFluid(-180);
            width: clampFluid(319);
            height: clampFluid(207);
            display: flex;
            align-items: flex-start;
            opacity: 0;
            z-index: -1;
            pointer-events: none;
            transition: opacity $transition ease;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                transition: rotate $transition ease;
            }
            @include _1279{
                display: none !important;
            }
        }
        // &:nth-child(3), &:nth-child(6){
        //     .image{
        //         img{
        //             width: auto;
        //             margin-left: 30px;
        //             direction: rtl;
        //         }
        //     }
        // }
        @include hover{
            z-index: 2;
            .image{
                opacity: 1;
                img{
                    rotate: 12deg;
                }
            }
            &:nth-child(3), &:nth-child(6){
                .image{
                    img{
                        rotate: -12deg;
                    }
                }
            }
            &.not_img {
                .name {
                    color: $green;
                }
            }
        }
        @include _1023{
            display: flex;
            justify-content: space-between;
        }
    }
    &__btn_container {
        width: 100%;
        margin-top: 90px;
        display: flex;
        justify-content: center;
    }
    // &__right_block {
    //     background: $tone;
    //     padding: 32px 21px 30px;
    //     display: flex;
    //     flex-direction: column;
    //     .title {
    //         text-align: center;
    //         text-transform: uppercase;
    //         &.font82 {
    //             @include font-82;
    //         }
    //         &.font82-57 {
    //             @include font-82;
    //             span {
    //                 display: block;
    //                 @include font-52;
    //             }
    //         }
    //     }
    //     .text {
    //         width: 100%;
    //         text-align: center;
    //         @include font-36;
    //         margin-bottom: 38px;
    //         margin-top: auto;
    //     }
    //     .btn_container {
    //         width: 100%;
    //         display: flex;
    //         justify-content: center;
    //         span {
    //             white-space: nowrap;
    //         }
    //     }
    //     &.grid4 {
    //         grid-column: 3/4;
    //         grid-row: 1/3;
    //     }
    // }
    &.last{
        border-radius: 0 0 clampFluid(60) clampFluid(60);
        background: linear-gradient(180deg, #121B24 62%, #212D37 100%);
    }
    .background_line{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    .container{
        position: relative;
        z-index: 1;
    }
    @include _1279{
        padding-top: 24px;
        padding-bottom: 86px;
    }
    @include _1023{
        padding-top: clampFluid(1,24,320,1023);
        padding-bottom: clampFluid(30,86,320,1024);
    }
}
