@use "sass:math";

@function calcFluid($f-min, $f-max, $v-min: 320px, $v-max: 1279px) {
	$k: math.div($f-max - $f-min, $v-max - $v-min);
	$b: $f-min - $k * $v-min;

	$b: $b * 1px;

	@return calc(#{$k} * 100vw + #{$b});
}
@function r($sizePX, $baseSizePX: 15) {
	@return #{math.div($sizePX, $baseSizePX)}rem;
}

@mixin calcFluid($f-min, $f-max, $property, $v-min: 320, $v-max: 1279) {
	--k: calc(math.div((#{$f-max} - #{$f-min}),(#{$v-max} - #{$v-min})));
	--b: calc((#{$f-min} - var(--k) * #{$v-min}) * 1px);

	#{$property}: calc(var(--k) * 100vw + var(--b));
}

@function clampFluid($clamp-min, $clamp-max: 'None', $calc-1: 'None', $calc-2: 'None', $w-min: 'None', $w-max: 'None'){
    @if $clamp-max == 'None'{
        $clamp-max: $clamp-min;
        $clamp-min: $clamp-max * math.div(2, 3);
    };
    $clamp-min-rend: 'None';
    $clamp-max-rend: 'None';
    $w: math.div($clamp-max, 1920) * 100 * 1vw;
//     @if $clamp-min == 0{
//         $clamp-min: 0.001;
//     }
//     @if $clamp-max == 0{
//         $clamp-max-rend: 0.001;
//     }
    @if $clamp-max < $clamp-min{
        $clamp-min-rend: $clamp-max * 1px;
        $clamp-max-rend: $clamp-min * 1px;
    }
    @else {
        $clamp-max-rend: $clamp-max * 1px;
        $clamp-min-rend: $clamp-min * 1px;
    }
    @if $calc-1 == 'None'{
        @return clamp($clamp-min-rend, $w, $clamp-max-rend);
    };
    @if $w-min == 'None'{
        $k: math.div(($clamp-max - $clamp-min), ($calc-2 - $calc-1));
        $b: $clamp-min - $k * $calc-1;
        $b: $b * 1px;
        @return clamp($clamp-min-rend, calc(#{$k} * 100vw + #{$b} ), $clamp-max-rend);
    };
    @if $w-max != 'None'{
        $k: math.div(($calc-2 - $calc-1), ($w-max - $w-min));
        $b: $calc-1 - $k * $w-min;
        $b: $b * 1px;
        @return clamp($clamp-min-rend, calc(#{$k} * 100vw + #{$b} ), $clamp-max-rend);
    }
}
@mixin ph($hoverPH) {
	&.placeholder { @content; }
	&:-moz-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
	&::-webkit-input-placeholder { @content; }
	&:hover::placeholder{color:#{$hoverPH};}
}
@mixin hover {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent; // убирает стандартное подсвечивание элемента при касании на устройствах iOS
    touch-action: manipulation; // озволяет браузеру оптимизировать обработку событий касания, например, предотвращая двойной тап для зума
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
    @media (hover: none), (hover: on-demand) { // рименяются на устройствах, не поддерживающих наведение или поддерживающих его по запросу, что характерно для тачскринов
        &:active {
            @content;
        }
    }
}
@mixin container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: clampFluid(1725);
    @include _1279{
        max-width: 100%;
        padding: 0 56px;
    }
    @include _1023{
        padding: 0 clampFluid(18,56,320,1023);
    }
}
@mixin case_container {
    max-width: 1920px;
    width: 59.4%;
    margin-left: auto;
    margin-right: auto;
    @include _1279 {
        padding: 0;
    }
    @include _1023 {
        width: 100%;
        padding: 0 20px;
    }
}
// Media
@mixin _1920 { @media (max-width: 1920px) { @content; }}
@mixin _1920min { @media (min-width: 1920px) { @content; }}
@mixin _1440 { @media (max-width: 1440px) { @content; }}
@mixin _min1280{ @media ( min-width: 1280px ) { @content; }}
@mixin _1279{ @media ( max-width: 1279px ) { @content; }}
@mixin _1200 { @media (max-width: 1200px) { @content; }}
@mixin _1024 { @media (max-width: 1024px) { @content; }}
@mixin _1023{ @media ( max-width: 1023px ) { @content; }}
@mixin _991 { @media (max-width: 991px) { @content; }}
@mixin _991min { @media (min-width: 991px) { @content; }}
@mixin _930 { @media (max-width: 930px) { @content; }}
@mixin _850 { @media (max-width: 850px) { @content; }}
@mixin _768 { @media (max-width: 768px) { @content; }}
@mixin _767{ @media ( max-width: 767px ) {@content;}}
@mixin _670 { @media (max-width: 670px) { @content; }}
@mixin _669{ @media ( max-width: 669px ) {@content;}}
@mixin _650 { @media (max-width: 650px) { @content; }}
@mixin _600 { @media (max-width: 600px) { @content; }}
@mixin _550{ @media ( max-width: 550px ) {@content;}}
@mixin _500 { @media (max-width: 500px) { @content; }}
@mixin _470 { @media (max-width: 470px) { @content; }}
@mixin _425 { @media (max-width: 425px) { @content; }}
@mixin _424 { @media (max-width: 424px) { @content; }}
@mixin _375 { @media (max-width: 375px) { @content; }}
@mixin _369 { @media (max-width: 369px) { @content; }}
@mixin _320 { @media (max-width: 320px) { @content; }}