.content-result-case {
    .content-text {
        p {
            color: $text;
        }
    }
    .repeater-items {
        display: flex;
        flex-wrap: wrap;
        margin-top: clampFluid(54);
        gap: clampFluid(30) clampFluid(15);
    }
    .repeater-item {
        position: relative;
        width: clampFluid(270);
        .value {
            @include font-60;
            font-style: italic;
            margin-bottom: clampFluid(16.5);
        }
        .text {
            @include font-21;
        }
        svg {
            width: clampFluid(134);
            height: clampFluid(118);
            fill: $tone2;
            position: absolute;
            top: clampFluid(-20);
            left: clampFluid(-25);
            z-index: -1;
            @include _1279 {
                width: clampFluid(56,89.3,320,1279);
                height: clampFluid(49,78.6,320,1279);
            }
        }
    }
}