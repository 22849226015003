.page_404 {
    margin-top: clampFluid(96);
    // margin-bottom: clampFluid(100);
    padding-bottom: clampFluid(100);
    h1 {
        @include font-100-5;
        margin-bottom: clampFluid(20);
        @include _1279 {
            margin-bottom: 16px;
        }
        @include _1023 {
            font-size: 30px;
            line-height: 33px;
        }
    }
    .text {
        @include font-21;
        color: $text;
        margin-bottom: clampFluid(64);
        @include _1023 {
            margin-bottom: clampFluid(16,42.6,320,1023);
        }
    }
    &__container {
        display: flex;
        justify-content: space-between;
        .left {
            width: 39%;
            @include _1279 {
                width: 100%;
            }
        }
        .right {
            img {
                height: clampFluid(579);
                width: clampFluid(947);
                object-fit: cover;
                object-position: bottom;
                @include _1279 {
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 947px;
                    max-height: 579px;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            @include _1279 {
                width: 100%;
            }
        }
        @include _1279 {
            flex-direction: column-reverse;
        }
    }
    @include _1023 {
        padding-bottom: 40px;
    }
}