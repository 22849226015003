.advantages{
    position: relative;
    z-index: 1;
    margin-top: clampFluid(36);
    padding-bottom: clampFluid(83.5);
    overflow: hidden;
    &__name{
        @include font-100-5;
        @include _1279{
            font-size: 80px;
            line-height: 72px;
            white-space: nowrap;
        }
        @include _1023{
            font-size: clampFluid(30,80,320,1023);
            line-height: clampFluid(30,72,320,1023);
        }
    }
    &__text_name{
        @include font-25-5;
        max-width: clampFluid(225);  
        margin-left: clampFluid(15);
        margin-top: clampFluid(-8);
        &.item4 {
            max-width: clampFluid(260);
        }
        @include _1279{
            font-size: 24px;
            line-height: 28.8px;
            margin-top: 3px;
            max-width: max-content;
            width: 100%;
        }
        @include _1023{
            font-size: clampFluid(14,24,320,1023);
            line-height: clampFluid(16.8,28.8,320,1023);
            margin-left: 0;
            margin-top: clampFluid(6,3,320,1023);
        }
    }
    &__title2 {
        @include font-60;
        display: flex;
        flex-direction: column;
        margin-top: clampFluid(23);
        span{
            font-style: italic;
            color: $green;
        }
        @include _1279{
            margin-top: 61px;
        }
        @include _1023{
            margin-top: 0;
        }
    }
    &__items{
        display: flex;
        span{
            color: $green;
        }
        .item{
            display: flex;
            flex-direction: column;
            &:nth-child(2){
                padding: 0 clampFluid(67.5);
                border-left: 1px solid $line;
                border-right: 1px solid $line;
                margin: 0 clampFluid(67.5);
                @include _1279{
                    padding: 0 51px;
                    margin: 0 51px;
                }
                @include _1023{
                    border: none;
                    margin: 0;
                    padding: 0 clampFluid(32,51,320,1023);
                }
            }
            &:not(.item4):last-child{
                margin-right: clampFluid(5);
                @include _1279{
                    margin-right: 0;
                }
            }
            &.item2 {
                padding: 0 clampFluid(25);
                margin: 0 clampFluid(25);
            }
            &.item3 {
                border-right: 1px solid $line;
                padding-right: clampFluid(25);
                margin-right: clampFluid(25);
            }
        }
        @include _1279{
            width: min-content;
            margin: 33px auto 0;
        }
        @include _1023{
            margin-top: clampFluid(28,33,320,1023);
        }
    }
    &__container{
        display: flex;
        justify-content: space-between;
        @include _1279{
            flex-direction: column;
        }
        @include _1023{
            margin-top: 14px;
        }
    }
    .bgc{
        font-size: clampFluid(270);
        line-height: clampFluid(243);
        color: $tone2;
        position: absolute;
        bottom: clampFluid(-29);
        left: 43px;
        right: 43px;
        z-index: -1;
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        font-weight: 900;
        font-style: normal;
        font-variation-settings: "slnt" 0;
        div{
            &:first-child{
                user-select: none;
                text-rendering: optimizeSpeed;
                text-transform: uppercase;
                &.flex {
                    display: flex;
                    justify-content: space-between;
                }
                @include _1279{
                    display: none !important;
                }
            }
        }
        .mob{
            display: none;
            font-size: 230px;
            line-height: 207px;
            text-align: center;
            @include _1279{
                display: block;
            }
            @include _1023{
                font-size: clampFluid(90,230,320,1023);
                line-height: clampFluid(81,207,320,1023);
            }
        }
        @include _1279{
            bottom: -21px;
        }
        @include _1023{
            left: clampFluid(9,43,320,1023);
            right: clampFluid(9,43,320,1023);
            bottom: clampFluid(-8,-21,320,1023);
        }
    }
    @include _1279{
        padding-bottom: 67px;
    }
    @include _1023{
        overflow: visible;
        margin-top: clampFluid(-1,24,320,1023);
        padding-bottom: clampFluid(53,67,320,1023);
    }
}