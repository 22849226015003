.content-image {
  display: flex;
  justify-content: center;
  // padding-top: clampFluid(50);
  // padding-bottom: clampFluid(60);
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: clampFluid(20);
    // margin-bottom: clampFluid(60);
    // margin-top: clampFluid(50);
    // margin-left: auto;
    // margin-right: auto;
    object-fit: contain;
    &.original_size {
      width: auto;
    }
  }
}