.scroll_place {
    padding-top: clampFluid(78);
    padding-bottom: clampFluid(175);
    position: relative;
    z-index: 1;
    &.brb {
        border-radius: 0 0 clampFluid(60) clampFluid(60);
        overflow: hidden;
    }
    .background_line {
        z-index: -1;
    }
    &__title {
        @include font-100-5;
        line-height: clampFluid(100.5);
        text-align: center;
        text-wrap: balance;
        span {
            font-style: italic;
        }
    }
    &__container {
        display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(clampFluid(553), 1fr));
        grid-template-columns: repeat(3, 1fr);
        gap: clampFluid(30);
        margin-top: clampFluid(91);
        @include _1279 {
            grid-template-columns: 100%;
            gap: 34px;
        }
    }
    &__item {
        background: $tone;
        border: 1px solid $line;
        border-radius: clampFluid(20);
        padding-right: clampFluid(20);
        padding-bottom: clampFluid(40);
        padding-left: clampFluid(30);
        display: flex;
        flex-direction: column;
        transition: all $transition ease;
        .numb {
            @include font-100-5;
            line-height: clampFluid(100.5);
            color: $green;
            font-style: italic;
            margin-bottom: clampFluid(15);
            margin-top: clampFluid(-55);
            @include _1023 {
                font-size: 50px;
                line-height: 50px;
                margin-top: clampFluid(-25,-36,320,1023);
            }
        }
        .name {
            @include font-36;
            margin-bottom: auto;
            @include _1023 {
                line-height: clampFluid(18.7,31,320,1023);
            }
        }
        .text {
            @include font-18;
            color: $white05;
            margin-top: clampFluid(40);
        }
        &.active {
            background: var(--active-color);
            border-color: var(--active-color);
            transform: translateY(clampFluid(-30));
            @include _1279 {
                transform: none;
            }
        }
    }
    @include _1279 {
        padding-top: clampFluid(41,52,320,1279);
        padding-bottom: clampFluid(50,116,320,1279);
    }
}
