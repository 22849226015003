.content-text {
  h1 {
    @include font-100;
    margin-bottom: clampFluid(60);
    @include _1023 {
      margin-bottom: clampFluid(16,40,320,1023);
    }
  }
  h2 {
    @include font-60;
    margin-bottom: clampFluid(25);
    margin-top: clampFluid(50);
    @include _1023 {
      margin-bottom: clampFluid(16,40,320,1023);
    }
  }
  h3 {
    @include font-36;
    margin-top: clampFluid(50);
    margin-bottom: clampFluid(25);
    @include _1023 {
      margin-top: 36px;
      margin-bottom: clampFluid(12,16,320,1023);
    }
  }
  h4 {
    @include font-25-5;
    font-weight: 400;
    margin-top: clampFluid(50);
    margin-bottom: clampFluid(25);
    @include _1023 {
      margin-top: 36px;
      margin-bottom: clampFluid(12,16,320,1023);
    }
  }
  p {
    margin-bottom: clampFluid(25);
    &.caption {
      font-size: clampFluid(18);
      line-height: clampFluid(23.4);
      color: $text;
      @include _1279 {
        font-size: 12px;
        line-height: 14.67px;
      }
    }
    @include _1023 {
      margin-bottom: clampFluid(14,16,320,1023);
    }
  }
  ul, ol, a, p{
    @include font-21;
  }
  ul, ol {
    margin-bottom: clampFluid(25);
    padding-left: clampFluid(42);
    li {
      margin-bottom: clampFluid(16);
      ul, ol {
        margin-top: clampFluid(25);
        @include _1023 {
          margin-top: clampFluid(8,11,320,1023);
        }
      }
      @include _1023 {
        margin-bottom: clampFluid(8,11,320,1023);
      }
    }
  }
  ul {
    li {
        position: relative;
        list-style-type: disc;
        &::marker{
            color: $green;
        }
    }
  }
  ol {
      list-style: decimal;
  }
  a {
    color: $green;
    &:not(:visited) {
      text-decoration: underline;
    }
    transition: all $transition ease;
    @include hover {
        text-decoration: none;
    }
    &:visited {
        color: #406AFF;
        // text-decoration: none !important;
    }
  }
  img {
    display: block;
    margin: clampFluid(60) auto;
    max-width: 100%;
    width: auto !important;
    height: auto !important;
  }
  .table {
    overflow: auto;
    margin-bottom: 20px;
  }
  table {
    min-width: 100%;
    overflow: hidden;
    border-collapse: collapse;
    border: 1px solid $line;
    td,
    th {
      padding: clampFluid(25) clampFluid(36);
      @include font-21;
      border: 1px solid $line;
    }
    tr {
      &:first-child {
        td {
          @include font-28;
          background: $line;
          &:not(:last-child) {
            border-right: 1px solid #121B24;
          }
        }
      }
    }
  }

  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
}
