.tariff {
    position: relative;
    padding-bottom: 100px;
    z-index: 1;
    &.mt {
        z-index: 0;
        margin-top: -50px;
    }
    &__head {
        position: relative;
        padding-top: clampFluid(57);
        padding-bottom: clampFluid(87);
        background: var(--head-color);
        &.mt {
            padding-top: clampFluid(107);
        }
        @include _1279 {
            background: transparent;
            // padding-top: 0;
            padding-bottom: clampFluid(31,58,320,1279);
        }
    }
    &__hashtag {
        width: 100%;
        text-align: center;
        margin-bottom: clampFluid(15);
    }
    &__title {
        width: 80%;
        margin: 0 auto;
        text-align: center;
        @include font-80;
        @include _1023 {
            width: 100%;
        }
    }
    &__items {
        // display: grid;
        display: flex;
        justify-content: center;
        // width: 80%;
        // width: fit-content;

        margin: 0 auto;
        position: relative;
        padding-top: clampFluid(39);
        &.items_3 {
            // grid-template-columns: repeat(3, 1fr);
            gap: clampFluid(27);
            @include _1279 {
                // grid-template-columns: 100%;
                max-width: 600px;
                width: 100%;
                gap: 45px;
            }
        }
        &.items_2 {
            // grid-template-columns: repeat(2, 1fr);
            gap: clampFluid(53);
            width: 60%;
            @include _1279 {
                // grid-template-columns: 100%;
                max-width: 600px;
                width: 100%;
            }
        }
        @include _1279 {
            padding-top: 0;
            flex-direction: column;
        }
    }
    &__item {
        border: 1px solid $text;
        border-radius: clampFluid(20);
        padding-left: clampFluid(30);
        padding-right: clampFluid(30);
        padding-top: clampFluid(17);
        padding-bottom: clampFluid(67);
        position: relative;
        width: min-content;
        min-width: clampFluid(400);
        &.items_2 {
            height: fit-content;
        }
        .name {
            width: 100%;
            text-align: center;
            @include font-36;
            font-style: italic;
            margin-top: clampFluid(12);
            @include _1279 {
                margin-top: 0;
            }
            @include _1023 {
                font-size: clampFluid(20,36,320,1023);
                line-height: clampFluid(22,39.6,320,1023);
            }
        }
        .cost {
            width: 100%;
            text-align: center;
            @include font-60;
            font-style: italic;
            color: $green;
            margin-top: clampFluid(9);
            white-space: nowrap;
            &.bottom_line {
                border-bottom: 1px solid $text;
                margin-bottom: clampFluid(28);
                padding-bottom: clampFluid(19);
            }
            &.font-36 {
                @include font-36;
                font-style: italic;
                color: $green;
                @include _1023 {
                    // font-size: clampFluid(20,36,320,1023);
                    // line-height: clampFluid(22,39.6,320,1023);
                    font-size: clampFluid(18,36,320,1023);
                    line-height: clampFluid(20,39.6,320,1023);
                }
            }
            span {
                @include font-21;
                color: $text
            }
            &__plus {
                width: 100%;
                text-align: center;
                @include font-40;
                font-style: italic;
                color: $green;
                white-space: nowrap;
                &.bottom_line {
                    border-bottom: 1px solid $text;
                    margin-bottom: clampFluid(28);
                    padding-bottom: clampFluid(19);
                }
            }
        }
        .points {
            .list {
                @include font-21;
                >ul {
                    margin-left: clampFluid(25);
                }
                ol, ul {
                    li {
                        span {
                            color: $green;
                        }
                        margin-bottom: clampFluid(14);
                    }
                    >li {
                        position: relative;
                        &::before {
                            content: '';
                            position: absolute;
                            top: clampFluid(-5);
                            left: clampFluid(-37);
                            width: clampFluid(45);
                            height: clampFluid(43);
                            background: url('data:image/svg+xml;utf8,<svg viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d_2047_3468)"><path d="M19.3005 20.482C20.4153 18.3181 21.4732 16.8184 22.9455 15.1953C25.278 12.6244 28.4108 10.3881 31.6601 9.02105C31.9231 8.91046 32.1289 9.26325 31.9034 9.43724C26.244 13.7951 22.6122 18.8538 19.9624 25.7864C19.8113 26.1822 19.259 26.2101 19.068 25.8318C17.3781 22.483 16.1365 20.0342 13.1894 18.1431C12.8858 17.9481 12.9621 17.4891 13.312 17.3989C15.7768 16.7639 17.43 18.101 19.3005 20.482Z" fill="%23C0FF00"/></g><defs><filter id="filter0_d_2047_3468" x="0.510532" y="0.56391" width="43.9789" height="42.0785" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4.05338"/><feGaussianBlur stdDeviation="6.24473"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0.751724 0 0 0 0 1 0 0 0 0 0 0 0 0 1 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2047_3468"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2047_3468" result="shape"/></filter></defs></svg>') no-repeat center center;
                            background-size: contain;
                            @include _1279 {
                                width: 45px;
                                height: 43px;
                                top: -5px;
                                left: -37px;
                            }
                        }
                    }
                    ol, ul {
                        li {
                            &::before {
                                content: '';
                                display: none;
                            }
                            color: $text;
                        }
                    }
                }
                p {
                    &:not(:first-child) {
                        margin-top: clampFluid(14);
                    }
                    span {
                        color: $green;
                    }
                }
                div {
                    margin-top: clampFluid(26);
                    color: $text;
                }
                @include _1023 {
                    font-size: 14px;
                    line-height: 15.4px;
                }
            }
        }
        .point_text {
            @include font-21;
            margin-left: clampFluid(-5);
        }
        .btn_container {
            position: absolute;
            bottom: clampFluid(-34);
            left: 0;
            right: 0;
            width: 100%;
            display: flex;
            justify-content: center;
        }
        &.items_3 {
            &:nth-child(even) {
                background: $tone;
                margin-top: clampFluid(-77);
                height: fit-content;
                @include _1279 {
                    margin-top: 0;
                }
            }
            @include _1279 {
                &.item-2 {
                    grid-row: 3/4;
                }
            }
        }
        @include _1279 {
            min-width: 0;
            width: 100%;
        }
    }
    &__bottom_text {
        margin-top: clampFluid(60);
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        @include font-25-5;
        color: $text;
        @include _1023 {
            font-size: clampFluid(12,17,320,1023);
            line-height: clampFluid(14.4,18.7,320,1023);
            text-align: center;
        }
    }
    &.last {
        border-radius: 0 0 clampFluid(60) clampFluid(60);
        overflow: hidden;
    }
    .background_line {
        z-index: -1;
    }
}